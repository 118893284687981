/* eslint-disable operator-linebreak */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { authenticateUser } from '../../features/auth/authSlice';
import LoginForm from './LoginForm';
import MFAForm from './MFAForm';
import { LOGIN } from '../../constants';
import { handleOpenEulaModal, logEventToBackEnd } from '../../features/global/globalSlice';

interface FormInput {
    username: string;
    password: string;
    rememberMe: boolean;
}

const LoginContainer = () => {
    const [currentScreen, setCurrentScreen] = useState<'login' | 'MFA'>('login');
    const { oktaAuth, authState } = useOktaAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAuthenticated = authState?.isAuthenticated;
    const authLoading = useAppSelector((state) => state.auth.loading);
    const [currentLogginEmail, setCurrentLogginEmail] = useState<string>('');
    const [shouldRememberUsername, setshouldRememberUsername] = useState<boolean>(false);

    useEffect(() => {
        if (isAuthenticated && !authLoading) {
            navigate('/clients', { replace: true });
        }
    }, []);

    const navigatetoclients = () => {
        navigate('/clients');
    };

    const onSubmit = async (values: FormInput) => {
        const sessionExists = await oktaAuth.session.exists();
        if (sessionExists) {
            oktaAuth.clearStorage();
            oktaAuth.closeSession();
        }
        const res: any = await dispatch(authenticateUser({ oktaAuth, ...values }));

        if (res.payload.status === LOGIN.MFA_CHALLENGE || res.payload.status === 'MFA_ENROLL_ACTIVATE') {
            setCurrentScreen('MFA');
            setCurrentLogginEmail(values?.username);
            setshouldRememberUsername(values?.rememberMe);
        }

        if (res.payload.status === LOGIN.SUCCESS) {
            //Login to remember username
            values?.rememberMe
                ? localStorage.setItem('GOE_APP_REMEMBERED_EMAIL', values?.username)
                : localStorage.removeItem('GOE_APP_REMEMBERED_EMAIL');
            // if (res?.payload?.user?.freemium) {
            //     navigate('/add-goal');
            // } else

            dispatch(logEventToBackEnd('LOGIN_SUCCESS'));
            if (
                // res?.payload?.user?.acceptedEulaVersion !== res?.payload?.user?.eula?.version ||
                res?.payload?.user?.acceptedUserAgreementVersion !== res?.payload?.user?.userAgreement?.version
            ) {
                navigatetoclients();
                dispatch(handleOpenEulaModal(true));
            } else {
                navigatetoclients();
            }
        }
    };

    switch (currentScreen) {
        case LOGIN.MFA:
            return (
                <MFAForm
                    currentLogginEmail={currentLogginEmail}
                    goBackToLogin={() => {
                        setCurrentScreen('login');
                    }}
                    rememberMe={shouldRememberUsername}
                />
            );
        default:
            return <LoginForm onSubmit={onSubmit} />;
    }
};

export default LoginContainer;
