import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

interface CheckboxComponentProps extends CheckboxProps {
    label?: string;
}

const StyledCheckbox = styled(Checkbox)<CheckboxProps>(({ theme, ...props }) => ({
    color: theme.palette.grey[600],
    '.MuiSvgIcon-root': {
        color: props.id === 'weath-splitter' ? 'black !important' : ''
    }
}));

function CheckboxComponent(props: CheckboxComponentProps) {
    return (
        <FormGroup>
            <FormControlLabel control={<StyledCheckbox {...props} />} label={props.label} />
        </FormGroup>
    );
}

export default CheckboxComponent;
