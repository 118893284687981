import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';

import GlobalLoader from '../components/molecules/global-loader';
import { useAppSelector } from '../app/hooks';

const allowedPathsForAdvisor = [
    '/clients',
    '/personalized-plan',
    '/add-client',
    '/add-goal',
    '/edit-goal',
    '/client-profile'
];

export const RequiredAuth: React.FC = () => {
    const okta = useOktaAuth();
    const navigate = useNavigate();
    const isAuthenticated = okta?.authState?.isAuthenticated;
    const user = useAppSelector((state) => state.auth.user);

    useEffect(() => {
        if (okta?.authState && !isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.origin, window.location.href);
            okta?.oktaAuth.setOriginalUri(originalUri);
            navigate(originalUri);
        }
    }, [okta?.authState, isAuthenticated]);

    if (!isAuthenticated) {
        return <GlobalLoader open />;
    }

    if (user.userRole !== 'admin' && !allowedPathsForAdvisor.includes(window.location.pathname)) {
        return <Navigate to="/clients" />;
    }

    return <Outlet />;
};
