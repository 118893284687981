import { styled, Typography, typographyClasses, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import FinancialAdvisor from '../../components/organisms/financialAdvisors';
import {
    updateFinancialAdvisorDeleteModalState,
    fetchAdminsAndAdvisors,
    deleteUser
} from '../../features/advisor-admin';

import { updateGlobalLoaderState } from '../../features/global/globalSlice';

import { updateSelectedUser, updateSelectedFinancialAdvisorClients } from '../../features/advisor-admin';

import { FINANCIAL_ADVISORS_ACCESS_KEYS } from '../../constants';
import { StyledTableContainer } from '../../components/organisms/financialAdvisors/financialAdvisorTable';

const StyledTypography = styled(Typography)(() => ({
    [`&.${typographyClasses.root}`]: {
        fontSize: '22px',
        lineHeight: '16px',
        fontWeight: 600,
        color: '#000000',
        margin: '30px 0 20px 0'
    }
}));

export default function FinancialAdvisors() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const tenant = useAppSelector((state: any) => state.auth.user.tenant);

    const financialAdvisors = useAppSelector((state) => state.advisorAdmin.financialAdvisors);
    const showFinancialAdvisorRemoveModal = useAppSelector(
        (state) => state.advisorAdmin.showFinancialAdvisorRemoveModal
    );
    const advisorEmailId = useAppSelector((state) => state.advisorAdmin.advisorEmailId);
    const clients = useAppSelector((state) => state.advisorAdmin.clients);
    const loading = useAppSelector((state) => state.advisorAdmin.loading);

    useEffect(() => {
        dispatch(fetchAdminsAndAdvisors());
    }, []);

    const [deleteModalState, setDeleteModalState] = useState(false);

    useEffect(() => {
        setDeleteModalState(showFinancialAdvisorRemoveModal as any);
    }, [advisorEmailId, showFinancialAdvisorRemoveModal]);

    const handleCloseDeleteModal = () => {
        dispatch(updateFinancialAdvisorDeleteModalState(false));
    };

    const handleRemove = (advisor: any) => {
        dispatch(updateFinancialAdvisorDeleteModalState(advisor));
    };

    const handleRemoveFinancialAdvisor = async () => {
        dispatch(updateGlobalLoaderState(true));

        const userId: string = (showFinancialAdvisorRemoveModal as any).id;
        await dispatch(deleteUser({ userId, tenant }));
        setDeleteModalState(false);
        dispatch(updateFinancialAdvisorDeleteModalState(false));

        dispatch(updateGlobalLoaderState(false));
    };

    const handleAdminAdvisorNavigate = async (advisor: any) => {
        if (!isEmpty(clients)) {
            const payloadData = await clients.filter((value) => value.advisorEmail === advisor.advisorEmail);
            dispatch(updateSelectedFinancialAdvisorClients(payloadData));
        }
        dispatch(updateSelectedUser(advisor));
        navigate('/user');
    };

    const StyledLink = styled(Link)(() => ({
        textDecoration: 'none',
        cursor: 'pointer'
    }));

    const columns = [
        {
            accessorKey: FINANCIAL_ADVISORS_ACCESS_KEYS.ADVISOR_NAME,
            id: FINANCIAL_ADVISORS_ACCESS_KEYS.ADVISOR_NAME,
            header: t('FINANCIAL_ADVISORS_TABLE_ADVISOR_NAME_COLUMN'),
            cell: ({ row }: any) => (
                <StyledLink onClick={() => handleAdminAdvisorNavigate(row.original)}>
                    {`${row.original.advisorName}`}
                </StyledLink>
            )
        },
        {
            accessorKey: FINANCIAL_ADVISORS_ACCESS_KEYS.ADVISOR_EMAIL,
            id: FINANCIAL_ADVISORS_ACCESS_KEYS.ADVISOR_EMAIL,
            header: t('FINANCIAL_ADVISORS_TABLE_ADVISOR_EMAIL_COLUMN'),
            cell: ({ getValue }: any) => getValue()
        },
        {
            accessorKey: FINANCIAL_ADVISORS_ACCESS_KEYS.NO_OF_CLIENTS,
            id: FINANCIAL_ADVISORS_ACCESS_KEYS.NO_OF_CLIENTS,
            header: t('FINANCIAL_ADVISORS_TABLE_ADVISOR_NO_OF_CLIENTS_COLUMN'),
            cell: ({ getValue }: any) => getValue()
        },
        {
            accessorKey: FINANCIAL_ADVISORS_ACCESS_KEYS.NO_OF_GOALS,
            id: FINANCIAL_ADVISORS_ACCESS_KEYS.NO_OF_GOALS,
            header: t('FINANCIAL_ADVISORS_TABLE_ADVISOR_NO_OF_GOALS_COLUMN'),
            cell: ({ getValue }: any) => getValue()
        },
        {
            accessorKey: FINANCIAL_ADVISORS_ACCESS_KEYS.REMOVE,
            id: 'remove',
            header: t('FINANCIAL_ADVISORS_TABLE_ADVISOR_REMOVE_COLUMN'),
            enableSorting: false,
            cell: ({ row }: any) => (
                <StyledLink onClick={() => handleRemove(row.original)}>
                    {t('TEXT_ADMIN_USER_MANAGEMENT_REMOVE_BUTTON')}
                </StyledLink>
            )
        }
    ];

    return (
        <>
            <StyledTypography variant="h2">{t('FINANCIAL_ADVISORS_TABLE_HEADER')}</StyledTypography>

            {loading ? (
                <StyledTableContainer
                    sx={{
                        minHeight: '440px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress />
                </StyledTableContainer>
            ) : (
                <FinancialAdvisor
                    financialAdvisors={financialAdvisors}
                    deleteModalState={deleteModalState}
                    advisorSelected={showFinancialAdvisorRemoveModal}
                    columns={columns}
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    handleRemoveFinancialAdvisor={handleRemoveFinancialAdvisor}
                />
            )}
        </>
    );
}
