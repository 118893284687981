import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { formatClientName, returnTableCellValue } from '../../utils/table';
import Header from './header';
import ClientInfo from './clientInfo';
import AllGoals from './allGoals';
import SingleGoal from './singleGoal';
// import Charts from './charts';
import ContactAdvisor from './contactAdvisor';
import { updatePDFLoading } from '../../features/client-summary';
import BanorteDisclaimer from './BanorteDisclaimer';

const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1440px',
    maxHeight: '90%',
    overflow: 'scroll',
    backgroundColor: 'common.white',
    boxShadow: 24,
    color: 'common.black',
    opacity: '0'
};

const PDFWrapper = styled('div')(() => ({
    margin: 0,
    minWidth: '1440px'
}));

const InnerWrapper = styled('div')(() => ({
    padding: '20px 20px 10px 20px'
}));

const PDFModal = ({
    open,
    handleClose,
    clientSummary,
    updateProfolioCompositionData,
    portfolioData,
    portfolioComposistionChartData,
    portfolioComposistionEquityValue,
    portfolioComposistionFixedIncomeValue,
    portfolioCompositionYearLabel
}: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const enableSourceCaveatNumbers = useAppSelector((state) =>
        Boolean(state.global.orgInfo?.enableSourceCaveatNumbers)
    );
    const tenantId = useAppSelector((state) => state?.global?.globalConfig?.subdomain);

    useEffect(() => {
        async function generate() {
            const input = document.querySelectorAll('.pdf') as NodeListOf<HTMLElement>;
            const len = input.length;
            // eslint-disable-next-line
            const doc = new jsPDF('p', 'mm', 'a4', true);
            for (let i = 0; i < len; i += 1) {
                // eslint-disable-next-line
                await html2canvas(input[i], {
                    logging: true,
                    scale: 1,
                    useCORS: true
                }).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = 210;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 0;
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                        heightLeft -= pageHeight;
                    }
                    if (i + 1 < len) {
                        doc.addPage();
                    } else {
                        window.open(doc.output('bloburl'), '_blank');
                        // doc.save('goe-output.pdf');
                        dispatch(updatePDFLoading(false));
                        handleClose();
                    }
                });
            }
        }
        if (open) {
            generate().catch(console.error);
        }
    });

    if (!open) return null;

    return (
        <div>
            <Box sx={boxStyle}>
                <PDFWrapper>
                    <InnerWrapper
                        className="pdf"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <Header clientName={formatClientName(clientSummary)} />
                        <ClientInfo
                            clientEmail={clientSummary.clientEmail}
                            externalData={clientSummary.externalData}
                            enableSourceCaveatNumbers={enableSourceCaveatNumbers}
                            portfolioValue={sum(returnTableCellValue(clientSummary, 'goalAmount'))}
                        />

                        <AllGoals formFields={clientSummary.formFields} />

                        <div style={{ padding: '0 20px' }}>
                            <Typography>{parse(t('TEXT_PDF_TOP_DISCLAIMER'))}</Typography>
                        </div>
                    </InnerWrapper>
                    {clientSummary.formFields.map((formField: object, key: number) => (
                        <InnerWrapper className="pdf" key={key}>
                            <SingleGoal
                                key={key}
                                externalData={clientSummary.externalData}
                                formField={formField}
                                response={clientSummary.response[key]}
                                request={clientSummary.request[key]}
                                portfolioData={portfolioData}
                                updateProfolioCompositionData={updateProfolioCompositionData}
                                portfolioComposistionChartData={portfolioComposistionChartData}
                                portfolioComposistionEquityValue={portfolioComposistionEquityValue}
                                portfolioComposistionFixedIncomeValue={portfolioComposistionFixedIncomeValue}
                                portfolioCompositionYearLabel={portfolioCompositionYearLabel}
                            />
                        </InnerWrapper>
                    ))}
                    <InnerWrapper className="pdf">
                        <ContactAdvisor
                            advisorName={clientSummary.AdvisorName}
                            advisorEmail={clientSummary.advisorEmail}
                        />
                    </InnerWrapper>
                    {tenantId === 'banorte' && (
                        <InnerWrapper className="pdf">
                            <BanorteDisclaimer />
                        </InnerWrapper>
                    )}
                </PDFWrapper>
            </Box>
        </div>
    );
};

export default PDFModal;
