import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';
import { ItemContainer, ErrorSpan } from '../login/LoginForm';
import ButtonComponent from '../../components/atoms/button';
import Input from '../../components/atoms/input';

const HyperLink = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.layout.primary
}));

type ForgotPasswordFormType = {
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values: { username: string }) => void;
};

const ForgotPasswordForm = ({ onSubmit }: ForgotPasswordFormType) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loading } = useAppSelector((state) => state.auth);

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<{ username: string }>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            username: ''
        }
    });

    return (
        <form onSubmit={loading ? () => {} : handleSubmit(onSubmit)} noValidate>
            <Typography variant="h2">{t('TEXT_FORGOT_PASSWORD_SCREEN_HEADER')}</Typography>

            <ItemContainer style={{ marginBottom: '20px' }}>
                <Controller
                    name="username"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t('TEXT_CLIENT_INFO_EMAIL_VALIDATION')
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            error={!!errors.username}
                            type="text"
                            data-testid="email"
                            placeholder={t('PLACEHOLDER_LOGIN_SCREEN_EMAIL')}
                            label="Enter Email Address"
                            fullWidth
                            labelSx={{ background: 'none !important' }}
                        />
                    )}
                />
                {errors.username ? <ErrorSpan role="alert">{errors.username.message}</ErrorSpan> : null}
            </ItemContainer>

            <ButtonComponent type="submit" variant="contained" fullWidth>
                {loading ? <CircularProgress color="inherit" size="24px" /> : 'Continue'}
            </ButtonComponent>

            <ItemContainer sx={{ alignItems: 'flex-end' }}>
                <HyperLink
                    onClick={() => {
                        navigate('/');
                    }}
                    data-testid="back-to-sign-in-link"
                    sx={{ color: 'green' }}
                >
                    {t('TEXT_MFA_SCREEN_BACK_TO_SIGN_IN')}
                </HyperLink>
            </ItemContainer>
        </form>
    );
};

export default ForgotPasswordForm;
