import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

const SkeletonLoading = () => (
    <div style={{ marginBottom: '20px' }}>
        <Grid container spacing={4}>
            <Grid item xs={12} md={2} lg={1.5}>
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Grid>
            <Grid item xs={12} md={3} lg={2.5}>
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Grid>
            <Grid item xs={12} md={3} lg={2.5}>
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Grid>
            <Grid item xs={12} md={2} lg={1.5}>
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Grid>
            <Grid item xs={12} md={2} lg={1.5}>
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Grid>
            <Grid item xs={12} md={2} lg={1.5}>
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Grid>
        </Grid>
    </div>
);

export default SkeletonLoading;
