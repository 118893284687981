import { memo, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { isEmpty, sum } from 'lodash';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import moment from 'moment';

import GlidePathContainer from '../charts/glide-path';
import WealthPathContainer from '../charts/wealth-path';
import PortfolioCompositionContainer from '../charts/portfolio-composition';
import TabsContainer from '../../containers/client-summary/tabs';
import ClientDetails from '../../components/molecules/client-details';
import FormField from '../../components/molecules/client-summary/formFields';
import { returnTableCellValue, getLastModifiedDate } from '../../utils/table';
import { useAppSelector } from '../../app/hooks';

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    color: theme.palette.common.white,
    zIndex: 100,
    fontSize: '1rem',
    padding: '0 32px !important',
    overflowX: 'scroll',
    maxHeight: '95%',
    '@media(max-width: 991px)': {
        top: '10%',
        transform: 'translate(-50%, 0%)',
        padding: '0 32px !important',
        width: '90%'
    }
}));

export const ModalContent = styled(Typography)<TypographyProps>(() => ({
    fontSize: '30px',
    lineHeight: '38px'
}));

const DescTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    padding: '5px 0px',
    color: theme.palette.text.primary,
    '@media (max-width: 1438px)': {
        margin: '0 16px'
    }
}));

const ClientSummary = ({
    clientSummary,
    formField,
    open,
    handleClose,
    request,
    response,
    portfolioComposistionChartData,
    portfolioComposistionEquityValue,
    portfolioComposistionFixedIncomeValue,
    portfolioCompositionYearLabel,
    updateProfolioCompositionData,
    portfolioData
}: any) => {
    const { t } = useTranslation();

    const [clientPorfolioValue, setClientPorfolioValue] = useState<number | null>(null);
    const [clientLastModifiedDate, setClientLastModifiedDate] = useState<any | null>(null);
    const [clientData, setClientData] = useState<any>('');

    const enableSourceCaveatNumbers = useAppSelector((state) =>
        Boolean(state.global.orgInfo?.enableSourceCaveatNumbers)
    );

    useEffect(() => {
        if (!isEmpty(clientSummary)) {
            setClientPorfolioValue(sum(returnTableCellValue(clientSummary, 'initialInvestment')));
            setClientLastModifiedDate(
                moment.utc(getLastModifiedDate(clientSummary)).local().format('MM/DD/YYYY hh:mm:ss')
            );
            setClientData(clientSummary);
        }
    }, [clientSummary]);

    return (
        <div data-testid="client-summary">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowY: 'scroll' }}
                disableScrollLock
                disableEscapeKeyDown
                disableEnforceFocus
            >
                <StyledBox sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
                    <ClientDetails
                        handleClose={handleClose}
                        data={clientData}
                        portfolioValue={clientPorfolioValue}
                        lastModifiedDate={clientLastModifiedDate}
                    />
                    <TabsContainer
                        clientSummary={clientSummary}
                        updateProfolioCompositionData={updateProfolioCompositionData}
                        portfolioData={portfolioData}
                        portfolioComposistionChartData={portfolioComposistionChartData}
                        portfolioComposistionEquityValue={portfolioComposistionEquityValue}
                        portfolioComposistionFixedIncomeValue={portfolioComposistionFixedIncomeValue}
                        portfolioCompositionYearLabel={portfolioCompositionYearLabel}
                    />
                    <FormField data={formField} />
                    <Grid container spacing={3} sx={{ marginTop: '10px' }}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <WealthPathContainer
                                showHeader
                                showFooter={false}
                                showLegends
                                showAmount={false}
                                chartHeight={320}
                                showSkeletonLoader
                                className="client-summary-style"
                                request={request?.data}
                                response={response?.data}
                                formField={formField?.data}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <GlidePathContainer
                                showHeader
                                showFooter={false}
                                showLegends
                                showAmount={false}
                                chartHeight={300}
                                pieChartFontSize={12}
                                showSkeletonLoader
                                portfolioData={portfolioData}
                                request={request?.data}
                                response={response?.data}
                                updateProfolioCompositionData={updateProfolioCompositionData}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <PortfolioCompositionContainer
                                showHeader
                                showFooter={false}
                                showLegends
                                showYearLabel={false}
                                chartHeight={300}
                                showSkeletonLoader
                                showPercentageOnBar={false}
                                chartData={portfolioComposistionChartData}
                                equity={portfolioComposistionEquityValue}
                                fixedIncome={portfolioComposistionFixedIncomeValue}
                                label={portfolioCompositionYearLabel}
                            />
                        </Grid>
                    </Grid>
                    <DescTypography data-testid="client-summary-modal-footer-text" variant="subtitle2">
                        {t('DESCRIPTION_FOOTER_ONE')}
                    </DescTypography>

                    {enableSourceCaveatNumbers && (
                        <DescTypography
                            variant="subtitle2"
                            data-testid="client-summary-modal-footer-super-script-1"
                            sx={{ margin: 0, padding: 0 }}
                        >
                            {t('ORIGIN_OF_DATA_POINT_1', {
                                asOfDate: moment(formField?.updatedAt).format('MM/DD/YYYY')
                            })}
                        </DescTypography>
                    )}

                    {enableSourceCaveatNumbers && clientData?.externalData?.data && (
                        <DescTypography
                            variant="subtitle2"
                            data-testid="client-summary-modal-footer-super-script-2"
                            sx={{ margin: 0, padding: 0 }}
                        >
                            {t('ORIGIN_OF_DATA_POINT_2', {
                                asOfDate: moment(clientData?.externalData?.data?.lastUpdatedAt).format('MM/DD/YYYY')
                            })}
                        </DescTypography>
                    )}
                </StyledBox>
            </Modal>
        </div>
    );
};

export default memo(ClientSummary);
