import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BreadcrumbsComponent from '../../components/molecules/breadcrumbs';
import ScheduleUpcomingReleaseForm from './ScheduleUpcomingReleaseForm';

const Container = styled(Box)(() => ({}));

export default function ScheduleUpcomingRelease() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const breadcrumbs = [
        <Typography
            key="1"
            variant="body1"
            onClick={() => navigate('/admin')}
            sx={{
                color: 'primary.main',
                fontSize: '22px',
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: 'underline 1px',
                    textUnderlineOffset: '3px'
                }
            }}
        >
            {t('TEXT_ADMIN_DASHBOARD_HEADER')}
        </Typography>,
        <Typography key="2" variant="body1" sx={{ color: 'primary.main', fontSize: '22px' }}>
            {t('TEXT_RELEASE_BANNER_HEADER')}
        </Typography>
    ];

    return (
        <Container>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} />

            <Typography variant="h2">{t('TEXT_SCHEDULE_RELEASE_BANNER_HEADER')}</Typography>

            <ScheduleUpcomingReleaseForm />
        </Container>
    );
}
