/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import CheckIcon from '@mui/icons-material/Check';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { cloneDeep, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { retirementObj, generateIncomeObj, futureEventObj, goalObj } from '../../services/configs/configs';
import { updateShowAdvisorPortalButtonOnHeader, updateActiveTabIndex } from '../../features/global/globalSlice';
import {
    ADD_CLIENT_CLIENT_INFO,
    ADD_CLIENT_RISK_TOLERANCE,
    ADD_CLIENT_SAVING_GOAL,
    ADD_CLIENT_INCOME_GOAL,
    ADD_CLIENT_RETIREMENT_GOAL,
    GOAL_TYPE_TRANSLATION_MAP
} from '../../constants';
import AddClientInformation from '../../components/molecules/client-Info';
import RiskTolerance from '../../components/molecules/risk-tolerance';
import { CtaContainer, ModalContent } from '../../components/molecules/modal/styles';
import Modal from '../../components/molecules/modal';
import InvestmentGoal from '../../components/organisms/investment-goal-form';
import ChooseGoal from '../../components/organisms/choose-saving-goal';

import {
    updateInvestorGoalData,
    updateAccordionState,
    updateRiskToleranceData
} from '../../features/add-client/add-client';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ButtonComponent from '../../components/atoms/button';
import { FormInput } from '../../components/molecules/client-Info';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import { Typography } from '@mui/material';

interface AccordianProps {
    count?: number;
    index?: number;
    lastindex?: number;
}
interface GoalsheadingProps {
    active?: boolean | number;
    stepname?: string;
}
interface InputProps {
    accordionDataObj: Array<AccordionDataProps>;
}
interface AccordionDataProps {
    value: number;
    name: string;
    text: string;
    helperText: string;
    description: string;
    subheading1?: string;
    subheading2?: string;
    subheadingHelper1?: string;
    subheadingHelper2?: string;
}

const AccordianWrapper = styled('div')<AccordianProps>(({ theme, count, index, lastindex }) => ({
    GoalsHeading: {
        backgroundColor: count === index && count !== lastindex ? '' : theme.palette.primary.main,
        margin: '0 20px'
    },
    '.MuiAccordion-region': {
        margin: '0 20px'
    }
}));

const GoalsHeadings = styled('div')<GoalsheadingProps>(({ theme, active, stepname }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: active
        ? ''
        : stepname === ADD_CLIENT_CLIENT_INFO
        ? theme.palette.goal.heading.background.clientInfo
        : stepname === ADD_CLIENT_RISK_TOLERANCE
        ? theme.palette.goal.heading.background.riskTolerance
        : stepname === ADD_CLIENT_SAVING_GOAL
        ? theme.palette.goal.heading.background.savingGoal
        : '',
    cursor: 'pointer',
    alignItems: 'center',
    padding: '9px',
    boxShadow: active ? 'none' : 'inset 0px -3px 3px -3px rgb(50 50 50 / 75%)',
    WebkitBoxShadow: active ? 'none' : 'inset 0px -3px 3px -3px rgb(50 50 50 / 75%)'
}));
const GoalsQuest = styled(Typography)(({ theme }) => ({
    margin: '0 20px',
    minWidth: '30%',
    color: theme.palette.primary.main
}));
const GoalsCompleted = styled(Typography)(() => ({
    color: 'black',
    fontSize: '30px',
    display: 'flex',
    lineHeight: '32px',
    textAlign: 'right',
    maxWidth: '60%',
    paddingRight: '26px',
    '.MuiSvgIcon-root': {
        fontSize: '2rem'
    },
    '.tooltip': {
        background: 'red'
    }
}));
const GoalsCompletedText = styled(Typography)<any>(() => ({
    paddingRight: '9px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block'

    // '.tooltiptext': {
    //   visibility: 'hidden',
    //   width: '100px',
    //   backgroundColor: 'green',
    //   color: 'lightgreen',
    //   textAlign: 'center',
    //   borderRadius: '6px',
    //   padding: '5px 0',

    //   /* Position the tooltip */
    //   position: 'absolute',
    //   zIndex: 1
    // },
    // ':hover.tooltiptext ': {
    //   visibility: 'visible'
    // }
}));
interface DataConatinerprops {
    addmargin: boolean | number;
}
const GoalsDescription = styled(Typography)<any>(() => ({
    fontWeight: '400'
}));
const LoaderContainer = styled('div')(() => ({
    margin: '20% 45%'
}));

const DataContainer = styled('div')<DataConatinerprops>(() => ({
    padding: '10px',
    // margin: addmargin ? '60px 0' : ''
    marginBottom: '20px'
}));
export const Customtooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.layout.primary,
        // background: "red",
        maxWidth: 800,
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.common.white,
        border: `2px solid ${theme.palette.layout.primary}`
    }
}));
export default function accordian({ accordionDataObj }: InputProps) {
    const [goalType, setGoalType] = useState<any[]>([]);
    const [toolTip, setToolTip] = useState<boolean>(false);

    const [showStep4ToStep3Modal, setShowStep4ToStep3Modal] = useState(false);
    const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
    const [blockUserNavigation, setBlockUserNavigation] = useState(true);

    const [showPrompt, confirmNavigation, cancelNavigation]: any = useCallbackPrompt(blockUserNavigation);

    const showDialog: boolean = useAppSelector((state) => state.global?.showDialog);
    const investorData: any[] = useAppSelector((state: any) => state.addClient.invertorGoalData || []);
    const count: number = useAppSelector((state: any) => state.addClient.accordionState || 0);
    const clientInfoHelperText: string = useAppSelector((state: any) => state.addClient.clientHelperText || '');
    const riskToleranceHelperText: string = useAppSelector(
        (state: any) => state.addClient.riskToleranceHelperText || ''
    );
    const activeTabIndex: number = useAppSelector((state) => state.global?.activeTabIndex);
    const loading = useAppSelector((state) => state.addClient.loading);
    const freemium: boolean = false;
    const clientData = useAppSelector((state: any) => state.addClient.clientInfo || {});
    const form = useForm<FormInput>({
        mode: 'all',
        reValidateMode: 'onChange',

        defaultValues: clientData
    });
    const riskData: any[] = useAppSelector((state) => state?.addClient?.riskToleranceData || []);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [dataValue] = useState(accordionDataObj);
    const navigate = useNavigate();

    const [accordionindex, setAccordionIndex] = useState<number>(0);
    const lastindex = accordionDataObj.length - 1;

    useEffect(() => {
        dispatch(updateShowAdvisorPortalButtonOnHeader(false));

        const formData = [...investorData];
        const arr: any = [];
        formData.forEach((itm: any) => {
            if (itm?.goalType) {
                arr.push(itm.goalType);
            }
        });
        setGoalType(arr);
    }, [investorData]);

    const handleClick = () => {
        setBlockUserNavigation(false);
        setShowCancelConfirmationModal(true);
    };

    const handleStartOverForFreemium = () => {
        dispatch(updateAccordionState(0));
        // const initialRiskData = [...riskData]
        // initialRiskData.forEach((item) => item.active = false)
        // dispatch(updateRiskToleranceData(initialRiskData));
    };
    const handleNextStep = () => {
        // setCount((state: number) => state + 1);
        dispatch(updateAccordionState(count + 1));
    };
    const handleAddAnotherGoal = (count: number) => {
        const obj = cloneDeep(goalObj);
        const formData = [...investorData];
        let total = 0;
        formData.forEach((itm: any) => {
            if (!itm.goalType) {
                total += 1;
            }
        });
        if (total < 1) {
            formData.push(obj);
        }
        dispatch(updateInvestorGoalData(formData));
        dispatch(updateAccordionState(count - 1));
        dispatch(updateActiveTabIndex(investorData.length));
    };
    const savingGoalChange = (value: string) => {
        if (value === ADD_CLIENT_INCOME_GOAL) {
            if (isEmpty(investorData) || investorData[0].goalType !== value) {
                const obj = cloneDeep(generateIncomeObj);

                obj.goalType = value;

                dispatch(updateInvestorGoalData([obj]));
            }
        } else if (value === ADD_CLIENT_RETIREMENT_GOAL) {
            if (isEmpty(investorData) || investorData[0].goalType !== value) {
                const obj = cloneDeep(retirementObj);
                obj.goalType = value;

                dispatch(updateInvestorGoalData([obj]));
            }
        } else if (isEmpty(investorData) || investorData[0].goalType !== value) {
            const obj = cloneDeep(futureEventObj);
            obj.goalType = value;

            dispatch(updateInvestorGoalData([obj]));
        }
    };

    const changeActive = (index: any) => {
        let data = cloneDeep(riskData);
        data = data.map((itm: any, i) => ({
            ...itm,
            active: i === index
        }));
        dispatch(updateRiskToleranceData(data));
    };

    return (
        <div data-testid="add-client">
            {loading ? (
                <LoaderContainer>
                    <CircularProgress sx={{ height: '80px !important', width: '80px !important' }} />
                </LoaderContainer>
            ) : (
                dataValue.map((step, index) => (
                    <AccordianWrapper
                        id="add-client-accordion-wrapper"
                        count={count}
                        key={index}
                        index={index}
                        lastindex={lastindex}
                    >
                        <Slide key={step.value} direction="up" in={count >= index} unmountOnExit>
                            <Accordion
                                className="GoalsHeading"
                                id="add-client-accodion"
                                expanded={count === index}
                                disableGutters
                                onChange={() => {
                                    dispatch(updateAccordionState(index));
                                    dispatch(updateActiveTabIndex(activeTabIndex));
                                }}
                            >
                                <GoalsHeadings
                                    id="add-client-goal-heading"
                                    // onClick={() => setCount(goalType.length > 3 ? lastindex : index)}
                                    onClick={
                                        step.name === ADD_CLIENT_SAVING_GOAL
                                            ? () => {
                                                  if (showDialog) {
                                                      setShowStep4ToStep3Modal(true);
                                                      setAccordionIndex(index);
                                                  } else {
                                                      dispatch(updateAccordionState(index));
                                                      dispatch(updateActiveTabIndex(activeTabIndex));
                                                  }
                                              }
                                            : undefined
                                    }
                                    stepname={step.name}
                                    active={+(count === index)}
                                >
                                    <GoalsQuest id="add-client-goal-heading-info" variant="h2">{`${index + 1}. ${t(
                                        step.text
                                    )}`}</GoalsQuest>
                                    {count === index ? (
                                        ''
                                    ) : (
                                        <GoalsCompleted id="add-client-goal-heading-span">
                                            {step.name === ADD_CLIENT_SAVING_GOAL ? (
                                                <Customtooltip
                                                    open={toolTip}
                                                    // onClose={() => setToolTip(false)}
                                                    // onOpen={() => setToolTip(true)}
                                                    title={goalType.toString()}
                                                    className="tooltip"
                                                    placement="top-start"
                                                >
                                                    <GoalsCompletedText
                                                        variant="h2"
                                                        id="add-client-goal-heading-text"
                                                        onMouseLeave={() => setToolTip(false)}
                                                        onMouseEnter={() => setToolTip(true)}
                                                        show={+(step.name === ADD_CLIENT_SAVING_GOAL)}
                                                    >
                                                        {t(
                                                            GOAL_TYPE_TRANSLATION_MAP[
                                                                goalType.toString() as
                                                                    | 'Save for a Future Event'
                                                                    | 'Retirement Savings'
                                                                    | 'Generate Income'
                                                            ]
                                                        )}
                                                    </GoalsCompletedText>
                                                </Customtooltip>
                                            ) : (
                                                <GoalsCompletedText
                                                    id="add-client-goal-heading-text"
                                                    show={+(step.name === ADD_CLIENT_SAVING_GOAL)}
                                                    variant="h2"
                                                >
                                                    {step.name === ADD_CLIENT_CLIENT_INFO
                                                        ? clientInfoHelperText
                                                        : step.name === ADD_CLIENT_RISK_TOLERANCE
                                                        ? t(riskToleranceHelperText)
                                                        : ''}

                                                    {t(step.helperText)}
                                                </GoalsCompletedText>
                                            )}

                                            <CheckIcon id="add-client-goal-heading-icon" />
                                        </GoalsCompleted>
                                    )}
                                </GoalsHeadings>
                                <GoalsDescription
                                    id="add-client-goal-description"
                                    addmargin={+(count !== lastindex)}
                                    vairant="body1"
                                >
                                    {t(step.description)}
                                </GoalsDescription>
                                <DataContainer addmargin={+(count !== lastindex)} id="add-client-goal-data-container">
                                    {step.name === ADD_CLIENT_CLIENT_INFO ? (
                                        <AddClientInformation
                                            clientData={clientData}
                                            handleCancel={() => handleClick()}
                                            handleNextStep={() => handleNextStep()}
                                            form={form}
                                        />
                                    ) : step.name === ADD_CLIENT_RISK_TOLERANCE ? (
                                        <RiskTolerance
                                            heading1={step?.subheading1 || ''}
                                            heading2={step?.subheading2 || ''}
                                            subheading1={step?.subheadingHelper1 || ''}
                                            subheading2={step?.subheadingHelper2 || ''}
                                            showHr
                                            handleCancel={() => handleClick()}
                                            handleStartOverForFreemium={() => handleStartOverForFreemium()}
                                            handleNextStep={() => {
                                                dispatch(updateAccordionState(count + 1));
                                            }}
                                            freemium={freemium}
                                            riskData={riskData}
                                            changeActive={changeActive}
                                        />
                                    ) : step.name === ADD_CLIENT_SAVING_GOAL ? (
                                        <ChooseGoal
                                            savingGoalChange={savingGoalChange}
                                            handleCancel={() => handleClick()}
                                            handleNextStep={() => dispatch(updateAccordionState(count + 1))}
                                            freemium={freemium}
                                            handleStartOverForFreemium={() => handleStartOverForFreemium()}
                                        />
                                    ) : (
                                        <InvestmentGoal
                                            // data={investorData}
                                            // goalType={goalType.length}
                                            handleCancel={() => handleClick()}
                                            // handleBack={() => dispatch(updateAccordionState(count - 1))}
                                            handleAddAnotherGoal={() => handleAddAnotherGoal(count)}
                                            // handleGetYourPlans={() => goToPlans()}
                                            freemium={freemium}
                                            handleStartOverForFreemium={() => handleStartOverForFreemium()}
                                            allowGoingToNextScreen={() => {
                                                setBlockUserNavigation(false);
                                            }}
                                        />
                                    )}
                                    <Modal
                                        handleClose={() => {
                                            setBlockUserNavigation(true);
                                            setShowCancelConfirmationModal(false);
                                        }}
                                        open={showCancelConfirmationModal}
                                        backgroundColor="rgba(0,0,0,0.1)"
                                    >
                                        <ModalContent id="modal-modal-title" variant="body1">
                                            {t('TEXT_CANCEL_ADD_CLIENT_POPUP_1')}
                                        </ModalContent>
                                        <CtaContainer>
                                            <ButtonComponent
                                                variant="outlined"
                                                size="medium"
                                                onClick={() => {
                                                    setBlockUserNavigation(true);
                                                    setShowCancelConfirmationModal(false);
                                                }}
                                            >
                                                {t('TEXT_NO')}
                                            </ButtonComponent>
                                            <ButtonComponent
                                                variant="contained"
                                                size="medium"
                                                onClick={() => {
                                                    setShowCancelConfirmationModal(false);
                                                    navigate('/clients');
                                                }}
                                            >
                                                {t('TEXT_YES')}
                                            </ButtonComponent>
                                        </CtaContainer>
                                    </Modal>

                                    <Modal
                                        handleClose={() => setShowStep4ToStep3Modal(false)}
                                        open={showStep4ToStep3Modal}
                                        backgroundColor="rgba(0,0,0,0.1)"
                                    >
                                        <ModalContent id="modal-modal-title" variant="body1">
                                            {t('TEXT_CANCEL_ADD_CLIENT_POPUP_2')}
                                        </ModalContent>
                                        <CtaContainer>
                                            <ButtonComponent
                                                variant="outlined"
                                                size="medium"
                                                onClick={() => setShowStep4ToStep3Modal(false)}
                                            >
                                                {t('TEXT_NO')}
                                            </ButtonComponent>
                                            <ButtonComponent
                                                variant="contained"
                                                size="medium"
                                                onClick={() => {
                                                    setShowStep4ToStep3Modal(false);
                                                    dispatch(updateAccordionState(accordionindex));
                                                    dispatch(updateActiveTabIndex(activeTabIndex));
                                                }}
                                            >
                                                {t('TEXT_YES')}
                                            </ButtonComponent>
                                        </CtaContainer>
                                    </Modal>

                                    <Modal
                                        handleClose={cancelNavigation}
                                        open={showPrompt}
                                        backgroundColor="rgba(0,0,0,0.1)"
                                    >
                                        <ModalContent id="modal-modal-title" variant="body1">
                                            {t('TEXT_CANCEL_ADD_CLIENT_POPUP_2')}
                                        </ModalContent>
                                        <CtaContainer>
                                            <ButtonComponent
                                                variant="outlined"
                                                size="medium"
                                                onClick={cancelNavigation}
                                            >
                                                {t('TEXT_NO')}
                                            </ButtonComponent>
                                            <ButtonComponent
                                                variant="contained"
                                                size="medium"
                                                onClick={confirmNavigation}
                                            >
                                                {t('TEXT_YES')}
                                            </ButtonComponent>
                                        </CtaContainer>
                                    </Modal>
                                </DataContainer>
                            </Accordion>
                        </Slide>
                    </AccordianWrapper>
                ))
            )}
        </div>
    );
}
