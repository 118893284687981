/* eslint-disable operator-linebreak */
import { useState, useEffect } from 'react';
import { CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddUser from '../../components/molecules/add-user';
import ButtonComponent from '../../components/atoms/button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addUser } from '../../features/add-client/add-client';
import { checkIfUserExists } from '../../features/advisor-admin';
import GlobalLoader from '../../components/molecules/global-loader';
import { setApplicationMessage } from '../../features/messaging/messagingSlice';

const Container = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontStyle: 'normal',
    fontSize: '32px',
    color: theme.palette.primary.main,
    padding: '0',
    boxShadow: 'none',
    WebkitBoxShadow: 'none'
}));

const HeaderText = styled('span')(({ theme }) => ({
    color: theme.palette.common.black
}));

const ButtonContainer = styled('span')(() => ({
    display: 'inline-block',
    margin: '6px'
}));

interface FormInput {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    accessLevel: string;
}

export default function AddUserContainer({ fromAdvisor }: any) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { tenant } = useAppSelector((state: any) => state.auth.user);
    const [isCheckingEmail, setIsCheckingEmail] = useState<any>(null);
    const [emailExistsError, setEmailExistsError] = useState(null);
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        watch,
        setError
    } = useForm<FormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            accessLevel: ''
        }
    });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            checkForEmailDupe(watch('emailAddress'), Boolean(errors.emailAddress));
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [watch('emailAddress')]);

    const checkForEmailDupe = async (value: string, hasError: any) => {
        if (value && !hasError) {
            setIsCheckingEmail(true);
            let res: any;
            try {
                res = await dispatch(checkIfUserExists(value));
            } catch (e) {
                setIsCheckingEmail(false);
                setEmailExistsError(null);
                return false;
            }

            if (res.payload) {
                setError('emailAddress', {
                    type: 'manual',
                    message: t('TEXT_CLIENT_INFO_EMAIL_ALREADY_EXISTS')
                });

                setEmailExistsError(t('TEXT_CLIENT_INFO_EMAIL_ALREADY_EXISTS'));
                setIsCheckingEmail(false);
                return false;
            }

            setIsCheckingEmail(false);
            setEmailExistsError(null);
            return true;
        }
    };

    const handleCancel = () => {
        navigate('/admin');
    };

    const onSubmit = async (values: {
        fullName: string;
        emailAddress: string;
        phoneNumber: string;
        accessLevel: string;
    }) => {
        const finished = await checkForEmailDupe(values.emailAddress, false);

        setLoading(true);
        if (finished) {
            const res: any = await dispatch(
                addUser({
                    firstName: values.fullName,
                    email: values.emailAddress,
                    phoneNumber: values.phoneNumber,
                    role: values.accessLevel,
                    tenant
                })
            );

            console.log(res);

            if (!res.error) {
                navigate('/admin');
            } else {
                dispatch(
                    setApplicationMessage({
                        showMessage: true,
                        message: t('TEXT_ERROR_ADDING_USER'),
                        variant: 'error'
                    })
                );
            }
        }

        setLoading(false);
    };

    const translation = {
        fullName: {
            label: fromAdvisor ? t('TEXT_ADD_USER_FULL_NAME_LABEL_ADVISOR') : t('TEXT_ADD_USER_FULL_NAME_LABEL_ADMIN'),
            placeholder: t('TEXT_ADD_USER_FULL_NAME_PLACEHOLDER'),
            requiredErrorMessage: t('TEXT_ADD_USER_FULL_NAME_REQUIRED_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            maxValidationErrorMessage: t('TEXT_ADD_USER_FULL_NAME_MAX_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            minValidationErrorMessage: t('TEXT_ADD_USER_FULL_NAME_MIN_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            validationErrorMessage: t('TEXT_ADD_USER_FULL_NAME_INVALID_VALIDATION')
        },
        email: {
            label: t('TEXT_ADD_USER_EMAIL_LABEL'),
            placeholder: t('TEXT_ADD_USER_EMAIL_PLACEHOLDER'),
            requiredErrorMessage: t('TEXT_ADD_USER_EMAIL_REQUIRED_VALIDATION', {
                user: fromAdvisor ? t('TEXT_ADVISOR') : t('TEXT_ADMIN')
            }),
            validationErrorMessage: t('TEXT_ADD_USER_EMAIL_INVALID_VALIDATION')
        },
        phonenumber: {
            label: t('TEXT_ADD_USER_PHONE_NUMBER_LABEL'),
            placeholder: t('TEXT_ADD_USER_PHONE_NUMBER_PLACEHOLDER'),
            validationErrorMessage: t('TEXT_ADD_USER_PHONE_NUMBER_INVALID_VALIDATION')
        }
    };

    if (loading) {
        return <GlobalLoader open />;
    }

    return (
        <>
            <Container>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <HeaderText>
                        {fromAdvisor
                            ? t('FINANCIAL_ADVISORS_ADD_NEW_ADVISOR')
                            : t('TEXT_ADMIN_USER_MANAGEMENT_ADD_NEW')}
                    </HeaderText>
                </div>
                <AddUser
                    onSubmit={onSubmit}
                    onCancel={handleCancel}
                    handleSubmit={handleSubmit}
                    emailExistsError={emailExistsError}
                    isCheckingEmail={isCheckingEmail}
                    control={control}
                    errors={errors}
                    isValid={isValid}
                    translation={translation}
                    t={t}
                />

                <div>
                    <ButtonContainer
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginTop: '25px'
                        }}
                    >
                        <ButtonComponent variant="outlined" onClick={handleCancel}>
                            {t('BUTTON_CANCEL')}
                        </ButtonComponent>
                        <ButtonComponent
                            type="submit"
                            form="add-user-form"
                            variant={!isValid || isCheckingEmail || emailExistsError ? 'outlined' : 'contained'}
                            custom={+!isValid || isCheckingEmail || emailExistsError}
                            disabled={!isValid || isCheckingEmail || emailExistsError}
                            sx={{ marginLeft: '20px' }}
                        >
                            {t('TEXT_ADMIN_ADD_USER_BUTTON')}
                        </ButtonComponent>
                    </ButtonContainer>
                </div>
            </Container>
        </>
    );
}
