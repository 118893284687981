import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Link } from '@mui/material';
import TabsComponent from '../../components/molecules/tabs/admin-tabs';
import AdvisorAdminFAClientsTable from '../../containers/advisor-admin-fa-clients-table';
import AdvisorSummary from '../../containers/advisor-summary';
import BreadcrumbsComponent from '../../components/molecules/breadcrumbs';
import { updateBreadcrumbLabel } from '../../features/advisor-admin';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { formatAdvisorName } from '../../utils';
import { useTranslation } from 'react-i18next';

export const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '36px',
    fontWeight: 200,
    lineHeight: '32px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    margin: '36px 0 40px 0',
    display: 'inline-block'
}));

export default function AdvisorAdminFADashboard() {
    const { t } = useTranslation();
    const tabs = [
        {
            label: t('TEXT_ADMIN_TAB_1'),
            Component: (
                <>
                    <AdvisorSummary />
                </>
            )
        },
        {
            label: t('TEXT_ADMIN_TAB_2'),
            Component: (
                <>
                    <AdvisorAdminFAClientsTable />
                </>
            )
        },
        {
            label: t('TEXT_ADMIN_TAB_4'),
            Component: (
                <div style={{ margin: '40px 0' }}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                        {t('TEXT_USER_MAINTENANCE_SENTENCE_1')}
                    </Typography>
                    <Typography variant="h3" mt={3} color="GrayText">
                        {t('TEXT_USER_MAINTENANCE_SENTENCE_2')}{' '}
                        <Link color="inherit" href={`mailto:${t('APPLICATION_SUPPORT_EMAIL')}`}>
                            {t('APPLICATION_SUPPORT_EMAIL')}
                        </Link>
                    </Typography>
                </div>
            )
        }
    ];

    const dispatch = useAppDispatch();
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event: any, newValue: React.SetStateAction<number> | any) => {
        const breadcrumbLabel: any = tabs[newValue]?.label;
        dispatch(updateBreadcrumbLabel(breadcrumbLabel));
        setTabIndex(newValue);
    };

    useEffect(() => {
        dispatch(updateBreadcrumbLabel(tabs[0]?.label));
    }, []);

    const navigate = useNavigate();
    const breadcrumbLabel = useAppSelector((state) => state.advisorAdmin.breadcrumbLabel);
    const selectedUser = useAppSelector((state) => state.advisorAdmin.selectedUser);

    const breadcrumbs = useMemo(
        () => [
            <Typography
                key="1"
                variant="body1"
                onClick={() => navigate('/admin')}
                sx={{
                    color: 'primary.main',
                    cursor: 'pointer',
                    '&:hover': {
                        textDecoration: 'underline 1px',
                        textUnderlineOffset: '3px'
                    }
                }}
            >
                {t('TEXT_ADMIN_DASHBOARD_HEADER')}
            </Typography>,
            <Typography
                key="2"
                variant="body1"
                onClick={() => {
                    handleTabChange(null, 1);
                }}
                sx={{
                    color: 'primary.main',
                    cursor: 'pointer',
                    '&:hover': {
                        textDecoration: 'underline 1px',
                        textUnderlineOffset: '3px'
                    }
                }}
            >
                {formatAdvisorName(selectedUser) || 'Please select a advisor'}
            </Typography>,
            <Typography key="3" variant="body1" sx={{ color: 'primary.main' }}>
                {breadcrumbLabel}
            </Typography>
        ],
        [selectedUser, breadcrumbLabel]
    );

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
            <TabsComponent tabs={tabs} value={tabIndex} handleChange={handleTabChange} />
        </>
    );
}
