/* eslint-disable operator-linebreak */
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { Typography, TypographyProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';

import Dialog from '../dialog';
import CheckboxComponent from '../../../atoms/checkbox';
import SliderComponent from '../../../atoms/goal-purpose-slider';
import Input from '../../../atoms/input';
import ButtonComponent from '../../../atoms/button';
import SelectComponent from '../../../atoms/select';
import { FormInput } from '../../client-Info';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { CtaContainer, ModalContent } from '../../../molecules/modal/styles';
import Modal from '../../../molecules/modal';
import {
    updateRequestPayload,
    updateInvestorGoalData,
    updateLoader,
    createGoalData,
    updateSplitDialog
} from '../../../../features/add-client/add-client';
import {
    updateClientSummary,
    updateFormField,
    updateRequest,
    updateResponse
} from '../../../../features/personalized-plan';
import {
    updateClientSummary as editClientSummary,
    updateFormField as editFormField,
    updateGoalEdited,
    updateRequest as editRequest,
    updateResponse as editResponse,
    updateEditLoader
} from '../../../../features/edit-goal';
import { TRUE, RETIREMENT, RISK_PROFILE_API_MAP, TENANT_ID_MAP, SCHEDULED_FREQUENCY } from '../../../../constants';
import {
    frequency,
    autoEscalation,
    years,
    Months,
    fundingSources,
    autoEscalationFrequency,
    bequestValue,
    getGoalPriorityName,
    annualDistributionYear,
    getInfusionType,
    withdrawalPeriodYears,
    current_Date,
    FUNDING_SOURCE_ENTER_AMOUNT_VALUE,
    FUNDING_SOURCE_EXISTING_ACCOUNTS_VALUE,
    getExistingAccountsForSelect
} from '../../../../services/configs/configs';
import {
    numberWithCommas,
    removeLeadingZeroes,
    removeNonNumeric,
    retirementSavingCalculation,
    retirementSavingInfusionArrayCalculation,
    createReqPayloadObject,
    checkIfFormValuesAreEqual,
    formatCurrency,
    getInfusionsAdjustedWithInflations,
    getInflatedAmount
} from '../../../../utils/forms';
import {
    updateActiveTabIndex,
    updateCopyData,
    updateDialogState,
    updatePdfEdited,
    updateSaveExitEdited,
    logEventToBackEnd,
    toggleEditGoalWealthSplitterApplied
} from '../../../../features/global/globalSlice';
import {
    deleteGoal,
    updateClientSummaryModalState
} from '../../../../features/my-clients/client-side-dashboard-table-slice';
import { uipApiInstance } from '../../../../axios';
import SelectAccountComponent from '../../select-account';
import { updateFormWithSuggestions } from '../../../../features/client-summary';
import { setApplicationMessage } from '../../../../features/messaging/messagingSlice';

const isHighPoint =
    TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'advisorportaldev'] === 'highpoint';

interface DataList {
    data: any;
    marks: any;
    handleCancel?: () => void;
    goalConfirm?: boolean;
    id?: number;
    edit?: boolean;
    goalId?: number;
    wealthSplit?: boolean;
    handleAddAnotherGoal?: any;
    applySplit?: any;
    // handleGetYourPlans: () => void;
    freemium?: boolean;
    handleStartOverForFreemium?: () => void;
    allowGoingToNextScreen?: () => void;
}
interface RetirementFormInput {
    goalType: string;
    goalName: string;
    accountNumber: string;
    initialInvestment: number | string;
    retirementMonth: number | string;
    retirementYear: number | string;
    scheduledContributionAmount: number | string;
    scheduledContributionFrequency: string;
    autoEsclationPercentage: number | string;
    autoEsclationFrequency: number | string;
    withdrawalPeriod: number | string;
    withdrawalAmount: number | string;
    withdrawalFrequency: string;
    bequest: boolean;
    goalPriority: string;
    goalAmount: number | string;
    endDate: string;
    startDate: string;
    goalPriorityValue: number;
    riskProfile: string;
    fundingSource: string;
    existingAccountId: Array<String>;
    additionalFunding: string;
}

const GridContainer = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    marginTop: '2px'
}));

const AccordianFooter = styled('div')(() => ({
    marginTop: '110px',
    marginBottom: '30px',
    textAlign: 'right'
}));

const ButtonContainer = styled('span')(() => ({
    display: 'inline-block',
    margin: '6px'
}));

const ErrorSpan = styled('span')(({ theme }) => ({
    color: theme.palette.goal.formFields.errorLabel,
    fontSize: '12px'
}));

const RemodelButtonContainer = styled('div')(() => ({
    marginTop: '12px',
    minWidth: '220px'
}));
export const TooltipContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    justifyContent: 'center',
    '.custom-tooltip': {
        margin: '12px 0 0 0'
    }
}));
const ContainerWithSuperScript = styled('div')(() => ({
    position: 'relative',
    display: 'inline-flex'
}));

const SuperCript = styled(Typography)<TypographyProps>(() => ({
    marginLeft: '10px',
    position: 'absolute',
    right: -15,
    top: -10
}));

const DescTypography = styled(Typography)<TypographyProps>(() => ({
    fontSize: '14px',
    fontWeight: 100,
    lineHeight: '18px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    margin: '30px 0 0 0'
}));

export const calculateRequestPayloadForRetire = (data: any, inflationRate: number) => {
    const retirementMonth = data.retirementMonth;
    const retirementYear = data.retirementYear;
    const withdrawalPeriod = data.withdrawalPeriod;
    const contribAmount = data.scheduledContributionAmount;
    const withdrawalAmount = data.withdrawalAmount;
    const initialInvestment = data.initialInvestment;
    const goalPriority = data.goalPriority;
    const bequestAmount = data.goalAmount;
    const accountNumber = data.accountNumber;
    const autoEsclationFrequency = data.autoEsclationFrequency;
    const autoEsclationPercentage = data.autoEsclationPercentage;
    const infusionType = getInfusionType(data.scheduledContributionFrequency);
    const {
        current_date,
        current_year,
        retirement_Date,
        contributionEndDate,
        endDate,
        endYear,
        infusion_starting_Date
    } = retirementSavingCalculation(retirementYear, retirementMonth, withdrawalPeriod);
    const {
        // infusions,
        tempInfusions,
        distributionStartDate
    } = retirementSavingInfusionArrayCalculation(
        contribAmount,
        withdrawalAmount,
        infusion_starting_Date,
        contributionEndDate,
        autoEsclationFrequency,
        autoEsclationPercentage,
        data.scheduledContributionFrequency,
        retirement_Date,
        endDate
    );

    const year = current_year;
    const yearDiff = endYear - current_year;

    let inflationBasedOnFrquency = inflationRate;

    if (data.scheduledContributionFrequency === SCHEDULED_FREQUENCY.MONTHLY) {
        inflationBasedOnFrquency = ((1 + inflationRate / 100) ** (1 / 12) - 1) * 100;
    }

    const adjustedInfusions = getInfusionsAdjustedWithInflations(
        tempInfusions,
        inflationBasedOnFrquency,
        data.scheduledContributionFrequency === SCHEDULED_FREQUENCY.MONTHLY ? 'months' : 'years',
        current_date
    );
    const adjustedTempInfusions = tempInfusions.map((t, i) => ({
        amount: adjustedInfusions[i],
        date: t.date
    }));

    const annualDistributionAmount = adjustedInfusions.map((itm: any) => Math.abs(itm));
    const annualDistributionYears = annualDistributionYear(year, yearDiff);

    const reqPayloadObj = createReqPayloadObject(
        distributionStartDate,
        RETIREMENT,
        current_date,
        adjustedTempInfusions,
        data.riskProfile,
        initialInvestment,
        goalPriority,
        data.goalName,
        accountNumber,
        getInflatedAmount(
            bequestAmount,
            inflationRate,
            moment(current_date, 'DD-MM-YYYY'),
            moment(endDate, 'DD-MM-YYYY'),
            'years'
        ),
        endDate,
        infusionType,
        adjustedInfusions,
        annualDistributionAmount,
        annualDistributionYears
    );
    return { endDate, current_date, reqPayloadObj };
};
export default function RetirementSavingForm({
    data,
    marks,
    handleCancel,
    goalConfirm,
    handleAddAnotherGoal,
    id,
    goalId,
    wealthSplit,
    edit,
    applySplit,
    allowGoingToNextScreen
}: // handleStartOverForFreemium,
// freemium
// handleBack,

//
// handleGetYourPlans
DataList) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showBequestAmount, setShowBequestAmount] = useState<boolean>(false);
    // const [formVlauesChanged, setFormValuesChanged] = useState<boolean>(false);
    const [dataValue, setDataValue] = useState<any>({});
    const [showAccountNumber, setShowAccountNumber] = useState<boolean>(false);
    const accountNumberFieldVisible = useAppSelector((state) => state.global.orgInfo?.enableAccountNumberTextField);
    const activeTabIndex = useAppSelector((state) => state.global.activeTabIndex);
    const editGoalClientSummary = useAppSelector((state) => state.editGoal.clientSummary);
    const riskToleranceHelperText: string = useAppSelector((state) => state.addClient.riskToleranceHelperText || '');
    const [addAnotherGoal, setAddAnotherGoal] = useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const formFields: any[] = useAppSelector((state) => state.addClient.invertorGoalData || []);
    const clientInfo: FormInput = useAppSelector((state) => state.addClient.clientInfo || []);
    const assignedClientId = useAppSelector((state) => state.addClient.assignedClientId);
    const clientData = useAppSelector((state) =>
        state.clientSideTableProposals.actualData?.find((x: any) => x.assignedClientId === assignedClientId)
    );
    const enableFundingSourceDropDown = useAppSelector((state) =>
        Boolean(state.global.orgInfo?.enableFundingSourceDropDown)
    );
    const enableSourceCaveatNumbers = useAppSelector((state) => state.global.orgInfo?.enableSourceCaveatNumbers);
    const inflationRate = useAppSelector((state) => state.global.orgInfo?.inflationRate);
    const clientSummaryFromHomePage = useAppSelector((state) => state.client.clientSummary);
    const requestPayLoads = useAppSelector((state) => state.addClient.requestPayLoadObj);
    const user = useAppSelector((state) => state.auth.user);
    const domainEmail = useAppSelector((state) => state.global.domainEmail || '');
    const [open, setOpen] = useState<boolean>(false);
    const openDialog: boolean = useAppSelector((state) => state.addClient.openDialog);
    const personalizedPlan = useAppSelector((state) => state.personalizedPlan);
    // const [showAnnualDropdown, setShowAnnualDropdown] = useState<boolean>(false);
    const [DisableAutoEscalationFrequency, setDisableAutoEscalationFrequency] = useState<boolean>(true);
    const copyData: any = useAppSelector((state) => state.global.copyData);
    const [resetDialogState, setResetDialogState] = useState<boolean>(false);
    const [DeleteDialog, setDeleteDialog] = useState<boolean>(false);
    // const checkLastGoal = formFields?.length - 1 === activeTabIndex;
    const goalConfirmPageFormFieldLength = personalizedPlan?.clientSummary?.formFields?.length > 1;
    const editGoalPageFormFieldLength = editGoalClientSummary?.formFields?.length > 1;
    const checkLastPageGoalIsSaved = !requestPayLoads[activeTabIndex]?.infusions;

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors, isValid },
        watch,
        register,
        unregister,
        getValues
    } = useForm<RetirementFormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: data
    });
    const currentFormValues = getValues();
    const fundingSourceValue = watch('fundingSource');
    const existingAccountsSelected = watch('existingAccountId');
    const additionalFunding = watch('additionalFunding');
    const retirementMonth = watch('retirementMonth');
    const retirementYear = watch('retirementYear');

    const currentMonth = moment().format('M');
    const currentYear = moment().year();
    const shouldStartFromCurrentYear = retirementMonth > currentMonth;

    useEffect(() => {
        if (retirementYear == currentYear && retirementMonth < currentMonth) {
            setValue('retirementYear', '');
        }
    }, [retirementMonth]);

    const suggestionValuesToUpdate = useAppSelector((state) => state.client.suggestionValuesToUpdate);

    useEffect(() => {
        if (suggestionValuesToUpdate && Object.keys(suggestionValuesToUpdate).length) {
            Object.keys(suggestionValuesToUpdate).forEach((key: any) => {
                setValue(key, suggestionValuesToUpdate[key]);
            });

            window.scrollTo({ top: 0, behavior: 'smooth' });

            dispatch(updateFormWithSuggestions({}));
        }
    }, [suggestionValuesToUpdate]);

    useEffect(() => {
        if (edit) {
            if (
                !checkIfFormValuesAreEqual(
                    clientSummaryFromHomePage?.formFields?.[activeTabIndex]?.data,
                    currentFormValues
                )
            ) {
                dispatch(updateDialogState(true));
                dispatch(updateGoalEdited(true));
            }

            if (!checkIfFormValuesAreEqual(data, currentFormValues)) {
                dispatch(toggleEditGoalWealthSplitterApplied(false));
                dispatch(updatePdfEdited(true));
                dispatch(updateSaveExitEdited(true));
            }
        } else {
            if (!checkIfFormValuesAreEqual(data, currentFormValues)) {
                dispatch(updateDialogState(true));
                dispatch(updateGoalEdited(true));
                dispatch(updatePdfEdited(true));
                dispatch(updateSaveExitEdited(true));
            }
        }
    }, [watch()]);

    useEffect(() => {
        if (clientData?.externalData?.data?.accountList?.length) {
            if (fundingSourceValue === FUNDING_SOURCE_ENTER_AMOUNT_VALUE) {
                register('initialInvestment');
            } else if (fundingSourceValue === FUNDING_SOURCE_EXISTING_ACCOUNTS_VALUE) {
                register('initialInvestment');
                register('existingAccountId');
                register('additionalFunding');
            } else {
                unregister('initialInvestment');
                unregister('existingAccountId');
                unregister('additionalFunding');
            }
        }
    }, [register, unregister, fundingSourceValue]);

    useEffect(() => {
        if (
            clientData?.externalData?.data?.accountList?.length &&
            fundingSourceValue === FUNDING_SOURCE_EXISTING_ACCOUNTS_VALUE
        ) {
            if (existingAccountsSelected?.length) {
                const accountsAmountTotal = existingAccountsSelected
                    ?.map(
                        (y: any) =>
                            clientData?.externalData?.data?.accountList.find((z: any) => z.accountNumber === y)
                                ?.accountBalance
                    )
                    .reduce((a, b) => a + b);

                const total: number = Number(
                    accountsAmountTotal + Number(`${additionalFunding}`?.replace(/\D/g, '') || 0)
                );

                setValue('initialInvestment', Math.round(total));
            } else {
                setValue(
                    'initialInvestment',
                    numberWithCommas(
                        Math.round(
                            Number(
                                Number(data.initialInvestment) + Number(`${additionalFunding}`?.replace(/\D/g, '') || 0)
                            )
                        )
                    )
                );
            }
        }
    }, [additionalFunding, existingAccountsSelected]);

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    useEffect(() => {
        if (!isEmpty(data)) {
            setDataValue(data);
            dispatch(updateDialogState(false));
            dispatch(updateGoalEdited(false));
            dispatch(updatePdfEdited(false));
            dispatch(updateSaveExitEdited(false));
            reset(data);
            if (data.autoEsclationPercentage) {
                setDisableAutoEscalationFrequency(false);
            }

            if (data?.initialInvestment) {
                convertToCommaFormatOnRefresh(data?.initialInvestment, 'initialInvestment');
            }
            if (data.scheduledContributionAmount) {
                convertToCommaFormatOnRefresh(data?.scheduledContributionAmount, 'scheduledContributionAmount');
            }
            if (data.goalAmount) {
                convertToCommaFormatOnRefresh(data?.goalAmount, 'goalAmount');
            }
            if (data.scheduledWithdrawalAmount) {
                convertToCommaFormatOnRefresh(data?.scheduledWithdrawalAmount, 'scheduledWithdrawalAmount');
            }
        }
    }, [data, reset]);

    useEffect(() => {
        if (data.bequest === TRUE) {
            setShowBequestAmount(true);
        } else {
            setShowBequestAmount(false);
        }
    }, [data, reset]);

    useEffect(() => {
        if (accountNumberFieldVisible) {
            setShowAccountNumber(true);
        } else {
            setShowAccountNumber(false);
        }
    }, [accountNumberFieldVisible]);

    useEffect(() => {
        if (!isEmpty(formFields) && formFields?.length - 1 === activeTabIndex) {
            setDisableButton(false);
        } else {
            formFields.forEach((itm: any) => {
                if (!itm.goalName) {
                    setDisableButton(true);
                }
            });
        }
    }, [formFields, activeTabIndex]);

    const formSubmitHandler = (formdata: RetirementFormInput) => {
        /** function to calculate the request payload for Goe Engine */
        const { reqPayloadObj } = calculateFormData(formdata);
        if (addAnotherGoal) {
            formFields[activeTabIndex] = dataValue;
            requestPayLoads[activeTabIndex] = reqPayloadObj;
            dispatch(updateInvestorGoalData(formFields));
            dispatch(updateRequestPayload(requestPayLoads));
            handleAddAnotherGoal();
        } else if (edit) {
            dispatch(updateCopyData(editGoalClientSummary));
            dispatch(toggleEditGoalWealthSplitterApplied(true));
            remodeldata(reqPayloadObj);
        } else if (goalConfirm) {
            const arr = [...formFields];
            arr[activeTabIndex] = dataValue;
            dispatch(updateInvestorGoalData(arr));
            dispatch(updateCopyData(personalizedPlan.clientSummary));
            dispatch(updateFormField([dataValue]));
            dispatch(updateRequest([reqPayloadObj]));
            remodeldata(reqPayloadObj);
        } else {
            formFields[activeTabIndex] = dataValue;
            requestPayLoads[activeTabIndex] = reqPayloadObj;
            dispatch(updateInvestorGoalData(formFields));
            dispatch(updateRequestPayload(requestPayLoads));
            getdata();
        }
    };

    const calculateFormData = (formdata: any) => {
        const initialInvestment = formdata ? Number(removeNonNumeric(formdata.initialInvestment)) : 0;
        const contribAmount = formdata ? Number(removeNonNumeric(formdata.scheduledContributionAmount)) : 0;
        const withdrawalAmount = formdata ? Number(removeNonNumeric(formdata.withdrawalAmount)) : 0;
        const accountNumber = formdata ? formdata.accountNumber : 0;
        const bequestAmount = formdata ? Number(removeNonNumeric(formdata.goalAmount)) : 0;
        const goalPriority = getGoalPriorityName(formdata.goalPriorityValue);
        const retirementMonth = Number(formdata?.retirementMonth);
        const retirementYear = Number(formdata?.retirementYear);
        const withdrawalPeriod = Number(formdata?.withdrawalPeriod);
        const autoEsclationFrequency = Number(formdata?.autoEsclationFrequency);
        const autoEsclationPercentage = Number(formdata?.autoEsclationPercentage);
        dataValue.goalName = formdata?.goalName.trim() || '';
        dataValue.initialInvestment = initialInvestment;
        dataValue.retirementMonth = retirementMonth;
        dataValue.retirementYear = retirementYear;
        dataValue.scheduledContributionAmount = contribAmount;
        dataValue.scheduledContributionFrequency = formdata?.scheduledContributionFrequency || '';
        dataValue.autoEsclationPercentage = autoEsclationPercentage;
        dataValue.autoEsclationFrequency = autoEsclationFrequency;
        dataValue.withdrawalPeriod = withdrawalPeriod;
        dataValue.retirementMonth = formdata?.retirementMonth || '';
        dataValue.retirementYear = formdata?.retirementYear || '';
        dataValue.withdrawalAmount = withdrawalAmount;
        dataValue.accountNumber = accountNumber || '';
        dataValue.withdrawalFrequency = formdata?.scheduledContributionFrequency || '';
        dataValue.bequest = formdata?.bequest || '';
        dataValue.goalAmount = bequestAmount;
        dataValue.goalPriorityValue = formdata?.goalPriorityValue || 4;
        dataValue.goalPriority = goalPriority;
        dataValue.goalType = formdata?.goalType || '';
        dataValue.riskProfile = data.riskProfile
            ? data.riskProfile.toLowerCase()
            : RISK_PROFILE_API_MAP[riskToleranceHelperText];

        dataValue.fundingSource = formdata?.fundingSource;
        dataValue.existingAccountId = formdata?.existingAccountId;
        dataValue.additionalFunding = formdata ? Number(removeNonNumeric(formdata.additionalFunding)) : 0;

        const { endDate, current_date, reqPayloadObj } = calculateRequestPayloadForRetire(
            dataValue,
            inflationRate || 0
        );

        dataValue.startDate = current_date;
        dataValue.endDate = endDate;
        return { reqPayloadObj };
    };
    const remodeldata = async (requestPayload: any) => {
        const clientSummary = cloneDeep(personalizedPlan.clientSummary);
        const formFieldArr: any[] = [];
        const requestArr: any[] = [];
        let formFieldObj: any = {};
        let requestObj: any = {};
        let responseObj: any = {};
        if (edit) {
            formFieldObj = { id, goalId, isEdited: true, data };
            formFieldArr.push({ id, goalId, isEdited: true, data });
        } else {
            clientSummary.formFields[activeTabIndex] = {
                id,
                // goalId,
                data
            };
            clientSummary.request[activeTabIndex] = {
                id,
                data: requestPayload
            };
        }
        requestArr.push({ id, data: requestPayload });
        try {
            dispatch(createGoalData(requestPayload)).then((response) => {
                if (response.payload.body && response.payload.statusCode === 200) {
                    if (edit) {
                        requestObj = { id, data: requestPayload };
                        responseObj = { id, data: response.payload.body };
                        const cloneObj = cloneDeep(editGoalClientSummary);
                        cloneObj.formFields[activeTabIndex] = formFieldObj;
                        cloneObj.request[activeTabIndex] = requestObj;
                        cloneObj.response[activeTabIndex] = responseObj;
                        dispatch(editFormField(formFieldObj));
                        dispatch(editRequest(requestObj));
                        dispatch(editResponse(responseObj));
                        dispatch(editClientSummary(cloneObj));
                        dispatch(updateGoalEdited(false));
                        dispatch(updatePdfEdited(false));
                        dispatch(updateSaveExitEdited(false));
                    } else {
                        clientSummary.response[activeTabIndex] = {
                            id,
                            data: response.payload.body
                        };
                        const createClientObj = {
                            apiName: '',
                            apiVer: 'v4',
                            id: uuidv4(),
                            clientFirstName: clientInfo.investorFullName,
                            clientEmail: clientInfo.investorEmail,
                            createdBy: user?.userEmail,
                            domainEmail,
                            formFields: clientSummary.formFields,
                            request: clientSummary.request,
                            response: clientSummary.response,
                            isMultiGoal: false,
                            iwsApplied: false,
                            proposalDate: current_Date,
                            riskProfile: dataValue?.riskProfile
                        };
                        dispatch(updateGoalEdited(false));
                        dispatch(updatePdfEdited(false));
                        dispatch(updateSaveExitEdited(false));
                        dispatch(updateClientSummary(createClientObj));
                        // dispatch(updateInvestorGoalData([dataValue]));
                    }

                    // dispatch(updateLoader(false));
                } else {
                    console.log(response.payload);
                    dispatch(logEventToBackEnd('RUN_PIPE_API_ERROR'));
                    dispatch(
                        setApplicationMessage({
                            showMessage: true,
                            message: `ERROR: ${requestPayload.goalName} - ${response.payload.body}`,
                            variant: 'error'
                        })
                    );
                }
            });
        } catch (err) {
            // Handle Error Here
            // console.error(err);
        }
    };
    const getdata = async () => {
        allowGoingToNextScreen?.();
        dispatch(updateLoader(true));
        const requestPayloadArray = [...requestPayLoads];
        const response: any = await Promise.allSettled(
            requestPayloadArray.map(async (requestPayload: any, index: number) => {
                const res = await uipApiInstance.post('/api/advisorportal/runpipe', requestPayload, {
                    headers: {
                        clientEmail: domainEmail,
                        version: 4
                    }
                });
                if (res.data.body && res.data.statusCode === 200) {
                    return {
                        request: { id: index + 1, data: requestPayload },
                        response: { id: index + 1, data: res.data.body }
                    };
                } else {
                    console.log(res.data);
                    dispatch(logEventToBackEnd('RUN_PIPE_API_ERROR'));
                    return {
                        request: { id: index + 1, data: requestPayload },
                        response: { id: index + 1, data: res.data.body },
                        error: res.data
                    };
                }
            })
        );

        const formFieldArr: any[] = [];
        const requestArr: any[] = [];
        const responseObjArr: any[] = [];
        if (!isEmpty(response)) {
            if (response.some((item: any) => item.value.error)) {
                const messages = response
                    .filter((item: any) => item.value.error)
                    .map((item: any) => {
                        return `${item.value.request.data.goalName} - ${item.value.response.data};`;
                    });

                dispatch(
                    setApplicationMessage({
                        showMessage: true,
                        message: `ERROR: ${messages.join(' ')}`,
                        variant: 'error'
                    })
                );
                return;
            }

            response.forEach((item: any) => {
                requestArr.push(item.value.request);
                responseObjArr.push(item.value.response);
            });

            formFields.forEach((item: any, index: number) => {
                formFieldArr.push({ id: index + 1, data: item });
            });
            const createClientObj = {
                apiName: '',
                apiVer: 'v4',
                id: uuidv4(),
                clientFirstName: clientInfo.investorFullName,
                clientEmail: clientInfo.investorEmail,
                advisorEmail: user?.userEmail,
                domainEmail,
                formFields: formFieldArr,
                request: requestArr,
                response: responseObjArr,
                isMultiGoal: false,
                iwsApplied: false,
                proposalDate: current_Date,
                riskProfile: dataValue?.riskProfile
            };
            dispatch(updateClientSummary(createClientObj));
            dispatch(updateCopyData(createClientObj));
            dispatch(updatePdfEdited(false));
            dispatch(updateSaveExitEdited(false));
            dispatch(updateLoader(false));

            navigate('/personalized-plan');
        }
    };
    const handleWealthSplitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formFieldObj = {
            id,
            goalId,
            wealthSplit: event.target.checked,
            isEdited: true,
            data
        };
        const cloneObj = cloneDeep(editGoalClientSummary);
        cloneObj.formFields[activeTabIndex] = formFieldObj;
        dispatch(editFormField(formFieldObj));
        dispatch(editClientSummary(cloneObj));
    };
    const handleClose = () => {
        setOpen(!open);
        dispatch(updateSplitDialog(false));
    };
    const sliderValueChange = (value: number) => {
        setValue('goalPriorityValue', value);
    };
    const checkBequestValue = (e: any, name: any) => {
        if (e.target.value === TRUE) {
            setShowBequestAmount(true);
            setValue(name, e.target.value);
            setValue('goalAmount', '');
        } else {
            setShowBequestAmount(false);
            setValue(name, e.target.value);
            setValue('goalAmount', '');
        }
    };
    const checkAutoEscalation = (e: any, name: any) => {
        if (e.target.value === '0') {
            setDisableAutoEscalationFrequency(true);
            setValue(name, e.target.value);
            setValue('autoEsclationFrequency', '');
        } else {
            setDisableAutoEscalationFrequency(false);
            setValue(name, e.target.value);
            // setValue('autoEsclationFrequency', '');
        }
    };

    const resetData = () => {
        setResetDialogState(true);
    };
    const handleCloseTabModal = () => {
        setResetDialogState(false);
        setDeleteDialog(false);
    };
    const confirmationDialog = () => {
        const obj = cloneDeep(copyData);
        if (edit) {
            const data = obj.formFields[activeTabIndex].data;
            reset(data);
            const formFieldObj = { id, goalId, isEdited: true, data };
            const cloneObj = cloneDeep(editGoalClientSummary);
            const requestObj = obj.request[activeTabIndex];
            const responseObj = obj.response[activeTabIndex];
            cloneObj.formFields[activeTabIndex] = formFieldObj;
            cloneObj.request[activeTabIndex] = requestObj;
            cloneObj.response[activeTabIndex] = responseObj;
            dispatch(editFormField(formFieldObj));
            dispatch(editRequest(requestObj));
            dispatch(editResponse(responseObj));
            dispatch(editClientSummary(cloneObj));
            dispatch(updateGoalEdited(false));
            dispatch(updatePdfEdited(false));
            dispatch(updateSaveExitEdited(false));
        } else {
            const data = obj.formFields[activeTabIndex].data;
            reset(data);
            const formFieldObj = { id, data };
            const cloneObj = cloneDeep(personalizedPlan.clientSummary);
            const requestObj = obj.request[activeTabIndex];
            const responseObj = obj.response[activeTabIndex];
            cloneObj.formFields[activeTabIndex] = formFieldObj;
            cloneObj.request[activeTabIndex] = requestObj;
            cloneObj.response[activeTabIndex] = responseObj;
            dispatch(updateFormField(formFieldObj));
            dispatch(updateRequest(requestObj));
            dispatch(updateResponse(responseObj));
            dispatch(updateClientSummary(cloneObj));
            dispatch(updateGoalEdited(false));
            dispatch(updatePdfEdited(false));
            dispatch(updateSaveExitEdited(false));
        }
        setResetDialogState(false);
    };
    const convertToCommaFormatOnRefresh = (val: any, name: any) => {
        if (name === 'initialInvestment') {
            const value = numberWithCommas(removeNonNumeric(val));
            setValue(name, value);
        } else {
            const value = numberWithCommas(removeLeadingZeroes(removeNonNumeric(val)));
            setValue(name, value);
        }
    };
    // const convertToCommaFormatAndReturn = (e: any) => {
    //     const value = numberWithCommas(removeLeadingZeroes(removeNonNumeric(e.target.value)));
    //     return value;
    // };
    const convertToCommaFormat = (e: any, name: any) => {
        const value = Number(removeNonNumeric(e.target.value));
        setValue(name, `${numberWithCommas(value)}`);
    };
    const goalAmountValidation = (e: any, name: any) => {
        const value = numberWithCommas(removeLeadingZeroes(removeNonNumeric(e.target.value)));
        setValue(name, value);
    };
    const confirmDelete = () => {
        setDeleteDialog(true);
    };
    const handleDelete = () => {
        setDeleteDialog(false);
        if (goalConfirm) {
            /** this is specifically for goal personalized plan page and for add client when travesing back to add client from goal personalized plan page
             *  and we have to update copy data obj also that we are using for reset data that is n-1  */
            const checkLastActiveTabIndex = personalizedPlan.clientSummary.formFields.length - 1 === activeTabIndex;
            const obj = cloneDeep(personalizedPlan.clientSummary);
            const arr = cloneDeep(formFields);
            const request = cloneDeep(requestPayLoads);
            obj.formFields.splice(activeTabIndex, 1);
            obj.request.splice(activeTabIndex, 1);
            obj.response.splice(activeTabIndex, 1);
            arr.splice(activeTabIndex, 1);
            request.splice(activeTabIndex, 1);
            dispatch(updateInvestorGoalData(arr));
            dispatch(updateRequestPayload(request));
            dispatch(updateActiveTabIndex(checkLastActiveTabIndex ? activeTabIndex - 1 : activeTabIndex));
            dispatch(updateClientSummary(obj));
            dispatch(updateCopyData(obj));
        } else if (edit) {
            /** this one is specific for edit goal page and we have to update copy data obj also that we are using for reset data that is n-1  */
            const checkLastActiveTabIndex = editGoalClientSummary.formFields.length - 1 === activeTabIndex;
            const obj = cloneDeep(editGoalClientSummary);
            const copyObj = cloneDeep(copyData);
            obj.formFields.splice(activeTabIndex, 1);
            obj.request.splice(activeTabIndex, 1);
            obj.response.splice(activeTabIndex, 1);
            copyObj.formFields.splice(activeTabIndex, 1);
            copyObj.request.splice(activeTabIndex, 1);
            copyObj.response.splice(activeTabIndex, 1);
            const goalID: any = editGoalClientSummary.formFields[activeTabIndex].goalId;
            dispatch(deleteGoal(goalID)).then((response) => {
                if (response) {
                    dispatch(updateEditLoader(false));
                    dispatch(editClientSummary(obj));
                    dispatch(updateCopyData(copyObj));
                    dispatch(updateActiveTabIndex(checkLastActiveTabIndex ? activeTabIndex - 1 : activeTabIndex));
                    dispatch(updateLoader(false));
                    if (obj.formFields.length < 1) {
                        dispatch(updateClientSummaryModalState(false));
                        navigate('/clients');
                    }
                }
            });
        } else {
            /** this one is specific for add client accordion page */
            const arr = cloneDeep(formFields);
            const request = cloneDeep(requestPayLoads);
            arr.splice(activeTabIndex, 1);
            request.splice(activeTabIndex, 1);
            dispatch(updateInvestorGoalData(arr));
            dispatch(updateRequestPayload(request));
        }
    };

    return (
        <form onSubmit={handleSubmit(formSubmitHandler)} id="retirement-form" data-testid="retirement-form" noValidate>
            <GridContainer container spacing={2}>
                <GridContainer item xs={12} sm={4} md={goalConfirm ? 2 : edit ? 1.75 : 3}>
                    <Input
                        disabled={!goalConfirm && !edit && disableButton}
                        value={t('TEXT_RETIREMENT_SAVINGS')}
                        name="retirement-goalType"
                        label={t('LABEL_GOAL_TYPE')}
                        type="text"
                        readOnly
                    />
                </GridContainer>
                <GridContainer item xs={12} sm={4} md={goalConfirm || edit ? 3.25 : 3.5}>
                    <Controller
                        name="goalName"
                        control={control}
                        aria-invalid={errors.goalName ? 'true' : 'false'}
                        rules={{
                            required: true,
                            pattern: {
                                value: /^([a-zA-Z0-9 ]+)$/i,
                                message: t('INVESTMENT_GOAL_SECTION_GOAL_NAME_PATTERN_VALIDATION')
                            },
                            maxLength: {
                                value: 100,
                                message: t('INVESTMENT_GOAL_SECTION_GOAL_NAME_MAX_VALIDATION_TEXT')
                            },
                            minLength: {
                                value: 2,
                                message: t('INVESTMENT_GOAL_SECTION_GOAL_NAME_MIN_VALIDATION_TEXT')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <Input
                                disabled={!goalConfirm && !edit && disableButton}
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                name={`retirement-${name}`}
                                inputRef={ref}
                                error={!!errors.goalName}
                                label={t('LABEL_GOAL_NAME')}
                                type="text"
                                placeholder={t('PLEACEHOLDER_ENTER_NAME')}
                            />
                        )}
                    />
                    {errors.goalName && <ErrorSpan role="alert">{errors.goalName.message}</ErrorSpan>}
                </GridContainer>

                {!(clientData?.externalData?.data?.accountList?.length && enableFundingSourceDropDown) ? (
                    <GridContainer item xs={12} sm={4} md={edit ? 1.5 : goalConfirm ? 2.5 : 2.5}>
                        <Controller
                            name="initialInvestment"
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 25,
                                pattern: {
                                    value: /^[^.]*$/,
                                    message: t('TEXT_AMOUNT_FIELD_DECIMAL_VALIDATION')
                                }
                            }}
                            render={({ field: { onChange, value, ref, name, onBlur } }) => (
                                <Input
                                    disabled={!goalConfirm && !edit && disableButton}
                                    onChange={(e) => {
                                        e.target.value = formatCurrency(e.target.value);
                                        onChange(e);
                                    }}
                                    onBlur={(e: any) => {
                                        if (e.target.value) {
                                            convertToCommaFormat(e, name);
                                        }
                                        onBlur();
                                    }}
                                    name={`retirement-${name}`}
                                    value={value}
                                    inputRef={ref}
                                    error={!!errors.initialInvestment}
                                    label={t('LABEL_INITIAL_WEALTH')}
                                    type="text"
                                    currencysymbol={isHighPoint ? 'MXN$' : '$'}
                                />
                            )}
                        />
                        {errors.initialInvestment && (
                            <ErrorSpan role="alert" sx={{ position: 'absolute' }}>
                                {errors.initialInvestment.message}
                            </ErrorSpan>
                        )}
                    </GridContainer>
                ) : null}

                {showAccountNumber && (
                    <GridContainer item xs={12} sm={6} md={2}>
                        <Controller
                            name="accountNumber"
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 36,
                                pattern: {
                                    value: /^([a-zA-Z0-9 ]+)$/i,
                                    message: t('INVESTMENT_GOAL_SECTION_GOAL_NAME_PATTERN_VALIDATION')
                                },
                                minLength: {
                                    value: 2,
                                    message: t('INVESTMENT_GOAL_SECTION_GOAL_NAME_MIN_VALIDATION_TEXT')
                                }
                            }}
                            render={({ field: { onChange, value, ref, name, onBlur } }) => (
                                <Input
                                    disabled={!goalConfirm && !edit && disableButton}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    type="text"
                                    value={value}
                                    name={`retirement-${name}`}
                                    inputRef={ref}
                                    error={!!errors.accountNumber}
                                    label={t('LABEL_ACCOUNT_NUMBER')}
                                    placeholder={t('PLACEHOLDER_ACCOUNT_NUMBER')}
                                />
                            )}
                        />

                        {errors.accountNumber && (
                            <ErrorSpan role="alert" sx={{ position: 'absolute' }}>
                                {errors.accountNumber.message}
                            </ErrorSpan>
                        )}
                    </GridContainer>
                )}

                {edit && (
                    <GridContainer id="retirement_field_container" item xs={12} sm={6} md={2.5}>
                        <TooltipContainer>
                            <CheckboxComponent
                                id="weath-splitter"
                                value={wealthSplit}
                                checked={!!wealthSplit}
                                disabled={!editGoalPageFormFieldLength}
                                onChange={handleWealthSplitChange}
                                label={t('LABEL_WEALTH_SPLITTER')}
                            />
                        </TooltipContainer>
                    </GridContainer>
                )}
                <Dialog open={open} applySplit={applySplit} handleClose={handleClose} />
                <Modal handleClose={handleCloseTabModal} open={DeleteDialog || resetDialogState}>
                    <ModalContent id="modal-modal-title" variant="body1">
                        {DeleteDialog
                            ? 'Are you sure you want to delete this goal? this goal will be deleted.'
                            : ' Are you sure you want to reset the data? your current data will be lost.'}
                    </ModalContent>
                    <CtaContainer>
                        <ButtonComponent variant="outlined" size="medium" onClick={handleCloseTabModal}>
                            {t('TEXT_NO')}
                        </ButtonComponent>
                        <ButtonComponent
                            variant="contained"
                            size="medium"
                            onClick={DeleteDialog ? handleDelete : confirmationDialog}
                        >
                            {t('TEXT_YES')}
                        </ButtonComponent>
                    </CtaContainer>
                </Modal>
                {(goalConfirm || edit) && (
                    <GridContainer item xs={12} sm={4} md={3}>
                        <RemodelButtonContainer>
                            <ButtonContainer>
                                <ButtonComponent type="reset" onClick={resetData} variant="outlined">
                                    {t('BUTTON_RESET')}
                                </ButtonComponent>
                            </ButtonContainer>
                            {(goalConfirmPageFormFieldLength || editGoalPageFormFieldLength || edit) && (
                                <ButtonContainer>
                                    <ButtonComponent variant="outlined" data-testid="delete" onClick={confirmDelete}>
                                        Delete
                                    </ButtonComponent>
                                </ButtonContainer>
                            )}
                            <ButtonContainer>
                                <ButtonComponent
                                    variant={
                                        !(!checkIfFormValuesAreEqual(data, currentFormValues) && isValid)
                                            ? 'outlined'
                                            : 'contained'
                                    }
                                    custom={+!(!checkIfFormValuesAreEqual(data, currentFormValues) && isValid)}
                                    disabled={!(!checkIfFormValuesAreEqual(data, currentFormValues) && isValid)}
                                    type="submit"
                                >
                                    {t('BUTTON_REMODEL')}
                                </ButtonComponent>
                            </ButtonContainer>
                        </RemodelButtonContainer>
                    </GridContainer>
                )}
            </GridContainer>

            {clientData?.externalData?.data?.accountList?.length && enableFundingSourceDropDown ? (
                <GridContainer container spacing={2} alignItems="flex-end">
                    <GridContainer item xs={12} sm={4} md={goalConfirm ? 2 : edit ? 1.75 : 4}>
                        <Controller
                            name="fundingSource"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value, ref, name } }) => (
                                <SelectComponent
                                    disabled={!goalConfirm && !edit && disableButton}
                                    autoWidth={false}
                                    multiple={false}
                                    native={false}
                                    inputRef={ref}
                                    name={`retirement-${name}`}
                                    // defaultval={+true}
                                    data={fundingSources}
                                    value={value}
                                    label={t('LABEL_FUNDING_SOURCE')}
                                    placeholder={t('PLACEHOLDER_FUNDING_SOURCE')}
                                    id="fundingSource"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            )}
                        />
                    </GridContainer>

                    {fundingSourceValue === FUNDING_SOURCE_ENTER_AMOUNT_VALUE ? (
                        <GridContainer item xs={12} sm={4} md={goalConfirm || edit ? 3.25 : 4}>
                            <Controller
                                name="initialInvestment"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: 25,
                                    pattern: {
                                        value: /^[^.]*$/,
                                        message: t('TEXT_AMOUNT_FIELD_DECIMAL_VALIDATION')
                                    }
                                }}
                                render={({ field: { onChange, value, ref, name, onBlur } }) => (
                                    <Input
                                        disabled={!goalConfirm && !edit && disableButton}
                                        onChange={(e) => {
                                            e.target.value = formatCurrency(e.target.value);
                                            onChange(e);
                                        }}
                                        name={`retirement-${name}`}
                                        value={value}
                                        onBlur={(e: any) => {
                                            if (e.target.value) {
                                                convertToCommaFormat(e, name);
                                            }
                                            onBlur();
                                        }}
                                        inputRef={ref}
                                        error={!!errors.initialInvestment}
                                        label={t('LABEL_INITIAL_WEALTH')}
                                        type="text"
                                        currencysymbol={isHighPoint ? 'MXN$' : '$'}
                                    />
                                )}
                            />

                            {errors.initialInvestment && (
                                <ErrorSpan role="alert" sx={{ position: 'absolute' }}>
                                    {errors.initialInvestment.message}
                                </ErrorSpan>
                            )}
                        </GridContainer>
                    ) : null}

                    {fundingSourceValue === FUNDING_SOURCE_EXISTING_ACCOUNTS_VALUE ? (
                        <>
                            <GridContainer item xs={12} sm={4} md={goalConfirm ? 2 : edit ? 1.75 : 4}>
                                <Controller
                                    name="existingAccountId"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, onBlur, value, ref, name } }) => (
                                        <SelectAccountComponent
                                            disabled={!goalConfirm && !edit && disableButton}
                                            autoWidth={false}
                                            multiple={false}
                                            native={false}
                                            inputRef={ref}
                                            name={`retirement-${name}`}
                                            // defaultval={+true}
                                            data={getExistingAccountsForSelect(
                                                clientData?.externalData?.data?.accountList
                                            )}
                                            value={value}
                                            label={t('LABEL_EXISTING_ACCOUNT_PICKER')}
                                            placeholder={t('PLACEHOLDER_EXISTING_ACCOUNT_PICKER')}
                                            id="existingAccountId"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        />
                                    )}
                                />
                            </GridContainer>

                            <GridContainer item md={1.5} sm={6} xs={6}>
                                <Controller
                                    name="additionalFunding"
                                    control={control}
                                    rules={{
                                        maxLength: 25,
                                        pattern: {
                                            value: /^[^.]*$/,
                                            message: t('TEXT_AMOUNT_FIELD_DECIMAL_VALIDATION')
                                        }
                                    }}
                                    render={({ field: { onChange, value, ref, name, onBlur } }) => (
                                        <Input
                                            disabled={!goalConfirm && !edit && disableButton}
                                            onChange={(e) => {
                                                e.target.value = formatCurrency(e.target.value, false);
                                                onChange(e);
                                            }}
                                            name={`retirement-${name}`}
                                            error={!!errors.additionalFunding}
                                            value={value}
                                            onBlur={(e: any) => {
                                                if (e.target.value) {
                                                    convertToCommaFormat(e, name);
                                                }
                                                onBlur();
                                            }}
                                            inputRef={ref}
                                            label={t('LABEL_ADDITIONAL_FUNDING_AMOUNT')}
                                            type="text"
                                            currencysymbol={isHighPoint ? 'MXN$' : '$'}
                                        />
                                    )}
                                />

                                {errors.additionalFunding && (
                                    <ErrorSpan role="alert" sx={{ position: 'absolute' }}>
                                        {errors.additionalFunding.message}
                                    </ErrorSpan>
                                )}
                            </GridContainer>

                            <GridContainer item md={1.5} sm={6} xs={6}>
                                <Controller
                                    name="initialInvestment"
                                    control={control}
                                    rules={{
                                        required: true,
                                        maxLength: 25
                                    }}
                                    render={({ field: { onChange, value, ref, name } }) => (
                                        <Input
                                            disabled={true}
                                            onChange={onChange}
                                            name={`retirement-${name}`}
                                            value={value}
                                            inputRef={ref}
                                            error={!!errors.initialInvestment}
                                            label={
                                                <ContainerWithSuperScript>
                                                    <Typography variant="body2" color="black">
                                                        {t('LABEL_TOTAL_INITIAL_FUNDING_AMOUNT')}
                                                    </Typography>
                                                    {enableSourceCaveatNumbers && <SuperCript>3</SuperCript>}
                                                </ContainerWithSuperScript>
                                            }
                                            type="text"
                                            currencysymbol={isHighPoint ? 'MXN$' : '$'}
                                        />
                                    )}
                                />

                                {errors.initialInvestment && (
                                    <ErrorSpan role="alert">{errors.initialInvestment.message}</ErrorSpan>
                                )}
                            </GridContainer>
                        </>
                    ) : null}
                </GridContainer>
            ) : null}

            <GridContainer container spacing={2} alignItems="flex-end">
                <GridContainer item md={2.5} sm={6} xs={12}>
                    <Controller
                        name="retirementMonth"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                inputRef={ref}
                                name={`retirement-${name}`}
                                // defaultval={+true}
                                data={Months}
                                value={value}
                                label={t('LABEL_RETIREMENT_DATE')}
                                placeholder={t('PLEACEHOLDER_MONTH')}
                                id="retirementMonth"
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>
                <GridContainer item md={1.5} sm={6} xs={12}>
                    <Controller
                        name="retirementYear"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                inputRef={ref}
                                name={`retirement-${name}`}
                                data={years(shouldStartFromCurrentYear)}
                                value={value}
                                placeholder={t('PLEACEHOLDER_YEAR')}
                                id="retirementYear"
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>

                <GridContainer item md={2.75} sm={6} xs={6}>
                    <Controller
                        name="scheduledContributionAmount"
                        control={control}
                        rules={{
                            required: true,
                            maxLength: 25,
                            pattern: {
                                value: /^[^.]*$/,
                                message: t('TEXT_AMOUNT_FIELD_DECIMAL_VALIDATION')
                            }
                        }}
                        render={({ field: { onChange, value, ref, name, onBlur } }) => (
                            <Input
                                disabled={!goalConfirm && !edit && disableButton}
                                onChange={(e) => {
                                    e.target.value = formatCurrency(e.target.value, false);
                                    onChange(e);
                                }}
                                value={value}
                                onBlur={(e: any) => {
                                    if (e.target.value) {
                                        convertToCommaFormat(e, name);
                                    }
                                    onBlur();
                                }}
                                name={`retirement-${name}`}
                                inputRef={ref}
                                error={!!errors.scheduledContributionAmount}
                                label={t('LABEL_SCHEDULED_CONTRIBUTIONS')}
                                type="text"
                                currencysymbol={isHighPoint ? 'MXN$' : '$'}
                            />
                        )}
                    />

                    {errors.scheduledContributionAmount && (
                        <ErrorSpan role="alert" sx={{ position: 'absolute' }}>
                            {errors.scheduledContributionAmount.message}
                        </ErrorSpan>
                    )}
                </GridContainer>
                <GridContainer item md={1.25} sm={6} xs={6}>
                    <Controller
                        name="scheduledContributionFrequency"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                name={`retirement-${name}`}
                                inputRef={ref}
                                // defaultval={+true}
                                data={frequency}
                                value={value}
                                label={t('LABEL_FREQUENCY')}
                                placeholder={t('PLEACEHOLDER_SELECT')}
                                id="scheduledContributionFrequency"
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>

                <GridContainer item md={1.5} sm={6} xs={6}>
                    <Controller
                        name="autoEsclationPercentage"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, ref, name, onChange } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                name={`retirement-${name}`}
                                inputRef={ref}
                                data={autoEscalation()}
                                value={`${value}`}
                                label={t('LABEL_PERCENTAGE')}
                                placeholder={t('PLEACEHOLDER_SELECT')}
                                id="autoEsclationPercentage"
                                onChange={(e) => {
                                    onChange(e);
                                    checkAutoEscalation(e, name);
                                }}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>
                <GridContainer item md={1.5} sm={6} xs={6}>
                    <Controller
                        name="autoEsclationFrequency"
                        control={control}
                        rules={{ required: !DisableAutoEscalationFrequency && true }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <SelectComponent
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                name={`retirement-${name}`}
                                inputRef={ref}
                                data={autoEscalationFrequency}
                                disabled={DisableAutoEscalationFrequency || (!goalConfirm && !edit && disableButton)}
                                value={value}
                                label={t('LABEL_FREQUENCY')}
                                placeholder={t('PLEACEHOLDER_SELECT')}
                                id="autoEsclationFrequency"
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>
            </GridContainer>

            <GridContainer container spacing={2} alignItems="flex-end">
                <GridContainer item md={2.5} sm={6} xs={12}>
                    <Controller
                        name="withdrawalPeriod"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                name={`retirement-${name}`}
                                inputRef={ref}
                                data={withdrawalPeriodYears(2)}
                                value={value}
                                label={t('LABEL_WITHDRAWAL_PERIOD')}
                                placeholder={t('PLEACEHOLDER_SELECT')}
                                id="withdrawalPeriod"
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>
                <GridContainer item md={1.5} sm={6} xs={12}>
                    <Controller
                        name="retirementMonth"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                inputRef={ref}
                                // defaultval={+true}
                                name={`retirement-${name}`}
                                data={Months}
                                value={value}
                                label={t('LABEL_STARTING_ON')}
                                placeholder={t('PLEACEHOLDER_MONTH')}
                                id="retirementMonth"
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>
                <GridContainer item md={1.25} sm={6} xs={12}>
                    <Controller
                        name="retirementYear"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                inputRef={ref}
                                data={years(shouldStartFromCurrentYear)}
                                name={`retirement-${name}`}
                                value={value}
                                placeholder={t('PLEACEHOLDER_YEAR')}
                                id="retirementYear"
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>
                <GridContainer item md={1.5} sm={6} xs={12}>
                    <Controller
                        name="withdrawalAmount"
                        control={control}
                        rules={{
                            required: true,
                            maxLength: 25,
                            pattern: {
                                value: /^[^.]*$/,
                                message: t('TEXT_AMOUNT_FIELD_DECIMAL_VALIDATION')
                            }
                        }}
                        render={({ field: { onChange, value, ref, name, onBlur } }) => (
                            <Input
                                disabled={!goalConfirm && !edit && disableButton}
                                onChange={(e) => {
                                    e.target.value = formatCurrency(e.target.value);
                                    onChange(e);
                                }}
                                value={value}
                                onBlur={(e: any) => {
                                    if (e.target.value) {
                                        convertToCommaFormat(e, name);
                                    }
                                    onBlur();
                                }}
                                inputRef={ref}
                                name={`retirement-${name}`}
                                error={!!errors.withdrawalAmount}
                                type="text"
                                currencysymbol={isHighPoint ? 'MXN$' : '$'}
                                label={t('LABEL_AMOUNT')}
                            />
                        )}
                    />

                    {errors.withdrawalAmount && (
                        <ErrorSpan role="alert" sx={{ position: 'absolute' }}>
                            {errors.withdrawalAmount.message}
                        </ErrorSpan>
                    )}
                </GridContainer>
                <GridContainer item md={1.25} sm={6} xs={12}>
                    <Controller
                        name="scheduledContributionFrequency"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                inputRef={ref}
                                name={`retirement-${name}`}
                                data={frequency}
                                value={value}
                                // defaultval={+true}
                                label={t('LABEL_FREQUENCY')}
                                placeholder={t('PLEACEHOLDER_SELECT')}
                                id="withdrawalFrequency"
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>
                <GridContainer item md={3} sm={6} xs={12}>
                    <Controller
                        name="goalPriorityValue"
                        control={control}
                        render={({ field: { value, name, onBlur } }) => (
                            <SliderComponent
                                valueChange={sliderValueChange}
                                min={1}
                                max={4}
                                step={1}
                                val={value}
                                name={`retirement-${name}`}
                                marks={marks}
                                disabled={!goalConfirm && !edit && disableButton}
                                onBlur={onBlur}
                                // error={!!errors.initialInvestment}
                                label={t('LABEL_GOAL_PRIORITY')}
                            />
                        )}
                    />
                </GridContainer>
            </GridContainer>

            <GridContainer container spacing={3} alignItems="flex-end">
                <GridContainer item xs={12} sm={6} md={1.2}>
                    <Controller
                        name="bequest"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onBlur, onChange, value, ref, name } }) => (
                            <SelectComponent
                                disabled={!goalConfirm && !edit && disableButton}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                inputRef={ref}
                                name={`retirement-${name}`}
                                data={bequestValue}
                                value={value}
                                label={t('LABEL_BEQUEST')}
                                placeholder={t('PLEACEHOLDER_SELECT')}
                                id="bequest"
                                onChange={(e) => {
                                    onChange(e);
                                    checkBequestValue(e, name);
                                }}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </GridContainer>
                {showBequestAmount && (
                    <GridContainer item xs={12} sm={6} md={1.5}>
                        <Controller
                            name="goalAmount"
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 25,
                                pattern: {
                                    value: /^[^.]*$/,
                                    message: t('TEXT_AMOUNT_FIELD_DECIMAL_VALIDATION')
                                }
                            }}
                            render={({ field: { onChange, value, ref, name, onBlur } }) => (
                                <Input
                                    disabled={!goalConfirm && !edit && disableButton}
                                    onChange={(e) => {
                                        e.target.value = formatCurrency(e.target.value);
                                        onChange(e);
                                        goalAmountValidation(e, name);
                                    }}
                                    value={value}
                                    onBlur={(e: any) => {
                                        if (e.target.value) {
                                            convertToCommaFormat(e, name);
                                        }
                                        onBlur();
                                    }}
                                    name={`retirement-${name}`}
                                    inputRef={ref}
                                    error={!!errors.goalAmount}
                                    label={t('LABEL_TARGET_BEQUEST_VALUE')}
                                    type="text"
                                    currencysymbol={isHighPoint ? 'MXN$' : '$'}
                                    placeholder={t('PLEACEHOLDER_AMOUNT')}
                                />
                            )}
                        />

                        {errors.goalAmount && (
                            <ErrorSpan role="alert" sx={{ position: 'absolute' }}>
                                {errors.goalAmount.message}
                            </ErrorSpan>
                        )}
                    </GridContainer>
                )}
            </GridContainer>

            <GridContainer container spacing={2} sx={{ marginTop: '15px' }}>
                <GridContainer item xs={12} sm={6} md={4}>
                    {t('TEXT_REQUIRED_FIELD')}
                </GridContainer>
            </GridContainer>

            {clientData?.externalData?.data?.accountList?.length &&
                enableFundingSourceDropDown &&
                enableSourceCaveatNumbers &&
                fundingSourceValue === FUNDING_SOURCE_EXISTING_ACCOUNTS_VALUE && (
                    <DescTypography>
                        {t('ORIGIN_OF_DATA_POINT_3', {
                            asOfDate: moment(clientData?.externalData?.data?.lastUpdatedAt).format('MM/DD/YYYY')
                        })}
                    </DescTypography>
                )}

            {!goalConfirm && !edit && (
                <AccordianFooter>
                    <ButtonContainer>
                        <ButtonComponent variant="outlined" data-testid="retirement-cancel" onClick={handleCancel}>
                            {t('BUTTON_CANCEL')}
                        </ButtonComponent>
                    </ButtonContainer>
                    {!checkLastPageGoalIsSaved && formFields.length > 1 && (
                        <ButtonContainer>
                            <ButtonComponent variant="outlined" data-testid="retirement-cancel" onClick={confirmDelete}>
                                Delete
                            </ButtonComponent>
                        </ButtonContainer>
                    )}
                    <ButtonContainer>
                        <ButtonComponent
                            type="submit"
                            data-testid="retirement-addAnotherGoal"
                            variant={!isValid || disableButton ? 'outlined' : 'contained'}
                            custom={+!isValid || +disableButton}
                            disabled={!isValid || disableButton}
                            onClick={() => setAddAnotherGoal(true)}
                        >
                            {t('BUTTON_ADD_ANOTHER_GOAL')}
                        </ButtonComponent>
                    </ButtonContainer>
                    <ButtonContainer>
                        <ButtonComponent
                            type="submit"
                            data-testid="retirement-getYourPlan"
                            variant={!isValid || disableButton ? 'outlined' : 'contained'}
                            custom={+!isValid || +disableButton}
                            disabled={!isValid || disableButton}
                        >
                            {t('BUTTON_GET_YOUR_PLANS')}
                        </ButtonComponent>
                    </ButtonContainer>
                </AccordianFooter>
            )}
        </form>
    );
}
