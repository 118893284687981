import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { cloneDeep, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Typography, { TypographyProps } from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import AddClientInformation from '../../components/molecules/client-Info';
import RiskTolerance from '../../components/molecules/risk-tolerance';
import ButtonComponent from '../../components/atoms/button';
import { FormInput } from '../../components/molecules/client-Info';
import { RISK_PROFILE_MAP } from '../../constants';
import Modal from '../../components/molecules/modal';
import { CtaContainer } from '../../components/molecules/modal/styles';
import {
    updateClient,
    updateActiveTabIndex,
    updateClientSummary as updateClientSummaryOnView,
    updateFormField as updateFormFieldOnView,
    updateRequest as updateRequestOnView,
    updateResponse as updateResponseOnView
} from '../../features/client-summary';
import { updateClientSummary, updateFormField, updateRequest, updateResponse } from '../../features/edit-goal';
import { createGoalData } from '../../features/add-client/add-client';
import { updateGlobalLoaderState, updateCopyData } from '../../features/global/globalSlice';
import { RISK_PROFILE_API_MAP } from '../../constants';
import GlobalLoader from '../../components/molecules/global-loader';

const StyledContainer = styled('div')(() => ({
    width: '100%',
    padding: '20px 0'
}));

const StyledHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main
}));

const RiskWrapper = styled('div')(() => ({
    width: '70%',
    margin: '30px auto',
    '@media (max-width: 991px)': {
        width: '100%'
    }
}));

const AccordianFooter = styled('div')(() => ({
    marginTop: '10px',
    marginBottom: '30px',
    textAlign: 'right'
}));

const ButtonContainer = styled('span')(() => ({
    display: 'inline-block',
    margin: '6px'
}));

const ModalHeader = styled(Typography)<TypographyProps>(() => ({
    fontWeight: 600
}));

const RiskChange = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px'
}));

const SideRiskWrapper = styled('div')(() => ({
    margin: '20px'
}));

const SideRiskName = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.grey[900]
}));

const SideRiskValue = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontSize: '30px',
    fontWeight: 400
}));

const ArrowContainer = styled('div')(({ theme }) => ({
    color: theme.palette.grey[600]
}));

function formatClientData(clientSummary: any) {
    return {
        investorFullName:
            clientSummary.clientFirstName + (clientSummary.clientLastName ? ' ' + clientSummary.clientLastName : ''),
        investorEmail: clientSummary.clientEmail,
        investorDOB: clientSummary.clientDOB
    };
}

function formatRiskData(riskProfileData: any, riskSelected: string) {
    return riskProfileData.map((x: any) => {
        if (x.label === RISK_PROFILE_MAP[riskSelected]) {
            return {
                ...x,
                active: true
            };
        } else {
            return x;
        }
    });
}

export default function ClientProfile() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { userId } = useAppSelector((state: any) => state.auth.user);
    const clientSummary = useAppSelector((state: any) => state.client.clientSummary || {});
    const riskProfileData = useAppSelector((state: any) => state.global.orgInfo?.riskProfile);
    const isUpdatingClient = useAppSelector((state: any) => state.client.clientSummary.isUpdatingClient);
    const riskSelected = clientSummary?.riskTolerance?.toLowerCase() || '';
    const formattedClientSummary = formatClientData(clientSummary);
    const [clientData, setClientData] = useState(formattedClientSummary);
    const [riskData, setRiskData] = useState(formatRiskData(riskProfileData, riskSelected));
    const newRisk = riskData.find((data: any) => data.active);
    const [modalOpen, setModalOpen] = useState<string | boolean>(false);

    useEffect(() => {
        setRiskData(formatRiskData(riskProfileData, riskSelected));
    }, [riskSelected]);

    const form = useForm<FormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: clientData
    });

    useEffect(() => {
        setClientData(formattedClientSummary);
        form.reset(formattedClientSummary);
    }, [
        form.reset,
        formattedClientSummary?.investorDOB,
        formattedClientSummary?.investorEmail,
        formattedClientSummary?.investorFullName
    ]);

    const dob = form.watch('investorDOB');
    const fullName = form.watch('investorFullName');
    const email = form.watch('investorEmail');

    const formattedDob = dob ? moment(dob).format(t('TEXT_CLIENT_INFO_INVESTOR_DOB_FORMAT')) : '';
    const investorDob =
        clientData.investorDOB && moment(clientData.investorDOB).format(t('TEXT_CLIENT_INFO_INVESTOR_DOB_FORMAT'));
    const formattedClientDatadob = clientData.investorDOB ? investorDob : '';

    const changeActive = (index: any) => {
        let data = cloneDeep(riskData);
        data = data.map((itm: any, i: number) => ({
            ...itm,
            active: i === index
        }));
        setRiskData(data);
    };

    async function remodelAll(newRiskValue: string, data: any) {
        dispatch(updateGlobalLoaderState(true));
        let updatedClientSummary = cloneDeep(clientSummary);
        const response = await Promise.allSettled(
            clientSummary.request.map(async (req: any) => {
                const res = await dispatch(createGoalData({ ...req.data, riskProfile: newRiskValue }));
                if (res.payload?.body) {
                    return {
                        request: {
                            id: req.id,
                            goalId: req.goalId,
                            data: { ...req.data, riskProfile: newRiskValue }
                        },
                        response: { id: req.id, goalId: req.goalId, data: res.payload.body }
                    };
                }
            })
        );
        const requestArr: any[] = [];
        const responseObjArr: any[] = [];
        if (!isEmpty(response)) {
            response.forEach((item: any) => {
                requestArr.push(item.value.request);
                responseObjArr.push(item.value.response);
            });

            updatedClientSummary = {
                ...updatedClientSummary,
                request: requestArr,
                response: responseObjArr,
                clientFirstName: data.firstName,
                clientEmail: data.email,
                riskTolerance: data.riskTolerance,
                clientDOB: data.dob
            };
            dispatch(updateCopyData(updatedClientSummary));
            dispatch(updateClientSummary(updatedClientSummary));
            dispatch(updateActiveTabIndex(0));
            dispatch(updateRequest(updatedClientSummary.request[0]));
            dispatch(updateFormField(updatedClientSummary.formFields[0]));
            dispatch(updateResponse(updatedClientSummary.response[0]));
            navigate('/edit-goal', {
                state: {
                    updateClientPayload: data,
                    nextNavigationRoute: modalOpen === '/clients' ? '/clients' : '/client-profile'
                }
            });

            // await dispatch(updateGoal(updatedClientSummary));
        }
        dispatch(updateGlobalLoaderState(false));
    }

    async function handleSave(data = clientData) {
        const newRiskValue = RISK_PROFILE_API_MAP[newRisk.label];
        const payload = {
            id: clientSummary.assignedClientId,
            firstName: data.investorFullName,
            email: data.investorEmail,
            dob: data.investorDOB,
            riskTolerance: newRiskValue,
            userId,
            externalData: clientSummary.externalData
        };

        if (newRiskValue !== riskSelected) {
            remodelAll(newRiskValue, payload);
        } else {
            await dispatch(updateClient(payload));
            let updatedClientSummary = cloneDeep(clientSummary);

            updatedClientSummary = {
                ...updatedClientSummary,
                clientFirstName: data.investorFullName,
                clientEmail: data.investorEmail,
                clientDOB: data.investorDOB
            };

            dispatch(updateCopyData(updatedClientSummary));
            dispatch(updateClientSummaryOnView(updatedClientSummary));
            dispatch(updateActiveTabIndex(0));
            dispatch(updateRequestOnView(updatedClientSummary.request[0]));
            dispatch(updateFormFieldOnView(updatedClientSummary.formFields[0]));
            dispatch(updateResponseOnView(updatedClientSummary.response[0]));
        }
    }

    function handleUpdateClient(data: any, exit: any) {
        setClientData(data);
        const newRiskValue = RISK_PROFILE_API_MAP[newRisk.label];
        if (newRiskValue !== riskSelected) {
            setModalOpen(exit === true ? '/clients' : true);
        } else {
            handleSave(data);
            if (exit === true) {
                navigate('/clients');
            }
        }
    }

    if (isUpdatingClient) {
        return <GlobalLoader open />;
    }

    return (
        <StyledContainer>
            <StyledHeader variant="h2" tabIndex={0}>
                {t('CLIENT_PROFILE_HEADER')}
            </StyledHeader>
            <AddClientInformation
                handleCancel={() => {}}
                handleNextStep={() => {}}
                clientData={clientData}
                hideActions
                form={form}
            />
            <RiskWrapper>
                <RiskTolerance
                    heading1={'HEADER_SUB_RISK_TOLERANCE_SECTION1'}
                    heading2={'HEADER_SUB_RISK_TOLERANCE_SECTION2'}
                    subheading1={'HEADER_SUB_HELPER_RISK_TOLERANCE_SECTION1'}
                    subheading2={'HEADER_SUB_HELPER_RISK_TOLERANCE_SECTION2'}
                    showHr
                    handleCancel={() => {}}
                    handleStartOverForFreemium={() => {}}
                    handleNextStep={() => {}}
                    hideActions
                    riskData={riskData}
                    changeActive={changeActive}
                />
            </RiskWrapper>
            <AccordianFooter>
                <ButtonContainer>
                    <ButtonComponent
                        data-testid="cancel"
                        variant="outlined"
                        onClick={() => {
                            navigate('/clients');
                        }}
                    >
                        {t('BUTTON_CANCEL')}
                    </ButtonComponent>
                </ButtonContainer>
                {/* <ButtonContainer>
                    <ButtonComponent
                        data-testid="save-client-profile"
                        variant="contained"
                        onClick={form.handleSubmit(handleUpdateClient)}
                        custom={
                            +!(
                                form.formState?.isValid &&
                                (formattedDob !== formattedClientDatadob ||
                                    fullName !== clientData.investorFullName ||
                                    email !== clientData.investorEmail ||
                                    t(newRisk.label).toLowerCase() !== riskSelected)
                            )
                        }
                        disabled={
                            !(
                                form.formState?.isValid &&
                                (formattedDob !== formattedClientDatadob ||
                                    fullName !== clientData.investorFullName ||
                                    email !== clientData.investorEmail ||
                                    t(newRisk.label).toLowerCase() !== riskSelected)
                            )
                        }
                    >
                        {t('BUTTON_SAVE')}
                    </ButtonComponent>
                </ButtonContainer> */}
                <ButtonContainer>
                    <ButtonComponent
                        data-testid="save-and-exit-client-profile"
                        variant="contained"
                        onClick={() => {
                            form.handleSubmit((data) => handleUpdateClient(data, true))();
                        }}
                        custom={
                            +!(
                                form.formState?.isValid &&
                                (formattedDob !== formattedClientDatadob ||
                                    fullName !== clientData.investorFullName ||
                                    email !== clientData.investorEmail ||
                                    t(newRisk.label).toLowerCase() !== riskSelected)
                            )
                        }
                        disabled={
                            !(
                                form.formState?.isValid &&
                                (formattedDob !== formattedClientDatadob ||
                                    fullName !== clientData.investorFullName ||
                                    email !== clientData.investorEmail ||
                                    t(newRisk.label).toLowerCase() !== riskSelected)
                            )
                        }
                    >
                        {t('BUTTON_SAVE_AND_EXIT')}
                    </ButtonComponent>
                </ButtonContainer>
            </AccordianFooter>
            <Modal handleClose={() => setModalOpen(false)} open={Boolean(modalOpen)}>
                <ModalHeader variant="h3" tabIndex={0}>
                    {t('EDIT_CLIENT_CONFIRMATION_POPUP_TITLE')}
                </ModalHeader>
                <Typography tabIndex={0}>{t('EDIT_CLIENT_CONFIRMATION_POPUP_TEXT')}</Typography>
                <RiskChange>
                    <SideRiskWrapper>
                        <SideRiskName tabIndex={0}>{t('EDIT_CLIENT_CONFIRMATION_POPUP_LEFT_RISK_NAME')}</SideRiskName>
                        <SideRiskValue tabIndex={0}>{t(RISK_PROFILE_MAP[riskSelected])}</SideRiskValue>
                    </SideRiskWrapper>
                    <ArrowContainer>
                        <ArrowForwardIcon />
                    </ArrowContainer>
                    <SideRiskWrapper>
                        <SideRiskName tabIndex={0}>{t('EDIT_CLIENT_CONFIRMATION_POPUP_RIGHT_RISK_NAME')}</SideRiskName>
                        <SideRiskValue tabIndex={0}>{t(newRisk?.label)}</SideRiskValue>
                    </SideRiskWrapper>
                </RiskChange>
                <CtaContainer>
                    <ButtonComponent
                        variant="outlined"
                        size="medium"
                        data-testid="edit-client-confirmation-popup-cancel"
                        onClick={() => setModalOpen(false)}
                    >
                        {t('BUTTON_CANCEL')}
                    </ButtonComponent>
                    <ButtonComponent
                        variant="contained"
                        size="medium"
                        data-testid="edit-client-confirmation-popup-save"
                        onClick={() => {
                            handleSave();
                            setModalOpen(false);
                        }}
                    >
                        {t('BUTTON_SAVE_AND_EXIT')}
                    </ButtonComponent>
                </CtaContainer>
            </Modal>
        </StyledContainer>
    );
}
