import { memo, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from '../../app/hooks';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import DataTableOrganism from '../../components/organisms/advisor-admin-table';
import { AlignTableCell } from '../../components/organisms/advisor-admin-table/styles';
import TableSkeletonLoader from '../../components/organisms/advisor-admin-table/loader';
import { ClientsDataType } from '../../features/advisor-admin-fa';
import { useAppSelector } from '../../app/hooks';
import { PAGINATION, ADMIN_USERS_TABLE_ACCESS_KEYS } from '../../constants';
import { numberWithCommas, addCurrencySymbol } from '../../utils/table';
import { fetchAdvisorAdminFAClients } from '../../features/advisor-admin';
import { exportService } from '../../utils/export-services';
import moment from 'moment';

export const HeaderText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    margin: '30px 0 30px 0',
    display: 'inline-block'
}));

function AdvisrAdminFAClientsTableContainer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const clients = useAppSelector((state) => state.advisorAdmin.clients || []);
    const loading = useAppSelector((state) => state.advisorAdmin.loading);
    const selectedUser = useAppSelector((state) => state.advisorAdmin.selectedUser);

    useEffect(() => {
        dispatch(fetchAdvisorAdminFAClients(selectedUser?.id || ''));
    }, []);

    const handleAddClientClick = () => {
        navigate('/add-user');
    };

    const handleFileExport = () => {
        const excelSheetData: Array<{
            advisorName: String;
            clientName: string;
            goalName: String;
            goalAmount: String;
            model: String;
            account: Array<String>;
        }> = [];
        clients.forEach((client: any) => {
            if (client.goals?.length) {
                client.goals.forEach((goal: any) => {
                    const output = {
                        advisorName: client.advisorName || 'N/A',
                        clientName: client.clientFullName || 'N/A',
                        goalName: goal?.form_fields?.data?.goalName,
                        goalAmount: goal?.form_fields?.data?.goalAmount,
                        model: goal?.response?.data?.analysisReport?.recommendedPortfolioId,
                        account: goal?.form_fields?.data?.existingAccountId?.join(',') || []
                    };

                    excelSheetData.push(output);
                });
            } else {
                const output = {
                    advisorName: client.advisorName || 'N/A',
                    clientName: client.clientFullName || 'N/A',
                    goalName: 'N/A',
                    goalAmount: 'N/A',
                    model: 'N/A',
                    account: []
                };

                excelSheetData.push(output);
            }
        });

        exportService(excelSheetData, 'targetModelReport');
    };

    const columnsTranslation = {
        client_full_name: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_1'),
        client_email: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_2'),
        advisor_name: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_3'),
        goals: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_4'),
        portfolio_value: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_5'),
        last_modified: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_6')
    };

    const columns = useMemo<ColumnDef<ClientsDataType>[]>(
        () => [
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.CLIENT_FULL_NAME,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.CLIENT_FULL_NAME,
                header: columnsTranslation.client_full_name,
                cell: ({ getValue }) => getValue()
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.CLIENT_EMAIL,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.CLIENT_EMAIL,
                enableSorting: false,
                header: columnsTranslation.client_email,
                cell: ({ getValue }) => <AlignTableCell position="start">{getValue() as any}</AlignTableCell>
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.GOALS,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.GOALS,
                header: columnsTranslation.goals,
                enableSorting: false,
                cell: ({ getValue }) => (
                    <AlignTableCell position="start">{(getValue() as Array<any>)?.length}</AlignTableCell>
                )
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.PORTFOLIO_VALUE,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.PORTFOLIO_VALUE,
                header: columnsTranslation.portfolio_value,
                cell: ({ getValue }) => <>{addCurrencySymbol(numberWithCommas(getValue()))}</>
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.LAST_MODIFIED,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.LAST_MODIFIED,
                header: columnsTranslation.last_modified,
                enableSorting: false,
                cell: ({ getValue }) => (
                    <AlignTableCell position="start">
                        {getValue() ? moment(getValue() as string, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
                    </AlignTableCell>
                )
            }
        ],
        [clients]
    );

    return (
        <>
            <HeaderText tabIndex={0}>{t('TEXT_ADMIN_TAB_2')}</HeaderText>
            {!loading ? (
                <DataTableOrganism
                    data={clients}
                    columns={columns}
                    handleAddClientClick={handleAddClientClick}
                    columnVisibility={{}}
                    searchPlaceHolder={t('PLACEHOLDER_SEARCH')}
                    pageLimit={PAGINATION.PAGE_LIMIT}
                    hideExtraText
                    addButtonText={t('BUTTON_ADD_NEW_USER')}
                    handleFileExport={handleFileExport}
                    hideFileExport={!clients?.length}
                />
            ) : (
                <TableSkeletonLoader />
            )}
        </>
    );
}

export default memo(AdvisrAdminFAClientsTableContainer);
