import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const CtaContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'end',
    marginTop: '30px',
    '& button': {
        padding: '10px',
        fontWeight: 'bold',
        '&:first-of-type': {
            margin: '0 5px'
        }
    }
}));

export const ModalContent = styled(Typography)<TypographyProps>(() => ({
    fontSize: '30px',
    lineHeight: '38px'
}));
