/* eslint-disable indent */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import mockApiInstance from '../../axios';
import { formatTableData } from '../../utils/table';
import { PAGINATION } from '../../constants';
import { logout } from '../auth/authSlice';

interface TableData {
    clientName?: string;
    id?: string | number;
    goalName?: string;
    goalAmount?: number | string;
    goals?: number | string;
    equityFixedIncome?: number | string;
    lastModified?: number | string;
    score?: number | string;
    actualData?: number | string;
    subRows?: TableData[];
}

type InitialState = {
    pageNumber: number;
    pageLimit: number;
    totalCount: number;
    lastPage: null | number;
    from: null | number;
    to: null | number;
    actualData: Array<any>;
    tableData: Array<TableData>;
    loading: boolean;
    error?: null | undefined | any;
    showDeleteModal?: boolean;
    deleteId?: any;
    clientSummaryModalState: boolean;
};
const initialState: InitialState = {
    pageNumber: PAGINATION.PAGE_NUMBER_INITIAL,
    pageLimit: PAGINATION.PAGE_LIMIT,
    totalCount: 34,
    lastPage: null,
    from: null,
    to: null,
    actualData: [],
    tableData: [],
    loading: false,
    error: null,
    showDeleteModal: false,
    deleteId: {},
    clientSummaryModalState: false
};

export function pagination(totalCount: number, pageNumber: number, pageLimit: number) {
    return {
        lastPage: Math.ceil(totalCount / pageLimit),
        from: (pageNumber - 1) * pageLimit + 1,
        to: pageNumber * pageLimit < totalCount ? pageNumber * pageLimit : totalCount
    };
}

export const fetchServerSideProposal = createAsyncThunk(
    'proposals/fetchServerSideProposal',
    async ({ pageNumber, pageLimit }: { pageNumber: number; pageLimit: number }) => {
        try {
            const response = await mockApiInstance.get(`/proposal?_page=${pageNumber}&_limit=${pageLimit}`);
            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('err', error);
        }
    }
);

export const fetchSearchProposal = createAsyncThunk('proposals/fetchSearchProposals', async (searchString: string) => {
    const response = await mockApiInstance.get(`/proposal?q=${searchString}`);
    return response.data;
});

export const fetchSortingProposal = createAsyncThunk(
    'proposals/fetchSortingProposal',
    async ({ name, order }: { name: string; order: string }) => {
        const response = await mockApiInstance.get(`/proposal?_sort=${name}&_order=${order}`);
        return response.data;
    }
);

export const deleteGoal = createAsyncThunk('proposals/deleteGoal', async (goalId: string) => {
    const response = await mockApiInstance.get(`/proposal?q=${goalId}`);
    return response.data;
});

export const deleteClient = createAsyncThunk('proposals/deleteGoal', async (clientId: string) => {
    const response = await mockApiInstance.get(`/proposal?q=${clientId}`);
    return response.data;
});

const proposalTableSlice = createSlice({
    name: 'proposal',
    initialState,
    reducers: {
        updatePageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
        },
        updatePageLimit: (state, action) => {
            state.pageLimit = action.payload;
        },
        updateTotalCount: (state, action) => {
            state.totalCount = action.payload;
        },
        updateDeleteModalState: (state, action) => {
            state.showDeleteModal = action.payload;
        },
        updateDeleteId: (state, action) => {
            state.deleteId = action.payload;
        },
        updateClientSummaryModalState: (state, action) => {
            state.clientSummaryModalState = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(fetchServerSideProposal.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchServerSideProposal.fulfilled, (state, action) => {
                const paginationData = pagination(state.totalCount, state.pageNumber, state.pageLimit);
                state.loading = false;
                state.actualData = !isEmpty(action.payload) ? action.payload : [];
                state.tableData = !isEmpty(action.payload) ? formatTableData(action.payload, true) : [];
                state.lastPage = paginationData.lastPage;
                state.from = paginationData.from;
                state.to = paginationData.to;
                state.error = '';
            }),
            builder.addCase(fetchServerSideProposal.rejected, (state, action) => {
                state.loading = false;
                state.actualData = [];
                state.error = action.payload;
            }),
            builder.addCase(fetchSearchProposal.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchSearchProposal.fulfilled, (state, action) => {
                const paginationData = pagination(state.totalCount, state.pageNumber, state.pageLimit);
                state.loading = false;
                state.actualData = !isEmpty(action.payload) ? action.payload : [];
                state.tableData = !isEmpty(action.payload) ? formatTableData(action.payload, true) : [];
                state.lastPage = paginationData.lastPage;
                state.from = paginationData.from;
                state.to = paginationData.to;
                state.error = '';
            }),
            builder.addCase(fetchSearchProposal.rejected, (state, action) => {
                state.loading = false;
                state.actualData = [];
                state.error = action.payload;
            }),
            builder.addCase(fetchSortingProposal.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchSortingProposal.fulfilled, (state, action) => {
                const paginationData = pagination(state.totalCount, state.pageNumber, state.pageLimit);
                state.loading = false;
                state.actualData = !isEmpty(action.payload) ? action.payload : [];
                state.tableData = !isEmpty(action.payload) ? formatTableData(action.payload, true) : [];
                state.lastPage = paginationData.lastPage;
                state.lastPage = paginationData.lastPage;
                state.from = paginationData.from;
                state.to = paginationData.to;
                state.error = '';
            }),
            builder.addCase(fetchSortingProposal.rejected, (state, action) => {
                state.loading = false;
                state.actualData = [];
                state.error = action.payload;
            });
    }
});

export const {
    updatePageNumber,
    updatePageLimit,
    updateTotalCount,
    updateDeleteModalState,
    updateDeleteId,
    updateClientSummaryModalState
} = proposalTableSlice.actions;

export default proposalTableSlice.reducer;
