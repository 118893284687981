import { memo, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DataTableOrganism from '../../components/organisms/advisor-admin-table';
import { AlignTableCell } from '../../components/organisms/advisor-admin-table/styles';
import TableSkeletonLoader from '../../components/organisms/advisor-admin-table/loader';
import { ClientsDataType } from '../../features/advisor-admin';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { PAGINATION, ADMIN_USERS_TABLE_ACCESS_KEYS } from '../../constants';
import { numberWithCommas, addCurrencySymbol } from '../../utils/table';
import { fetchAllClients } from '../../features/advisor-admin';
import { exportService } from '../../utils/export-services';
import SelectComponent from '../../components/atoms/select';
import { assignAdvisorToClient } from '../../features/advisor-admin';

export const HeaderText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    margin: '30px 0 30px 0',
    display: 'inline-block'
}));

function AdvisorAdminClientsTableContainer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const clients = useAppSelector((state) => state.advisorAdmin.clients || []);
    const loading = useAppSelector((state) => state.advisorAdmin.loading);
    const financialAdvisors = useAppSelector((state) => state.advisorAdmin.financialAdvisors || []);
    const admins = useAppSelector((state) => state.advisorAdmin.admins || []);
    const porfolios = useAppSelector((state) => state.global.portfolio);

    let listOfAdvisors: {
        label: string;
        value: string;
    }[] = [];

    financialAdvisors.forEach((financialAdvisor) => {
        listOfAdvisors.push({
            label: financialAdvisor.advisorName,
            value: financialAdvisor.id
        });
    });

    admins.forEach((admin) => {
        listOfAdvisors.push({
            label: `${admin.adminName} (Admin)`,
            value: admin.id
        });
    });

    useEffect(() => {
        dispatch(fetchAllClients());
    }, []);

    const handleAddClientClick = () => {
        navigate('/add-user');
    };

    const handleFileExport = () => {
        const excelcolumnsTranslation = {
            goalId: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_0'),
            advisorName: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_1'),
            clientFullName: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_2'),
            goalName: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_3'),
            goalAmount: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_4'),
            model: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_5'),
            account: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_6'),
            fund: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_7'),
            percentageAllocated: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_8'),
            asOfToday: t('TEXT_ADMIN_CLIENTS_TARGET_MODAL_EXCEL_COLUMN_9')
        };

        const excelSheetData: any = [];
        let currentDate = new Date().toLocaleDateString();
        clients.forEach((client: any) => {
            if (client.goals?.length) {
                client.goals.forEach((goal: any, index: number) => {
                    const output = {
                        [excelcolumnsTranslation.goalId]: goal._id || index + 1,
                        [excelcolumnsTranslation.advisorName]: client.advisorName || 'N/A',
                        [excelcolumnsTranslation.clientFullName]: client.clientFullName || 'N/A',
                        [excelcolumnsTranslation.goalName]: goal?.form_fields?.data?.goalName,
                        [excelcolumnsTranslation.goalAmount]: goal?.form_fields?.data?.goalAmount,
                        [excelcolumnsTranslation.model]: goal?.response?.data?.analysisReport?.recommendedPortfolioId,
                        [excelcolumnsTranslation.account]: goal?.form_fields?.data?.existingAccountId?.join(',') || [],
                        [excelcolumnsTranslation.asOfToday]: currentDate
                    };

                    const recommendedProtfolio =
                        porfolios.find(
                            (portfolio: any) => portfolio.portfolioId === output[excelcolumnsTranslation.model]
                        )?.assets || [];

                    const tickers: any[] = [];
                    recommendedProtfolio.forEach((rp: any) => {
                        rp.subAssets.forEach((sa: any) => {
                            tickers.push({
                                [excelcolumnsTranslation.fund]: sa.ticker,
                                [excelcolumnsTranslation.percentageAllocated]: sa.allocatedValue
                            });
                        });
                    });

                    tickers.forEach((ticker: any) => {
                        excelSheetData.push({ ...output, ...ticker });
                    });
                });
            } else {
                const output = {
                    [excelcolumnsTranslation.advisorName]: client.advisorName || 'N/A',
                    [excelcolumnsTranslation.clientFullName]: client.clientFullName || 'N/A',
                    [excelcolumnsTranslation.goalName]: 'N/A',
                    [excelcolumnsTranslation.goalAmount]: 'N/A',
                    [excelcolumnsTranslation.model]: 'N/A',
                    [excelcolumnsTranslation.account]: [],
                    [excelcolumnsTranslation.asOfToday]: currentDate
                };

                excelSheetData.push(output);
            }
        });

        exportService(excelSheetData, t('TEXT_ADMIN_CLIENTS_TARGET_MODEL_REPORT_FILE_NAME'));
    };

    const handleSelectAdvisor = (payload: { userId: string; clientId: string }) => {
        dispatch(assignAdvisorToClient(payload));
    };

    const columnsTranslation = {
        client_full_name: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_1'),
        client_email: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_2'),
        advisor_name: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_3'),
        goals: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_4'),
        portfolio_value: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_5'),
        last_modified: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_6')
    };

    const columns = useMemo<ColumnDef<ClientsDataType>[]>(
        () => [
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.CLIENT_FULL_NAME,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.CLIENT_FULL_NAME,
                header: columnsTranslation.client_full_name,
                cell: ({ getValue }) => getValue()
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.CLIENT_EMAIL,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.CLIENT_EMAIL,
                enableSorting: false,
                header: columnsTranslation.client_email,
                cell: ({ getValue }) => <AlignTableCell position="start">{getValue() as any}</AlignTableCell>
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.ADVISOR_ID,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.ADVISOR_ID,
                header: columnsTranslation.advisor_name,
                enableSorting: false,
                cell: ({ getValue, row }) => {
                    return (
                        <SelectComponent
                            autoWidth={false}
                            multiple={false}
                            native={false}
                            data={listOfAdvisors.sort((a, b) => {
                                const nameA = a.label.toUpperCase(); // ignore upper and lowercase
                                const nameB = b.label.toUpperCase(); // ignore upper and lowercase
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                // names must be equal
                                return 0;
                            })}
                            name={`select-financial-advisor-for-${row.original.clientEmail}`}
                            label={''}
                            placeholder={t('TEXT_SELECT_ADVISOR')}
                            value={getValue() as any}
                            onChange={(event: any) =>
                                handleSelectAdvisor({
                                    userId: event.target.value,
                                    clientId: row.original.id
                                })
                            }
                            id="scheduledFrequency"
                            customheight="unset"
                        />
                    );
                    // return <AlignTableCell position="start">{getValue() as any}</AlignTableCell>;
                }
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.GOALS,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.GOALS,
                header: columnsTranslation.goals,
                enableSorting: false,
                cell: ({ getValue }) => (
                    <AlignTableCell position="start">{(getValue() as Array<any>)?.length}</AlignTableCell>
                )
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.PORTFOLIO_VALUE,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.PORTFOLIO_VALUE,
                header: columnsTranslation.portfolio_value,
                cell: ({ getValue }) => <>{getValue() ? addCurrencySymbol(numberWithCommas(getValue())) : 'N/A'}</>
            },
            {
                accessorKey: ADMIN_USERS_TABLE_ACCESS_KEYS.LAST_MODIFIED,
                id: ADMIN_USERS_TABLE_ACCESS_KEYS.LAST_MODIFIED,
                header: columnsTranslation.last_modified,
                enableSorting: false,
                cell: ({ getValue }) => (
                    <AlignTableCell position="start">
                        {getValue() ? moment(getValue() as any).format('MM/DD/YYYY') : 'N/A'}
                    </AlignTableCell>
                )
            }
        ],
        [clients]
    );

    return (
        <>
            <HeaderText tabIndex={0}>{t('TEXT_ADMIN_CLIENTS_HEADER')}</HeaderText>
            {!loading ? (
                <DataTableOrganism
                    data={clients}
                    columns={columns}
                    handleAddClientClick={handleAddClientClick}
                    columnVisibility={{}}
                    searchPlaceHolder={t('PLACEHOLDER_SEARCH')}
                    pageLimit={PAGINATION.PAGE_LIMIT}
                    hideExtraText
                    addButtonText={t('BUTTON_ADD_NEW_USER')}
                    handleFileExport={handleFileExport}
                />
            ) : (
                <TableSkeletonLoader />
            )}
        </>
    );
}

export default memo(AdvisorAdminClientsTableContainer);
