import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    showMessage: false,
    variant: 'error',
    message: ''
};

const messagingSlice = createSlice({
    name: 'messagingState',
    initialState,
    reducers: {
        setApplicationMessage: (state, action: PayloadAction<any>) => {
            state.showMessage = action.payload.showMessage;
            state.message = action.payload.message;
            state.variant = action.payload.variant;
        }
    },
    extraReducers: () => {}
});

export const { setApplicationMessage } = messagingSlice.actions;

export default messagingSlice.reducer;
