import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const TabButton = styled(Tab)(({ theme }) => ({
    ...theme.typography.h3,
    color: `${theme.palette.primary.main} !important`,
    textTransform: 'none',
    padding: '14px',
    borderBottom: 'none',
    fontWeight: '400',
    '&.Mui-selected': {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.light
    }
}));

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

function TabsComponent({ tabs, value, handleChange }: any) {
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="admin panel tabs" centered variant="fullWidth">
                    {tabs.map(({ label }: any, i: number) => (
                        <TabButton label={label} key={i} disableRipple />
                    ))}
                </Tabs>
            </Box>
            {tabs.map(({ Component }: any, i: number) => (
                <TabPanel value={value} index={i} key={i}>
                    {Component}
                </TabPanel>
            ))}
        </Box>
    );
}

export default TabsComponent;
