import { memo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { RequiredAuth } from './SecureRoute';
import { accordionDataObj, freemiumAccordionDataObj } from '../services/configs/configs';

import Home from '../pages/home';
import PersonalizedPlan from '../pages/personalized-plan';
import EditGoal from '../pages/edit-goal';
import AddClient from '../pages/add-client';
import ForgotPassword from '../pages/forgot-password';
import Login from '../pages/login';
import NotFound from '../pages/404';
import AddUser from '../pages/add-user';
import Admin from '../pages/advisor-admin';
import UserSummary from '../pages/user-summary';
import { useAppSelector } from '../app/hooks';
import ClientProfile from '../pages/client-profile';
import ScheduleUpcomingRelease from '../pages/schedule-upcoming-release';

const AppRoutes = () => {
    const { authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const authLoading = useAppSelector((state) => state.auth.loading);

    return (
        <Routes>
            <Route path="/" element={isAuthenticated && !authLoading ? <Navigate to="/clients" /> : <Login />} />
            <Route
                path="/forgot-password"
                element={isAuthenticated && !authLoading ? <Navigate to="/clients" /> : <ForgotPassword />}
            />
            <Route path="/clients" element={<RequiredAuth />}>
                <Route path="" element={<Home />} />
            </Route>
            <Route path="/client-profile" element={<RequiredAuth />}>
                <Route path="" element={<ClientProfile />} />
            </Route>
            <Route path="/add-goal" element={<RequiredAuth />}>
                <Route path="" element={<AddClient accordionDataObj={freemiumAccordionDataObj} />} />
            </Route>
            <Route path="/add-client" element={<RequiredAuth />}>
                <Route path="" element={<AddClient accordionDataObj={accordionDataObj} />} />
            </Route>
            <Route path="/personalized-plan" element={<RequiredAuth />}>
                <Route path="" element={<PersonalizedPlan />} />
            </Route>
            <Route path="/add-user" element={<RequiredAuth />}>
                <Route path="" element={<AddUser />} />
            </Route>
            <Route path="/edit-goal" element={<RequiredAuth />}>
                <Route path="" element={<EditGoal />} />
            </Route>
            <Route path="/admin" element={<RequiredAuth />}>
                <Route path="" element={<Admin />} />
            </Route>
            <Route path="/user" element={<RequiredAuth />}>
                <Route path="" element={<UserSummary />} />
            </Route>
            <Route path="/schedule-upcoming-release" element={<RequiredAuth />}>
                <Route path="" element={<ScheduleUpcomingRelease />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default memo(AppRoutes);
