import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from '../../utils/forms';

interface ClientContactAndPorforlio {
    clientEmail: string;
    portfolioValue: number;
    externalData?: any;
    enableSourceCaveatNumbers?: boolean;
}
const SuperCript = styled(Typography)<TypographyProps>(() => ({
    marginLeft: '10px',
    position: 'absolute',
    right: -15,
    top: -10
}));

const ClientInfoContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: '20px',
    hr: {
        background: `${theme.palette.grey[600]}`
    }
}));

const HR = styled('div')(({ theme }) => ({
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey[400],
    margin: '10px 0'
}));

const ClientInfo = ({
    clientEmail,
    portfolioValue,
    enableSourceCaveatNumbers,
    externalData
}: ClientContactAndPorforlio) => {
    const { t } = useTranslation();
    return (
        <ClientInfoContainer>
            <div className="left-section">
                <Typography tabIndex={0} variant="body2" sx={{ color: '#767676' }}>
                    {t('TEXT_PDF_MODAL_CLIENT_CONTACT_TITLE')}
                </Typography>
                <HR />
                <Typography tabIndex={0} variant="body2" sx={{ fontWeight: 700, color: 'black' }}>
                    e: {clientEmail}
                </Typography>
            </div>
            <div className="right-section">
                <Typography tabIndex={0} variant="body2" sx={{ color: '#767676' }}>
                    {t('TEXT_PDF_MODAL_PORFORLIO_VALUE_TITLE')}
                </Typography>
                <HR />
                <div>
                    <Typography tabIndex={0} variant="h3" sx={{ fontWeight: 700 }}>
                        $ {numberWithCommas(portfolioValue)}
                    </Typography>
                    {enableSourceCaveatNumbers && externalData?.data && <SuperCript>2</SuperCript>}
                </div>
            </div>
        </ClientInfoContainer>
    );
};

export default ClientInfo;
