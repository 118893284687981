import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setAutoFreeze } from 'immer';
import axios from 'axios';
import moment from 'moment';

import { uipApiInstance } from '../../axios';
import { logout } from '../auth/authSlice';

setAutoFreeze(false);

type ReleaseBannerDataType = {
    bannerEndTime?: string;
    bannerStartTime?: string;
    bannerMessage?: string;
    bannerStatus?: string;
};

type InitialState = {
    portfolio: Array<any>;
    loading: boolean;
    globalLoader: boolean;
    error?: null | undefined | any;
    showErrorModal?: boolean;
    globalConfig?: any;
    test?: any;
    showDialog: boolean;
    copyData: any;
    PdfEdited?: boolean;
    SaveEdited?: boolean;
    resetDialog?: boolean;
    activeTabIndex: number;
    showAdvisorPortalButtonOnHeader?: boolean;
    orgInfo?: any;
    domainEmail?: string;
    showEulaModal: boolean;
    openEulaFromClients: boolean;
    scheduledReleaseBanner?: ReleaseBannerDataType;
    editGoalWealthSplitterApplied?: boolean;
};

const initialState: InitialState = {
    portfolio: [],
    domainEmail: '',
    loading: false,
    globalLoader: false,
    error: {},
    showErrorModal: false,
    test: '',
    showDialog: false,
    copyData: {},
    PdfEdited: false,
    SaveEdited: false,
    resetDialog: false,
    activeTabIndex: 0,
    showAdvisorPortalButtonOnHeader: false,
    orgInfo: {},
    showEulaModal: false,
    openEulaFromClients: false,
    scheduledReleaseBanner: {},
    editGoalWealthSplitterApplied: false
};

const makeUseMemoRun = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const logEventToBackEnd = createAsyncThunk('globalState/logEventToBackEnd', async (action: string) => {
    const response = await uipApiInstance({
        method: 'POST',
        url: '/api/advisorportal/actionsaudit',
        data: {
            action
        }
    });

    return response;
});

export const fetchPortfolio = createAsyncThunk(
    'globalState/fetchPortfolio',
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state: any = getState();

            const isAuthenticated = state.auth.isAuthenticated;
            const orgEmail = state.global.orgInfo?.orgEmail;

            if (!isAuthenticated) {
                return { portfolios: [], domainEmail: '' };
            }

            const response = await uipApiInstance({
                method: 'GET',
                url: `/api/advisorportal/marketportfolios`,
                withCredentials: false
            });

            const latestDate = moment
                .max(
                    response.data.data
                        .filter((y: any) => y.orgEmail === orgEmail)
                        .map((e: any) => moment(e.__meta.timestamp, 'YYYY-MM-DD HH:mm:ss.SSSS'))
                )
                .valueOf();

            const latestResponse = response.data.data.find(
                (x: any) =>
                    moment(x.__meta.timestamp, 'YYYY-MM-DD HH:mm:ss.SSSS').valueOf() === latestDate &&
                    x.orgEmail === orgEmail
            );

            return {
                portfolios: latestResponse.portfolioData?.data || latestResponse.portfolioData?.config?.data,
                domainEmail: latestResponse.orgEmail
            };
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('MARKET_PORFOLIOS_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const getTenantBasedConfig = createAsyncThunk(
    'globalState/getTenantBasedConfig',
    async ({ tenantId, i18n }: { tenantId: string; i18n: any }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: 'GET',
                baseURL: process.env.REACT_APP_UIP_API_URL,
                url: `/api/advisorportal/gettenantinfo?subdomain=${tenantId}`,
                withCredentials: false
            });

            const tenantConfigs = response.data.tenantConfig?.sort((a: any, b: any) => {
                const convertToTime = (date: any) => moment(date).valueOf();

                return convertToTime(b?.__meta?.timestamp) - convertToTime(a?.__meta?.timestamp);
            });

            if (tenantConfigs?.[0]?.language) {
                i18n.changeLanguage(tenantConfigs?.[0]?.language?.[0]);
            } else {
                i18n.changeLanguage(tenantConfigs?.[0]?.subdomain === 'highpoint' ? 'es' : 'en');
            }

            return tenantConfigs?.[0];
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_TENANT_INFO_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const updateTenantInfo = createAsyncThunk(
    'globalState/updateTenantInfo',
    async (requestedPayload: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: `/api/advisorportal/updatetenantinfo`,
                withCredentials: false,
                data: requestedPayload
            });

            return response?.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('UPDATE_TENANT_INFO_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const getOrganizationConfig = createAsyncThunk(
    'globalState/getOrganizationConfig',
    async (_, { getState, rejectWithValue, dispatch }) => {
        const state: any = getState();

        const tenantId = state?.global?.globalConfig?.subdomain;
        try {
            const response = await uipApiInstance({
                method: 'GET',
                url: `/api/advisorportal/getorganizationconfig?tenant=${tenantId}`,
                withCredentials: false
            });

            return response?.data?.tenantConfig?.[0];
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_ORGANIZATION_CONFIG_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const getCSRFToken = createAsyncThunk('globalState/getCSRFToken', async () => {
    const response = await uipApiInstance({
        method: 'GET',
        url: '/getCSRFToken'
    });
    uipApiInstance.defaults.headers.post['X-CSRF-Token'] = response.data.CSRFToken;
});

export const getReleaseBanner = createAsyncThunk('globalState/getReleaseBanner', async () => {
    const response = await uipApiInstance({
        method: 'GET',
        url: '/api/advisorportal/getbanner'
    });

    return response.data;
});

export const updateReleaseBanner = createAsyncThunk(
    'globalState/updateReleaseBanner',
    async (releaeBannerData: ReleaseBannerDataType, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advisorportal/updatebanner',
                data: releaeBannerData
            });

            return response.data?.tenantConfig?.banner_info;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('UPDATE_RELEASE_BANNER_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

const globalSlice = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        updateErrorModalState: (state, action: PayloadAction<any>) => {
            state.showErrorModal = action.payload.showErrorModal;
            state.error = action.payload.error;
        },
        updateLayoutColor: (state, action: PayloadAction<any>) => {
            state.test = makeUseMemoRun();
            state.globalConfig.styles = {
                ...state.globalConfig.styles,
                ...action.payload
            };
        },
        updateLogo: (state, action: PayloadAction<any>) => {
            state.globalConfig.logo = action.payload;
        },
        updateSubdomain: (state, action: PayloadAction<any>) => {
            state.globalConfig.subdomain = action.payload;
        },
        updateDialogState: (state, action: PayloadAction<any>) => {
            state.showDialog = action.payload;
        },
        updateGlobalLoaderState: (state, action: PayloadAction<any>) => {
            state.globalLoader = action.payload;
        },
        updatePdfEdited: (state, action: PayloadAction<any>) => {
            state.PdfEdited = action.payload;
        },
        updateSaveExitEdited: (state, action: PayloadAction<any>) => {
            state.SaveEdited = action.payload;
        },
        updateCopyData: (state, action: PayloadAction<any>) => {
            state.copyData = action.payload;
        },
        updateResetDialog: (state, action: PayloadAction<any>) => {
            state.resetDialog = action.payload;
        },
        updateActiveTabIndex: (state, action: PayloadAction<any>) => {
            state.activeTabIndex = action.payload;
        },
        updateShowAdvisorPortalButtonOnHeader: (state, action: PayloadAction<any>) => {
            state.showAdvisorPortalButtonOnHeader = action.payload;
        },
        toggleEditGoalWealthSplitterApplied: (state, action: PayloadAction<any>) => {
            state.editGoalWealthSplitterApplied = action.payload;
        },
        handleOpenEulaModal: (state, action: PayloadAction<any>) => {
            state.showEulaModal = action.payload;
        },
        handleOpenEulaFromClients: (state, action: PayloadAction<any>) => {
            state.openEulaFromClients = action.payload;
        },
        resetGlobalReducer: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(logout, (state) => ({ ...initialState, globalConfig: state.globalConfig })),
            builder.addCase(fetchPortfolio.fulfilled, (state, action) => {
                state.loading = false;
                state.portfolio = action.payload.portfolios;
                state.domainEmail = action.payload.domainEmail;
                state.error = {};
                state.showErrorModal = false;
            }),
            builder.addCase(fetchPortfolio.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.showErrorModal = true;
            }),
            builder.addCase(getTenantBasedConfig.pending, (state) => {
                state.globalLoader = true;
            }),
            builder.addCase(getTenantBasedConfig.fulfilled, (state, action) => {
                state.globalLoader = false;
                state.globalConfig = action.payload;
                state.error = {};
                state.showErrorModal = false;
            }),
            builder.addCase(getTenantBasedConfig.rejected, (state, action) => {
                state.globalLoader = false;
                state.error = action.error;
                state.showErrorModal = true;
            });
        builder.addCase(getOrganizationConfig.fulfilled, (state, action) => {
            state.orgInfo = action.payload.orgInfo;
        }),
            builder.addCase(getOrganizationConfig.rejected, (state, action) => {
                state.error = action.error;
                state.showErrorModal = true;
            });
        builder.addCase(getReleaseBanner.fulfilled, (state, action) => {
            state.scheduledReleaseBanner = action.payload;
        });
        builder.addCase(updateReleaseBanner.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(updateReleaseBanner.fulfilled, (state, action) => {
                state.loading = false;
                state.scheduledReleaseBanner = action.payload;
            }),
            builder.addCase(updateReleaseBanner.rejected, (state) => {
                state.loading = false;
            });
    }
});

export const {
    updateErrorModalState,
    updateLayoutColor,
    updateDialogState,
    updateGlobalLoaderState,
    updateLogo,
    updateSubdomain,
    updatePdfEdited,
    updateCopyData,
    updateSaveExitEdited,
    updateResetDialog,
    resetGlobalReducer,
    updateActiveTabIndex,
    updateShowAdvisorPortalButtonOnHeader,
    handleOpenEulaModal,
    handleOpenEulaFromClients,
    toggleEditGoalWealthSplitterApplied
} = globalSlice.actions;

export default globalSlice.reducer;
