// @ts-nocheck
import moment from 'moment';
import Highcharts from 'highcharts';
import { EQUITY } from '../constants';

export const numberWithCommas = (x: any) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatDataForGlidePathCharts = (portfolioPath: number[], portfolio: any[]) => {
    const filterEquityData = portfolio.reduce(
        (res: { [x: string]: any }, curr: { portfolioId: string | number; assets: any[] }) => {
            res[curr.portfolioId] = curr.assets.find(
                (asset: { type: string }) => asset.type === EQUITY
            ).totalAllocatedvalue;
            return res;
        },
        {}
    );

    const arearangeData = portfolioPath.map((id) => filterEquityData[id]);

    const chartHeight = 100;

    const series = portfolioPath.map((id) => ({
        y: filterEquityData[id],
        portfolio: JSON.stringify(portfolio.filter((v) => v.portfolioId === id)[0].assets)
    }));

    const fixedIncome = arearangeData.map((el) => [el, chartHeight]);

    const equity = arearangeData.map((el) => [0, el]);

    const equityLabelPosition = Math.max(...arearangeData) * 2;
    const fixedIncomeLabelPosition = arearangeData.length - 2;

    return { series, fixedIncome, equity, equityLabelPosition, fixedIncomeLabelPosition };
};

export const getCurrentYear = () => {
    const year = moment().year();

    return year.toString();
};

export const annualDistributioncategories = (infusion: any) => {
    const result: { amount: number; year: string | number | any }[] = [];

    infusion.reduce(
        (
            res: { [x: string]: { amount: number } },

            value: { year: string | number | any; amount: number | any }
        ) => {
            if (!res[value.year]) {
                res[value.year] = { year: value.year, amount: 0 };

                result.push(res[value.year]);
            }

            res[value.year].amount += Math.abs(value.amount);

            return res;
        },

        {}
    );

    return result.map((val: any) => val.amount);
};

export const format = (value: any, currency: string, translation: any) => {
    if (value >= 1000000000000000) {
        return (
            currency +
            numberWithCommas(parseFloat(Highcharts.numberFormat(value / 1000000000000000, 2))) +
            translation('TEXT_QUADRILLION')
        );
    }
    if (value >= 1000000000000) {
        return (
            currency +
            numberWithCommas(parseFloat(Highcharts.numberFormat(value / 1000000000000, 2))) +
            translation('TEXT_TRILLION')
        );
    }
    if (value >= 1000000000) {
        return (
            currency +
            numberWithCommas(parseFloat(Highcharts.numberFormat(value / 1000000000, 2))) +
            translation('TEXT_BILLION')
        );
    }
    if (value >= 1000000) {
        return (
            currency +
            numberWithCommas(parseFloat(Highcharts.numberFormat(value / 1000000, 2))) +
            translation('TEXT_MILLION')
        );
    }
    if (value >= 1000) {
        const formattedValue = parseFloat(Highcharts.numberFormat(value / 1000, 2));
        if (formattedValue === 1000) {
            return `${currency}1${translation('TEXT_MILLION')}`;
        }
        return (
            currency +
            numberWithCommas(parseFloat(Highcharts.numberFormat(value / 1000, 2))) +
            translation('TEXT_THOUSAND')
        );
    }
    return currency + numberWithCommas(parseFloat(Highcharts.numberFormat(value, 2))).toString();
};
