import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
// import { ReactComponent as Split } from '../../../../containers/common/assets/split.svg';
import ButtonComponent from '../../../atoms/button';
import { useAppSelector } from '../../../../app/hooks';
import { useTranslation } from 'react-i18next';

interface dialogProps {
    open: any;
    handleClose: any;
    applySplit: any;
}
const DialogTitleContainer = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: '26px'
}));
const DialogContentContainer = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.common.black,
    marginBottom: '14px',
    fontWeight: 400
}));
const DivContainer = styled('div')(() => ({
    padding: '40px'
}));
const Table = styled('table')(() => ({
    width: '100%',
    borderCollapse: 'collapse'
}));

const Row = styled('tr')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[600]}`,
    // alignItems:'lef',
    textAlign: 'left',
    '&:last-child': {
        borderBottom: '1px solid black'
    },
    borderTop: `1px solid ${theme.palette.grey[600]}`,
    '&:first-child': {
        borderTop: '1px solid black'
    }
}));

const Head = styled('th')(() => ({
    padding: '5px 0'
}));
const Cell = styled('td')(({ theme }) => ({
    textAlign: 'left',
    padding: '5px 10px',
    '&:last-child': {
        color: theme.palette.primary.main
    }
}));
export default function ResponsiveDialog({ open, handleClose, applySplit }: dialogProps) {
    const theme = useTheme();
    const { t } = useTranslation();
    const [total, settotal] = useState(0);
    const welthSplitData = useAppSelector((state) => state.addClient.wealthSplitterData);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {
        let total = 0;
        if (!isEmpty(welthSplitData)) {
            welthSplitData.forEach((itm) => {
                total += itm.Orig_Curr_Wealth;
            });
        }
        settotal(total);
    }, [welthSplitData]);

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DivContainer>
                    <DialogTitleContainer id="responsive-dialog-title">
                        {t('TEXT_WEALTH_SPLITTER_DIALOG_TITLE')}
                    </DialogTitleContainer>
                    <DialogContent>
                        <DialogContentContainer>
                            {t('TEXT_WEALTH_SPLITTER_DIALOG_DESCRIPTION', { total })}
                        </DialogContentContainer>
                        <DialogContentContainer>
                            <Table>
                                <Row>
                                    <Head tabIndex={0}>{t('TEXT_WEALTH_SPLITTER_DIALOG_TABLE_HEADER_1')}</Head>
                                    <Head tabIndex={0}>{t('TEXT_WEALTH_SPLITTER_DIALOG_TABLE_HEADER_2')}</Head>
                                    <Head tabIndex={0}>{t('TEXT_WEALTH_SPLITTER_DIALOG_TABLE_HEADER_3')}</Head>
                                    <Head tabIndex={0}>{t('TEXT_WEALTH_SPLITTER_DIALOG_TABLE_HEADER_4')}</Head>
                                </Row>
                                {welthSplitData?.map((val: any, key: number) => (
                                    <Row key={key}>
                                        <Cell tabIndex={0}>{key + 1}</Cell>
                                        <Cell tabIndex={0}>{val?.Goal || ''}</Cell>
                                        <Cell tabIndex={0}>${val?.Orig_Curr_Wealth || 0}</Cell>
                                        <Cell tabIndex={0}>${Math.round(val?.Wealth_Split) || 0}</Cell>
                                    </Row>
                                ))}
                            </Table>
                        </DialogContentContainer>
                    </DialogContent>

                    <DialogActions>
                        <ButtonComponent variant="outlined" autoFocus onClick={handleClose}>
                            {t('TEXT_WEALTH_SPLITTER_DIALOG_TABLE_HEADER_CANCEL_BUTTON')}
                        </ButtonComponent>
                        <ButtonComponent variant="contained" onClick={applySplit} autoFocus>
                            {t('TEXT_WEALTH_SPLITTER_DIALOG_TABLE_HEADER_SPLIT_BUTTON')}
                        </ButtonComponent>
                    </DialogActions>
                </DivContainer>
            </Dialog>
        </div>
    );
}
