/* eslint-disable operator-linebreak */
import { styled } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import MaskedInput from 'react-input-mask';
import CircularProgress from '@mui/material/CircularProgress';
import SelectComponent from '../../atoms/select';
import { useAppSelector } from '../../../app/hooks';

import Input from '../../atoms/input';
import { useTranslation } from 'react-i18next';

const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    marginTop: '2px'
}));

const ErrorSpan = styled('span')(({ theme }) => ({
    color: theme.palette.goal.formFields.errorLabel,
    fontSize: '12px'
}));

const selectData = (t: any) => [
    {
        value: 'advisor',
        label: t('TEXT_ADMIN_USER_FA_HEADER')
    },
    // {
    //     value: 'manager',
    //     label: 'Manager'
    // },
    {
        value: 'admin',
        label: t('TEXT_ADMIN_USER_FA_ADMIN_HEADER')
    }
];

export default function UserInfo({
    onSubmit,
    handleSubmit,
    control,
    errors,
    emailExistsError,
    isCheckingEmail,
    translation
}: any) {
    const { t } = useTranslation();
    const { subdomain } = useAppSelector((state: any) => state.global?.globalConfig || {});

    return (
        <form id="add-user-form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <GridContainer container spacing={2}>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Controller
                        name="fullName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: translation.fullName.requiredErrorMessage
                            },
                            pattern: {
                                value: /^[0-9A-Za-zñáéíóúü_ ]*$/i,
                                message: translation.fullName.validationErrorMessage
                            },
                            maxLength: {
                                value: 50,
                                message: translation.fullName.maxValidationErrorMessage
                            },
                            minLength: {
                                value: 2,
                                message: translation.fullName.minValidationErrorMessage
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.fullName}
                                label={translation.fullName.label}
                                type="text"
                                placeholder={translation.fullName.placeholder}
                            />
                        )}
                    />
                    {errors.fullName && <ErrorSpan role="alert">{errors.fullName.message}</ErrorSpan>}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Controller
                        name="emailAddress"
                        control={control}
                        aria-invalid={errors?.emailAddress || emailExistsError ? 'true' : 'false'}
                        rules={{
                            required: {
                                value: true,
                                message: translation.email.requiredErrorMessage
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: translation.email.validationErrorMessage
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors?.emailAddress || !!emailExistsError}
                                label={translation.email.label}
                                type="email"
                                showcheckmark={isCheckingEmail ? '' : 'True'}
                                placeholder={translation.email.placeholder}
                                CustomEndIcon={<CircularProgress size="24px" />}
                            />
                        )}
                    />
                    {(errors.emailAddress || emailExistsError) && (
                        <ErrorSpan role="alert">{errors?.emailAddress?.message || emailExistsError}</ErrorSpan>
                    )}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            pattern: {
                                value:
                                    subdomain === 'highpoint'
                                        ? /^[2-9]{2}\s[0-9]{2}\s[0-9]{4}-[0-9]{4}$/i
                                        : /^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$/i,
                                message: translation.phonenumber.validationErrorMessage
                            }
                        }}
                        render={({ field }) => (
                            <MaskedInput
                                mask={subdomain === 'highpoint' ? '99 99 9999-9999' : '999-999-9999'}
                                value={field.value}
                                onChange={field.onChange}
                            >
                                {(inputProps: any) => (
                                    <Input
                                        {...inputProps}
                                        type="text"
                                        label={translation.phonenumber.label}
                                        error={!!errors?.phoneNumber}
                                        placeholder={translation.phonenumber.placeholder}
                                    />
                                )}
                            </MaskedInput>
                        )}
                    />
                    {errors.phoneNumber && <ErrorSpan role="alert">{errors.phoneNumber.message}</ErrorSpan>}
                </GridContainer>

                <GridContainer item xs={12} sm={6} md={3} lg={3} sx={{ lineHeight: '20px' }}>
                    <Controller
                        name="accessLevel"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Access level is required'
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <SelectComponent
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.accessLevel}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                label={t('TEXT_ADMIN_ACCESS_LEVEL')}
                                data={selectData(t)}
                                placeholder={t('TEXT_DROP_DOWN_PLACEHOLDER')}
                                id="accessLevel"
                            />
                        )}
                    />
                    {errors.accessLevel && <ErrorSpan role="alert">{errors.accessLevel.message}</ErrorSpan>}
                </GridContainer>
            </GridContainer>
        </form>
    );
}
