import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
// import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { chooseGoal, generateIncomeObj, retirementObj, futureEventObj } from '../../../services/configs/configs';
import {
    updateGoalData,
    updateSavingGoalHelperText,
    updateInvestorGoalData
} from '../../../features/add-client/add-client';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CircularCheckbox from '../../atoms/choose-savings-goal-checkbox';
import ButtonComponent from '../../atoms/button';
import { GOAL_TYPE } from '../../../constants/index';
import { updateActiveTabIndex } from '../../../features/global/globalSlice';

interface DataList {
    handleCancel: any;
    handleNextStep: any;
    // savingGoalChange: any;
    data: any;
    freemium: boolean;
    handleStartOverForFreemium: () => void;
}
interface CircleProps {
    active?: number;
}
// const Circle = styled('div')<CircleProps>(({ theme, active }) => ({
const ProgressContainer = styled('div')<CircleProps>(({ active }) => ({
    display: 'inline-flex',
    position: 'relative',
    width: '100%',
    minHeight: '250px',
    pointerEvents: active ? 'none' : 'auto'
}));
const AccordianFooter = styled('div')(() => ({
    marginTop: '10px',
    marginBottom: '30px',
    textAlign: 'right'
}));

const ButtonContainer = styled('span')(() => ({
    display: 'inline-block',
    margin: '6px'
}));
const ChooseGoal = ({ data, handleCancel, handleNextStep, handleStartOverForFreemium }: DataList) => {
    const { t } = useTranslation();
    const [state, setState] = useState<any[]>(chooseGoal);
    const [disable, setDisable] = useState(false);
    const dispatch = useAppDispatch();
    const freemium = false;
    const goalData: any[] = useAppSelector((state) => state.addClient.chooseGoalData || []);
    const index: number = useAppSelector((state) => state.global.activeTabIndex);
    const investorData: any[] = useAppSelector((state) => state.addClient.invertorGoalData || []);
    const confirmedGoal: any[] = useAppSelector((state) => state.personalizedPlan.clientSummary.formFields || []);
    const disabledOnBack = !(investorData.length === confirmedGoal.length);
    useEffect(() => {
        if (isEmpty(goalData)) {
            dispatch(updateGoalData(cloneDeep(chooseGoal)));
        }
    }, []);
    useEffect(() => {
        const dataval = [...state];
        if (!isEmpty(data?.goalName)) {
            dataval.forEach((itm: any) => {
                if (itm.value === data.goalType) {
                    itm.active = true;
                } else {
                    itm.active = false;
                }
            });
            setState(dataval);
            if (!data.goalName) {
                setDisable(false);
            } else {
                setDisable(true);
            }
        } else {
            dataval.forEach((itm: any) => {
                itm.active = false;
            });
            setDisable(false);
            setState(dataval);
        }
    }, [data, index]);
    useEffect(() => {
        if (!isEmpty(state)) {
            validateCheckbox();
        }
    }, []);
    const validateCheckbox = () => {
        for (let i = 0; i < state.length; i += 1) {
            if (state[i].active) {
                return true;
            }
        }
        return false;
    };

    const disableCheckbox = validateCheckbox();
    const SaveAndCreateAGoal = () => {
        if (!disable) {
            const result = state.filter((el) => el.active === true);
            const value = result[0].value;
            dispatch(updateSavingGoalHelperText(result[0].label));
            const formData = [...investorData];

            if (value === GOAL_TYPE.GENERATE_INCOME) {
                const genIncObj = cloneDeep(generateIncomeObj);
                genIncObj.goalType = value;
                formData[index] = genIncObj;
                dispatch(updateInvestorGoalData(formData));
                dispatch(updateActiveTabIndex(index));
            } else if (value === GOAL_TYPE.RETIREMENT) {
                const retIncObj = cloneDeep(retirementObj);

                retIncObj.goalType = value;
                formData[index] = retIncObj;
                dispatch(updateInvestorGoalData(formData));
                dispatch(updateActiveTabIndex(index));
            } else {
                const futIncObj = cloneDeep(futureEventObj);

                futIncObj.goalType = value;

                formData[index] = futIncObj;
                dispatch(updateInvestorGoalData(formData));
                dispatch(updateActiveTabIndex(index));
            }
        }

        handleNextStep();
    };
    const changeActive = (index: any) => {
        const data = [...state];
        data.forEach((itm: any, i) => {
            if (i === index) {
                itm.active = true;
            } else {
                itm.active = false;
            }
        });
        setState(data);
        dispatch(updateGoalData(data));
    };

    return (
        <>
            <ProgressContainer data-testid="choose-goal-container" active={+disable}>
                {state.length ?
                    state.map((item, index) => (
                          <CircularCheckbox
                              key={index}
                              value={item.value}
                              helperText={item.helperText}
                              label={item.label}
                              disabled={item.disabled}
                              active={item.active}
                              iconType={item.icon}
                              changeActive={() => {
                                  changeActive(index);
                              }}
                          />
                      )) :
                    null}
            </ProgressContainer>
            <AccordianFooter>
                {freemium ? (
                    <ButtonContainer>
                        <ButtonComponent data-testid="cancel" variant="outlined" onClick={handleStartOverForFreemium}>
                            {t('BUTTON_START_OVER')}
                        </ButtonComponent>
                    </ButtonContainer>
                ) : (
                    <ButtonContainer>
                        <ButtonComponent variant="outlined" data-testid="choose-goal-cancel" onClick={handleCancel}>
                            {t('BUTTON_CANCEL')}
                        </ButtonComponent>
                    </ButtonContainer>
                )}
                <ButtonContainer>
                    <ButtonComponent
                        variant={!disableCheckbox ? 'outlined' : 'contained'}
                        custom={+!disableCheckbox}
                        disabled={!disableCheckbox || (disable && disabledOnBack)}
                        data-testid="choose-goal-nex-step"
                        onClick={SaveAndCreateAGoal}
                    >
                        {t('BUTTON_NEXT_STEP')}
                    </ButtonComponent>
                </ButtonContainer>
            </AccordianFooter>
        </>
    );
};

export default ChooseGoal;
