import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { EQUITY } from '../../constants';
import PortfolioCompositionOrganism from '../../components/organisms/charts/portfolio-composition';
import { useAppSelector } from '../../app/hooks';

const portfolioCompositionContainer = ({ chartData, label, equity, fixedIncome, ...rest }: any) => {
    const theme = useTheme();
    const enableSourceCaveatNumbers = useAppSelector((state) =>
        Boolean(state.global.orgInfo?.enableSourceCaveatNumbers)
    );

    const [data, setData] = useState([]);
    const [labelXaxis, setLableXaxis] = useState(0);
    const [labelYaxis, setLableYaxis] = useState(0);

    useEffect(() => {
        if (!isEmpty(chartData)) {
            let updatedData: any = chartData
                .map(
                    (val: {
                        subAssets: { ticker: string; allocatedValue: string; tickerLink: string }[];
                        type: string;
                    }) =>
                        val.subAssets.map((v: { ticker: string; allocatedValue: string; tickerLink: string }) => ({
                            name: JSON.stringify([v.ticker, val.type, v.tickerLink]),
                            y: v.allocatedValue,
                            color:
                                val.type === EQUITY ?
                                    theme.palette.charts.portfolioComposition.equity :
                                    theme.palette.charts.portfolioComposition.fixedIncome
                        }))
                )
                .flat();

            updatedData = updatedData.sort(
                (a: { y: string | any }, b: { y: string | any }) => parseFloat(b.y) - parseFloat(a.y)
            );
            setLableYaxis(Math.max(...updatedData.map((o: { y: any }) => o.y)));
            setLableXaxis(updatedData.length - 1);
            setData(updatedData);
        }
    }, [chartData]);
    return (
        <PortfolioCompositionOrganism
            theme={theme}
            labelXaxis={labelXaxis}
            labelYaxis={labelYaxis}
            label={label}
            data={data}
            equity={equity}
            fixedIncome={fixedIncome}
            enableSourceCaveatNumbers={enableSourceCaveatNumbers}
            {...rest}
        />
    );
};

export default portfolioCompositionContainer;
