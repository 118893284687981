import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Grid, { GridProps } from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useNavigate } from 'react-router-dom';
import { numberWithCommas, formatClientName } from '../../../utils/table';
import { useAppSelector } from '../../../app/hooks';
import { RISK_PROFILE_MAP, TENANT_ID_MAP } from '../../../constants';

const isHighPoint =
    TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'advisorportaldev'] === 'highpoint';

const IconAndNameWrapper = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}));

const StyledHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap'
}));

const StyledBorderColorIcon = styled(BorderColorIcon)(() => ({
    marginRight: '10px',
    fontSize: '28px'
}));

const StyledParagraph = styled(Typography)<TypographyProps>(() => ({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '32px'
}));

const CloseIconContainer = styled('div')(() => ({
    '& .MuiSvgIcon-root': {
        height: '40px',
        width: '40px',
        cursor: 'pointer'
    },
    '@media (max-width: 991px)': {
        display: 'none'
    }
}));

const StyledPortfolioContainer = styled('div')(() => ({
    textAlign: 'end',
    marginRight: '42px',
    '@media (max-width: 991px)': {
        textAlign: 'center',
        margin: 'unset'
    }
}));

const IconContainer = styled('span')(() => ({
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
        fontSize: '16px',
        fontWeight: 600,
        marginRight: '16px',
        height: '18px',
        width: '18px'
    }
}));

const DetailsSection = styled(Grid)<GridProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media (max-width: 991px)': {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px'
    }
}));

const InnerGrid = styled(Grid)<GridProps>(() => ({
    alignItems: 'flex-end',
    '@media (max-width: 991px)': {
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const PortfolioSection = styled(Grid)<GridProps>(() => ({
    display: 'flex',
    justifyContent: 'end',
    '@media (max-width: 991px)': {
        justifyContent: 'center'
    }
}));

const ContainerWithSuperScript = styled('div')(() => ({
    position: 'relative',
    display: 'inline-flex'
}));

const SuperCript = styled(Typography)<TypographyProps>(() => ({
    marginLeft: '10px',
    position: 'absolute',
    right: -15,
    top: -10
}));

const StyledTypography = styled('span')(({ theme }) => ({
    marginLeft: '5px',
    color: theme.palette.primary.main,
    fontWeight: '600'
}));

const ClientDetailsComponent = ({ handleClose, data, portfolioValue, lastModifiedDate }: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const enableSourceCaveatNumbers = useAppSelector((state) =>
        Boolean(state.global.orgInfo?.enableSourceCaveatNumbers)
    );

    return (
        <>
            <Grid container mt="15px">
                <DetailsSection item xs={12} sm={12} md={6} lg={8}>
                    <InnerGrid container spacing={2} sx={{ alignItems: 'flex-end' }}>
                        <Grid item>
                            <IconAndNameWrapper data-testid="client-name" onClick={() => navigate('/client-profile')}>
                                <StyledBorderColorIcon />

                                <StyledHeader variant="h2" tabIndex={0}>
                                    {data?.clientFirstName && formatClientName(data)}
                                </StyledHeader>
                            </IconAndNameWrapper>
                        </Grid>

                        <Grid item>
                            {data?.clientEmail && (
                                <IconContainer>
                                    <MailOutlineIcon sx={{ color: 'black' }} />
                                    <StyledParagraph variant="body1" tabIndex={0} data-testid="client-email">
                                        {data?.clientEmail}
                                    </StyledParagraph>
                                </IconContainer>
                            )}
                        </Grid>
                    </InnerGrid>

                    <Typography sx={{ marginTop: '10px', display: 'inline-flex', fontWeight: '600' }}>
                        {t('TEXT_GOAL_SUMMARY_RISK_TOLERANCE')}
                        <StyledTypography color={'theme.palette.primary.main'}>
                            {t(RISK_PROFILE_MAP[data.riskTolerance])}
                        </StyledTypography>
                    </Typography>
                </DetailsSection>
                <PortfolioSection item xs={12} sm={12} md={6} lg={4}>
                    {(portfolioValue || portfolioValue === 0) && (
                        <StyledPortfolioContainer>
                            <ContainerWithSuperScript>
                                <StyledHeader variant="h2" tabIndex={0}>
                                    {isHighPoint ? 'MXN$' : '$'}
                                    {numberWithCommas(portfolioValue)}
                                </StyledHeader>

                                {enableSourceCaveatNumbers && data?.externalData?.data && <SuperCript>2</SuperCript>}
                            </ContainerWithSuperScript>

                            {lastModifiedDate && (
                                <StyledParagraph
                                    variant="body1"
                                    tabIndex={0}
                                    data-testid="client-total-portfolio-value"
                                >
                                    {t('TEXT_PORTFOLIO_LAST_UPDATED_VALUE', {
                                        lastModifiedDate
                                    })}
                                </StyledParagraph>
                            )}
                        </StyledPortfolioContainer>
                    )}
                    <CloseIconContainer>
                        <CloseIcon
                            role="button"
                            tabIndex={0}
                            aria-label="close-client-summary-page"
                            onKeyPress={() => handleClose()}
                            onClick={() => handleClose()}
                            sx={{ color: 'common.black' }}
                            data-testid="close-client-summary-modal-button"
                        />
                    </CloseIconContainer>
                </PortfolioSection>
            </Grid>
        </>
    );
};

export default ClientDetailsComponent;
