import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from 'lodash';
import Input from '../../components/atoms/input';
import ButtonComponent from '../../components/atoms/button';
import { ReactComponent as Logo } from '../../components/organisms/header/assests/logo.svg';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { hex2rgba } from '../../utils';
import Modal from '../../components/molecules/modal';
import { toggleSessionExpiredModal, logout } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

export const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    minHeight: '100vh'
}));

export const LeftContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.layout.primary,
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10%'
}));

export const RightContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    // background: `${theme.palette.layout.primary} 0.5`,
    // background: 'linear-gradient(90deg, #092e39 50%)',
    // filter: 'brightness(0.4)',
    backgroundColor: `${hex2rgba(theme.palette.layout.primary, 0.5)}`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 50px'
}));

export const ErrorSpan = styled(Typography)(({ theme }) => ({
    color: theme.palette.goal.formFields.errorLabel,
    display: 'flex',
    marginTop: '5px'
}));

export const ItemContainer = styled('div')(({ theme }) => ({
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& a': {
        color: theme.palette.background.paper
    }
}));

const SupportText = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.paper,
    textAlign: 'center',
    marginTop: 20,
    '& a': {
        color: theme.palette.background.paper
    }
}));

const ForgotPasswordLink = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.paper,
    textAlign: 'center',
    cursor: 'pointer',
    ':hover': {
        textDecoration: 'underline'
    }
}));

export const FormContainer: any = styled(Grid)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.layout.primary,
    padding: 30,
    borderRadius: 10,
    maxWidth: '330px'
}));

export const Button = styled(ButtonComponent)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    minWidth: '35%',
    color: theme.palette.primary.main,
    textTransform: 'capitalize'
}));

export const WelcomText = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.default
}));

const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.common.black,
    margin: '30px'
}));

const StyleCheckbox = styled(Checkbox)(() => ({
    color: '#fff',
    '&.Mui-checked': {
        color: '#fff'
    }
}));

const CloseIconContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
        height: '40px',
        width: '40px'
    }
}));

interface FormInput {
    username: string;
    password: string;
    rememberMe: boolean;
}

interface LoginFormProps {
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values: FormInput) => void;
}

const LoginForm = ({ onSubmit }: LoginFormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loading, error } = useAppSelector((state) => state.auth);
    const logo = useAppSelector((state) => state.global?.globalConfig?.logo || '');
    const showSessionExpiredModal = useAppSelector((state) => state.auth.showSessionExpiredModal);
    const [serverError, setServerError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(showSessionExpiredModal);
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue
    } = useForm<FormInput>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            username: '',
            password: '',
            rememberMe: false
        }
    });

    useEffect(() => {
        setServerError(error);
    }, [error]);

    useEffect(() => {
        dispatch(logout());
        (async () => {
            const email = await localStorage.getItem('GOE_APP_REMEMBERED_EMAIL');

            if (email) {
                setValue('username', email);
                setValue('rememberMe', true);
            }
        })();
    }, []);

    const handleCloseModal = () => {
        dispatch(toggleSessionExpiredModal(false));
        setShowModal(false);
    };

    return (
        <GridContainer container>
            <LeftContainer item xs={12} sm={12} md={7} lg={7}>
                <WelcomText variant="h2">{t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_1')}</WelcomText>
                <WelcomText variant="h1" sx={{ fontWeight: 600 }}>
                    {t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_2')}
                </WelcomText>
                <WelcomText variant="h3">{t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_3')}</WelcomText>
            </LeftContainer>

            <RightContainer item xs={12} sm={12} md={5} lg={5}>
                <FormContainer container direction="column">
                    <form onSubmit={loading ? () => {} : handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <ItemContainer>
                            {logo ? (
                                <img
                                    src={logo}
                                    alt="Franklin Templeton"
                                    data-testid="brand-logo"
                                    width="200"
                                    height="auto"
                                />
                            ) : (
                                <Logo title="login" data-testid="brand-logo" />
                            )}
                        </ItemContainer>
                        <ItemContainer>
                            <Typography variant="h5" color="Background">
                                {t('HEADER_LOGIN_SCREEN_FORM')}
                            </Typography>
                        </ItemContainer>

                        <ItemContainer sx={{ alignItems: 'flex-start' }}>
                            <Controller
                                name="username"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t('TEXT_CLIENT_INFO_EMAIL_VALIDATION')
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        inputRef={ref}
                                        error={!!errors.username}
                                        type="text"
                                        data-testid="email"
                                        placeholder={t('PLACEHOLDER_LOGIN_SCREEN_EMAIL')}
                                        fullWidth
                                        sx={{ border: 'none !important' }}
                                    />
                                )}
                            />
                            {errors.username ? (
                                <ErrorSpan variant="subtitle2" role="alert">
                                    {errors.username.message}
                                </ErrorSpan>
                            ) : null}
                        </ItemContainer>

                        <ItemContainer sx={{ alignItems: 'flex-start' }}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        inputRef={ref}
                                        error={!!errors.password}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder={t('PLACEHOLDER_LOGIN_SCREEN_PASSWORD')}
                                        showcheckmark=""
                                        data-testid="password"
                                        CustomEndIcon={
                                            <IconButton
                                                onClick={() => {
                                                    setShowPassword(!showPassword);
                                                }}
                                            >
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        }
                                        sx={{ border: 'none !important' }}
                                    />
                                )}
                            />
                            {errors.password ? (
                                <ErrorSpan variant="subtitle2" role="alert">
                                    {errors.password.message}
                                </ErrorSpan>
                            ) : null}
                        </ItemContainer>
                        <ItemContainer sx={{ alignItems: 'flex-start', marginTop: '0px' }}>
                            <Controller
                                name="rememberMe"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <FormControlLabel
                                        ref={ref}
                                        onChange={onChange}
                                        checked={value}
                                        control={<StyleCheckbox />}
                                        label={
                                            <Typography variant="body1" color="white">
                                                {t('TEXT_LOGIN_SCREEN_REMEMBER_ME_CHECKBOX_LABEL')}
                                            </Typography>
                                        }
                                    />
                                )}
                            />
                        </ItemContainer>

                        {!isEmpty(serverError) ? (
                            <ErrorSpan variant="subtitle2" role="alert">
                                {t('TEXT_INCORRECT_PASSWORD')}
                            </ErrorSpan>
                        ) : null}

                        <ItemContainer>
                            <Button type="submit" variant="contained" data-testid="login-button">
                                {loading ? (
                                    <CircularProgress color="inherit" size="24px" />
                                ) : (
                                    t('TEXT_LOGIN_SCREEN_BUTTON')
                                )}
                            </Button>
                        </ItemContainer>

                        <ItemContainer>
                            <ForgotPasswordLink
                                variant="subtitle1"
                                onClick={() => {
                                    navigate('/forgot-password');
                                }}
                            >
                                {t('TEXT_LOGIN_SCREEN_FORGOT_PASSWORD_LINK')}
                            </ForgotPasswordLink>
                        </ItemContainer>
                    </form>
                </FormContainer>

                <SupportText variant="body1">
                    {t('TEXT_LOGIN_SCREEN_DISCLAIMER_1')}{' '}
                    <a href={`mailto:${t('APPLICATION_SUPPORT_EMAIL')}`}>{t('APPLICATION_SUPPORT_EMAIL')}</a>
                </SupportText>
            </RightContainer>
            <Modal style={{ padding: '0' }} handleClose={handleCloseModal} open={showModal}>
                <Header>
                    <Typography variant="h4" tabIndex={0}>
                        {t('SESSION_EXPIRED_MODAL_TITLE')}
                    </Typography>
                    <CloseIconContainer>
                        <CloseIcon
                            role="button"
                            tabIndex={0}
                            aria-label="close-session-expired-popup"
                            onKeyPress={handleCloseModal}
                            onClick={handleCloseModal}
                            data-testid="session-expired-modal-close-button"
                        />
                    </CloseIconContainer>
                </Header>
                <Typography variant="h6" style={{ margin: '30px' }} tabIndex={0}>
                    {t('SESSION_EXPIRED_MODAL_CONTENT')}
                </Typography>
            </Modal>
        </GridContainer>
    );
};

export default LoginForm;
