/* eslint-disable operator-linebreak */
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ReadOnlyInput, ReadOnlyInputGroup } from './groupedInputField';
import { getMonthName, numberWithCommas } from '../../../../utils';
import {
    TENANT_ID_MAP,
    MONTHS_TRASLATION_MAP,
    FREQUENCY_TRANSLATION_MAP,
    GOAL_PRIORITY_TRANSLATION_MAP
} from '../../../../constants';

const isHighPoint =
    TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'advisorportaldev'] === 'highpoint';

const GenerateIncomeFormFieldsContainer = styled('div')(() => ({}));

const GenerateIncomeFormFields = ({ formData, isPdfForm }: any) => {
    const { t } = useTranslation();
    return (
        <GenerateIncomeFormFieldsContainer data-testid="generate-income-form-fields">
            <Grid container spacing={1} sx={{ alignItems: 'flex-end' }}>
                {formData?.data?.initialInvestment ? (
                    <Grid item xs={isPdfForm ? 1.5 : 12} md={isPdfForm ? 1.5 : 2} lg={1.5}>
                        <ReadOnlyInput
                            label={t('LABEL_AMOUNT_INVESTED')}
                            value={numberWithCommas(formData?.data?.initialInvestment) || ''}
                            currencysymbol={isHighPoint ? 'MXN$' : '$'}
                        />
                    </Grid>
                ) : null}
                {formData?.data?.withdrawalStartingOnMonth && formData?.data?.withdrawalStartingOnYear ? (
                    <Grid item xs={isPdfForm ? 2.5 : 12} md={isPdfForm ? 2.5 : 3} lg={2.5}>
                        <ReadOnlyInputGroup
                            label={t('LABEL_WITHDRAWN_STARTING_ON')}
                            value1={t(MONTHS_TRASLATION_MAP[getMonthName(formData?.data?.withdrawalStartingOnMonth)])}
                            value2={formData?.data?.withdrawalStartingOnYear}
                            value1CurrencySymbol=""
                            value2CurrencySymbol=""
                        />
                    </Grid>
                ) : null}
                {formData?.data?.scheduledWithdrawalAmount && formData?.data?.withdrawalFrequency ? (
                    <Grid item xs={isPdfForm ? 2.5 : 12} md={isPdfForm ? 2.5 : 3} lg={2.5}>
                        <ReadOnlyInputGroup
                            label={t('LABEL_SCHEDULED_WITHDRAWALS')}
                            value1={numberWithCommas(formData?.data?.scheduledWithdrawalAmount) || ''}
                            value2={t(FREQUENCY_TRANSLATION_MAP[formData?.data?.withdrawalFrequency])}
                            value1CurrencySymbol={isHighPoint ? 'MXN$' : '$'}
                            value2CurrencySymbol=""
                        />
                    </Grid>
                ) : null}
                {formData?.data?.goalPriority ? (
                    <Grid item xs={isPdfForm ? 1.5 : 12} md={isPdfForm ? 1.5 : 3} lg={1.5}>
                        <ReadOnlyInput
                            label={t('LABEL_GOAL_IMPORTANCE')}
                            value={t(GOAL_PRIORITY_TRANSLATION_MAP[formData?.data?.goalPriority])}
                        />
                    </Grid>
                ) : null}
                {formData?.data?.withdrawalPeriod ? (
                    <Grid item xs={isPdfForm ? 1.5 : 12} md={isPdfForm ? 1.5 : 3} lg={1.5}>
                        <ReadOnlyInput
                            label={t('LABEL_WITHDRAWAL_PERIOD')}
                            value={`${formData?.data?.withdrawalPeriod} ${t('TEXT_YEARS')}`}
                        />
                    </Grid>
                ) : null}
                {formData?.data?.goalAmount || formData?.data?.goalAmount === 0 ? (
                    <Grid item xs={isPdfForm ? 3 : 12} md={3} lg={3}>
                        <ReadOnlyInputGroup
                            label={t('LABEL_TARGET_BEQUEST_VALUE')}
                            value1={formData?.data?.goalAmount ? t('TEXT_YES') : t('TEXT_NO')}
                            value2={formData.data.goalAmount ? numberWithCommas(formData?.data?.goalAmount) : '-'}
                            value2CurrencySymbol={isHighPoint ? 'MXN$' : '$'}
                            value1CurrencySymbol=""
                        />
                    </Grid>
                ) : null}
            </Grid>
        </GenerateIncomeFormFieldsContainer>
    );
};

export default GenerateIncomeFormFields;
