/* eslint-disable operator-linebreak */
import Highcharts, { Chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import highchartsMore from 'highcharts/highcharts-more';
import { isEmpty, isUndefined } from 'lodash';
import { fontFamily } from '../../../../styles/themes/constants';
import { format } from '../../../../utils/charts';

highchartsMore(Highcharts);

interface ExtendedChart extends Highcharts.Chart {
    plotBackground: any;
    labels: Highcharts.SVGElement[];
}

const WealthPathStyle = styled('div')(() => ({
    '& .personalized-edit-page-style .portfolio-value': {
        fontSize: '20px'
    },
    '& .personalized-edit-page-style .portfolio-name': {
        fontSize: '14px'
    },
    '& .client-summary-style .portfolio-value': {
        fontSize: '14px'
    },
    '& .client-summary-style .portfolio-name': {
        fontSize: '12px'
    }
}));

function returnMaxValueXaxisPoint(data: any, years: any): any {
    if (data.length > 0) {
        const max = Math.max(...data);
        const res = [] as any;
        data.forEach((item: number, index: any) => (item === max ? res.push(index) : null));
        const maxIndex = res.pop();
        return !isUndefined(years[maxIndex]) ? years[maxIndex] : years[years.length - 1];
    }
}

const WealthPathMolecule = ({
    theme,
    currency,
    xAxisCategories,
    wealthPathData,
    annualDistribution,
    targetAmount,
    translation,
    goalProb,
    // response,
    isBadProb,
    isModerateProb,
    chartHeight = 500,
    showAmount = true,
    // goalProbAt75,
    // goalProbAt50,
    // RECOMMENDED,
    className = 'personalized-edit-page-style'
}: any) => {
    const [, setChart] = useState(null);
    const chartRef = useRef<{
        chart: Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    const [xAxisData, setXAxisData] = useState([]);
    const [xAxisPointForPercentagePortfolio, setXAxisPointForPercentagePortfolio] = useState('');

    const callback = useCallback((HighchartsChart: React.SetStateAction<null>) => {
        setChart(HighchartsChart);
    }, []);

    let lineColor;
    if (isBadProb) {
        lineColor = theme.palette.wealthPath.badProb;
    } else if (isModerateProb) {
        lineColor = theme.palette.wealthPath.moderateProb;
    } else {
        lineColor = theme.palette.wealthPath.goodProb;
    }

    useEffect(() => {
        if (!isEmpty(wealthPathData) && !isEmpty(xAxisCategories)) {
            let xAxis: any;
            if (
                wealthPathData?.length === annualDistribution?.length &&
                wealthPathData?.length === xAxisCategories?.length
            ) {
                xAxis = xAxisCategories;
                setXAxisData(xAxisCategories);
            } else {
                xAxisCategories = [...xAxisCategories, 'T'];
                xAxis = [...xAxisCategories, 'T'];
                setXAxisData(xAxisCategories);
            }
            const maxXaxisPoint = returnMaxValueXaxisPoint(wealthPathData, xAxis);
            setXAxisPointForPercentagePortfolio(maxXaxisPoint);
        }
    }, [wealthPathData, xAxisCategories]);

    const maximumValueOfWealthPathData = wealthPathData?.length ? Math.max(...wealthPathData) : 0;

    const options: Highcharts.Options =
        !isEmpty(xAxisData) && !isEmpty(xAxisPointForPercentagePortfolio)
            ? {
                  chart: {
                      height: `${chartHeight}px`,
                      style: {
                          fontFamily
                      },
                      marginTop: showAmount ? 60 : 30,
                      spacingRight: showAmount ? 130 : 90,
                      spacingLeft: showAmount ? -20 : 2,
                      events: {
                          render() {
                              const chart = this as ExtendedChart;
                              const chartBBox = chart.plotBackground.getBBox();

                              chart.series.forEach((series: any) => {
                                  if (series.index === 0) {
                                      const amount = format(series.dataMax, currency, translation);
                                      if (series.visible) {
                                          const yAxis = series.yAxis;

                                          if (!series.customLabel) {
                                              series.customLabel = chart.renderer
                                                  .text(
                                                      `<h1 class="portfolio-value">${amount?.toString()}</h1> <br> <span class="portfolio-name" >${
                                                          series.name
                                                      }</span>`
                                                  )
                                                  .css({
                                                      color: series.color,
                                                      fontSize: 14 as any,
                                                      fontWeight: 'bold'
                                                  })
                                                  .add();
                                          }

                                          series.customLabel
                                              .attr({
                                                  x: chartBBox.x + chartBBox.width + 5,
                                                  y: yAxis.toPixels(series.dataMax)
                                              })
                                              .show();
                                      } else {
                                          series.customLabel.hide();
                                      }
                                  }
                              });
                          }
                      }
                  },
                  plotOptions: {
                      series: {
                          animation: false
                      }
                  },
                  title: {
                      text: ''
                  },

                  subtitle: {
                      text: ''
                  },

                  yAxis: {
                      max: targetAmount
                          ? maximumValueOfWealthPathData > targetAmount
                              ? maximumValueOfWealthPathData
                              : targetAmount
                          : null,
                      tickWidth: 1,
                      tickColor: 'black',
                      lineWidth: 1,
                      id: 'portfolio-value-axis',
                      plotLines: targetAmount
                          ? [
                                {
                                    color: 'black',
                                    zIndex: 1,
                                    width: 2,
                                    value: targetAmount,
                                    dashStyle: 'Dash',
                                    label: {
                                        text: translation('TEXT_WEALTH_PATH_TARGET_AMOUNT'),
                                        align: 'center',
                                        style: {
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            zIndex: 2
                                        }
                                    }
                                }
                            ]
                          : [],
                      title: showAmount
                          ? {
                                align: 'high',
                                offset: 0,
                                rotation: 0,
                                y: -20,
                                x: 20,
                                text: `${translation('TEXT_WEALTH_PATH_AMOUNT')} (${currency})`,
                                margin: 20,
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: '15px',
                                    color: 'black'
                                }
                            }
                          : {
                                text: ''
                            },
                      labels: {
                          format: '${value}',
                          style: {
                              // fontWeight: "bold",
                              // color: "black"
                          },
                          formatter() {
                              return format(this.value, currency, translation);
                          }
                      }
                  },
                  tooltip: {
                      backgroundColor: '#ffffff',
                      borderRadius: 10,
                      borderColor: 'none',
                      style: {
                          zIndex: 200
                      },
                      formatter() {
                          const options: any = this.series.options;
                          return `
        <div>
          ${
              this.series.index === 1 || this.series.index === 2
                  ? `<h1 style="font-size:16px;margin-bottom:14px;color:${theme.palette.primary.main};">
            ${this.series.name}: ${format(this.y, currency, translation)}
          </h1><br/><p style="font-size:14px;color:${theme.palette.primary.main};">${
                        options?.label ? options.label : ''
                    }</p>`
                  : `<h1 style="font-size:14px;margin-bottom:14px;color:${theme.palette.primary.main};">
          ${translation('TEXT_WEALTH_PATH_PORTFOLIO_VALUE')}
        </h1><br/><p style="font-size:16px;color:${theme.palette.primary.main};">${format(
                        this.y,
                        currency,
                        translation
                    )}</p>`
          }
        
        </div>`;
                      }
                  },
                  credits: {
                      enabled: false
                  },
                  xAxis: {
                      type: 'category',
                      lineWidth: 2,
                      lineColor: '#000',
                      tickWidth: 2,
                      tickColor: '#000',
                      gridLineColor: 'white',
                      gridLineWidth: 4,
                      title: {
                          useHTML: true,
                          text: `<div style="font-size:12px">${translation(
                              'TEXT_RECOMMENDATION_TENURE'
                          )}</div><div style="font-size:16px;font-weight:bold;">${
                              xAxisCategories?.length
                          } ${translation('TEXT_RECOMMENDATION_YEARS')}</div>`,
                          align: 'high',
                          offset: 0,
                          rotation: 0,
                          x: 56,
                          y: 10,
                          style: {
                              color: theme.palette.wealthPath.title
                          }
                      },
                      labels: {
                          style: {
                              fontWeight: 'bold',
                              color: theme.palette.wealthPath.axisLabel
                          }
                      },
                      categories: xAxisData
                  },

                  legend: {
                      layout: 'vertical',
                      align: 'right',
                      verticalAlign: 'middle',
                      enabled: false
                  },
                  series: [
                      {
                          name: `${goalProb}% ${translation('TEXT_WEALTH_PATH_PROBABILITY')}`,
                          lineWidth: 1,
                          color: lineColor,
                          showInLegend: true,
                          data: wealthPathData,
                          marker: {
                              symbol: 'circle',
                              radius: 3
                          }
                      } as any
                  ]
              }
            : {};

    // if (response?.analysisReport[RECOMMENDED.PROB_75] && response?.analysisReport[RECOMMENDED.WEALTH_75]) {
    //     options?.series?.push({
    //         name: translation('TEXT_WEALTH_PATH_75%_PERCENTAGE_PORTFOLIO_VALUE'),
    //         label: `${goalProbAt75}% ${translation('TEXT_WEALTH_PATH_GOAL_PROBABILITY')}`,
    //         lineWidth: 2,
    //         color: lineColor,
    //         showInLegend: false,
    //         data: [[xAxisPointForPercentagePortfolio, response?.analysisReport[RECOMMENDED.WEALTH_75]]],
    //         marker: {
    //             symbol: 'circle',
    //             radius: 3
    //         }
    //     } as any);
    // }

    // if (response?.analysisReport[RECOMMENDED.PROB_50] && response?.analysisReport[RECOMMENDED.WEALTH_50]) {
    //     options?.series?.push({
    //         name: translation('TEXT_WEALTH_PATH_50%_PERCENTAGE_PORTFOLIO_VALUE'),
    //         label: `${goalProbAt50}% ${translation('TEXT_WEALTH_PATH_GOAL_PROBABILITY')}`,
    //         lineWidth: 2,
    //         color: lineColor,
    //         showInLegend: false,
    //         data: [[xAxisPointForPercentagePortfolio, response?.analysisReport[RECOMMENDED.WEALTH_50]]],
    //         marker: {
    //             symbol: 'circle',
    //             radius: 3
    //         }
    //     } as any);
    // }

    return (
        <WealthPathStyle>
            {!isEmpty(options) && (
                <div data-testid="wealth-path-chart" className={className}>
                    <HighchartsReact highcharts={Highcharts} options={options} callback={callback} ref={chartRef} />
                </div>
            )}
        </WealthPathStyle>
    );
};

export default WealthPathMolecule;
