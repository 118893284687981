import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import UpcomingReleaseBanner from './UpcomingReleaseBanner';

const Container = styled(Box)(() => ({
    padding: '20px 0'
}));

export default function FTAdmin() {
    return (
        <Container>
            <UpcomingReleaseBanner />
        </Container>
    );
}
