import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const SkipLink = styled('a')(({ theme }) => ({
    top: '8%',
    left: '2%',
    overflow: 'hidden',
    position: 'fixed',
    transform: 'translateY(-600%)',
    transition: 'transform 0.3s',
    borderRadius: '2rem',
    fontWeight: 'bold',
    color: theme.palette.common.white,
    zIndex: '10000',
    border: '2px solid black',
    padding: '10px 20px',
    textAlign: 'center',
    background: theme.palette.common.black,
    textDecoration: 'unset',
    '&:focus': {
        transform: 'translateY(0%)'
    }
}));

const SkipToContent = () => {
    const { t } = useTranslation();
    return <SkipLink href="#main">{t('TEXT_SKIP_TO_CONTENT')}</SkipLink>;
};

export default SkipToContent;
