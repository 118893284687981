/* eslint-disable operator-linebreak */
import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface StyledButtonProps extends ButtonProps {
    children?: React.ReactNode;
    custom?: boolean | number;
    onClick?: () => void;
}

const StyledButton = styled(Button)<StyledButtonProps>(({ theme, custom, variant }) => ({
    textTransform: 'none',
    fontWeight: 600,
    background: custom
        ? theme.palette.goal.button.disabled
        : variant === 'outlined'
        ? ''
        : theme.palette.layout.primary,
    color: custom ? theme.palette.action.disabled : variant === 'outlined' ? theme.palette.layout.primary : '',
    border: custom
        ? `1px solid ${theme.palette.action.disabled}`
        : variant === 'outlined'
        ? `1px solid ${theme.palette.layout.primary}`
        : '',
    '&.MuiButton-containedPrimary:hover': {
        backgroundColor: custom ? '' : theme.palette.layout.primary
    },
    '&:hover': {
        backgroundColor: custom ? '' : theme.palette.layout.primary,
        color: custom ? '' : theme.palette.common.white
    }
}));

const ButtonComponent: React.FC<StyledButtonProps> = (props): React.ReactElement => {
    const { children, custom } = props;
    return (
        <StyledButton {...props} custom={custom}>
            {children}
        </StyledButton>
    );
};

export default ButtonComponent;
