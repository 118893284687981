/* eslint-disable operator-linebreak */
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ReadOnlyInput, ReadOnlyInputGroup } from './groupedInputField';
import { getMonthName, numberWithCommas } from '../../../../utils';
import {
    TENANT_ID_MAP,
    MONTHS_TRASLATION_MAP,
    FREQUENCY_TRANSLATION_MAP,
    GOAL_PRIORITY_TRANSLATION_MAP
} from '../../../../constants';

const isHighPoint =
    TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'advisorportaldev'] === 'highpoint';

const FutureEventFormFieldsContainer = styled('div')(() => ({}));

const FutureEventFormFields = ({ formData, isPdfForm }: any) => {
    const { t } = useTranslation();

    return (
        <FutureEventFormFieldsContainer data-testid="future-event-form-fields">
            <Grid container spacing={1} sx={{ alignItems: 'flex-end' }}>
                {formData?.data?.initialInvestment || formData?.data?.initialInvestment === 0 ? (
                    <Grid item xs={isPdfForm ? 1.5 : 12} md={isPdfForm ? 1.5 : 2} lg={1.5}>
                        <ReadOnlyInput
                            label={t('LABEL_INITIAL_INVESTMENT')}
                            value={numberWithCommas(formData?.data?.initialInvestment) || ''}
                            currencysymbol={isHighPoint ? 'MXN$' : '$'}
                        />
                    </Grid>
                ) : null}
                {formData?.data?.investorPlanMonth && formData?.data?.investorPlanYear ? (
                    <Grid item xs={isPdfForm ? 2.5 : 12} md={isPdfForm ? 2.5 : 3} lg={2.5}>
                        <ReadOnlyInputGroup
                            label={t('LABEL_INVESTOR_PLAN_TO_USE')}
                            value1={t(MONTHS_TRASLATION_MAP[getMonthName(formData?.data?.investorPlanMonth)])}
                            value2={formData?.data?.investorPlanYear}
                            value1CurrencySymbol=""
                            value2CurrencySymbol=""
                        />
                    </Grid>
                ) : null}
                {formData?.data?.goalAmount ? (
                    <Grid item xs={isPdfForm ? 1.5 : 12} md={isPdfForm ? 1.5 : 2} lg={1.5}>
                        <ReadOnlyInput
                            label={t('LABEL_HOW_MUCH_NEEDED')}
                            value={numberWithCommas(formData?.data?.goalAmount)}
                            currencysymbol={isHighPoint ? 'MXN$' : '$'}
                        />
                    </Grid>
                ) : null}
                {(formData?.data?.scheduledContribution || formData?.data?.scheduledContribution === 0) &&
                formData?.data?.scheduledFrequency ? (
                    <Grid item xs={isPdfForm ? 2.5 : 12} md={isPdfForm ? 2.5 : 3} lg={2.5}>
                        <ReadOnlyInputGroup
                            label={t('LABEL_SCHEDULED_CONTRIBUTIONS')}
                            value1={
                                formData?.data?.scheduledContribution
                                    ? numberWithCommas(formData?.data?.scheduledContribution) || ''
                                    : '-'
                            }
                            currencysymbol={isHighPoint ? 'MXN$' : '$'}
                            value2={t(FREQUENCY_TRANSLATION_MAP[formData?.data?.scheduledFrequency])}
                            value1CurrencySymbol={
                                formData?.data?.scheduledContribution ? (isHighPoint ? 'MXN$' : '$') : ''
                            }
                            value2CurrencySymbol=""
                        />
                    </Grid>
                ) : null}
                {formData?.data?.goalPriority ? (
                    <Grid item xs={isPdfForm ? 1.5 : 12} md={isPdfForm ? 1.5 : 2} lg={1.5}>
                        <ReadOnlyInput
                            label={t('LABEL_GOAL_IMPORTANCE')}
                            value={t(GOAL_PRIORITY_TRANSLATION_MAP[formData?.data?.goalPriority])}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </FutureEventFormFieldsContainer>
    );
};

export default FutureEventFormFields;
