import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { setAutoFreeze } from 'immer';
import { uipApiInstance } from '../../axios';
import { logout } from '../auth/authSlice';
import { logEventToBackEnd } from '../global/globalSlice';

setAutoFreeze(false);
// import mockApiInstance from '../../axios';

type InitialState = {
    clientSummary: any;
    activeTabIndex: number;
    formField: any;
    response: any;
    request: any;
    portfolioComposition: Array<any>;
    portfolioCompositionYearLabel: number;
    equity: number | string;
    fixedIncome: number | string;
    loading: boolean;
    remodelLoading: boolean;
    saveLoading: boolean;
    error?: null | undefined | any;
};

const initialState: InitialState = {
    clientSummary: {},
    formField: {},
    response: {},
    request: {},
    portfolioComposition: [],
    portfolioCompositionYearLabel: 0,
    equity: '',
    fixedIncome: '',
    activeTabIndex: 0,
    loading: false,
    remodelLoading: false,
    saveLoading: false,
    error: null
};

export const fetchPersonalizedData = createAsyncThunk('personalizedPlan/fetchPersonalizedData', async () => {
    // const response = await mockApiInstance.get('/aggregator');
    const response = await axios.get('server/aggregator/aggregator.json');
    return response.data.data;
});

export const saveGoal = createAsyncThunk(
    'personalizedPlan/saveProposal',
    async (payload: any, { dispatch, rejectWithValue }) => {
        try {
            await uipApiInstance.post('/api/advisorportal/saveproposal', payload);
            return true;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('SAVE_PROPOSAL_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

const personalizedPlan = createSlice({
    name: 'personalizedPlan',
    initialState,
    reducers: {
        updateProfolioCompositionData: (state, action: PayloadAction<any>) => {
            state.portfolioComposition = action.payload.data;
            state.portfolioCompositionYearLabel = action?.payload?.year || '';
            (state.equity = action.payload.equity || ''), (state.fixedIncome = action.payload.fixedIncome || '');
        },
        resetProfolioCompositionData: (state) => {
            state.portfolioComposition = [];
        },
        updateResponse: (state, action: PayloadAction<any>) => {
            state.response = action.payload;
        },
        updateRequest: (state, action: PayloadAction<any>) => {
            state.request = action.payload;
        },
        updateFormField: (state, action: PayloadAction<any>) => {
            state.formField = action.payload;
        },
        updateClientSummary: (state, action: PayloadAction<any>) => {
            state.clientSummary = action.payload;
        },
        updateActiveTabIndex: (state, action: PayloadAction<any>) => {
            state.activeTabIndex = action.payload;
        },
        updateRemodelLoader: (state, action: PayloadAction<any>) => {
            state.remodelLoading = action.payload;
        },
        updateSaveLoader: (state, action: PayloadAction<any>) => {
            state.saveLoading = action.payload;
        },
        resetPersonalizedPlanReducer: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(fetchPersonalizedData.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchPersonalizedData.fulfilled, (state, action) => {
                state.loading = false;
                state.response = action.payload;
                state.error = '';
            }),
            builder.addCase(fetchPersonalizedData.rejected, (state, action) => {
                state.loading = false;
                state.response = [];
                state.error = action.error;
            });
        builder.addCase(saveGoal.pending, (state) => {
            state.saveLoading = true;
        }),
            builder.addCase(saveGoal.fulfilled, (state, action) => {
                state.saveLoading = false;
                state.response = action.payload;
                state.error = '';
            }),
            builder.addCase(saveGoal.rejected, (state, action) => {
                state.saveLoading = false;
                state.response = [];
                state.error = action.error;
            });
    }
});

export const {
    updateProfolioCompositionData,
    resetProfolioCompositionData,
    updateResponse,
    resetPersonalizedPlanReducer,
    updateRequest,
    updateFormField,
    updateActiveTabIndex,
    updateClientSummary,
    updateRemodelLoader,
    updateSaveLoader
} = personalizedPlan.actions;

export default personalizedPlan.reducer;
