import React from 'react';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ToolTipSvg } from '../../../containers/common/assets/tooltip.svg';

export { ToolTipSvg };
const SliderContainer = styled('div')<{ value: number }>(({ theme }) => ({
    '.MuiSlider-root': {
        border: '1px solid black',
        padding: '1px 0'
        // marginLeft: '10px'
    },
    marginRight: '30px',
    marginLeft: '20px',

    '.MuiSlider-thumb': {
        color: theme.palette.primary.main
    },
    '.MuiSlider-rail': {
        color: `${theme.palette.common.white} `,
        height: '2px'
    },
    '.MuiSlider-track': {
        color: `${theme.palette.common.white} `,
        height: '2px'
    },
    '.MuiSlider-markLabel': {
        marginTop: '-14px !important',
        marginLeft: '10px',
        fontSize: '16px',
        fontWeight: 400,
        color: theme.palette.slider.label
    },
    '.MuiSlider-markLabelActive': {
        fontWeight: 600,
        color: theme.palette.common.black
    },
    '.MuiSlider-mark': {
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        border: `1px solid ${theme.palette.common.black} `,
        display: 'inline-block',
        '&[data-index="0"]': {
            left: '2% !important'
        },
        '&[data-index="3"]': {
            left: '96% !important'
        }
    }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
    height: '8px',
    color: theme.palette.common.white,
    '& .MuiSlider-thumb': {
        width: '20px',
        height: '20px',
        '&:hover, &.Mui-focusVisible': {
            boxShadow: 'none'
        },
        '&.Mui-active': {
            boxShadow: 'none'
        }
    },
    '& .MuiSlider-rail': {
        color: theme.palette.slider.rail,
        height: '10px'
    }
}));

const SliderLabel = styled(Typography)<TypographyProps>(() => ({
    margin: '4px 0',
    fontSize: '12px',
    // minWidth: '222px',
    marginLeft: '-10px'
}));

interface SliderComponentProps {
    label: string;
    min: number;
    max: number;
    val: number;
    step: number;
    valueChange?: any;
    name?: any;
    onBlur?: any;
    disabled?: boolean;
    marks: Array<{
        value: number;
        label: string;
    }>;
}
export const TooltipContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    // margin: '10px',
    justifyContent: 'center',
    '.custom-tooltip': {
        margin: '-20px 0 0 0'
    }
}));
export const Customtooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.layout.primary,
        // background: "red",
        maxWidth: 800,
        fontSize: 14,
        // fontWeight: 600,
        color: theme.palette.common.white,
        border: `2px solid ${theme.palette.layout.primary}`
    }
}));
const SliderComponent = ({
    label,
    min,
    max,
    val,
    step,
    marks,
    name,
    onBlur,
    disabled,
    valueChange
}: SliderComponentProps) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState<number>(4);

    React.useEffect(() => {
        setValue(val);
    }, [val]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
            valueChange(newValue);
        }
    };

    return (
        <>
            <TooltipContainer>
                <SliderLabel className="fd">
                    {label}

                    <Customtooltip
                        className="custom-tooltip"
                        title={parse(t('TEXT_GOAL_PRIORITY_SLIDER_INFO_POPUP'))}
                        placement="top-start"
                    >
                        <ToolTipSvg />
                    </Customtooltip>
                </SliderLabel>
            </TooltipContainer>
            <SliderContainer data-testid={name} value={value}>
                <StyledSlider
                    aria-labelledby="range-slider"
                    value={value}
                    track={false}
                    marks={marks}
                    name={name}
                    // onBlur={onBlur}
                    disabled={disabled}
                    onChange={handleChange}
                    min={min}
                    max={max}
                    onFocus={onBlur}
                    step={step}
                />
            </SliderContainer>
        </>
    );
};

export default SliderComponent;
