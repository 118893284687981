import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '32%',
    color: theme.palette.common.white,
    fontSize: '1rem',
    '@media(max-width: 1440px)': {
        width: '50%'
    },
    '@media(max-width: 992px)': {
        width: '80%'
    }
}));

export const ModalContent = styled(Typography)<TypographyProps>(() => ({
    fontSize: '30px',
    lineHeight: '38px'
}));

const ModalComponent = ({ open, handleClose, children, backgroundColor = '', style }: any) => (
    <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock
            disableEscapeKeyDown
            disableEnforceFocus
            BackdropProps={{ style: { backgroundColor } }}
        >
            <StyledBox sx={{ bgcolor: 'background.paper', p: 4, borderRadius: 1, ...style }}>{children}</StyledBox>
        </Modal>
    </div>
);

export default ModalComponent;
