import moment from 'moment';
import accounting from 'accounting';
import {
    GOAL_PRIORITY,
    INFUSION_ANNUAL,
    INFUSION_MONTHLY,
    ADD_CLIENT_ANNUAL,
    ADD_CLIENT_MONTH,
    TENANT_ID_MAP
} from '../../constants';

const subdomain = TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'highpointdev'];

export const lastReallocatedDate = '01-01-2021';
export const cashflowDate = '01-01-2021';
export const goalObj = {
    goalType: '',
    goalName: ''
};
export const current_Date = moment().format('DD-MM-YYYY');
export const createClientObj = {
    apiName: '',
    apiVer: 'v4',
    id: '',
    clientFirstName: '',
    clientLastName: '',
    clientEmail: '',
    formFields: Array<any>,
    request: Array<any>,
    response: Array<any>,
    isMultiGoal: false,
    iwsApplied: false,
    proposalDate: moment().format('MM/DD/YYYY'),
    riskProfile: ''
};

export const freemiumAccordionDataObj = [
    {
        value: 1,
        name: 'Risk Tolerance',
        text: 'HEADER_RISK_TOLERANCE_SECTION_FREEMIUM',
        helperText: '',
        subheading1: 'HEADER_SUB_RISK_TOLERANCE_SECTION1',
        subheading2: 'HEADER_SUB_RISK_TOLERANCE_SECTION2',
        subheadingHelper1: 'HEADER_SUB_HELPER_RISK_TOLERANCE_SECTION1',
        subheadingHelper2: 'HEADER_SUB_HELPER_RISK_TOLERANCE_SECTION2',
        description: 'DESCRIPTION_RISK_TOLERANCE_SECTION_FREEMIUM'
    },
    {
        value: 2,
        name: 'Saving Goal',
        text: 'HEADER_CHOOSE_GOAL_SECTION',
        helperText: '',
        helperTextArray: [],
        description: 'DESCRIPTION_CHOOSE_GOAL_SECTION'
    },

    {
        value: 3,
        name: 'Investment Goal',
        text: 'HEADER_INVESTMENT_GOAL_SECTION',
        helperText: '',
        description: ''
    }
];

export const accordionDataObj = [
    {
        value: 1,
        name: 'Client Info',
        text: 'HEADER_CLIENT_INFO',
        helperText: '',
        description: ''
    },
    {
        value: 2,
        name: 'Risk Tolerance',
        text: 'HEADER_RISK_TOLERANCE_SECTION',
        helperText: '',
        subheading1: 'HEADER_SUB_RISK_TOLERANCE_SECTION1',
        subheading2: 'HEADER_SUB_RISK_TOLERANCE_SECTION2',
        subheadingHelper1: 'HEADER_SUB_HELPER_RISK_TOLERANCE_SECTION1',
        subheadingHelper2: 'HEADER_SUB_HELPER_RISK_TOLERANCE_SECTION2',
        description: 'DESCRIPTION_RISK_TOLERANCE_SECTION'
    },
    {
        value: 3,
        name: 'Saving Goal',
        text: 'HEADER_CHOOSE_GOAL_SECTION',
        helperText: '',
        helperTextArray: [],
        description: 'DESCRIPTION_CHOOSE_GOAL_SECTION'
    },

    {
        value: 4,
        name: 'Investment Goal',
        text: 'HEADER_INVESTMENT_GOAL_SECTION',
        helperText: '',
        description: ''
    }
];
export const clientInfo = {
    investorFirstName: '',
    investorLastName: '',
    investorEmail: '',
    investorDOB: '',
    investorAddress: '',
    city: '',
    state: '',
    zip: '',
    country: ''
};
export const investmentGoalInfo = {
    goalType: '',
    goalName: '',
    initialInvestment: '',
    targetAmount: '',
    contributionAmount: '',
    contributionFrequency: 'Annually',
    moneyLeft: '',
    annualIncrease: '',
    targetMonth: '',
    targetYear: '',
    goalOption: 0,
    withdrawnAmount: '',
    withdrawnFrequency: 'Annually',
    withdrawnMonth: '',
    withdrawnYear: '',
    withdrawnDuration: ''
};
export const generateIncomeObj = {
    goalType: '',
    goalName: '',
    initialInvestment: '',
    scheduledWithdrawalAmount: '',
    withdrawalFrequency: 'Annually',
    withdrawalStartingOnMonth: '',
    withdrawalStartingOnYear: '',
    withdrawalPeriod: '',
    bequest: '',
    goalAmount: '',
    endDate: '',
    startDate: '',
    goalPriorityValue: 4,
    goalPriority: '',
    riskProfile: '',
    fundingSource: '',
    existingAccountId: [],
    additionalFunding: '0'
};
export const futureEventObj = {
    goalType: '',
    goalName: '',
    initialInvestment: '',
    scheduledContribution: '',
    scheduledFrequency: 'Annually',
    investorPlanMonth: '',
    investorPlanYear: '',
    goalAmount: '',
    endDate: '',
    startDate: '',
    goalPriorityValue: 4,
    goalPriority: '',
    riskProfile: '',
    fundingSource: '',
    existingAccountId: [],
    additionalFunding: '0'
};

export const retirementObj = {
    goalType: '',
    goalName: '',
    initialInvestment: '',
    retirementMonth: '',
    retirementYear: '',
    scheduledContributionAmount: '',
    scheduledContributionFrequency: 'Annually',
    autoEsclationPercentage: '',
    autoEsclationFrequency: '',
    withdrawalPeriod: '',
    withdrawalAmount: '',
    withdrawalFrequency: 'Annually',
    bequest: false,
    goalPriority: '',
    goalAmount: '',
    endDate: '',
    startDate: '',
    goalPriorityValue: 4,
    riskProfile: '',
    fundingSource: '',
    existingAccountId: [],
    additionalFunding: '0'
};

export const Add_Client_InvalidDate = 'Invalid Date';
export const getInfusionType = (value: string) => {
    let infusionType;
    if (value === ADD_CLIENT_ANNUAL) {
        infusionType = INFUSION_ANNUAL;
    } else if (value === ADD_CLIENT_MONTH) {
        infusionType = INFUSION_MONTHLY;
    }
    return infusionType;
};
export const annualDistributionYear = (year: number, num: number) => {
    const years = [];
    for (let i = 0; i <= num; i += 1) {
        years.push(year + i);
    }
    return years;
};

export const getGoalPriorityName = (value: number) => {
    let goalPriority;
    if (value === 1) {
        goalPriority = GOAL_PRIORITY.DREAM;
    } else if (value === 2) {
        goalPriority = GOAL_PRIORITY.WISH;
    } else if (value === 3) {
        goalPriority = GOAL_PRIORITY.WANT;
    } else if (value === 4) {
        goalPriority = GOAL_PRIORITY.NEED;
    }
    return goalPriority;
};
export const getGoalPriorityValue = (value: string) => {
    let goalPriority;
    if (value === GOAL_PRIORITY.NEED) {
        goalPriority = 4;
    } else if (value === GOAL_PRIORITY.WANT) {
        goalPriority = 3;
    } else if (value === GOAL_PRIORITY.WISH) {
        goalPriority = 2;
    } else if (value === GOAL_PRIORITY.DREAM) {
        goalPriority = 1;
    }
    return goalPriority;
};
export const bequestValue = [
    {
        value: 'true',
        label: 'TEXT_YES'
    },
    {
        value: 'false',
        label: 'TEXT_NO'
    }
];

export const goalTypeOptions = [
    {
        value: 'TEXT_RETIREMENT_SAVINGS',
        label: 'TEXT_RETIREMENT_SAVINGS'
    },
    {
        value: 'TEXT_FUTURE_EVENT_OR_ACTIVITY',
        label: 'TEXT_FUTURE_EVENT_OR_ACTIVITY'
    },
    {
        value: 'TEXT_GENERATE_INCOME',
        label: 'TEXT_GENERATE_INCOME'
    }
];
export const autoEscalationFrequency = [
    {
        value: 1,
        prefix: '1',
        label: 'TEXT_YEAR'
    },
    {
        value: 2,
        prefix: '2',
        label: 'TEXT_YEARS'
    },
    {
        value: 3,
        prefix: '3',
        label: 'TEXT_YEARS'
    },
    {
        value: 4,
        prefix: '4',
        label: 'TEXT_YEARS'
    },
    {
        value: 5,
        prefix: '5',
        label: 'TEXT_YEARS'
    }
];

export const Months = [
    {
        value: 1,
        label: 'TEXT_MONTH_JANUARY',
        disabled: false
    },
    {
        value: 2,
        label: 'TEXT_MONTH_FEBRUARY',
        disabled: true
    },
    {
        value: 3,
        label: 'TEXT_MONTH_MARCH',
        disabled: true
    },
    {
        value: 4,
        label: 'TEXT_MONTH_APRIL',
        disabled: true
    },
    {
        value: 5,
        label: 'TEXT_MONTH_MAY',
        disabled: true
    },
    {
        value: 6,
        label: 'TEXT_MONTH_JUNE',
        disabled: true
    },
    {
        value: 7,
        label: 'TEXT_MONTH_JULY',
        disabled: true
    },
    {
        value: 8,
        label: 'TEXT_MONTH_AUGUST',
        disabled: true
    },
    {
        value: 9,
        label: 'TEXT_MONTH_SEPTEMBER',
        disabled: true
    },
    {
        value: 10,
        label: 'TEXT_MONTH_OCTOBER',
        disabled: true
    },
    {
        value: 11,
        label: 'TEXT_MONTH_NOVEMBER',
        disabled: true
    },
    {
        value: 12,
        label: 'TEXT_MONTH_DECEMBER',
        disabled: true
    }
];

export const FUNDING_SOURCE_EXISTING_ACCOUNTS_VALUE = "Client's Existing Accounts";
export const FUNDING_SOURCE_ENTER_AMOUNT_VALUE = 'Enter An Amount';

export const fundingSources = [
    {
        value: FUNDING_SOURCE_EXISTING_ACCOUNTS_VALUE,
        label: 'TEXT_FUNDING_SOURCE_EXISTING_ACCOUNT'
    },
    {
        value: 'Enter An Amount',
        label: 'TEXT_FUNDING_SOURCE_ENTER_AMOUNT'
    }
];

export const getExistingAccountsForSelect = (arr: any) => {
    return arr.map((x: any) => ({
        value: x.accountNumber,
        label: `${x.DataProvider} (${x.currency?.trim()})`,
        amount: accounting.formatMoney(x.accountBalance)
    }));
};

export const addEquityFixedIncomeRatio = (clientSummary: any, portfolios: any) => {
    return clientSummary.formFields.map((formField: any) => {
        const goalId = formField.goalId;
        const recommendedPortfolioId = clientSummary.response.find((res: any) => res.goalId === goalId)?.data
            .analysisReport?.recommendedPortfolioId;
        const selectedPortfolio = portfolios.find((portfolio: any) => portfolio.portfolioId === recommendedPortfolioId);
        const selectedAsset = selectedPortfolio?.assets?.find((asset: any) => asset.type === 'Equity');
        const equityAllocation = selectedAsset?.totalAllocatedvalue;
        return {
            ...formField,
            data: {
                ...formField.data,
                equityFixedIncomeRatio: `${equityAllocation}/${100 - equityAllocation}`
            }
        };
    });
};

export const existingAccounts = [
    {
        id: '1',
        accountName: "Vera's 401k (USD)",
        accountValue: 51000
    },
    {
        id: '2',
        accountName: "Vera's Roth IRA (MEX)",
        accountValue: 14000
    },
    {
        id: '3',
        accountName: "Vera's Taxable Account (MEX)",
        accountValue: 115000
    }
];

const year = moment().year();

export const years = (startFromCurrentYear?: boolean) => {
    const arr: any = [];
    for (let i = startFromCurrentYear ? 0 : 1; i <= 40; i += 1) {
        arr.push({
            label: year + i,
            value: year + i
        });
    }
    return arr;
};
export const withdrawalPeriodYears = (num: number) => {
    const arr: any = [];
    for (let i = num; i < 51; i += 1) {
        arr.push({
            label: 'TEXT_YEARS',
            value: i,
            prefix: i
        });
    }
    return arr;
};
export const autoEscalation = () => {
    const arr: any = [];
    for (let i: number = 0; i <= 5; i += 0.5) {
        arr.push({
            label: `${i.toFixed(1)} ` + '%',
            value: `${i}`
        });
    }
    return arr;
};

export const frequency = [
    {
        value: ADD_CLIENT_ANNUAL,
        label: 'TEXT_ANNUALLY',
        disabled: false
    },
    {
        value: ADD_CLIENT_MONTH,
        label: 'TEXT_MONTHLY',
        disabled: true
    }
];

export const chooseGoal = [
    {
        icon: 'retirement',
        id: 1,
        value: 'Retirement Savings',
        label: 'TEXT_RETIREMENT_SAVINGS',
        helperText: '',
        disabled: false,
        active: false
    },

    {
        icon: subdomain === 'highpoint' ? 'futureEventHighpoint' : 'futureEvent',
        id: 2,
        value: 'Save for a Future Event',
        label: 'TEXT_FUTURE_EVENT_OR_ACTIVITY',
        helperText: '',
        disabled: false,
        active: false
    },

    {
        icon: 'income',
        id: 3,
        value: 'Generate Income',
        label: 'TEXT_GENERATE_INCOME',
        helperText: '',
        disabled: false,
        active: false
    }
];
