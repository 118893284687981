/* eslint-disable react/button-has-type */
import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Container = styled('div')(() => ({
    marginTop: '30px'
}));

export const HeaderTypography = styled(Typography)<TypographyProps>(() => ({
    fontSize: '22px',
    marginRight: '20px',
    fontWeight: 600
}));

const StyledErrorTypography = styled(Typography)<TypographyProps>(() => ({
    fontSize: '11px',
    margin: '3px 0px 0px',
    color: 'rgb(211, 47, 47)'
}));

const StyledInputContainer = styled('div')(() => ({
    height: '44px'
}));

const ColorPickerContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const ColorPickerSection = styled('div')(() => ({
    border: '1px solid',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px'
}));

const ColorViewSection = styled('div')<any>(({ bgcolor }) => ({
    border: '1px solid',
    height: '38px',
    width: '38px',
    backgroundColor: bgcolor || '#fff',
    marginRight: '10px'
}));

function ColorPicker({
    initialColor,
    color,
    onSubmit,
    handleSubmit,
    checkKeyDown,
    register,
    handleColorChange,
    formState
}: any) {
    const { t } = useTranslation();
    return (
        <Container>
            <ColorPickerContainer>
                <HeaderTypography variant="body1">{t('TEXT_ADMIN_PRIMARY_COLOR')}</HeaderTypography>
                <form id="hook-form" onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                    <ColorPickerSection>
                        <ColorViewSection bgcolor={initialColor} />
                        <StyledInputContainer>
                            <Input
                                type="text"
                                value={color}
                                {...register('color', {
                                    required: true,
                                    pattern: {
                                        value: /^#(?:[0-9a-f]{3}){1,2}$/i,
                                        message: t('TEXT_ADMIN_PRIMARY_COLOR_VALIDATION')
                                    },
                                    onChange: handleColorChange
                                })}
                            />
                            {formState?.errors?.color?.message && (
                                <StyledErrorTypography>
                                    {formState?.errors?.color?.message as any}
                                </StyledErrorTypography>
                            )}
                        </StyledInputContainer>
                    </ColorPickerSection>
                </form>

                <Button
                    type="submit"
                    form="hook-form"
                    variant="text"
                    sx={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                        textTransform: 'capitalize',
                        fontWeight: 500
                    }}
                    disabled={!(formState.isDirty && color !== initialColor)}
                >
                    {t('TEXT_ADMIN_CHANGE_BUTTON')}
                </Button>
            </ColorPickerContainer>
        </Container>
    );
}

export default ColorPicker;
