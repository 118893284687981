/* eslint-disable operator-linebreak */
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

import {
    updateClientInfoData,
    updateClientInfoHelperText,
    updateLoader,
    saveClient,
    checkDuplicateClient
} from '../../../features/add-client/add-client';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Input from '../../atoms/input';
import MaskedInputDate from '../../atoms/maskedInput';
import ButtonComponent from '../../atoms/button';
import { Add_Client_InvalidDate } from '../../../services/configs/configs';
import { Typography } from '@mui/material';

interface DataList {
    handleCancel: () => void;
    handleNextStep: () => void;
    clientData: FormInput;
    hideActions?: boolean;
    form: any;
}
export interface FormInput {
    investorFullName: string;
    investorEmail: string;
    investorDOB: string;
}

const GridContainer = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    marginTop: '2px'
}));

const AccordianFooter = styled('div')(() => ({
    marginTop: '20px',
    textAlign: 'right'
}));

const ButtonContainer = styled('span')(() => ({
    display: 'inline-block',
    margin: '6px'
}));
const ErrorSpan = styled(Typography)(({ theme }) => ({
    color: theme.palette.goal.formFields.errorLabel
}));

const Client_Info = ({ handleCancel, handleNextStep, clientData, hideActions = false, form }: DataList) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { userId, tenant } = useAppSelector((state: any) => state.auth.user);
    const [isSaveClientError, setIsSaveClientError] = useState(false);
    const [isCheckingEmail, setIsCheckingEmail] = useState<any>(null);
    const [emailExistsError, setEmailExistsError] = useState(null);

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        watch,
        setError
    } = form;
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (watch('investorEmail') !== clientData.investorEmail) {
                checkForEmailDupe(watch('investorEmail'), Boolean(errors.investorEmail));
            }
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [watch('investorEmail')]);

    const checkForEmailDupe = async (value: string, hasError: any) => {
        if (value && !hasError) {
            setIsCheckingEmail(true);
            let res: any;
            try {
                res = await dispatch(checkDuplicateClient(value));
            } catch (e) {
                setIsCheckingEmail(false);
                setEmailExistsError(null);
                return false;
            }
            if (res?.payload?.data?.length) {
                setError('investorEmail', {
                    type: 'manual',
                    message: t('TEXT_CLIENT_INFO_EMAIL_ALREADY_EXISTS')
                });

                setEmailExistsError(t('TEXT_CLIENT_INFO_EMAIL_ALREADY_EXISTS'));
                setIsCheckingEmail(false);
                return false;
            }

            setIsCheckingEmail(false);
            setEmailExistsError(null);
            return true;
        }
    };

    const formSubmitHandler = async (formdata: FormInput) => {
        dispatch(updateClientInfoData(formdata));
        dispatch(updateClientInfoHelperText(formdata.investorFullName));
        const payload = {
            firstName: formdata?.investorFullName,
            lastName: '',
            email: formdata?.investorEmail,
            dob: formdata?.investorDOB,
            tenant,
            userId
        };

        updateLoader(true);
        const success = await checkForEmailDupe(payload.email, false);

        try {
            if (success) {
                const res = await dispatch(saveClient(payload));

                if (res) {
                    handleNextStep();
                } else {
                    setIsSaveClientError(true);
                }
            } else {
                return;
            }
        } catch (e) {
            setIsSaveClientError(true);
        }

        updateLoader(false);
    };

    const dateOfBirthValidation = (date: any) => {
        if (!date) {
            return undefined;
        }
        const formattedData = moment(date);
        const today = moment();
        today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        const selectedDate = moment(date);

        if (selectedDate.toString() === 'Invalid Date') {
            return t('TEXT_CLIENT_INFO_DATE_VALIDATION');
        }

        const time = selectedDate.valueOf();

        const is18YearsOld =
            moment().diff(moment(formattedData, t('TEXT_CLIENT_INFO_INVESTOR_DOB_FORMAT')), 'years', false) >= 18;
        const isBelow99YearsOld =
            moment().diff(moment(formattedData, t('TEXT_CLIENT_INFO_INVESTOR_DOB_FORMAT')), 'years', false) <= 99;
        if (time > today.valueOf() || !is18YearsOld || !isBelow99YearsOld) {
            return !isBelow99YearsOld
                ? t('TEXT_CLIENT_INFO_DATE_OF_BIRTH_99_YEARS')
                : t('TEXT_CLIENT_INFO_DATE_OF_BIRTH_18_YEARS');
        }

        return undefined;
    };

    const getMaskBasedOnFormat = (format: string) =>
        format
            .split('')
            .map((eachString: string) => eachString.replace(/[^\d,/]+/g, '_'))
            .join('');

    const handleClose = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSaveClientError(false);
    };

    return (
        <form onSubmit={handleSubmit(formSubmitHandler)} noValidate>
            <GridContainer container spacing={2}>
                <GridContainer item xs={12} sm={6} md={4}>
                    <Controller
                        name="investorFullName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_CLIENT_INFO_FULL_NAME_VALIDATION')
                            },
                            pattern: {
                                value: /^[0-9A-Za-zñáéíóúü_ ]*$/i,
                                message: t('TEXT_CLIENT_INFO_FULL_NAME_VALIDATION')
                            },
                            maxLength: {
                                value: 50,
                                message: t('TEXT_CLIENT_INFO_NAME_MAX_VALIDATION_TEXT')
                            },
                            minLength: {
                                value: 2,
                                message: t('TEXT_CLIENT_INFO_NAME_MIN_VALIDATION_TEXT')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.investorFullName}
                                label={t('TEXT_CLIENT_INFO_INVESTOR_FULL_NAME')}
                                type="text"
                                placeholder={t('TEXT_ADD_USER_FULL_NAME_PLACEHOLDER')}
                            />
                        )}
                    />
                    {errors.investorFullName && (
                        <ErrorSpan role="alert" variant="subtitle2">
                            {errors.investorFullName.message}
                        </ErrorSpan>
                    )}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3}>
                    <Controller
                        name="investorEmail"
                        control={control}
                        aria-invalid={errors.investorEmail || emailExistsError ? 'true' : 'false'}
                        rules={{
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t('TEXT_CLIENT_INFO_EMAIL_VALIDATION')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                name="email"
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.investorEmail || !!emailExistsError}
                                label={t('TEXT_CLIENT_INFO_INVESTOR_EMAIL')}
                                type="email"
                                placeholder={t('PLACEHOLDER_EMAIL')}
                                showcheckmark={isCheckingEmail ? '' : 'True'}
                                CustomEndIcon={<CircularProgress size="24px" />}
                            />
                        )}
                    />

                    {(errors.investorEmail || emailExistsError) && (
                        <ErrorSpan role="alert" variant="subtitle2">
                            {errors?.investorEmail?.message || emailExistsError}
                        </ErrorSpan>
                    )}
                </GridContainer>
                <GridContainer item xs={12} sm={6} md={3}>
                    <Controller
                        name="investorDOB"
                        control={control}
                        rules={{
                            validate: dateOfBirthValidation
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <MaskedInputDate
                                label={t('TEXT_CLIENT_INFO_INVESTOR_DOB')}
                                error={!!errors.investorDOB || value === Add_Client_InvalidDate}
                                inputFormat={t('TEXT_CLIENT_INFO_INVESTOR_DOB_FORMAT')}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                disablePicker
                                mask={getMaskBasedOnFormat(t('TEXT_CLIENT_INFO_INVESTOR_DOB_FORMAT'))}
                                placeholder={t('TEXT_CLIENT_INFO_INVESTOR_DOB_FORMAT')}
                            />
                        )}
                    />
                    {errors.investorDOB && (
                        <ErrorSpan role="alert" variant="subtitle2">
                            {errors.investorDOB.message}
                        </ErrorSpan>
                    )}
                </GridContainer>
            </GridContainer>

            <GridContainer container spacing={2} sx={{ marginTop: '15px' }}>
                <GridContainer item xs={12} sm={6} md={4}>
                    <Typography>{t('TEXT_REQUIRED_FIELD')}</Typography>
                </GridContainer>
            </GridContainer>
            {!hideActions && (
                <AccordianFooter>
                    <ButtonContainer>
                        <ButtonComponent variant="outlined" onClick={handleCancel}>
                            {t('BUTTON_CANCEL')}
                        </ButtonComponent>
                    </ButtonContainer>
                    <ButtonComponent
                        type="submit"
                        variant={!isValid || isCheckingEmail || emailExistsError ? 'outlined' : 'contained'}
                        custom={+!isValid || isCheckingEmail || emailExistsError}
                        disabled={!isValid || isCheckingEmail || emailExistsError}
                    >
                        {t('BUTTON_ADD_CLIENT')}
                    </ButtonComponent>
                </AccordianFooter>
            )}

            <Snackbar open={isSaveClientError} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                        setIsSaveClientError(false);
                    }}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {t('TEXT_CLIENT_INFO_SAVE_CLIENT_ERROR')}
                </MuiAlert>
            </Snackbar>
        </form>
    );
};
export default Client_Info;
