import { useState, useEffect, KeyboardEvent, SetStateAction } from 'react';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';

import ColorPicker from '../../components/molecules/color-picker';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { updateLayoutColor, updateTenantInfo } from '../../features/global/globalSlice';

const ColorPickerContainer = ({ formattedGlobal }: any) => {
    const styles = useAppSelector((state: { global: any }) => state.global?.globalConfig?.styles?.config || {});
    const initialColor = styles?.layout?.primary;

    const [color, setColor] = useState('');

    const dispatch = useAppDispatch();
    const { register, handleSubmit, formState, reset } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            color
        }
    });

    const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    const handleColorChange = (e: { target: { value: SetStateAction<string> } }) => {
        setColor(e.target.value);
    };

    useEffect(() => {
        if (!isEmpty(styles)) {
            setColor(styles?.layout?.primary);
        }
    }, [styles]);

    const onSubmit = (data: any) => {
        const requestedPayload = {
            ...formattedGlobal,
            styles: {
                config: {
                    layout: {
                        primary: data.color
                    }
                }
            }
        };
        dispatch(updateTenantInfo(requestedPayload)).then((success) => {
            if (success) {
                dispatch(
                    updateLayoutColor({
                        config: {
                            layout: {
                                primary: data.color
                            }
                        }
                    })
                );
                if (typeof window !== 'undefined') {
                    window.location.reload();
                }
            }
        });
        reset?.({}, { keepValues: true });
    };

    return (
        <ColorPicker
            initialColor={initialColor}
            color={color}
            setColor={setColor}
            onSubmit={onSubmit}
            formState={formState}
            register={register}
            handleSubmit={handleSubmit}
            handleColorChange={handleColorChange}
            checkKeyDown={checkKeyDown}
        />
    );
};

export default ColorPickerContainer;
