/* eslint-disable no-lone-blocks */
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import GlidePathContainer from '../../containers/charts/glide-path';
import WealthPathContainer from '../../containers/charts/wealth-path';
import RecommendationContainer from '../../containers/charts/recommendation';
import PortfolioCompositionContainer from '../../containers/charts/portfolio-composition';
import { fetchPortfolio, updateShowAdvisorPortalButtonOnHeader } from '../../features/global/globalSlice';
import ModalComponent from '../../components/molecules/modal';
import { CtaContainer, ModalContent } from '../../components/molecules/modal/styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TabsContainer from '../../containers/personalized-plan/tabs';
import FormContainer from '../../containers/personalized-plan/form';
import { updateProfolioCompositionData, saveGoal } from '../../features/personalized-plan';
import PDFModal from '../../containers/pdf';
import ButtonComponent from '../../components/atoms/button';
import { addEquityFixedIncomeRatio } from '../../services/configs/configs';
import { logEventToBackEnd } from '../../features/global/globalSlice';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';

const ChartsSection = styled('div')(() => ({
    marginTop: '40px'
}));

const LoaderContainer = styled('div')(() => ({
    margin: '20% 45%'
}));

export const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '36px',
    fontWeight: 200,
    lineHeight: '32px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    margin: '36px 0 40px 0',
    display: 'inline-block'
}));

const CloseIconContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
        height: '40px',
        width: '40px',
        cursor: 'pointer'
    }
}));

const PersonalizedPlan = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [modalState, setModalState] = useState(false);
    const [clientData, setClientData] = useState<any>({});
    const [pdfModalOpen, setPdfPreviewOpen] = useState(false);

    const [blockUserNavigation, setBlockUserNavigation] = useState(true);
    const [showPrompt, confirmNavigation, cancelNavigation]: any = useCallbackPrompt(blockUserNavigation);

    const { portfolio } = useAppSelector((state: { global: any }) => state.global);
    const clientSummary = useAppSelector(
        (state: { personalizedPlan: { clientSummary: any } }) => state.personalizedPlan.clientSummary
    );
    const formField = useAppSelector(
        (state: { personalizedPlan: { formField: any } }) => state.personalizedPlan.formField
    );
    const response = useAppSelector(
        (state: { personalizedPlan: { response: any } }) => state.personalizedPlan.response
    );
    const request = useAppSelector((state: { personalizedPlan: { request: any } }) => state.personalizedPlan.request);
    const loader = useAppSelector((state: { addClient: { loading: any } }) => state.addClient.loading);
    const saveLoader = useAppSelector(
        (state: { personalizedPlan: { saveLoading: any } }) => state.personalizedPlan.saveLoading
    );
    const portfolioData = useAppSelector((state: { global: { portfolio: any } }) => state?.global?.portfolio || []);
    const assignedClientId = useAppSelector((state) => state?.addClient?.assignedClientId);
    const portfolioComposistionChartData = useAppSelector(
        (state: { personalizedPlan: { portfolioComposition: any } }) =>
            state?.personalizedPlan?.portfolioComposition || []
    );
    const portfolioCompositionYearLabel = useAppSelector(
        (state: { personalizedPlan: { portfolioCompositionYearLabel: any } }) =>
            state?.personalizedPlan?.portfolioCompositionYearLabel || ''
    );
    const portfolioComposistionEquityValue = useAppSelector(
        (state: { personalizedPlan: { equity: any } }) => state?.personalizedPlan?.equity || ''
    );
    const portfolioComposistionFixedIncomeValue = useAppSelector(
        (state: { personalizedPlan: { fixedIncome: any } }) => state?.personalizedPlan?.fixedIncome || ''
    );
    const userEmail = useAppSelector((state) => state.auth?.user?.userEmail);
    useEffect(() => {
        dispatch(updateShowAdvisorPortalButtonOnHeader(false));
        if (isEmpty(portfolio)) {
            dispatch(fetchPortfolio());
        }
    }, [portfolio]);

    useEffect(() => {
        if (!isEmpty(clientSummary)) {
            setClientData(clientSummary);
        }
    }, [clientSummary]);

    const saveGoalAndExit = async (payload: any) => {
        setBlockUserNavigation(false);

        const formattedPayload = { ...payload };
        formattedPayload.assignedClientId = assignedClientId;
        formattedPayload.advisorEmail = userEmail;
        formattedPayload.formFields = addEquityFixedIncomeRatio(formattedPayload, portfolio);
        delete formattedPayload.id;
        delete formattedPayload.proposalDate;
        delete formattedPayload.riskProfile;

        try {
            dispatch(saveGoal(formattedPayload)).then((res: any) => {
                if (res) {
                    navigate('/clients', {
                        state: {
                            clientEmail: formattedPayload.clientEmail
                        }
                    });
                }
            });
        } catch (err) {
            setBlockUserNavigation(true);
            // console.error(err);
        }
    };
    const handleCloseIcon = () => {
        setModalState(true);
        setBlockUserNavigation(false);
    };

    const handleModalCloseAndNavigate = (handleNavigate: any) => {
        if (handleNavigate) {
            setModalState(false);
            navigate('/clients');
        } else {
            setModalState(false);
            setBlockUserNavigation(true);
        }
    };
    return saveLoader ? (
        <LoaderContainer>
            <CircularProgress sx={{ height: '80px !important', width: '80px !important' }} />
        </LoaderContainer>
    ) : (
        <div data-testid="personalized-plan">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <HeaderText tabIndex={0}>{t('HEADER_PERSONALIZED_PLAN_PAGE')}</HeaderText>
                <CloseIconContainer>
                    <CloseIcon
                        role="button"
                        tabIndex={0}
                        aria-label="close-edit-plan-page"
                        onKeyPress={() => handleCloseIcon()}
                        onClick={() => handleCloseIcon()}
                        sx={{ color: 'common.black' }}
                    />
                </CloseIconContainer>
            </div>
            <TabsContainer
                clientSummary={clientData}
                openPDFPreview={() => {
                    dispatch(logEventToBackEnd('PDF_GENERATED'));
                    setPdfPreviewOpen(true);
                }}
                saveGoalAndExit={() => saveGoalAndExit(clientSummary)}
                onBackClick={(callback: any) => {
                    setBlockUserNavigation(false);
                    callback?.();
                }}
            />
            <FormContainer showLoader={loader} formdata={formField} />
            <ChartsSection>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <WealthPathContainer
                            showHeader={false}
                            showFooter
                            showLegends={false}
                            showLoader={loader}
                            showSkeletonLoader
                            formField={formField?.data}
                            response={response?.data}
                            request={request?.data}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <RecommendationContainer
                            showSkeletonLoader
                            request={request?.data}
                            response={response?.data}
                            formField={formField?.data}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} marginTop={2}>
                        <GlidePathContainer
                            showHeader={false}
                            showFooter
                            showLegends={false}
                            showSkeletonLoader
                            showLoader={loader}
                            portfolioData={portfolioData}
                            request={request?.data}
                            response={response?.data}
                            updateProfolioCompositionData={updateProfolioCompositionData}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} marginTop={2}>
                        <PortfolioCompositionContainer
                            showHeader={false}
                            showFooter
                            showLegends={false}
                            showSkeletonLoader
                            showLoader={loader}
                            chartData={portfolioComposistionChartData}
                            equity={portfolioComposistionEquityValue}
                            fixedIncome={portfolioComposistionFixedIncomeValue}
                            label={portfolioCompositionYearLabel}
                        />
                    </Grid>
                </Grid>
            </ChartsSection>
            <ModalComponent
                open={modalState || showPrompt}
                handleClose={() => {
                    handleModalCloseAndNavigate(false);
                    setBlockUserNavigation(true);
                    cancelNavigation();
                }}
            >
                <ModalContent id="modal-modal-title" variant="body1">
                    {t('TEXT_MODAL_CONFIRMATION')}
                </ModalContent>
                <CtaContainer>
                    <ButtonComponent
                        variant="outlined"
                        size="medium"
                        onClick={() => {
                            handleModalCloseAndNavigate(false);
                            setBlockUserNavigation(true);
                            cancelNavigation();
                        }}
                    >
                        {t('TEXT_NO')}
                    </ButtonComponent>
                    <ButtonComponent
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            handleModalCloseAndNavigate(true);
                            confirmNavigation();
                        }}
                    >
                        {t('TEXT_YES')}
                    </ButtonComponent>
                </CtaContainer>
            </ModalComponent>

            <PDFModal
                open={pdfModalOpen}
                handleClose={() => setPdfPreviewOpen(false)}
                clientSummary={clientSummary}
                updateProfolioCompositionData={updateProfolioCompositionData}
                portfolioData={portfolioData}
                portfolioComposistionChartData={portfolioComposistionChartData}
                portfolioComposistionEquityValue={portfolioComposistionEquityValue}
                portfolioComposistionFixedIncomeValue={portfolioComposistionFixedIncomeValue}
                portfolioCompositionYearLabel={portfolioCompositionYearLabel}
            />
        </div>
    );
};

export default memo(PersonalizedPlan);
