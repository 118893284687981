/* eslint-disable operator-linebreak */
import { styled } from '@mui/material/styles';
import { MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { useTranslation } from 'react-i18next';
import { BootstrapFormControl, BootstrapInputLabel } from '../input';

const StyledSelect = styled(Select)(({ theme, error, value, disabled, customheight }: any) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        WebkitTextFillColor: disabled && value ? theme.palette.common.black : '',
        backgroundColor: error
            ? theme.palette.goal.formFields.errorBackground
            : value
            ? theme.palette.goal.formFields.background
            : disabled
            ? theme.palette.grey[200]
            : theme.palette.common.white,
        border: error
            ? `1px solid ${theme.palette.error.main}`
            : disabled && value
            ? `1px solid ${theme.palette.primary.main}`
            : value
            ? `1px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.common.black}`,
        fontSize: '1rem',
        width: '100%',
        height: customheight ? customheight : '40px !important',
        padding: '8px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        '&:focus': {
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
            borderColor: error
                ? theme.palette.error.main
                : value
                ? theme.palette.primary.main
                : theme.palette.common.black
        },
        '&::placeholder': {
            color: theme.palette.grey[600],
            fontStyle: 'italic',
            fontSize: '0.9rem'
        }
    }
}));

const StyledMenuItem = styled(MenuItem)(() => ({}));
interface PlaceholderProps {
    error?: boolean;
}
const Placeholder = styled('div')<PlaceholderProps>(({ theme, error }) => ({
    color: error ? theme.palette.common.black : theme.palette.grey.A200
}));

// interface SelectOption {
//   value: string | number | null | boolean;
//   label: string;
// }

interface SelectProps extends SelectInputProps {
    label?: string;
    value: string | number | null | boolean | Array<String>;
    data: any[];
    placeholder: string;
    id: string;
    disabled?: boolean;
    defaultval?: number;
    error?: boolean;
    name?: any;
    initialValue?: string;
    customheight?: string;
}

function SelectComponent(props: SelectProps) {
    const { data, placeholder, label, value, name, disabled, defaultval, error, initialValue, customheight } = props;
    const { t } = useTranslation();
    return (
        <BootstrapFormControl variant="standard">
            <BootstrapInputLabel error={error} variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
                {label}
                {label && error ? ` - *${t('TEXT_REQUIRED')}` : ''}
            </BootstrapInputLabel>
            <StyledSelect
                displayEmpty
                disableUnderline
                disabled={disabled}
                data-testid={name}
                error={error}
                IconComponent={KeyboardArrowDownIcon}
                renderValue={
                    value
                        ? undefined
                        : () => (
                              <Placeholder error={Boolean(initialValue) || error}>
                                  {initialValue || placeholder}
                              </Placeholder>
                          )
                }
                customheight={customheight}
                {...props}
            >
                {data.map((val: any, index) => (
                    <StyledMenuItem
                        key={index}
                        value={val?.value}
                        disabled={defaultval && val.disabled ? val.disabled : false}
                    >
                        {val.prefix ? `${val.prefix} ${t(val.label)}` : t(val.label)}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </BootstrapFormControl>
    );
}

export default SelectComponent;
