import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    updateManagerGroupedData,
    updateAdvisorGroupedData,
    updateTableColumnHiding
} from '../../../features/my-clients/client-side-dashboard-table-slice';
import { memoizeGroupByAdvisor, memoizeGroupByManager } from '../../../utils/table';
import CheckboxComponent from '../../atoms/checkbox';

const FilterCheckbox = () => {
    const MANAGER_GROUP = 'MANAGER_GROUP';
    const ADVISOR_GROUP = 'ADVISOR_GROUP';
    const { t } = useTranslation();

    const boxOne = {
        id: MANAGER_GROUP,
        name: MANAGER_GROUP,
        value: MANAGER_GROUP,
        label: t('TEXT_GROUP_BY_MANAGER'),
        checked: false
    };

    const boxTwo = {
        id: ADVISOR_GROUP,
        name: ADVISOR_GROUP,
        value: ADVISOR_GROUP,
        label: t('TEXT_GROUP_BY_ADVISOR'),
        checked: false
    };

    const dispatch = useAppDispatch();

    const tableData: any[] = useAppSelector((state) => state.clientSideTableProposals.tableData || []);
    const columnVisibility = useAppSelector((state) => state.clientSideTableProposals.defaultColumnVisibility || []);
    const [checkboxes, setCheckboxes] = React.useState<any[]>([]);

    const handleChange = (id: string) => {
        const firstId = MANAGER_GROUP;
        const val = checkboxes?.map((checkbox) => {
            if (firstId === id) {
                checkbox.checked = checkbox?.id === firstId ? !checkbox.checked : false;
            } else if (checkbox?.id === id) {
                checkbox.checked = !checkbox?.checked;
            } else if (checkbox?.id === firstId) {
                checkbox.checked = false;
            }
            return checkbox;
        });

        const { name, checked }: any = val.find((v) => v.id === id);

        if (checked && name === ADVISOR_GROUP && !isEmpty(tableData)) {
            dispatch(updateManagerGroupedData([]));
            dispatch(updateTableColumnHiding({ managerName: false, advisorName: true }));
            const data = memoizeGroupByAdvisor(tableData);
            dispatch(updateAdvisorGroupedData(data));
        } else if (!checked && name === ADVISOR_GROUP) {
            dispatch(updateTableColumnHiding({}));
            dispatch(updateAdvisorGroupedData([]));
        }

        if (checked && name === MANAGER_GROUP && !isEmpty(tableData)) {
            dispatch(updateTableColumnHiding({}));
            dispatch(updateAdvisorGroupedData([]));
            const data = memoizeGroupByManager(tableData);
            dispatch(updateManagerGroupedData(data));
        } else if (!checked && name === MANAGER_GROUP) {
            dispatch(updateTableColumnHiding({}));
            dispatch(updateManagerGroupedData([]));
        }

        setCheckboxes(val);
    };

    useEffect(() => {
        if (!isEmpty(columnVisibility)) {
            const { managerName, advisorName } = columnVisibility;
            const checkboxes = [managerName ? boxOne : undefined, advisorName ? boxTwo : undefined].filter(Boolean);
            setCheckboxes(checkboxes);
        }
    }, [columnVisibility]);

    useEffect(
        () => () => {
            dispatch(updateAdvisorGroupedData([]));
            dispatch(updateManagerGroupedData([]));
        },
        []
    );

    return !isEmpty(checkboxes) ? (
        <>
            {checkboxes?.map((checkbox) => (
                <CheckboxComponent
                    id={checkbox.id}
                    key={checkbox.id}
                    value={checkbox.value}
                    checked={checkbox.checked}
                    onChange={() => handleChange(checkbox.id)}
                    label={checkbox.label}
                />
            ))}
        </>
    ) : null;
};

export default FilterCheckbox;
