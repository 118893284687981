import { styled } from '@mui/material/styles';

const Label = styled('span')(() => ({
    color: 'red'
}));

const LabelComponent = ({
    equity,
    fixedIncome,
    color,
    pieChartFontSize = 18
}: {
    equity?: string | any;
    fixedIncome?: string | any;
    color: any;
    pieChartFontSize: number | any;
}) => (
    <div style={{ backgroundColor: '#fff', borderRadius: 5 }}>
        <Label style={{ color }}>{equity}</Label>
        <br />
        <Label style={{ color, fontSize: `${pieChartFontSize}px` }}>{`${fixedIncome}%`}</Label>
    </div>
);

export default LabelComponent;
