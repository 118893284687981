import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

const animation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100%{
    opacity: 1;
  }
`;

const AnimationBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, 0.11)',

    borderRadius: '5px',
    width: '100%',
    height: '420px',
    animation: `${animation} 1.5s ease-in-out 0.5s infinite`
});

const Loader = ({ Icon }: any) => (
    <AnimationBox>
        <Icon
            sx={{
                color: 'white',
                width: '180px',
                height: '180px'
            }}
        />
    </AnimationBox>
);

export default Loader;
