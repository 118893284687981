/* eslint-disable indent */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
// import axios from '../../axios';
import { flatten } from '../../utils';
import { logout } from '../auth/authSlice';

export type translationType = {
    id: string | number;
    lookupKey: string | number;
    lookupValue: string | number;
    description?: string;
};

type InitialState = {
    translations: Array<translationType>;
    activeTabIndex: number;
    loading: boolean;
    error?: null | undefined | any;
    translationSelected?: translationType;
};

const initialState: InitialState = {
    translations: [],
    activeTabIndex: 0,
    loading: false,
    error: null,
    translationSelected: undefined
};

export const fetchTranslations = createAsyncThunk('translation/fetchTranslations', async () => {
    const response = await axios.get('locales/en/translation.json');

    const translations: any = flatten(response.data, '');

    const objectForUI = Object.keys(translations).map((key: string, index: number) => ({
        id: index + 1,
        lookupKey: key,
        lookupValue: translations[key],
        description: 'None'
    }));

    return objectForUI;
});

export const fetchSearchTranslations = createAsyncThunk(
    'translation/fetchSearchTranslations',
    async (searchString: string) => {
        const response = await axios.get(`locales/en/translation.json?q=${searchString}`);
        const translations: any = flatten(response.data, '');

        const objectForUI = Object.keys(translations).map((key: string, index: number) => ({
            id: index + 1,
            lookupKey: key,
            lookupValue: translations[key],
            description: 'None'
        }));

        return objectForUI;
    }
);

const manageTranslation = createSlice({
    name: 'translationState',
    initialState,
    reducers: {
        updateTranslations: (state, action: PayloadAction<any>) => {
            state.translations = action.payload;
        },
        updateActiveTabIndex: (state, action: PayloadAction<any>) => {
            state.activeTabIndex = action.payload;
        },
        selectTranslation: (state, action: PayloadAction<any>) => {
            state.translationSelected = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(fetchTranslations.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchTranslations.fulfilled, (state) => {
                state.loading = false;
                state.error = '';
            }),
            builder.addCase(fetchTranslations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }),
            builder.addCase(fetchSearchTranslations.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchSearchTranslations.fulfilled, (state) => {
                state.loading = false;
                state.error = '';
            }),
            builder.addCase(fetchSearchTranslations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { updateTranslations, updateActiveTabIndex, selectTranslation } = manageTranslation.actions;

export default manageTranslation.reducer;
