import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppDispatch } from '../../app/hooks';
import { HeaderTypography } from '../../components/molecules/color-picker';
import TargetBox from './target-box';
import Button from '@mui/material/Button';
import { updateTenantInfo, updateLogo } from '../../features/global/globalSlice';

const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column'
}));

// export const DataContext = React.createContext({});

const CompanyLogo = ({ formattedGlobal }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [uploadedFile, setUploadedFile] = useState(null);

    const onAddFile = (file: any) => {
        setUploadedFile(file?.[0]);
    };

    function applyChange() {
        if (uploadedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const requestedPayload = {
                    ...formattedGlobal,
                    logo: reader.result
                };
                dispatch(updateTenantInfo(requestedPayload)).then((success) => {
                    if (success) {
                        dispatch(updateLogo(reader.result));
                    }
                });
            };
            reader.readAsDataURL(uploadedFile);
            if (typeof window !== 'undefined') {
                setTimeout(() => window.location.reload(), 1000);
            }
        }
    }

    return (
        <Wrapper>
            <HeaderTypography variant="body1" style={{ margin: '30px 0' }}>
                {t('ADMIN_UPLOAD_COMPANY_LOGO')}
            </HeaderTypography>
            <DndProvider backend={HTML5Backend}>
                <TargetBox checkAndAddFile={onAddFile} uploadedFile={uploadedFile} />
            </DndProvider>
            {uploadedFile ? (
                <Button
                    variant="text"
                    onClick={() => {
                        applyChange();
                    }}
                    style={{
                        width: 'fit-content',
                        alignSelf: 'center',
                        cursor: 'pointer',
                        textTransform: 'capitalize',
                        fontWeight: 500
                    }}
                >
                    {t('TEXT_ADMIN_CHANGE_BUTTON')}
                </Button>
            ) : null}
        </Wrapper>
    );
};

export default CompanyLogo;
