import { Key, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import { updateFormField, updateRequest, updateResponse } from '../../../features/personalized-plan';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TabsComponent from '../../../components/molecules/scrollable-tabs';
import { updateActiveTabIndex } from '../../../features/global/globalSlice';

const TabsContainer = ({ openPDFPreview, saveGoalAndExit, onBackClick }: any) => {
    const dispatch = useAppDispatch();
    const activeTabIndex = useAppSelector((state) => state?.global?.activeTabIndex);
    const clientdata = useAppSelector((state) => state?.personalizedPlan?.clientSummary);
    const [responses, setResponses] = useState([]);
    const [formFields, setFormFields] = useState([]);
    const [requests, setRequests] = useState([]);

    const toggleTab = (value: Key | null | undefined | number | any, index: any) => {
        dispatch(updateActiveTabIndex(index));
        // dispatch(updateResponse(requests.find((v: any) => v.id === value.id)));
        // dispatch(updateResponse(responses.find((v: any) => v.id === value.id)));
    };

    useEffect(() => {
        if (!isEmpty(clientdata)) {
            setFormFields(clientdata.formFields);
            setResponses(clientdata.response);
            setRequests(clientdata.request);
            dispatch(updateRequest(clientdata.request[activeTabIndex]));
            dispatch(updateFormField(clientdata.formFields[activeTabIndex]));
            dispatch(updateResponse(clientdata.response[activeTabIndex]));
        }
    }, [clientdata, activeTabIndex]);

    return !isEmpty(formFields) && !isEmpty(requests) && !isEmpty(responses) ? (
        <>
            <TabsComponent
                openPDFPreview={openPDFPreview}
                saveGoalAndExit={saveGoalAndExit}
                tabsData={formFields}
                goalConfirm
                toggleState={activeTabIndex}
                toggleTab={toggleTab}
                onBackClick={onBackClick}
            />
        </>
    ) : (
        <Skeleton sx={{ margin: '20px 0px' }} variant="rectangular" width="100%" height={60} />
    );
};

export default TabsContainer;
