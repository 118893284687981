import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { UnknownProperties } from '../../../../constants/types';

const DataLabel = ({ header, subHeader, color, alignment = null }: UnknownProperties) => (
    <div style={{ margin: '40px 60px' }}>
        <Typography
            variant="h1"
            component="h1"
            style={{
                color,
                textAlign: alignment && 'end',
                margin: '0 0 2px 0'
            }}
        >
            {header}
        </Typography>
        {!isEmpty(subHeader) && (
            <Typography variant="body1" component="div" style={{ fontSize: '14px' }}>
                {subHeader}
            </Typography>
        )}
    </div>
);

export default DataLabel;
