/* eslint-disable operator-linebreak */
import React from 'react';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MenuItem, Select, ListItemText, Checkbox } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

import { BootstrapFormControl, BootstrapInputLabel } from '../../atoms/input';

const StyledSelect = styled(Select)(({ theme, error, value, disabled }) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        WebkitTextFillColor: disabled && value ? theme.palette.common.black : '',
        backgroundColor: error
            ? theme.palette.goal.formFields.errorBackground
            : value
            ? theme.palette.goal.formFields.background
            : disabled
            ? theme.palette.grey[200]
            : theme.palette.common.white,
        border: error
            ? `1px solid ${theme.palette.error.main}`
            : disabled && value
            ? `1px solid ${theme.palette.primary.main}`
            : value
            ? `1px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.common.black}`,
        fontSize: 16,
        width: '100%',
        height: '40px !important',
        padding: '8px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        '&:focus': {
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
            borderColor: error
                ? theme.palette.error.main
                : value
                ? theme.palette.primary.main
                : theme.palette.common.black
        },
        '&::placeholder': {
            color: theme.palette.grey[600],
            fontStyle: 'italic',
            fontSize: 14
        }
    }
}));

const StyledMenuItem = styled(MenuItem)(() => ({}));

interface SelectProps extends SelectInputProps {
    label?: string;
    value: Array<String>;
    data: any[];
    placeholder: string;
    id: string;
    disabled?: boolean;
    defaultval?: number;
    error?: boolean;
    name?: any;
    initialValue?: string;
}

const SelectAccountComponent = (props: SelectProps) => {
    const { data, label, name, disabled, defaultval, error, value } = props;
    const { t } = useTranslation();

    return (
        <BootstrapFormControl variant="standard">
            <BootstrapInputLabel error={error}>
                {label}
                {label && error ? ` - *${t('TEXT_REQUIRED')}` : ''}
            </BootstrapInputLabel>
            <StyledSelect
                displayEmpty
                disableUnderline
                disabled={disabled}
                data-testid={name}
                error={error}
                IconComponent={KeyboardArrowDownIcon}
                {...props}
                multiple
                renderValue={(selected: any) => {
                    if (!selected.length) {
                        return null;
                    }

                    return (
                        <Typography>
                            {selected.length > 1
                                ? 'Multiple Accounts Selected'
                                : t(data.find((x) => x.value === selected[0]).label)}
                        </Typography>
                    );
                }}
            >
                {data.map((val: any) => (
                    <StyledMenuItem
                        key={val?.value}
                        value={val?.value}
                        disabled={defaultval && val.disabled ? val.disabled : false}
                    >
                        <Checkbox checked={value.indexOf(val?.value) > -1} />
                        <ListItemText primary={t(val.label)} />
                        <ListItemText
                            primary={t(val.amount)}
                            sx={{
                                marginLeft: '20px',
                                textAlign: 'right'
                            }}
                        />
                    </StyledMenuItem>
                ))}

                <StyledMenuItem disabled>
                    <Typography>{t('TEXT_SELECT_ACCOUNT_PICKER_INFO')}</Typography>
                </StyledMenuItem>
            </StyledSelect>
        </BootstrapFormControl>
    );
};

export default SelectAccountComponent;
