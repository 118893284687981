/* eslint-disable react/display-name */
import { styled } from '@mui/material/styles';
// import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import parse from 'html-react-parser';
import Typography, { TypographyProps } from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ButtonComponent from '../../atoms/button';

const Container = styled('main')(() => ({
    minHeight: '73.2vh',
    maxWidth: '1440px'
}));

const Header = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px'
}));

const HeaderText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '22px',
    fontWeight: 400,
    lineHeight: '27.5px',
    textDecoration: 'none',
    display: 'inline-block'
}));

const CloseIconContainer = styled('div')(() => ({
    '& .MuiSvgIcon-root': {
        height: '40px',
        width: '40px',
        cursor: 'pointer'
    }
}));

const CtaContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'end',
    margin: '30px 16px',
    padding: '30px 0',
    '& button': {
        '&:first-of-type': {
            margin: '0 5px'
        }
    }
}));

const DocContent = styled('div')(() => ({
    margin: '16px'
}));

export default function AgreementContentModal({ open, handleClose, fromClients, t, agreeDocument }: any) {
    if (!open) {
        return null;
    }

    return (
        <Dialog open={Boolean(open)} onClose={handleClose} disableEscapeKeyDown disableEnforceFocus>
            <Container>
                <Header>
                    <HeaderText tabIndex={0}>{open.title}</HeaderText>
                    <CloseIconContainer>
                        <CloseIcon
                            role="button"
                            tabIndex={0}
                            aria-label="close-agreement-modal"
                            onKeyPress={() => handleClose()}
                            onClick={() => handleClose()}
                            sx={{ color: 'common.black' }}
                            data-testid="close-agreement-modal"
                        />
                    </CloseIconContainer>
                </Header>
                <hr />
                <DocContent>{parse(open?.description)}</DocContent>
                {!fromClients && (
                    <CtaContainer>
                        <ButtonComponent variant="outlined" onClick={() => handleClose()}>
                            {t('TEXT_EULA_POPUP_CANCEL_BUTTON')}
                        </ButtonComponent>
                        <ButtonComponent variant="contained" onClick={() => agreeDocument(open.title)}>
                            {t('TEXT_EULA_POPUP_I_AGREE_BUTTON')}
                        </ButtonComponent>
                    </CtaContainer>
                )}
            </Container>
        </Dialog>
    );
}
