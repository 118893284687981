import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
    fetchRiskToleranceData,
    updaterRiskToleranceHelperText,
    updateInvestorGoalData,
    updateRequestPayload
} from '../../../features/add-client/add-client';
import CircularCheckbox from '../../atoms/circularCheckbox';
import ButtonComponent from '../../atoms/button';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { goalObj } from '../../../services/configs/configs';
import { updateClient } from '../../../features/client-summary';
import { RISK_PROFILE_API_MAP } from '../../../constants';

interface DataList {
    heading1: string;
    heading2: string;
    subheading1: string;
    subheading2: string;
    showHr: boolean;
    handleCancel: any;
    handleNextStep: any;
    handleStartOverForFreemium: any;
    freemium?: boolean;
    hideActions?: boolean;
    riskData: any;
    changeActive: ({}: any) => void;
}

const getCalucaltedValue = (numberOfCircles: number, widthOfProgressContainer: any) => {
    const cicleWidthValue = 0.25 * ((numberOfCircles - 1) * 2);
    const numberOfHalfContiners = 2 ** (numberOfCircles - cicleWidthValue);
    const halfWidthOfContainer = widthOfProgressContainer / 2;

    return halfWidthOfContainer * numberOfHalfContiners;
};

const Hr: any = styled('hr')(({ theme, widthOfProgressContainer, numberOfCircles }: any) => ({
    position: 'absolute',
    height: '2px',
    // width: '70%',
    width: getCalucaltedValue(numberOfCircles, widthOfProgressContainer),
    background: theme.palette.grey[600],

    [theme.breakpoints.up('sm')]: {
        top: 'calc(85px / 2)'
    },
    [theme.breakpoints.up('md')]: {
        top: 'calc(85px / 2)'
    },
    [theme.breakpoints.up('lg')]: {
        top: 'calc(85px / 2)'
    },
    left: '50%',
    zIndex: '0',
    transform: 'translate(-50%, -50%)'
}));
const RiskHeadings = styled('div')(({ theme }) => ({
    margin: '22px 0',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '30px',
    textTransform: 'capitalize',
    color: theme.palette.common.black
}));
const RiskSubHeadings = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column'
    // justifyContent: 'space-between'
}));
const SubheadingHelperText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '12px'
}));
const ProgressContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    minHeight: '250px'
}));
const AccordianFooter = styled('div')(() => ({
    marginTop: '10px',
    marginBottom: '30px',
    textAlign: 'right'
}));

const ButtonContainer = styled('span')(() => ({
    display: 'inline-block',
    margin: '6px'
}));

const Label = styled('span')(() => ({
    display: 'flex',
    alignItems: 'flex-start'
}));

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

const RiskToleranceData = ({
    heading1,
    heading2,
    subheading1,
    subheading2,
    showHr,
    handleCancel,
    handleNextStep,
    handleStartOverForFreemium,
    freemium = false,
    hideActions = false,
    riskData,
    changeActive
}: DataList) => {
    const ProgressContainerRef: any = useRef<any>();
    const dispatch = useAppDispatch();
    const { userId } = useAppSelector((state: any) => state.auth.user);
    const { assignedClientId, clientInfo } = useAppSelector((state: any) => state.addClient);
    const clientSummary = useAppSelector((state: any) => state.client.clientSummary || {});
    const [widthOfProgressContainer, setWidthOfProgressContainer] = useState(0);
    const [width] = useWindowSize();

    const { t } = useTranslation();
    const [dataValue, setDataValue] = useState<any[]>([]);

    useEffect(() => {
        if (!riskData?.length) {
            dispatch(fetchRiskToleranceData());
        }
    }, []);
    useEffect(() => {
        const newData = cloneDeep(riskData);
        setDataValue(newData);
    }, [riskData]);

    useLayoutEffect(() => {
        setTimeout(() => {
            setWidthOfProgressContainer(ProgressContainerRef?.current?.offsetWidth);
        });
    }, [width]);
    const setContainerWidth = (value: any) => {
        setWidthOfProgressContainer(ProgressContainerRef?.current?.offsetWidth || value);
    };
    const validateCheckbox = () => {
        for (let i = 0; i < dataValue.length; i += 1) {
            if (dataValue[i].active) {
                return true;
            }
        }
        return false;
    };
    const disableCheckbox = validateCheckbox();
    const SaveRiskTOleranceGoal = () => {
        const result = dataValue.filter((el) => el.active === true);
        const selectedRiskTolerance = RISK_PROFILE_API_MAP[result[0].label];

        dispatch(updaterRiskToleranceHelperText(result[0].label));
        const obj = cloneDeep(goalObj);
        dispatch(updateInvestorGoalData([obj]));
        dispatch(updateRequestPayload([obj]));
        const payload = {
            id: assignedClientId,
            firstName: clientInfo?.investorFullName,
            email: clientInfo?.investorEmail,
            dob: clientInfo?.investorDOB,
            riskTolerance: selectedRiskTolerance.toLowerCase(),
            userId,
            externalData: clientSummary.externalData
        };
        dispatch(updateClient(payload)).then((res) => {
            if (res) {
                handleNextStep();
            }
        });
    };

    return (
        <>
            {heading1 ? (
                <RiskHeadings>
                    <RiskSubHeadings>
                        <Label>{t(heading1)}</Label>
                        <SubheadingHelperText variant="subtitle1">{t(subheading1)}</SubheadingHelperText>
                    </RiskSubHeadings>
                    <RiskSubHeadings>
                        <Label>{t(heading2)}</Label>
                        <SubheadingHelperText variant="subtitle1">{t(subheading2)}</SubheadingHelperText>
                    </RiskSubHeadings>
                </RiskHeadings>
            ) : (
                ''
            )}

            <ProgressContainer>
                {showHr ? (
                    <Hr widthOfProgressContainer={widthOfProgressContainer} numberOfCircles={dataValue.length} />
                ) : (
                    ''
                )}
                {dataValue.map((item, index) => (
                    <CircularCheckbox
                        key={index}
                        value={item.value}
                        helperText={t(item.helperText)}
                        label={t(item.label)}
                        active={item.active}
                        changeActive={() => {
                            changeActive(index);
                        }}
                        setContainerWidth={setContainerWidth}
                        ref={ProgressContainerRef}
                    />
                ))}
            </ProgressContainer>
            {!freemium && !hideActions && (
                <AccordianFooter>
                    <ButtonContainer>
                        <ButtonComponent data-testid="cancel" variant="outlined" onClick={handleCancel}>
                            {t('BUTTON_CANCEL')}
                        </ButtonComponent>
                    </ButtonContainer>
                    <ButtonContainer>
                        <ButtonComponent
                            data-testid="next-step"
                            variant={!disableCheckbox ? 'outlined' : 'contained'}
                            custom={+!disableCheckbox}
                            disabled={!disableCheckbox}
                            onClick={SaveRiskTOleranceGoal}
                        >
                            {t('BUTTON_NEXT_STEP')}
                        </ButtonComponent>
                    </ButtonContainer>
                </AccordianFooter>
            )}
            {freemium && (
                <AccordianFooter>
                    <ButtonContainer>
                        <ButtonComponent data-testid="cancel" variant="outlined" onClick={handleStartOverForFreemium}>
                            {t('BUTTON_START_OVER')}
                        </ButtonComponent>
                    </ButtonContainer>
                    <ButtonContainer>
                        <ButtonComponent
                            data-testid="next-step"
                            variant={!disableCheckbox ? 'outlined' : 'contained'}
                            custom={+!disableCheckbox}
                            disabled={!disableCheckbox}
                            onClick={SaveRiskTOleranceGoal}
                        >
                            {t('BUTTON_NEXT_STEP')}
                        </ButtonComponent>
                    </ButtonContainer>
                </AccordianFooter>
            )}
        </>
    );
};

export default RiskToleranceData;
