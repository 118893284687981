import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';

import HeaderComponent from '../../components/organisms/header';
import FooterComponent from '../../components/organisms/footer';
import ForgotPasswordForm from './ForgotPasswordForm';
import { ItemContainer } from '../login/LoginForm';
import { forgotPassword } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';

const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    minHeight: 'calc(100vh - 162px)'
}));

const Container: any = styled(GridContainer)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    // background: `${theme.palette.layout.primary} 0.5`,
    // background: 'linear-gradient(90deg, #092e39 50%)',
    // filter: 'brightness(0.4)',
    // backgroundColor: `${hex2rgba(theme.palette.layout.primary, 0.5)}`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
}));

const FormContainer: any = styled(Grid)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[900]}`,
    padding: 30,
    borderRadius: 4,
    minWidth: '350px',
    maxWidth: '450px',
    wrap: '',
    width: 'unset',
    '@media (max-width: 400px)': {
        minWidth: '275px'
    }
}));

const HyperLink = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.layout.primary
}));

const ForgotPasswordContainer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { oktaAuth } = useOktaAuth();
    const [currentScreen, setCurrentScreen] = useState<'forgot-password' | 'forgot-password-confirmation'>(
        'forgot-password'
    );

    const onSubmit = async (values: { username: string }) => {
        dispatch(forgotPassword({ oktaAuth, email: values.username }));
        setCurrentScreen('forgot-password-confirmation');
    };

    return (
        <>
            <HeaderComponent showHeaderWithoutOptions />

            <GridContainer container>
                <Container
                    item
                    xs={12}
                    sx={{
                        justifyContent: 'flex-start',
                        paddingTop: '40px'
                    }}
                >
                    <FormContainer container direction="column">
                        {currentScreen === 'forgot-password' ? (
                            <ForgotPasswordForm onSubmit={onSubmit} />
                        ) : (
                            <>
                                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'center' }}>
                                    {t('TEXT_FORGOT_PASSWORD_LINK_SENT')}
                                </Typography>

                                <Typography
                                    variant="body1"
                                    sx={{
                                        marginBottom: '20px',
                                        textAlign: 'center',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {t('TEXT_LOGIN_SCREEN_DISCLAIMER_1')}{' '}
                                    <a href={`mailto:${t('APPLICATION_SUPPORT_EMAIL')}`}>
                                        {t('APPLICATION_SUPPORT_EMAIL')}
                                    </a>
                                </Typography>

                                <ItemContainer sx={{ alignItems: 'flex-end' }}>
                                    <HyperLink
                                        variant="body1"
                                        onClick={() => {
                                            navigate('/');
                                        }}
                                        data-testid="back-to-sign-in-link"
                                        sx={{ color: 'green' }}
                                    >
                                        {t('TEXT_MFA_SCREEN_BACK_TO_SIGN_IN')}
                                    </HyperLink>
                                </ItemContainer>
                            </>
                        )}
                    </FormContainer>
                </Container>
            </GridContainer>

            <FooterComponent showFooterWithoutOptions />
        </>
    );
};

export default ForgotPasswordContainer;
