import Grid from '@mui/material/Grid';
import CompanyLogo from './company-logo';
import FinancialAdvisors from './financialAdvisors';
import ColorPickerContainer from './color-picker';
import SummaryMetrics from '../../containers/metrics';
import { useAppSelector } from '../../app/hooks';

const AdminDashboardSummary = () => {
    const globalConfig = useAppSelector((state) => state.global.globalConfig);
    const formattedGlobal = globalConfig;

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <FinancialAdvisors />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <CompanyLogo formattedGlobal={formattedGlobal} />
                    <ColorPickerContainer formattedGlobal={formattedGlobal} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <SummaryMetrics />
                </Grid>
            </Grid>
        </>
    );
};

export default AdminDashboardSummary;
