import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import GenerateIncome from './generate-income';
import FutureEvent from './future-event';
import RetirementSaving from './retirement-saving';
import { GOAL_TYPE } from '../../../../constants';
import SkeletonLoading from './skeletonLoading';

const FormField = ({ data, isPdfForm }: any) => {
    const [formData, setFormData] = useState<any>(data);
    useEffect(() => {
        if (!isEmpty(data)) {
            setFormData(data);
        }
    }, [data]);
    return !isEmpty(formData) ? (
        <>
            {formData?.data?.goalType === GOAL_TYPE.GENERATE_INCOME ? (
                <GenerateIncome formData={formData} isPdfForm={isPdfForm} />
            ) : formData?.data?.goalType === GOAL_TYPE.FUTURE_EVENT ? (
                <FutureEvent formData={formData} isPdfForm={isPdfForm} />
            ) : formData?.data?.goalType === GOAL_TYPE.RETIREMENT ? (
                <RetirementSaving formData={formData} isPdfForm={isPdfForm} />
            ) : (
                <p>Invalid Goal Type</p>
            )}
        </>
    ) : (
        <SkeletonLoading />
    );
};

export default FormField;
