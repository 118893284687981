/* eslint-disable react/display-name */
import * as React from 'react';
import { styled } from '@mui/material/styles';
// import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
// import parse from 'html-react-parser';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import HeaderComponent from '../header';
import FooterComponent from '../footer';
import ButtonComponent from '../../atoms/button';
import AgreementContentModal from './AgreementContentModal';
import { handleOpenEulaModal } from '../../../features/global/globalSlice';
import { updateEula } from '../../../features/advisor-admin';

const MainSectionContainer = styled('main')(() => ({
    minHeight: '73.2vh',
    maxWidth: '1440px',
    margin: 'auto',
    '@media (max-width: 1438px)': {
        margin: '0 16px'
    }
}));

const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '36px',
    fontWeight: 200,
    lineHeight: '32px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    margin: '36px 0',
    display: 'inline-block'
}));

const AgreementCard = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `solid 1px ${theme.palette.grey[900]}`,
    margin: '30px 0',
    padding: '20px',
    cursor: 'pointer'
}));
const AgreementTitle = styled(Typography)<TypographyProps>(() => ({
    marginLeft: '15px',
    fontSize: '26px'
}));

const CtaContainer = styled('div')(() => ({
    position: 'absolute',
    right: '30px',
    bottom: '60px',
    '& button': {
        '&:first-of-type': {
            margin: '0 5px'
        }
    }
}));

const Row = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}));

const Transition = React.forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>
    ) => <Grow in style={{ transformOrigin: '0 0 0' }} timeout={1000} ref={ref} {...props} />
);

export default function EULAModal({ open, handleClose, handleCancel, t, fromClients }: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { userId, tenant, userRole } = useAppSelector((state: any) => state.auth.user);
    const { eula, userAgreement } = useAppSelector((state) => state.global.orgInfo);
    const isUpdatingEULA = useAppSelector((state: any) => state.advisorAdmin.isUpdatingEULA);

    const [openDoc, setOpenDoc] = React.useState<any>(false);
    const [docsAgreed, setDocsAgreed] = React.useState<Array<string>>([]);

    const agreeTODocsAdnContinue = () => {
        dispatch(
            updateEula({
                id: userId,
                role: userRole,
                tenant,
                eulaVersion: eula.version,
                isEulaVersion: true,
                isUserAgreementVersion: true,
                userAgreementVersion: userAgreement.version
            })
        ).then((success) => {
            if (success) {
                handleClose();
                dispatch(handleOpenEulaModal(false));
                navigate('/clients');
            }
        });
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                disableEscapeKeyDown
                disableEnforceFocus
            >
                <HeaderComponent eulaVariant />
                <MainSectionContainer>
                    <HeaderText tabIndex={0}>{t('AGREEMENTS_HEADER')}</HeaderText>
                    <Typography tabIndex={0}>{t('AGREEMENTS_TEXT_UNDER_HEADER')}</Typography>

                    {/* <AgreementCard
                        tabIndex={0}
                        onClick={() =>
                            setOpenDoc({
                                title: t('TEXT_EULA_TITLE'),
                                description: eula.text
                            })
                        }
                    >
                        <Row>
                            {docsAgreed.includes(t('TEXT_EULA_TITLE')) ? (
                                <CheckBoxIcon color="primary" />
                            ) : (
                                <CheckBoxOutlineBlankIcon />
                            )}
                            <AgreementTitle>{t('TEXT_EULA_TITLE')}</AgreementTitle>
                        </Row>

                        <ArrowForwardIcon />
                    </AgreementCard> */}

                    <AgreementCard
                        tabIndex={0}
                        onClick={() =>
                            setOpenDoc({
                                title: t('TEXT_USER_AGREEMENT_TITLE'),
                                description: userAgreement.text
                            })
                        }
                    >
                        <Row>
                            {!fromClients ? (
                                docsAgreed.includes(t('TEXT_USER_AGREEMENT_TITLE')) ? (
                                    <CheckBoxIcon color="primary" />
                                ) : (
                                    <CheckBoxOutlineBlankIcon />
                                )
                            ) : null}
                            <AgreementTitle>{t('TEXT_USER_AGREEMENT_TITLE')}</AgreementTitle>
                        </Row>
                        <ArrowForwardIcon />
                    </AgreementCard>

                    <CtaContainer>
                        <ButtonComponent variant="outlined" onClick={() => handleCancel()}>
                            {t('TEXT_EULA_POPUP_CANCEL_BUTTON')}
                        </ButtonComponent>

                        {!fromClients && (
                            <ButtonComponent
                                variant="contained"
                                disabled={
                                    !(
                                        docsAgreed.includes(t('TEXT_USER_AGREEMENT_TITLE'))
                                        // && docsAgreed.includes(t('TEXT_EULA_TITLE'))
                                    )
                                }
                                onClick={agreeTODocsAdnContinue}
                                style={{
                                    minWidth: '150px'
                                }}
                            >
                                {isUpdatingEULA ? <CircularProgress size={28} /> : t('TEXT_EULA_POPUP_CONTINUE_BUTTON')}
                            </ButtonComponent>
                        )}
                    </CtaContainer>
                </MainSectionContainer>

                <AgreementContentModal
                    open={openDoc}
                    handleClose={() => setOpenDoc(false)}
                    fromClients={fromClients}
                    t={t}
                    agreeDocument={(title: string) => {
                        setDocsAgreed((prevState: any) => {
                            const newArr = [...prevState, title];

                            return [...new Set(newArr)];
                        });
                        setOpenDoc(false);
                    }}
                ></AgreementContentModal>

                <FooterComponent eulaVariant />
            </Dialog>
        </div>
    );
}
