import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import GenerateIncomeForm from './forms/generate-income-form';
import FutureEventForm from './forms/future-event-form';
import RetirementSavingForm from './forms/retirement-saving-form';
import { ADD_CLIENT_RETIREMENT_GOAL, ADD_CLIENT_INCOME_GOAL } from '../../../constants';

interface DataList {
    data: any;
    handleCancel?: () => void;
    // handleBack: () => void;
    handleAddAnotherGoal?: () => void;
    // handleGetYourPlans: () => void;
    // goalType: any;
    freemium: boolean;
    handleStartOverForFreemium: () => void;
    allowGoingToNextScreen?: () => void;
}

const FormComponent = ({
    data,
    handleAddAnotherGoal,
    handleCancel,
    handleStartOverForFreemium,
    freemium,
    allowGoingToNextScreen
}: // handleBack,
// goalType,

// handleAddAnotherGoal,
// handleGetYourPlans
DataList) => {
    const { t } = useTranslation();
    const [formValues, setFormValues] = useState<any>({});

    useEffect(() => {
        if (!isEmpty(data)) {
            setFormValues(cloneDeep(data));
        }
    }, [data]);

    const marks = [
        {
            value: 1,
            label: t('TEXT_DREAM')
        },
        {
            value: 2,
            label: t('TEXT_WISH')
        },
        {
            value: 3,
            label: t('TEXT_WANT')
        },
        {
            value: 4,
            label: t('TEXT_NEED')
        }
    ];

    return !isEmpty(formValues) ? (
        <div data-testid="investor-goal">
            {formValues && formValues.goalType === ADD_CLIENT_INCOME_GOAL ? (
                <GenerateIncomeForm
                    data={formValues}
                    marks={marks}
                    handleCancel={handleCancel}
                    // handleBack={handleBack}
                    handleAddAnotherGoal={handleAddAnotherGoal}
                    freemium={freemium}
                    handleStartOverForFreemium={handleStartOverForFreemium}
                    allowGoingToNextScreen={allowGoingToNextScreen}
                />
            ) : formValues.goalType === ADD_CLIENT_RETIREMENT_GOAL ? (
                <RetirementSavingForm
                    data={formValues}
                    marks={marks}
                    handleCancel={handleCancel}
                    // handleBack={handleBack}
                    handleAddAnotherGoal={handleAddAnotherGoal}
                    freemium={freemium}
                    handleStartOverForFreemium={handleStartOverForFreemium}
                    allowGoingToNextScreen={allowGoingToNextScreen}
                />
            ) : (
                <FutureEventForm
                    data={formValues}
                    marks={marks}
                    handleCancel={handleCancel}
                    // handleBack={handleBack}
                    handleAddAnotherGoal={handleAddAnotherGoal}
                    freemium={freemium}
                    handleStartOverForFreemium={handleStartOverForFreemium}
                    allowGoingToNextScreen={allowGoingToNextScreen}
                />
            )}
        </div>
    ) : (
        <>no data found</>
    );
};

export default FormComponent;
