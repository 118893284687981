import { Key, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import { updateFormField } from '../../../../features/add-client/add-client';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import TabsComponent from '../../../../components/molecules/scrollable-tabs';
import { updateActiveTabIndex } from '../../../../features/global/globalSlice';

const TabsContainer = () => {
    const dispatch = useAppDispatch();
    const activeTabIndex = useAppSelector((state) => state?.global?.activeTabIndex);
    const clientdata: any[] = useAppSelector((state) => state?.addClient?.invertorGoalData);
    const [formFields, setFormFields] = useState<any>([]);
    const [tabIndex, settabIndex] = useState<number>(0);
    const toggleTab = (value: Key | null | undefined | number | any, index: any) => {
        dispatch(updateActiveTabIndex(index));
        // dispatch(updateResponse(requests.find((v: any) => v.id === value.id)));
        // dispatch(updateResponse(responses.find((v: any) => v.id === value.id)));
    };
    useEffect(() => {
        settabIndex(activeTabIndex);
    });

    useEffect(() => {
        if (!isEmpty(clientdata)) {
            setFormFields(clientdata);
            dispatch(updateFormField(clientdata[activeTabIndex]));
        }
    }, [clientdata, activeTabIndex]);

    return !isEmpty(formFields) ? (
        <>
            {clientdata.length > 1 && (
                <TabsComponent tabsData={formFields} goalTab toggleState={tabIndex} toggleTab={toggleTab} />
            )}
        </>
    ) : (
        <Skeleton sx={{ margin: '20px 0px' }} variant="rectangular" width="100%" height={60} />
    );
};

export default TabsContainer;
