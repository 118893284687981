/* eslint-disable operator-linebreak */
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/xlsx.mjs';
import { startCase } from 'lodash';

/* generate a download */
function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
    }

    return buf;
}

const camelToStart = (str: string) => {
    return startCase(str);
};

export const exportService = (info: any, fileName: string, notTransferCamelCase?: boolean) => {
    const ws: any = XLSX.utils.json_to_sheet(info);
    var range = XLSX.utils.decode_range(ws['!ref']);

    for (var C = range.s.r; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C

        if (!ws[address]) {
            continue;
        }

        ws[address].v = notTransferCamelCase ? ws[address].v : camelToStart(ws[address].v); //ws[address].v.toUpperCase()
    }

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
};
