/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { styled } from '@mui/material/styles';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import highchartsAnnotations from 'highcharts/modules/annotations';
// import DataLabel from '../data-label';
import Label from './label';
import { UnknownProperties } from '../../../../constants/types';
import { fontFamily } from '../../../../styles/themes/constants';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
    .highcharts-annotation-label span {
        pointer-events: none;
    }
`;
highchartsMore(Highcharts);
highchartsAnnotations(Highcharts);
interface ExtendedXAxisTitleOptions extends Highcharts.XAxisTitleOptions {
    enabled: boolean;
}
interface ExtendedChart extends Highcharts.Chart {
    crosshair: Highcharts.SVGElement;
}
interface ExtendedSeriesLineOptions extends Highcharts.SeriesLineOptions {
    enabledCrosshairs: boolean;
}

const GlidePathMolecule = ({
    xAxisCategories,
    equityPosition,
    fixedIncomePosition,
    dispatch,
    updateProfolioCompositionData,
    fixedIncomeData,
    equityData,
    seriesData,
    theme,
    translation,
    chartHeight = 500,
    pieChartFontSize,
    showAmount = true
}: UnknownProperties) => {
    const { t } = useTranslation();
    const [_, setChart] = useState(null);

    const chartRef = useRef(null);

    const callback = useCallback((HighchartsChart: React.SetStateAction<null>) => {
        setChart(HighchartsChart);
    }, []);

    const options: Highcharts.Options = {
        chart: {
            marginTop: showAmount ? 12 : 0,
            // marginTop: 0,
            height: `${chartHeight}px`,
            spacingLeft: 10,
            spacingRight: 10,
            style: {
                fontFamily
            }
        },
        title: showAmount
            ? {
                  text: t('TEXT_GLIDE_PATH_TEXT_TOP'),
                  align: 'left',
                  margin: 30,
                  style: {
                      fontWeight: 'bold',
                      fontSize: '15px',
                      color: '#000000'
                  }
              }
            : {
                  text: ''
              },
        subtitle: {
            text: ''
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: xAxisCategories,
            lineColor: theme.palette.common.black,
            lineWidth: 2,
            labels: {
                // rotation: 0,
            },
            title: {
                enabled: false
            } as ExtendedXAxisTitleOptions
        },
        yAxis: {
            title: {
                text: null
            },
            labels: {
                enabled: true
            },
            endOnTick: false
        },

        annotations: [
            {
                draggable: '',
                labelOptions: {
                    backgroundColor: 'none',
                    shape: 'none',
                    borderWidth: 0
                },
                labels: [
                    {
                        point: {
                            x: 0,
                            y: equityPosition,
                            xAxis: 0,
                            yAxis: 0
                        } as any,

                        useHTML: true
                        // formatter() {
                        //   return renderToStaticMarkup(
                        //     <DataLabel
                        //       header={translation.equity_header}
                        //       subHeader={translation.equity_sub_header}
                        //       color={theme.palette.charts.glidePath.label.equity}
                        //     />
                        //   );
                        // }
                    },
                    {
                        point: {
                            x: fixedIncomePosition,
                            y: 0,
                            xAxis: 0,
                            yAxis: 0
                        } as any,
                        useHTML: true
                        // formatter() {
                        //   // return renderToStaticMarkup(
                        //   //   <DataLabel
                        //   //     header={translation.fixed_income_header}
                        //   //     subHeader={translation.fixed_income_sub_header}
                        //   //     alignment
                        //   //     color={theme.palette.charts.glidePath.label.fixedIncome}
                        //   //   />
                        //   // );
                        //   const header = translation.fixed_income_header;
                        //   const subHeader = translation.fixed_income_sub_header;
                        //   const color = theme.palette.charts.glidePath.label.fixedIncome;

                        //   return `
                        //     <div style="margin:20px 30px;pointer-events: none;">
                        //       <h1 style=${`color:${color};text-align:end;margin:0 0 2px 0`}>
                        //         ${header}
                        //       </h1>
                        //       <p style="font-size:14px;;margin:0 0 2px 0">${subHeader}</p>
                        //     </div>`;
                        // }
                    }
                ]
            }
        ],
        plotOptions: {
            series: {
                animation: false,
                marker: {
                    symbol: 'circle',
                    radius: 2.5,
                    fillColor: 'white',
                    lineColor: theme.palette.charts.glidePath.line,
                    lineWidth: 2,
                    zIndex: 10000
                } as any,
                states: {
                    hover: {
                        enabled: false,
                        halo: {
                            size: 0
                        }
                    }
                },
                point: {
                    events: {
                        mouseOver(event: any) {
                            const { chart } = this.series;
                            const r = chart.renderer;
                            const left = chart.plotLeft;
                            const top = chart.plotTop;
                            const height = chart.plotHeight;
                            const x = this.plotX;
                            const y = this.plotY;

                            const percentOfChartWidth = (chart.chartWidth * 65) / 100;

                            const startPercentageOfWidth = (chart.chartWidth * 20) / 100;

                            if ((this.series.options as ExtendedSeriesLineOptions).enabledCrosshairs && x && y) {
                                (chart as ExtendedChart).crosshair = r
                                    .path(['M', left + x, top + height, 'L', left + x, y + top] as any)
                                    .attr({
                                        'stroke-width': 1,
                                        stroke: 'black',
                                        zIndex: 5
                                    })
                                    .add();
                            }

                            if (event) {
                                if (event.target && event.target.portfolio) {
                                    dispatch(
                                        updateProfolioCompositionData({
                                            data: JSON.parse(event.target.portfolio),
                                            year: event.target.category,
                                            equity: event.target.y,
                                            fixedIncome: 100 - event.target.y
                                        })
                                    );
                                }

                                if (chartRef !== null && (chartRef as any).current.chart.get('pie-chart')) {
                                    (chartRef as any).current.chart.get('pie-chart').remove();
                                }

                                const equityValue: number = (event.target as any).y;
                                chart.addSeries({
                                    type: 'pie',
                                    id: 'pie-chart',
                                    center: [(event?.target as any)?.plotX - 20, (event?.target as any)?.plotY - 20],
                                    innerSize: '70%',
                                    size: '5%',
                                    zIndex: 100000,
                                    dataLabels: {
                                        enabled: true,
                                        connectorWidth: 0,
                                        connectorPadding: 2,
                                        distance: 0,
                                        useHTML: true,
                                        crop: false,
                                        formatter() {
                                            return renderToStaticMarkup(
                                                <Label
                                                    equity={this.key}
                                                    fixedIncome={this.y}
                                                    color={this.color}
                                                    pieChartFontSize={pieChartFontSize}
                                                />
                                            );
                                        }
                                    },
                                    data: [
                                        {
                                            name: translation.fixed_income_header,
                                            y: 100 - (event?.target as any)?.y,
                                            color: theme.palette.charts.glidePath.pie.fixedIncome,
                                            dataLabels:
                                                (event.target as any).clientX > percentOfChartWidth
                                                    ? {
                                                          enabled: true,
                                                          align: 'right',
                                                          verticalAlign: 'bottom',
                                                          y:
                                                              equityValue > 75 && equityValue <= 100
                                                                  ? -15
                                                                  : equityValue >= 50 && equityValue <= 75
                                                                  ? -30
                                                                  : equityValue >= 25 && equityValue < 50
                                                                  ? 25
                                                                  : 5,
                                                          x:
                                                              equityValue > 75 && equityValue <= 100
                                                                  ? -75
                                                                  : equityValue >= 50 && equityValue <= 75
                                                                  ? -75
                                                                  : equityValue >= 25 && equityValue < 50
                                                                  ? -68
                                                                  : -68
                                                      }
                                                    : {
                                                          enabled: true,
                                                          x: 5
                                                      }
                                        },
                                        {
                                            name: translation.equity_header,
                                            y: (event?.target as any)?.y,
                                            color: theme.palette.charts.glidePath.pie.equity,
                                            dataLabels:
                                                (event.target as any).clientX < startPercentageOfWidth
                                                    ? {
                                                          enabled: true,
                                                          align: 'right',
                                                          verticalAlign: 'bottom',
                                                          y:
                                                              equityValue > 75 && equityValue <= 100
                                                                  ? 5
                                                                  : equityValue > 50 && equityValue <= 75
                                                                  ? 10
                                                                  : equityValue > 25 && equityValue <= 50
                                                                  ? 30
                                                                  : 35,
                                                          x:
                                                              equityValue > 75 && equityValue <= 100
                                                                  ? 30
                                                                  : equityValue > 50 && equityValue <= 75
                                                                  ? 35
                                                                  : equityValue > 25 && equityValue <= 50
                                                                  ? 35
                                                                  : 30
                                                      }
                                                    : {
                                                          enabled: true,
                                                          x: equityValue > 0 && equityValue <= 25 ? -5 : 0
                                                      }
                                        }
                                    ]
                                });
                            }
                        },
                        mouseOut() {
                            const { chart } = this.series;
                            if ((chart as ExtendedChart).crosshair) (chart as ExtendedChart).crosshair.destroy();
                        }
                    }
                },
                events: {
                    mouseOut() {
                        if (chartRef !== null && (chartRef as any).current.chart.get('pie-chart')) {
                            (chartRef as any).current.chart.get('pie-chart').remove();
                        }
                    }
                }
            }
        },

        tooltip: {
            enabled: false
        },

        series: [
            {
                type: 'arearange',
                name: translation.fixed_income_header,
                enableMouseTracking: false,
                color: theme.palette.charts.glidePath.fixedIncome,
                marker: {
                    enabled: false
                },
                data: fixedIncomeData
            },
            {
                type: 'arearange',
                name: translation.equity_header,
                marker: {
                    enabled: false
                },
                color: theme.palette.charts.glidePath.equity,
                enableMouseTracking: false,
                data: equityData
            },
            {
                stickyTracking: false,
                color: theme.palette.charts.glidePath.line,
                type: 'line',
                enabledCrosshairs: true,
                data: seriesData,
                lineWidth: 2
            } as unknown as ExtendedSeriesLineOptions
        ]
    };

    return (
        <Container>
            {options && xAxisCategories && fixedIncomeData && seriesData && equityData && (
                <div data-testid="glide-path-chart">
                    <HighchartsReact highcharts={Highcharts} options={options} callback={callback} ref={chartRef} />
                </div>
            )}
        </Container>
    );
};

export default GlidePathMolecule;
