import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { isEmpty } from 'lodash';
import { UnknownProperties } from '../../../../constants/types';
import { RecommendationMolecule } from '../../../molecules/charts/recommendation';
import Loader from '../../../molecules/charts/loader';

const RecommendationOrganism = ({
    currency,
    theme,
    translation,
    goalProb,
    targetAmount,
    isIncomeFlow,
    isRetirementFlow,
    suggestionList,
    isModerateProb,
    isBadProb,
    remainProb,
    showLoader,
    response,
    request,
    formField,
    showSkeletonLoader,
    goalType
}: UnknownProperties) =>
    !isEmpty(response) && !isEmpty(formField) && !showLoader ? (
        <>
            <RecommendationMolecule
                formField={formField}
                currency={currency}
                theme={theme}
                translation={translation}
                goalProb={goalProb}
                remainProb={remainProb}
                targetAmount={targetAmount}
                isIncomeFlow={isIncomeFlow}
                isRetirementFlow={isRetirementFlow}
                suggestionList={suggestionList}
                isModerateProb={isModerateProb}
                isBadProb={isBadProb}
                goalType={goalType}
                response={response}
                request={request}
            />
        </>
    ) : showSkeletonLoader ? (
        <Loader Icon={TextSnippetOutlinedIcon} />
    ) : null;

export default RecommendationOrganism;
