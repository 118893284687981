import { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { getInitials, getAdvisorName } from '../../../utils';
import ButtonComponent from '../../atoms/button';
import { updateShowAdvisorPortalButtonOnHeader } from '../../../features/global/globalSlice';
import ModalComponent, { ModalContent } from '../../molecules/modal';
import { sendForgotPasswordLink } from '../../../features/auth/authSlice';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: '320px',
        maxWidth: '440px',
        overflow: 'auto',
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

const StyledBrandName = styled(Typography)<TypographyProps>(() => ({}));

const StyledLogoutText = styled(Typography)<TypographyProps>(() => ({
    cursor: 'pointer'
}));

const MenuContainer = styled('div')(({ theme }) => ({
    padding: '30px 30px 10px 30px',
    color: theme.palette.common.black
}));

const MenuCtaSection = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px'
}));

const MenuDetailsSection = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0'
}));

const MenuItemLogo = styled('div')(() => ({
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: '600',
    marginRight: '20px'
}));

const MenuItemUserDetails = styled('div')(() => ({}));

const MenuItemUserName = styled(Typography)<TypographyProps>(() => ({
    marginBottom: '10px'
}));

const MenuItemUserEmailAndLastLogin = styled(Typography)<TypographyProps>(() => ({
    marginBottom: '10px'
}));

interface MenuComponentProps {
    open: boolean;
    anchorEl: null | HTMLElement;
    // eslint-disable-next-line no-unused-vars
    handleClose: (e?: any, r?: any) => void;
    handleLogout: () => void;
    lastLoggedInDateTime: string;
    loggedInUsername: string;
    userEmail: string;
}

function MenuComponent({
    open,
    anchorEl,
    handleClose,
    lastLoggedInDateTime,
    loggedInUsername,
    userEmail,
    handleLogout
}: MenuComponentProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { showAdvisorPortalButtonOnHeader } = useAppSelector((state) => state.global);
    const brandName = useAppSelector((state) => state.global.globalConfig?.disclaimers?.brandName);

    const { user } = useAppSelector((state) => state.auth);

    const [openResetPassword, setOpenResetPassword] = useState(false);

    useEffect(() => {
        if (['/add-user', '/admin', '/user', '/schedule-upcoming-release'].includes(location.pathname)) {
            dispatch(updateShowAdvisorPortalButtonOnHeader(true));
        } else {
            dispatch(updateShowAdvisorPortalButtonOnHeader(false));
        }
    }, [location.pathname]);

    const handleAdminPortalNavigate = () => {
        navigate('/admin');
        handleClose();
    };

    const handleAdvisorPortalNavigate = () => {
        navigate('/clients');
        handleClose();
    };

    const handleResetPasswordNavigation = () => {
        dispatch(sendForgotPasswordLink({ email: user?.userEmail }));
        setOpenResetPassword(true);
    };

    return (
        <StyledMenu
            id="user-details-menu-popover"
            MenuListProps={{
                'aria-labelledby': 'user-details-menu-popover'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={(e: any, r: any) => handleClose(e, r)}
        >
            <MenuContainer>
                <MenuCtaSection>
                    <StyledBrandName
                        variant="body1"
                        sx={{ fontWeight: 600 }}
                        tabIndex={0}
                        data-testid="menu-item-brand-name"
                    >
                        {brandName || t('TEXT_MENU_BRAND_NAME')}
                    </StyledBrandName>
                    <StyledLogoutText
                        role="button"
                        id="logout"
                        tabIndex={0}
                        variant="body1"
                        sx={{ fontWeight: 600 }}
                        onClick={() => handleLogout()}
                        data-testid="menu-item-logout-button"
                    >
                        {t('TEXT_MENU_LOGOUT')}
                    </StyledLogoutText>
                </MenuCtaSection>
                <MenuDetailsSection>
                    {loggedInUsername && (
                        <MenuItemLogo data-testid="menu-item-user-name-logo">
                            {getInitials(getAdvisorName(loggedInUsername))}
                        </MenuItemLogo>
                    )}
                    <MenuItemUserDetails>
                        {loggedInUsername && (
                            <MenuItemUserName variant="subtitle1" data-testid="menu-item-user-name">
                                {getAdvisorName(loggedInUsername)}
                            </MenuItemUserName>
                        )}
                        {userEmail && (
                            <MenuItemUserEmailAndLastLogin variant="body1" data-testid="menu-item-user-email">
                                {userEmail}
                            </MenuItemUserEmailAndLastLogin>
                        )}
                        {lastLoggedInDateTime && (
                            <MenuItemUserEmailAndLastLogin variant="body1" data-testid="menu-item-last-logged-in">
                                {t('TEXT_MENU_LAST_LOGGED_IN')}{' '}
                                {moment.utc(lastLoggedInDateTime).local().format('MM/DD/YYYY hh:mm:ss')}
                            </MenuItemUserEmailAndLastLogin>
                        )}
                    </MenuItemUserDetails>
                </MenuDetailsSection>
                <MenuDetailsSection sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {user?.userRole === 'admin' ? (
                        !showAdvisorPortalButtonOnHeader ? (
                            <ButtonComponent
                                variant="outlined"
                                onClick={() => handleAdminPortalNavigate()}
                                sx={{
                                    color: 'white',
                                    border: '1px solid white',
                                    backgroundColor: 'layout.primary',
                                    marginRight: '10px',
                                    '@media (max-width: 481px)': {
                                        display: 'none'
                                    }
                                }}
                            >
                                {t('TEXT_ADMIN_BUTTON')}
                            </ButtonComponent>
                        ) : (
                            <ButtonComponent
                                variant="outlined"
                                onClick={() => handleAdvisorPortalNavigate()}
                                sx={{
                                    color: 'white',
                                    border: '1px solid white',
                                    backgroundColor: 'layout.primary',
                                    marginRight: '10px',
                                    '@media (max-width: 481px)': {
                                        display: 'none'
                                    }
                                }}
                            >
                                {t('TEXT_ADVISOR_PORTAL_BUTTON')}
                            </ButtonComponent>
                        )
                    ) : null}

                    <ButtonComponent
                        variant="outlined"
                        onClick={() => handleResetPasswordNavigation()}
                        sx={{
                            color: 'layout.primary',
                            border: '1px solid layout.primary',
                            backgroundColor: 'white',
                            marginRight: '10px',
                            '@media (max-width: 481px)': {
                                display: 'none'
                            }
                        }}
                    >
                        {t('TEXT_RESET_PASSWORD_BUTTON')}
                    </ButtonComponent>
                </MenuDetailsSection>
            </MenuContainer>

            <ModalComponent
                handleClose={() => {
                    setOpenResetPassword(false);
                }}
                open={openResetPassword}
            >
                <ModalContent id="modal-modal-title" variant="body1">
                    {t('TEXT_RESET_PASSWORD_LINK_SENT')}
                </ModalContent>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <ButtonComponent
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            setOpenResetPassword(false);
                            handleClose();
                        }}
                        sx={{ alignSelf: 'flex-end' }}
                    >
                        {t('TEXT_RESET_PASSWORD_MODAL_BUTTON')}
                    </ButtonComponent>
                </div>
            </ModalComponent>
        </StyledMenu>
    );
}

export default MenuComponent;
