/* eslint-disable operator-linebreak */
import { styled } from '@mui/material/styles';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { isEmpty } from 'lodash';
import InputAdornment from '@mui/material/InputAdornment';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface LabelProps {
    error?: boolean;
    value?: any;
}
export const BootstrapFormControl = styled(FormControl)<LabelProps>(({ theme, error, value }) => ({
    width: '100%',
    display: 'flex',
    '.MuiInputBase-root': {
        backgroundColor: error
            ? theme.palette.goal.formFields.errorBackground
            : value && !error
            ? theme.palette.goal.formFields.background
            : theme.palette.common.white
    }
}));

export const BootstrapInputLabel = styled(Typography)<LabelProps>(({ theme, error }) => ({
    display: 'flex',
    position: 'relative',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    color: error ? theme.palette.goal.formFields.errorLabel : theme.palette.common.black,
    flexGrow: 1,
    width: '100%',
    // '& :first-child': {
    //   flexGrow: 1
    // },
    '.MuiInputBase-root': {
        width: '100%'
    }
}));

const BootstrapInput = styled(InputBase)(({ theme, error, value, type, disabled, readOnly }) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    minHeight: '40px',
    padding: '4px',
    '& .MuiInputBase-input': {
        position: 'relative',
        // backgroundColor: error
        //   ? theme.palette.goal.formFields.errorBackground
        //   : theme.palette.common.white,
        fontSize: '1rem',
        width: '100%',
        border: 'none',
        padding: '8px 0px',
        '&::placeholder': {
            color: theme.palette.common.black,
            fontSize: '0.9rem',
            opacity: 1
        }
    },
    border: error
        ? `1px solid ${theme.palette.error.main}`
        : readOnly
        ? `1px solid ${theme.palette.grey[900]}!important`
        : value
        ? `1px solid ${theme.palette.primary.main} !important`
        : `1px solid ${theme.palette.common.black} !important`,
    backgroundColor: readOnly ? `${theme.palette.grey[200]}!important` : '',
    borderRadius: 3,
    '& .MuiInputBase-input:valid': {
        backgroundColor: value && !error ? theme.palette.goal.formFields.background : '',
        borderColor: value && !error ? theme.palette.primary.main : ''
    },
    '.MuiSvgIcon-root': {
        visibility: value ? '' : 'hidden',
        fontSize: '18px',
        color: theme.palette.primary.main,
        backgroundColor: readOnly ? '' : theme.palette.goal.formFields.background
    },
    'input:-webkit-autofill': {
        WebkitBoxShadow: error
            ? `0 0 0 30px ${theme.palette.goal.formFields.errorBackground} inset !important`
            : value
            ? `0 0 0 30px ${theme.palette.goal.formFields.background} inset !important`
            : '0 0 0 30px white inset !important'
    },
    'input:not(:valid)': {
        backgroundColor: type === 'email' && value && !error ? theme.palette.goal.formFields.background : ''
    },
    '.Mui-disabled': {
        WebkitTextFillColor: disabled ? theme.palette.common.black : ''
    }
}));
const CurrencySpan = styled('span')(({ theme }) => ({
    color: theme.palette.common.black
}));
interface InputProps extends InputBaseProps {
    label?: any;
    type?: string;
    error?: boolean;
    currencysymbol?: string | number | undefined;
    showcheckmark?: string;
    onBlur?: any;
    readOnly?: boolean;
    CustomEndIcon?: React.ReactNode;
    labelSx?: any;
}

function Input({ CustomEndIcon, ...props }: InputProps) {
    const { t } = useTranslation();

    const {
        label,
        placeholder,
        error,
        value,
        name,
        type,
        onBlur,
        disabled,
        readOnly,
        showcheckmark = 'true',
        labelSx
    } = props;

    return (
        <BootstrapFormControl variant="standard" error={error} value={value}>
            <BootstrapInputLabel variant="subtitle2" error={error} sx={{ fontSize: '0.75rem', ...labelSx }}>
                {label}

                {label && error && value === '' ? ` - *${t('TEXT_REQUIRED')}` : ''}
            </BootstrapInputLabel>

            <BootstrapInput
                error={error}
                onBlur={onBlur}
                type={type}
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                placeholder={placeholder}
                startAdornment={
                    <InputAdornment position="start" sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CurrencySpan>{props.currencysymbol}</CurrencySpan>
                    </InputAdornment>
                }
                endAdornment={
                    !isEmpty(showcheckmark) ? (
                        <InputAdornment position="end">{error ? '' : <CheckIcon />}</InputAdornment>
                    ) : (
                        <InputAdornment position="end">{CustomEndIcon}</InputAdornment>
                    )
                }
                {...props}
            />
        </BootstrapFormControl>
    );
}

export default Input;
