import React from 'react';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useAppDispatch } from '../../app/hooks';
import SelectComponent from '../../components/atoms/select';
import MaskedInputDate from '../../components/atoms/maskedInput';
import { TIME_PICKER_OPTIONS } from '../../constants';
import Input from '../../components/atoms/input';
import ButtonComponent from '../../components/atoms/button';
import { updateReleaseBanner } from '../../features/global/globalSlice';
import { useNavigate } from 'react-router-dom';

const Form = styled('form')(() => ({}));

const GridContainer = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    marginTop: '2px'
}));

const ErrorSpan = styled(Typography)(({ theme }) => ({
    fontSize: '0.75rem',
    color: theme.palette.goal.formFields.errorLabel
}));

const ButtonContainer = styled('span')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px'
}));

interface ReleaseForm {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    bannerMessage: string;
}

export default function ScheduleUpcomingReleaseForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        control,
        formState: { errors },
        watch,
        handleSubmit
    } = useForm<ReleaseForm>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            bannerMessage: ''
        }
    });
    const startDate = watch('startDate');
    const startTime = watch('startTime');
    const endDate = watch('endDate');

    const [loading, setLoading] = React.useState(false);

    const onSchedule = (formValues: ReleaseForm) => {
        console.log(moment(formValues.startDate).format('MM/DD/YYYY'), moment(formValues.endDate).format('MM/DD/YYYY'));
        const banner = {
            bannerStartTime: `${moment(formValues.startDate).format('MM/DD/YYYY')} ${formValues.startTime}`,
            bannerEndTime: `${moment(formValues.endDate).format('MM/DD/YYYY')} ${formValues.endTime}`,
            bannerMessage: formValues.bannerMessage,
            bannerStatus: 'active'
        };

        setLoading(true);
        dispatch(updateReleaseBanner(banner)).then((res) => {
            if (res) {
                navigate('/admin', { state: { tabIndex: 3 } });
            }
            setLoading(false);
        });
    };

    const validateDate = (date: string, compareStartDate?: boolean) => {
        if (!moment(date).isValid()) {
            return t('TEXT_INVALID_DATE');
        }

        if (moment(date).isSameOrBefore(moment())) {
            return t('TEXT_DATE_CANNOT_BE_PAST');
        }

        if (compareStartDate) {
            if (startDate && date && moment(date).isBefore(moment(startDate))) {
                return t('TEXT_DATE_CANNOT_BE_BEFORE_START_DATE');
            }
        }

        return undefined;
    };

    const validateTime = (time: string) => {
        if (
            moment(startDate).isSame(moment(endDate)) &&
            moment(time, 'hh:mm:ss').isSameOrBefore(moment(startTime, 'hh:mm:ss'))
        ) {
            return t('TEXT_TIME_CANNOT_BE_BEFORE_START_TIME');
        }

        return undefined;
    };

    return (
        <Form onSubmit={handleSubmit(onSchedule)}>
            <GridContainer container spacing={2}>
                <GridContainer item xs={12} sm={6} md={3}>
                    <Controller
                        name="startDate"
                        control={control}
                        rules={{
                            required: true,
                            validate: (date) => validateDate(date)
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <MaskedInputDate
                                label={t('TEXT_START_DATE_LABEL')}
                                error={!!errors.startDate}
                                inputFormat={'MM/DD/YYYY'}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                mask={'__/__/____'}
                                minDate={moment().add('days', 1)}
                            />
                        )}
                    />
                    {errors.startDate && (
                        <ErrorSpan role="alert" variant="subtitle2">
                            {errors.startDate.message}
                        </ErrorSpan>
                    )}
                </GridContainer>

                <GridContainer item xs={12} sm={6} md={3}>
                    <Controller
                        name="startTime"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <SelectComponent
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.startTime}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                label={t('TEXT_START_TIME_LABEL')}
                                data={TIME_PICKER_OPTIONS}
                                placeholder={t('TEXT_DROP_DOWN_PLACEHOLDER')}
                                id="startTime"
                                customheight="unset"
                            />
                        )}
                    />
                    {errors.startTime && <ErrorSpan role="alert">{errors.startTime.message}</ErrorSpan>}
                </GridContainer>

                <GridContainer item xs={12} sm={6} md={3}>
                    <Controller
                        name="endDate"
                        control={control}
                        rules={{
                            required: true,
                            validate: (date) => validateDate(date, true)
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <MaskedInputDate
                                label={t('TEXT_END_DATE_LABEL')}
                                error={!!errors.endDate}
                                inputFormat={'MM/DD/YYYY'}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                mask={'__/__/____'}
                                minDate={moment().add('days', 1)}
                            />
                        )}
                    />
                    {errors.endDate && (
                        <ErrorSpan role="alert" variant="subtitle2">
                            {errors.endDate.message}
                        </ErrorSpan>
                    )}
                </GridContainer>

                <GridContainer item xs={12} sm={6} md={3}>
                    <Controller
                        name="endTime"
                        control={control}
                        rules={{
                            required: true,
                            validate: validateTime
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <SelectComponent
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                error={!!errors.endTime}
                                autoWidth={false}
                                multiple={false}
                                native={false}
                                label={t('TEXT_END_TIME_LABEL')}
                                data={TIME_PICKER_OPTIONS}
                                placeholder={t('TEXT_DROP_DOWN_PLACEHOLDER')}
                                id="endTime"
                                customheight="unset"
                            />
                        )}
                    />
                    {errors.endTime && <ErrorSpan role="alert">{errors.endTime.message}</ErrorSpan>}
                </GridContainer>
            </GridContainer>

            <GridContainer container spacing={2}>
                <GridContainer item xs={12} sm={12} md={12}>
                    <Controller
                        name="bannerMessage"
                        control={control}
                        rules={{
                            required: true,
                            maxLength: 200
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={(e) => {
                                    if (e.target.value?.length <= 200) onChange(e);
                                }}
                                value={value}
                                inputRef={ref}
                                error={!!errors.bannerMessage}
                                label={t('TEXT_BANNER_MESSAGE_LABEL')}
                                type="text"
                            />
                        )}
                    />
                    {errors.bannerMessage && <ErrorSpan role="alert">{errors.bannerMessage.message}</ErrorSpan>}
                </GridContainer>
            </GridContainer>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <Typography variant="subtitle2">{watch('bannerMessage')?.length || 0}/200</Typography>
            </div>

            <ButtonContainer>
                <ButtonComponent variant="outlined" onClick={() => navigate('/admin')}>
                    {t('BUTTON_CANCEL')}
                </ButtonComponent>
                {!loading && (
                    <ButtonComponent variant="contained" type="submit">
                        {t('BUTTON_SCHEDULE')}
                    </ButtonComponent>
                )}
                {loading && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </ButtonContainer>
        </Form>
    );
}
