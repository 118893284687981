/* eslint-disable react/display-name */
/* eslint-disable operator-linebreak */
import { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

interface CircleProps {
    active?: boolean | number;
}

interface DataList {
    value: number;
    label: string;
    active: boolean;
    helperText: string;
    changeActive: any;
    setContainerWidth?: any;
}

const CircleAndContentContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    zIndex: 2
}));

const Circle = styled('div')<CircleProps>(({ theme, active }) => ({
    backgroundColor: active ? theme.palette.primary.main : theme.palette.common.white,
    color: active ? theme.palette.common.white : theme.palette.grey[600],
    borderRadius: '50%',
    height: '85px',
    width: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: active ? `3px solid ${theme.palette.primary.main}` : `3px solid ${theme.palette.grey[600]}`,
    transition: '1.2s ease',
    cursor: 'pointer',
    'svg.MuiSvgIcon-root': {
        fontSize: '3.5rem'
    },
    '.hide': {
        display: 'none !important',
        color: 'blue'
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        border: `3px solid ${theme.palette.primary.main}`,
        boxShadow: '0 0 0 20px rgb(55 105 255 / 33%)'
    },
    'hover + .hide': {
        background: 'blue'
    }
}));

const Content = styled(Typography)<TypographyProps>(() => ({
    marginTop: '30px'
}));
const Helper = styled(Content)(() => ({
    fontSize: '12px'
}));

const SingleStepCircle = forwardRef(
    ({ value, label, active, helperText, changeActive, setContainerWidth }: DataList, ref) => {
        const [show, setShow] = useState(false);
        const circleContainerRef = useRef<any>();
        useEffect(() => {
            setContainerWidth(circleContainerRef.current.offsetWidth);
        }, []);

        useImperativeHandle(ref, () => ({
            offsetWidth: circleContainerRef.current.offsetWidth
        }));

        return (
            <>
                <CircleAndContentContainer key={`${value}`} ref={circleContainerRef}>
                    <Circle
                        onClick={changeActive}
                        onMouseOver={() => setShow(true)}
                        onMouseOut={() => setShow(false)}
                        active={+active}
                    >
                        {active ? <CheckIcon /> : null}
                    </Circle>
                    <Content variant="h4">{label}</Content>
                    {active || show ? <Helper variant="subtitle1">{helperText}</Helper> : ''}
                </CircleAndContentContainer>
            </>
        );
    }
);

export default SingleStepCircle;
