import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import PortfolioCompositionMolecule from '../../../molecules/charts/portfolio-composition';
import { UnknownProperties } from '../../../../constants/types';
import Legends from '../../../molecules/charts/legends/glide-path';
import Loader from '../../../molecules/charts/loader';

const StyledHeaderDescText = styled(Typography)<TypographyProps>(() => ({
    fontWeight: 700
}));

const StyledFooterHeaderText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '18px',
    fontWeight: 600,
    lineHeight: '24px'
}));

const StyledFooterDescText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '16px',
    marginTop: '18px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '20px'
}));

const ContainerWithSuperScript = styled('div')(() => ({
    position: 'relative',
    display: 'inline-flex'
}));

const SuperCript = styled(Typography)<TypographyProps>(() => ({
    marginLeft: '10px',
    position: 'absolute',
    right: -15,
    top: -10
}));

const portfolioCompositionOrganism = ({
    theme,
    label,
    data,
    equity,
    showLoader,
    fixedIncome,
    showHeader,
    showFooter,
    showLegends,
    showSkeletonLoader,
    enableSourceCaveatNumbers,
    hideChartLabel = false,
    ...rest
}: UnknownProperties) => {
    const { t } = useTranslation();
    return !isEmpty(data) && !showLoader ? (
        <>
            {showHeader && (
                <ContainerWithSuperScript>
                    <StyledHeaderDescText variant="h3">
                        {t('HEADER_PORTFOLIO_COMPOSITION_SUMMARY_PAGE')}
                        {label}
                    </StyledHeaderDescText>

                    {enableSourceCaveatNumbers && <SuperCript>1</SuperCript>}
                </ContainerWithSuperScript>
            )}
            {showFooter && <StyledFooterHeaderText>{t('HEADER_PORTFOLIO_COMPOSITION')}</StyledFooterHeaderText>}
            <PortfolioCompositionMolecule label={hideChartLabel ? '' : label} data={data} {...rest} />
            {showLegends && (
                <div data-testid="portfolio-composition-chart-legend">
                    <Legends
                        equity={equity}
                        fixedIncome={fixedIncome}
                        equityColor={theme?.palette?.charts?.portfolioComposition?.equity}
                        fixedIncomeColor={theme?.palette?.charts?.portfolioComposition?.fixedIncome}
                    />
                </div>
            )}
            {showFooter && <StyledFooterDescText>{t('DESCRIPTION_GPORTFOLIO_COMPOSITION')}</StyledFooterDescText>}
        </>
    ) : showSkeletonLoader ? (
        <Loader Icon={InsertChartOutlinedTwoToneIcon} />
    ) : null;
};

export default portfolioCompositionOrganism;
