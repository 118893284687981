import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import MultilineChartOutlinedIcon from '@mui/icons-material/MultilineChartOutlined';
import { Typography, TypographyProps } from '@mui/material';
import { UnknownProperties } from '../../../../constants/types';
import WealthPathMolecule from '../../../molecules/charts/wealth-path';
import Loader from '../../../molecules/charts/loader';

const StyledHeaderDescText = styled(Typography)<TypographyProps>(() => ({
    fontWeight: 700
}));

const StyledFooterDescText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '18px',
    fontWeight: 600,
    lineHeight: '24px'
}));

const ContainerWithSuperScript = styled('div')(() => ({
    position: 'relative',
    display: 'inline-flex'
}));

const SuperCript = styled(Typography)<TypographyProps>(() => ({
    marginLeft: '10px',
    position: 'absolute',
    right: -15,
    top: -10
}));

const WealthPathOrganism = ({
    theme,
    currency,
    xAxisCategories,
    wealthPathData,
    annualDistribution,
    targetAmount,
    translation,
    isModerateProb,
    isBadProb,
    goalProb,
    showLoader,
    isIncomeFlow,
    isFutureEvent,
    isRetirementFlow,
    showHeader,
    showFooter,
    showAmount,
    chartHeight,
    // remodelLoader,
    showSkeletonLoader,
    enableSourceCaveatNumbers,
    ...rest
}: UnknownProperties) =>
    !isEmpty(wealthPathData) && !isEmpty(annualDistribution) && !showLoader ? (
        <>
            {showHeader && (
                <ContainerWithSuperScript>
                    <StyledHeaderDescText variant="h3">
                        {translation('TEXT_WEALTH_PATH_SUMMARY_PAGE_HEADER')}
                    </StyledHeaderDescText>

                    {enableSourceCaveatNumbers && <SuperCript>1</SuperCript>}
                </ContainerWithSuperScript>
            )}
            {showFooter && (
                <>
                    <StyledFooterDescText>{translation('HEADER_WEALTH_PATH')}</StyledFooterDescText>
                </>
            )}
            <WealthPathMolecule
                theme={theme}
                currency={currency}
                xAxisCategories={xAxisCategories}
                wealthPathData={wealthPathData}
                annualDistribution={annualDistribution}
                targetAmount={targetAmount}
                translation={translation}
                isModerateProb={isModerateProb}
                isBadProb={isBadProb}
                goalProb={goalProb}
                isIncomeFlow={isIncomeFlow}
                isFutureEvent={isFutureEvent}
                isRetirementFlow={isRetirementFlow}
                showAmount={showAmount}
                chartHeight={chartHeight}
                {...rest}
            />
        </>
    ) : showSkeletonLoader ? (
        <Loader Icon={MultilineChartOutlinedIcon} />
    ) : null;
export default WealthPathOrganism;
