import { format, isValid, parseISO } from 'date-fns';

const SHORT_DATE_FORMAT = 'MM/dd/yyyy';

export const isValidDate = (date: string | Date): boolean => {
    if (typeof date === 'string') {
        return isValid(parseISO(date));
    }
    return isValid(date);
};

export const toShortDate = (date: string) => {
    console.log(date);
    const newDate = new Date(date);
    return isValidDate(newDate) ? format(newDate, SHORT_DATE_FORMAT) : null;
};
