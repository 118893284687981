import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import BanorteLogo from './banorte_logo.png';

const Wrapper = styled('div')(() => ({
    marginTop: '30px'
}));

const DisclaimerHeader = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const DisclaimerContent = styled('div')(() => ({
    marginTop: '30px'
}));

const BanorteDisclaimer = () => {
    return (
        <Wrapper>
            <DisclaimerHeader>
                <Typography variant="h1" sx={{ color: 'grey', display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: '#eb1529', marginRight: '5px', fontSize: '35px' }}>|</Typography>
                    {'Declaraciones Importantes'}
                </Typography>
                <img src={BanorteLogo} alt={'Banorte Asset Management, Inc.'} style={{ marginRight: '20px' }} />
            </DisclaimerHeader>
            <DisclaimerContent>
                <p style={{ fontSize: '14px' }}>
                    Banorte Wealth Management actúa como la marca corporativa de Banorte Securities International Ltd.,
                    Banorte Asset Management, Inc., Banorte Enterprises Ltd., Banorte Ventures Ltd., y Banorte
                    Securities Holdings International Inc., todas las cuales son afiliadas y subsidiarias últimas del
                    Grupo Financiero Banorte S.A.B. de C.V.
                </p>
                <p style={{ fontSize: '14px' }}>
                    Los servicios de asesoría de inversiones son proporcionados por Banorte Asset Management,
                    Inc.(&ldquo;BAM&rdquo;), que también opera bajo el nombre de Banorte Wealth Advisors y está
                    registrado ante la Comisión de Bolsa y Valores (SEC) como asesor de inversiones. La inscripción como
                    Asesor de Inversiones no implica ningún nivel de habilidad o entrenamiento.
                </p>
                <p style={{ fontSize: '14px' }}>
                    Nuestras cuentas de asesoría de inversiones están custodiadas con Pershing LLC, miembro de{' '}
                    FINRA(https://www.finra.org/) , NYSE(https://www.nyse.com), SPIC(https://www.sipc.org), una compañía
                    de BNY. Para estas cuentas, nuestro corredor de bolsa afiliado, Banorte Securities International
                    Ltd., también miembro de FINRA(https://www.finra.org/), SPIC(https://www.sipc.org/) , actúa como
                    &ldquo;corredor introductorio&rdquo;. Este rol implica facilitar la apertura de sus cuentas a través
                    de Pershing LLC. Las tarifas de corretaje y de asesoría de inversiones difieren, y es importante que
                    usted entienda las diferencias.
                </p>
                <p style={{ fontSize: '14px' }}>
                    Herramientas gratuitas y sencillas están disponibles para que investigue firmas y profesionales
                    financieros en Investor.gov/CRS(https://www.investor.gov/CRS), que también proporciona materiales
                    educativos sobre corredores de bolsa, asesores de inversiones e inversiones.
                </p>

                <p style={{ fontSize: '18px' }}>
                    <u>Este informe </u>
                </p>
                <p style={{ fontSize: '14px' }}>
                    Durante el curso normal de la relación con un Representante de Asesores de Inversiones
                    (&ldquo;IAR&rdquo;) de BAM, se le presentarán materiales de marketing que describen el rendimiento
                    de una inversión o el rendimiento de una propuesta de portafolio de valores. El contenido de este
                    informe ha sido preparado por Banorte Asset Management, Inc. para fines informativos a solicitud del
                    Representante de Asesor de Inversiones, quien certifica que usted es un inversionista con un
                    objetivo de inversión y tolerancia al riesgo adecuada para la cartera solicitada.
                    <br />
                </p>
                <p style={{ fontSize: '14px' }}>
                    Este informe puede incluir recomendaciones de valores o carteras específicos para usted, que BAM ha
                    basado en carteras modelo (&ldquo;Carteras Modelo&rdquo;) diseñadas por Franklin Advisers, Inc., uno
                    de los asesores de inversión más grandes del mundo (&ldquo;Franklin Templeton&rdquo;). El papel de
                    Franklin Templeton se limita al diseño y provisión a BAM de las Carteras Modelo y a hacer
                    recomendaciones de inversión no discrecionales a BAM en relación con la gestión de la cuenta de un
                    cliente por parte de BAM. Franklin Templeton no hace recomendaciones a ningún cliente de BAM ni
                    participa en la gestión de su cartera. Es responsabilidad exclusiva de BAM, a través de su IAR, (i)
                    determinar si una Cartera Modelo creada por Franklin Templeton y cada valor incluido en ella es
                    inicialmente y sigue siendo apropiado y adecuado para usted; y (ii) hacer determinaciones
                    discrecionales sobre los valores que se comprarán y venderán para cada cuenta. A discreción de BAM,
                    BAM puede optar por desviarse de cualquier Cartera Modelo proporcionada por Franklin Templeton.
                </p>
                <p style={{ fontSize: '14px' }}>
                    Franklin Templeton, al crear carteras modelo no discrecionales para Banorte, considera todos los
                    factores, incluidas las comisiones, de todos los productos (afiliados y no afiliados). Las carteras
                    modelo creadas para BAM por Franklin Templeton pueden incluir fondos gestionados por Franklin
                    Templeton o sus filiales (&ldquo;FT Affiliated Funds&rdquo;). Aunque Franklin Templeton y sus
                    filiales reciben comisiones basadas en activos y otras comisiones por prestar servicios de
                    asesoramiento y otros servicios a los FT Affiliated Funds que gestionan, nuestro acuerdo con
                    Franklin Templeton excluye cualquier activo de los fondos afiliados a Franklin Templeton de las
                    comisiones basadas en activos de las carteras modelo que Banorte paga a Franklin. Este acuerdo puede
                    presentar un posible conflicto de intereses para Banorte, ya que una mayor asignación a los FT
                    Affiliated Funds en las carteras modelo excluye más AUM de las comisiones de las carteras modelo que
                    Banorte paga a Franklin Templeton. Franklin Templeton tiene el incentivo de proponer que uno o más
                    FT Affiliated Funds se incluyan en cualquier cartera modelo. Además, Franklin Templeton tendrá
                    normalmente acceso a los datos de las tenencias o a los gestores de cartera de los FT Affiliated
                    Funds con una frecuencia o un nivel de detalle mayor que el disponible de los fondos de terceros, lo
                    que también puede crear conflictos de intereses para Franklin Templeton al proponer la asignación a
                    uno o más FT Affiliated Funds en una cartera modelo. Franklin Templeton puede incluir FT Affiliated
                    Funds en una cartera modelo sin considerar o sondear el universo de posibles fondos patrocinados por
                    entidades no afiliadas a Franklin Templeton (&ldquo;Third Party Funds&rdquo;), aunque puede haber (o
                    no) Third Party Funds que sean más apropiados para su inclusión en la cartera modelo (incluidos los
                    Third Party Funds disponibles en las clases de activos aplicables que tengan comisiones y gastos más
                    bajos, mayor rendimiento u otros términos favorables en relación con un FT Affiliated Fund). Los
                    diversos conflictos de interés por parte de Franklin Templeton y otros factores descritos en este
                    párrafo pueden resultar en una Cartera Modelo que logra un rendimiento menos favorable y/o incurre
                    en mayores gastos que una Cartera Modelo que no incluyó Fondos Afiliados de FT.
                </p>
                <p style={{ fontSize: '14px' }}>
                    Tenga en cuenta que este material no es una oferta, recomendación o solicitud para comprar o vender
                    valores, ni es una confirmación oficial de los términos. Los ejemplos y recomendaciones contenidos
                    en este material pueden no ser adecuados para todos los inversionistas. Cualquier análisis o
                    proyecciones contenidos en este producto son estimaciones y se basan en suposiciones y modelos. Esta
                    información no reemplaza la obligación de su Representante de Asesor de Inversiones, de aplicar su
                    mejor criterio al tomar decisiones de inversión o hacer recomendaciones a usted.
                </p>
                <p style={{ fontSize: '14px' }}>
                    La información de rendimiento se puede presentarse de muchas formas diferentes. El rendimiento
                    pasado no predice los resultados futuros. El rendimiento hipotético es un tipo de rendimiento y se
                    refiere en general a la información de retorno en retrospectiva, o futura y no son los rendimientos
                    reales de las cuentas de los clientes. Antes de tomar cualquier decisión de inversión, incluyendo la
                    selección de un Asesor de Inversiones, deberá entender cómo se calcula y presenta cualquier
                    representación de rendimiento.
                </p>

                <p style={{ fontSize: '18px', textDecoration: 'underline' }}>
                    <u>Información de rendimiento proyectado: Motor de optimización de objetivos</u>
                </p>
                <p style={{ fontSize: '14px' }}>
                    La información de rendimiento proyectado en este informe puede generarse a partir del Motor de
                    optimización de objetivos (GOE, por sus siglas en inglés) de Franklin Templeton. GOE crea una
                    cartera para cada objetivo que tiene un inversor. Luego, GOE ajusta activamente la combinación de
                    activos a lo largo del tiempo, buscando maximizar la probabilidad de alcanzar con éxito cada
                    objetivo. Cada cartera se basa en la probabilidad, se personaliza y responde a los cambios en el
                    mercado o a cualquier cambio que el inversor pueda hacer en el objetivo.
                </p>
                <p style={{ fontSize: '14px' }}>
                    GOE y cualquier dato, cartera, asignación e ilustración (actuales o futuros) generados a partir de
                    GOE (Salida) contenidos en este informe son puramente hipotéticos, no reflejan resultados de
                    inversión reales y no son garantía de resultados futuros. GOE utiliza las entradas proporcionadas
                    por usted o su IAR de BAM, como el objetivo de ingresos, el horizonte de inversión, la tolerancia al
                    riesgo, su cantidad inicial de inversión y la contribución futura de inversión esperada para
                    desarrollar una recomendación de asignación de activos personalizada para su objetivo. Las carteras
                    implementadas son seleccionadas por su IAR. Las carteras pueden incluir o consistir completamente en
                    uno o más fondos para los que Franklin Templeton o una firma afiliada actúan como asesor o subasesor
                    de inversiones. Los inversores deben leer el prospecto de cada producto con atención y considerar
                    los objetivos, riesgos, comisiones y gastos de inversión de los productos antes de invertir.
                </p>
                <p style={{ fontSize: '14px' }}>
                    Los resultados variarán con el tiempo y con cada uso de la herramienta. Su BAM IAR debe volver a
                    ejecutar la propuesta al menos anualmente y considerar el reequilibrio de sus carteras para asegurar
                    que los cambios de asignación se implementen de manera consistente con el tiempo. Su BAM IAR puede
                    modificar una meta actualizando las entradas de la meta, ya que se espera que su IAR mantenga su
                    perfil de cuenta y preferencias al día para asegurar que el consejo de asignación sea adecuado. El
                    rendimiento proyectado está sujeto a limitaciones y supuestos diseñados para determinar la
                    probabilidad de un resultado de inversión basado en un rango de posibles resultados. Es posible que
                    una o más de esas suposiciones no resulten ser precisas y, por diversas razones, el rendimiento real
                    puede diferir materialmente de las proyecciones.
                </p>
                <p style={{ fontSize: '14px' }}>
                    Su BAM IAR asume la plena responsabilidad de determinar la idoneidad y la conveniencia de las
                    estrategias de inversión, las asignaciones de activos o las recomendaciones de productos y clases de
                    acciones en este informe, teniendo en cuenta sus necesidades individuales de inversión. Por favor,
                    hable con su IAR antes de tomar cualquier decisión de inversión y revise la información específica
                    de riesgo de cada producto en el prospecto de cada uno.
                </p>
                <p style={{ fontSize: '14px' }}>
                    Todas las inversiones implican riesgos, incluida la posible pérdida de capital.
                </p>
                <p style={{ fontSize: '14px' }}>
                    La asignación de activos, la diversificación y el reequilibrio no garantizan una ganancia ni
                    protegen contra la pérdida de mercado. Este consejo no considera la situación fiscal del cliente, su
                    endeudamiento u otras inversiones o metas de inversión específicas al formular el consejo y las
                    recomendaciones de inversión. No hay garantía de que ninguna estrategia de asignación de activos
                    tenga éxito o de que se cumpla ninguna meta. Además, no hay garantía de que ningún escenario de
                    mercado o económico, las expectativas de riesgo/retorno o los modelos sean precisos.
                </p>
                <p style={{ fontSize: '14px' }}>
                    El rendimiento de una cartera puede variar significativamente del rendimiento de un índice como
                    resultado de los costes de transacción, los gastos y otros factores.
                </p>

                <p style={{ fontSize: '18px' }}>
                    <u>Riesgos Específicos </u>
                </p>
                <p style={{ fontSize: '14px' }}>Los valores comprados o vendidos en su cuenta: </p>
                <div style={{ marginLeft: '15px' }}>
                    <p style={{ fontSize: '14px' }}>
                        i. no están asegurados por la Corporación Federal de Seguro de Depósitos (&ldquo;FDIC&rdquo;).
                    </p>
                    <p style={{ fontSize: '14px' }}>
                        ii. no son depósitos u obligaciones de la institución financiera y no están garantizados por la
                        institución financiera; y{' '}
                    </p>
                    <p style={{ fontSize: '14px' }}>
                        iii. están sujetos a riesgos de inversión, incluida la posible pérdida del principal invertido.{' '}
                    </p>
                </div>
                <p style={{ fontSize: '14px' }}>
                    Antes de invertir, considere sus objetivos y las tarifas de BAM. El rendimiento pasado no garantiza
                    resultados o rendimientos probables en el futuro. Antes de invertir en cualquier instrumento, revise
                    cuidadosamente el material (prospecto) y literatura relacionada con dichos instrumentos para obtener
                    información importante sobre los riesgos, tarifas y otros hechos materiales acerca de la empresa
                    subyacente, incluyendo cualquier otra divulgación de riesgos proporcionada por BAM en relación con
                    el tipo de producto/inversión.
                </p>
                <p style={{ fontSize: '14px' }}>
                    BAM está afiliada y es subsidiaria final de Grupo Financiero Banorte S.A.B. de C.V. Más información
                    disponible en Formulario CRS
                    (https://content.banortesecurities.com/files/BSI_Updated_CRS_Combined_Spanish.pdf) .{' '}
                </p>
                <p style={{ fontSize: '14px' }}>
                    Para más información sobre los servicios de asesoramiento de inversiones de BAM visite{' '}
                    Forma-ADV(https://content.banortesecurities.com/files/BAM_Part_2A_FormADV_English.pdf), que se
                    encuentra en{' '}
                    https://www.banortesecurities.com/descargas(https://www.banortesecurities.com/descargas)
                </p>
            </DisclaimerContent>
        </Wrapper>
    );
};

export default BanorteDisclaimer;
