import React from 'react';
import TabsContainer from './tabs';
import ChooseGoal from '../../molecules/choose-savings-goal';
import { useAppSelector } from '../../../app/hooks';

function index({ handleCancel, handleNextStep, handleStartOverForFreemium }: any) {
    const formField = useAppSelector((state: { addClient: { formField: any } }) => state.addClient.formField);

    return (
        <div>
            <TabsContainer />
            <ChooseGoal
                data={formField}
                handleCancel={handleCancel}
                handleNextStep={handleNextStep}
                freemium={false}
                handleStartOverForFreemium={handleStartOverForFreemium}
            />
        </div>
    );
}

export default index;
