import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from '../../atoms/button';

const ErrorBoundaryContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 100px 0px;

    & h1,
    h3,
    h4 {
        padding: 10px 0px;
    }
`;

function ErrorBoundary({ resetErrorBoundary }: any) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onClick = () => {
        if (typeof window !== 'undefined') {
            navigate('/', { replace: true });

            resetErrorBoundary();
        }
    };

    return (
        <div>
            <ErrorBoundaryContainer>
                <Typography variant="h1">{t('HEADER_SERVER_ERROR')}</Typography>
                <Typography variant="h3">{t('DESCRIPTION_ERROR')}</Typography>
                <ButtonComponent sx={{ margin: '16px 0px' }} variant="contained" onClick={onClick}>
                    {t('TEXT_ERROR_RETRY')}
                </ButtonComponent>
            </ErrorBoundaryContainer>
        </div>
    );
}

export default ErrorBoundary;
