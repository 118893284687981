import React from 'react';
import TabsContainer from './tabs';
import { useAppSelector } from '../../../app/hooks';
import FormComponent from '../../molecules/investment-goals-forms';

function index({ handleCancel, handleAddAnotherGoal, handleStartOverForFreemium, allowGoingToNextScreen }: any) {
    const formField = useAppSelector((state: { addClient: { formField: any } }) => state.addClient.formField);
    return (
        <div>
            <TabsContainer />
            <FormComponent
                data={formField}
                handleCancel={handleCancel}
                handleAddAnotherGoal={handleAddAnotherGoal}
                freemium={false}
                handleStartOverForFreemium={handleStartOverForFreemium}
                allowGoingToNextScreen={allowGoingToNextScreen}
            />
        </div>
    );
}

export default index;
