/* eslint-disable indent */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { uipApiInstance } from '../../axios';
import { formatTableData } from '../../utils/table';
import { updateEditLoader } from '../edit-goal';
import { updateLoader } from '../add-client/add-client';
import { updateErrorModalState } from '../global/globalSlice';
import { logout } from '../auth/authSlice';
import { updateClientSummary } from '../client-summary';
import { logEventToBackEnd } from '../global/globalSlice';

interface TableData {
    managerName?: string;
    advisorName?: string;
    clientName?: string;
    id?: string | number;
    goalName?: string;
    goalAmount?: number | string;
    goals?: number | string;
    equityFixedIncome?: number | string;
    lastModified?: number | string;
    score?: number | string;
    actualData?: number | string;
    subRows?: TableData[];
}

type InitialState = {
    actualData: Array<any>;
    tableData: Array<TableData>;
    managerGroupedData: Array<TableData>;
    advisorGroupedData: Array<TableData>;
    loading: boolean;
    error?: null | undefined | any;
    defaultColumnVisibility?: any;
    columnVisibility?: any;
    showDeleteModal?: boolean;
    deleteId?: any;
    clientSummaryModalState: boolean;
    triggerdDelete: boolean;
};
const initialState: InitialState = {
    actualData: [],
    tableData: [],
    managerGroupedData: [],
    advisorGroupedData: [],
    loading: false,
    error: null,
    defaultColumnVisibility: { managerName: false, advisorName: false },
    columnVisibility: {},
    showDeleteModal: false,
    deleteId: {},
    clientSummaryModalState: false,
    triggerdDelete: false
};

export const fetchClientSideProposal = createAsyncThunk(
    'proposals/fetchClientSideProposal',
    async (_, { dispatch, rejectWithValue, getState }) => {
        try {
            const state: any = getState();

            if (!state.auth.isAuthenticated) {
                return [];
            }
            const response = await uipApiInstance.get('/api/advisorportal/getproposal');

            if (state?.client?.clientSummary?.clientEmail) {
                const data = response.data.data;
                const requiredData = data.find((x: any) => x.clientEmail === state.client.clientSummary.clientEmail);
                dispatch(updateClientSummary(requiredData));
            }

            dispatch(updateErrorModalState({ error: {}, showErrorModal: false }));
            return response.data;
        } catch (error) {
            dispatch(updateErrorModalState({ error, showErrorModal: true }));
            console.log(error);
            dispatch(logEventToBackEnd('GET_PROPOSAL_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

export const deleteGoal = createAsyncThunk(
    'proposals/deleteGoal',
    async (goalId: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance.delete(`/api/advisorportal/deleteproposal/${goalId}`);
            dispatch(updateLoader(true));
            dispatch(updatedTriggeredDelete(true));
            dispatch(updateEditLoader(true));
            return response;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('DELETE_PROPOSAL_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

export const deleteClient = createAsyncThunk(
    'proposals/deleteClient',
    async (clientId: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance.delete(`/api/advisorportal/deleteclient/${clientId}`);
            dispatch(updatedTriggeredDelete(true));
            return response;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('GET_CLIENT_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

const proposalTableSlice = createSlice({
    name: 'proposal',
    initialState,
    reducers: {
        updateManagerGroupedData: (state, action) => {
            state.managerGroupedData = action.payload;
        },
        updateAdvisorGroupedData: (state, action) => {
            state.advisorGroupedData = action.payload;
        },
        updateTableColumnHiding: (state, action) => {
            state.columnVisibility = action.payload;
        },
        updateDeleteModalState: (state, action) => {
            state.showDeleteModal = action.payload;
        },
        updateDeleteId: (state, action) => {
            state.deleteId = action.payload;
        },
        updateClientSummaryModalState: (state, action) => {
            state.clientSummaryModalState = action.payload;
        },
        updatedTriggeredDelete: (state, action) => {
            state.triggerdDelete = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(fetchClientSideProposal.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchClientSideProposal.fulfilled, (state, action) => {
                state.loading = false;
                state.actualData = action.payload.data;
                state.tableData = formatTableData(action.payload.data, true);
                state.error = null;
                state.triggerdDelete = false;
            }),
            builder.addCase(fetchClientSideProposal.rejected, (state, action) => {
                state.loading = false;
                state.actualData = [];
                state.tableData = [];
                state.error = action.error;
            }),
            builder.addCase(deleteGoal.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(deleteGoal.fulfilled, (state) => {
                state.loading = true;
                state.actualData = [];
                state.tableData = [];
                state.triggerdDelete = false;
            }),
            builder.addCase(deleteGoal.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder.addCase(deleteClient.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(deleteClient.fulfilled, (state) => {
                state.loading = true;
                state.actualData = [];
                state.tableData = [];
                state.triggerdDelete = false;
            }),
            builder.addCase(deleteClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export const {
    updateManagerGroupedData,
    updateAdvisorGroupedData,
    updateTableColumnHiding,
    updateDeleteModalState,
    updateDeleteId,
    updateClientSummaryModalState,
    updatedTriggeredDelete
} = proposalTableSlice.actions;

export default proposalTableSlice.reducer;
