/* eslint-disable operator-linebreak */
import React, {
    JSXElementConstructor,
    Key,
    ReactElement,
    ReactFragment,
    ReactPortal,
    useEffect,
    useState
} from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { CtaContainer, ModalContent } from '../../molecules/modal/styles';
import Modal from '../../molecules/modal';
import ButtonComponent from '../../atoms/button';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateDialogState, updatePdfEdited, updateSaveExitEdited } from '../../../features/global/globalSlice';
import { updatePDFLoading } from '../../../features/client-summary';

function ScrollableTabs({
    tabsData,
    toggleState,
    toggleTab,
    goalTab,
    addClient,
    handleAddGoal,
    handleEditGoals,
    editPage,
    goalConfirm,
    saveGoalAndExit,
    clientSummarPopup,
    openPDFPreview,
    splitWealth,
    onBackClick
}: any) {
    // const Container = styled('div')`
    //   margin: 1em 0 2em;
    //   borderBottom: `2px solid  ${ theme.palette.primary.main } `
    // `;
    const Container = styled('div')(({ theme }) => ({
        margin: '1rem 0 1rem',
        borderBottom: `2px solid  ${theme.palette.primary.main} `
    }));

    const ButtonContainer = styled('div')(() => ({
        float: 'right',
        display: 'flex',
        '& .MuiButton-root': {
            padding: '10px'
        }
    }));

    const CircularWrapper = styled('div')(() => ({
        display: 'flex',
        marginRight: '20px',
        alignItems: 'center'
    }));

    const TabsContainer = styled(Tabs)(() => ({
        '&.tab-container': {
            '.MuiTabs-flexContainer': {
                // borderBottom: `2px solid  ${theme.palette.primary.main}`,
                width: '100%',
                minWidth: 'max-content'
            },
            '.MuiButtonBase-root': {
                maxWidth: 'unset'
            },
            '.MuiTabs-scroller': {
                // borderBottom: `2px solid  ${theme.palette.primary.main}`,
            },

            '.MuiTabs-indicator': {
                display: 'none'
            },

            '.MuiTabs-scrollButtons.Mui-disabled': {
                // opacity: 0.3
            }
        }
    }));
    const TabButton = styled(Tab)(({ theme }) => ({
        color: 'black !important',
        textTransform: 'none',
        padding: '14px',
        height: '100%',
        fontSize: '1.5rem',
        border: `2px solid  ${theme.palette.common.black}`,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottom: 'none',
        '& :first-of-type': {
            flexGrow: 1
        },
        margin: '0 10px 0 0',
        '&.Mui-selected': {
            color: `${theme.palette.primary.main} !important`,
            borderTop: `2px solid  ${theme.palette.primary.main}`,
            borderLeft: `2px solid  ${theme.palette.primary.main}`,
            borderRight: `2px solid  ${theme.palette.primary.main}`,
            borderBottom: 'none'
            // boxShadow: '0 4px 0 -2px white'
        }
    }));

    const TabPaginationButton = styled(Button)(({ theme }) => ({
        color: 'black !important',
        textTransform: 'none',
        borderRadius: 'unset',
        fontSize: '24px',
        border: `2px solid  ${theme.palette.common.black}`,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        // borderBottom: `2px solid  ${theme.palette.primary.main}`,
        opacity: 'unset',
        minWidth: '40px'
    }));

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [tabModalState, setTabModalState] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const [value, setValue] = React.useState(toggleState);
    const [count, setCount] = React.useState<number>(0);
    const showDialog: boolean = useAppSelector((state) => state.global?.showDialog);
    const editGoalWealthSplitterApplied: boolean = useAppSelector((state) =>
        Boolean(state.global?.editGoalWealthSplitterApplied)
    );
    const disableGoalButton = useAppSelector((state) => state?.editGoal?.goalEdited || false);
    const disablePdfButton = useAppSelector((state) => state?.global?.PdfEdited || false);
    const disableSaveExitButton = useAppSelector((state) => state?.global?.SaveEdited || false);
    const pdfLoading = useAppSelector((state) => state.client.pdfLoading);
    const data: any[] = useAppSelector((state) => state?.editGoal?.clientSummary?.formFields || []);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        const condition = editPage ? (editGoalWealthSplitterApplied ? false : showDialog) : showDialog;
        if (condition) {
            setTabModalState(true);
            setTabValue(newValue);
        } else {
            setValue(newValue);
            toggleTab('newValue', newValue);
        }
    };
    useEffect(() => {
        setValue(toggleState);
    }, [toggleState]);

    useEffect(() => {
        if (!isEmpty(data)) {
            let initialCount = 0;
            data.forEach((itm) => {
                if (itm.wealthSplit) {
                    initialCount += 1;
                }
            });
            setCount(initialCount);
        }
    }, [data]);
    const handleCloseTabModal = () => {
        setTabModalState(false);
    };
    const confirmationDialog = () => {
        handleCloseTabModal();
        dispatch(updateDialogState(false));
        dispatch(updatePdfEdited(false));
        dispatch(updateSaveExitEdited(false));
        setValue(tabValue);
        toggleTab('newValue', tabValue);
    };

    return (
        <>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={goalTab ? 12 : 7} lg={goalTab ? 12 : 7} sm={12}>
                        <TabsContainer
                            className="tab-container"
                            onChange={handleChange}
                            value={value}
                            aria-label="Tabs where selection follows focus"
                            selectionFollowsFocus
                            variant="scrollable"
                            scrollButtons
                            ScrollButtonComponent={(props) => {
                                if (props.direction === 'left' && !props.disabled) {
                                    return (
                                        <TabPaginationButton {...props} disableRipple>
                                            <ArrowBackIosNewIcon sx={{ color: 'black' }} />
                                        </TabPaginationButton>
                                    );
                                }
                                if (props.direction === 'right' && !props.disabled) {
                                    return (
                                        <>
                                            <TabPaginationButton {...props} disableRipple>
                                                <ArrowForwardIosIcon sx={{ color: 'black' }} />
                                            </TabPaginationButton>
                                        </>
                                    );
                                }
                                return null;
                            }}
                        >
                            {tabsData.map(
                                (
                                    value: {
                                        data: any;
                                        goalName?: any;
                                        goalType?: any;
                                        value:
                                            | string
                                            | number
                                            | boolean
                                            | ReactElement<any, string | JSXElementConstructor<any>>
                                            | ReactFragment
                                            | ReactPortal
                                            | null
                                            | undefined;
                                    },
                                    index: Key | null | undefined | number
                                ) =>
                                    addClient && isEmpty(value.goalType) ? (
                                        ''
                                    ) : (
                                        <TabButton
                                            label={
                                                goalTab
                                                    ? value?.goalName
                                                        ? value.goalName
                                                        : `Goal ${Number(index) + 1}`
                                                    : value?.data?.goalName
                                            }
                                            key={index}
                                        />
                                    )
                            )}
                        </TabsContainer>
                    </Grid>
                    {!goalTab && (
                        <Grid
                            item
                            xs={12}
                            md={5}
                            lg={5}
                            sm={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                                marginLeft: 'auto',
                                '@media (max-width: 991px)': {
                                    display: 'none'
                                }
                            }}
                        >
                            {editPage ? (
                                <ButtonContainer>
                                    {pdfLoading && (
                                        <CircularWrapper>
                                            <CircularProgress />
                                        </CircularWrapper>
                                    )}
                                    {!pdfLoading && (
                                        <ButtonComponent
                                            variant="outlined"
                                            onClick={() => {
                                                dispatch(updatePDFLoading(true));
                                                openPDFPreview();
                                            }}
                                            custom={+disablePdfButton}
                                            disabled={disablePdfButton}
                                            sx={{ marginRight: '20px' }}
                                            data-testid={t('BUTTON_GET_PDF')}
                                        >
                                            {t('BUTTON_GET_PDF')}
                                        </ButtonComponent>
                                    )}
                                    <ButtonComponent
                                        variant="outlined"
                                        custom={+disableGoalButton}
                                        onClick={splitWealth}
                                        disabled={count < 2 || disableGoalButton}
                                        sx={{ marginRight: '20px' }}
                                        data-testid="split-initial-wealth"
                                    >
                                        {t('TEXT_SPLIT_INITIAL_WEALTH')}
                                    </ButtonComponent>
                                    <ButtonComponent
                                        variant={disableSaveExitButton ? 'outlined' : 'contained'}
                                        // variant="contained"
                                        data-testid="edit-remodel"
                                        custom={+disableSaveExitButton}
                                        disabled={disableSaveExitButton}
                                        onClick={saveGoalAndExit}
                                    >
                                        {t('BUTTON_SAVE_GOAL_AND_EXIT')}
                                    </ButtonComponent>
                                </ButtonContainer>
                            ) : clientSummarPopup ? (
                                <ButtonContainer>
                                    <ButtonComponent
                                        variant="outlined"
                                        onClick={handleAddGoal}
                                        sx={{ marginRight: '20px' }}
                                        data-testid="add-goal"
                                    >
                                        {t('TEXT_ADD_GOAL')}
                                    </ButtonComponent>
                                    {pdfLoading && (
                                        <CircularWrapper>
                                            <CircularProgress />
                                        </CircularWrapper>
                                    )}
                                    {!pdfLoading && (
                                        <ButtonComponent
                                            variant="outlined"
                                            onClick={() => {
                                                dispatch(updatePDFLoading(true));
                                                openPDFPreview();
                                            }}
                                            sx={{ marginRight: '20px' }}
                                            data-testid={t('BUTTON_GET_PDF')}
                                        >
                                            {t('BUTTON_GET_PDF')}
                                        </ButtonComponent>
                                    )}
                                    <ButtonComponent
                                        variant="contained"
                                        onClick={handleEditGoals}
                                        data-testid="edit-goal"
                                    >
                                        {t('HEADER_EDIT_GOAL')}
                                    </ButtonComponent>
                                </ButtonContainer>
                            ) : goalConfirm ? (
                                <ButtonContainer>
                                    <ButtonComponent
                                        variant="outlined"
                                        onClick={() => {
                                            if (onBackClick) {
                                                onBackClick(() => {
                                                    navigate(-1);
                                                });
                                            } else {
                                                navigate(-1);
                                            }
                                        }}
                                        sx={{ marginRight: '20px' }}
                                        data-testid="go-back-button"
                                    >
                                        {t('BUTTON_BACK')}
                                    </ButtonComponent>
                                    {pdfLoading && (
                                        <CircularWrapper>
                                            <CircularProgress />
                                        </CircularWrapper>
                                    )}
                                    {!pdfLoading && (
                                        <ButtonComponent
                                            variant="outlined"
                                            onClick={() => {
                                                dispatch(updatePDFLoading(true));
                                                openPDFPreview();
                                            }}
                                            custom={+disablePdfButton}
                                            disabled={disablePdfButton}
                                            sx={{ marginRight: '20px' }}
                                            data-testid={`${t('BUTTON_GET_PDF')}`}
                                        >
                                            {t('BUTTON_GET_PDF')}
                                        </ButtonComponent>
                                    )}
                                    <ButtonComponent
                                        variant={disableSaveExitButton ? 'outlined' : 'contained'}
                                        // variant="contained"
                                        data-testid="goal-confirm-remodel"
                                        custom={+disableSaveExitButton}
                                        disabled={disableSaveExitButton}
                                        onClick={saveGoalAndExit}
                                    >
                                        {t('BUTTON_SAVE_GOAL_AND_EXIT')}
                                    </ButtonComponent>
                                </ButtonContainer>
                            ) : null}
                        </Grid>
                    )}
                </Grid>

                <Modal handleClose={handleCloseTabModal} open={tabModalState}>
                    <ModalContent id="modal-modal-title" variant="body1">
                        {t('TEXT_CANCEL_ADD_CLIENT_POPUP_2')}
                    </ModalContent>
                    <CtaContainer>
                        <ButtonComponent variant="outlined" size="medium" onClick={() => handleCloseTabModal()}>
                            {t('TEXT_NO')}
                        </ButtonComponent>
                        <ButtonComponent variant="contained" size="medium" onClick={confirmationDialog}>
                            {t('TEXT_YES')}
                        </ButtonComponent>
                    </CtaContainer>
                </Modal>
            </Container>

            {!goalTab && (
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '@media (min-width: 991px)': {
                            display: 'none'
                        }
                    }}
                >
                    {editPage ? (
                        <ButtonContainer>
                            {pdfLoading && (
                                <CircularWrapper>
                                    <CircularProgress />
                                </CircularWrapper>
                            )}
                            {!pdfLoading && (
                                <ButtonComponent
                                    variant="outlined"
                                    onClick={() => {
                                        dispatch(updatePDFLoading(true));
                                        openPDFPreview();
                                    }}
                                    custom={+disablePdfButton}
                                    disabled={disablePdfButton}
                                    sx={{ marginRight: '20px' }}
                                    data-testid={`${t('BUTTON_GET_PDF')}-mobile`}
                                >
                                    {t('BUTTON_GET_PDF')}
                                </ButtonComponent>
                            )}
                            <ButtonComponent
                                variant="outlined"
                                custom={+disableGoalButton}
                                onClick={splitWealth}
                                disabled={count < 2 || disableGoalButton}
                                sx={{ marginRight: '20px' }}
                                data-testid="split-initial-wealth-mobile"
                            >
                                {t('TEXT_SPLIT_INITIAL_WEALTH')}
                            </ButtonComponent>
                            <ButtonComponent
                                variant={disableSaveExitButton ? 'outlined' : 'contained'}
                                // variant="contained"
                                data-testid="edit-remodel-mobile"
                                custom={+disableSaveExitButton}
                                disabled={disableSaveExitButton}
                                onClick={saveGoalAndExit}
                            >
                                {t('BUTTON_SAVE_GOAL_AND_EXIT')}
                            </ButtonComponent>
                        </ButtonContainer>
                    ) : clientSummarPopup ? (
                        <ButtonContainer>
                            <ButtonComponent
                                variant="outlined"
                                onClick={handleAddGoal}
                                sx={{ marginRight: '20px' }}
                                data-testid="add-goal-button-mobile"
                            >
                                {t('TEXT_ADD_GOAL')}
                            </ButtonComponent>
                            {pdfLoading && (
                                <CircularWrapper>
                                    <CircularProgress />
                                </CircularWrapper>
                            )}
                            {!pdfLoading && (
                                <ButtonComponent
                                    variant="outlined"
                                    onClick={() => {
                                        dispatch(updatePDFLoading(true));
                                        openPDFPreview();
                                    }}
                                    sx={{ marginRight: '20px' }}
                                    data-testid={`${t('BUTTON_GET_PDF')}-mobile`}
                                >
                                    {t('BUTTON_GET_PDF')}
                                </ButtonComponent>
                            )}
                            <ButtonComponent variant="contained" onClick={handleEditGoals}>
                                {t('HEADER_EDIT_GOAL')}
                            </ButtonComponent>
                        </ButtonContainer>
                    ) : goalConfirm ? (
                        <ButtonContainer>
                            <ButtonComponent
                                variant="outlined"
                                onClick={() => {
                                    if (onBackClick) {
                                        onBackClick(() => {
                                            navigate(-1);
                                        });
                                    } else {
                                        navigate(-1);
                                    }
                                }}
                                sx={{ marginRight: '20px' }}
                                data-testid="go-back-button-mobile"
                            >
                                {t('BUTTON_BACK')}
                            </ButtonComponent>
                            {pdfLoading && (
                                <CircularWrapper>
                                    <CircularProgress />
                                </CircularWrapper>
                            )}
                            {!pdfLoading && (
                                <ButtonComponent
                                    variant="outlined"
                                    onClick={() => {
                                        dispatch(updatePDFLoading(true));
                                        openPDFPreview();
                                    }}
                                    custom={+disablePdfButton}
                                    disabled={disablePdfButton}
                                    sx={{ marginRight: '20px' }}
                                    data-testid={`${t('BUTTON_GET_PDF')}-mobile`}
                                >
                                    {t('BUTTON_GET_PDF')}
                                </ButtonComponent>
                            )}
                            <ButtonComponent
                                variant={disableSaveExitButton ? 'outlined' : 'contained'}
                                // variant="contained"
                                data-testid="goal-confirm-remodel-mobile"
                                custom={+disableSaveExitButton}
                                disabled={disableSaveExitButton}
                                onClick={saveGoalAndExit}
                            >
                                {t('BUTTON_SAVE_GOAL_AND_EXIT')}
                            </ButtonComponent>
                        </ButtonContainer>
                    ) : null}
                </Grid>
            )}
        </>
    );
}

export default ScrollableTabs;
