import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from '../../utils/forms';
import { GOAL_PRIORITY_TRANSLATION_MAP } from '../../constants';
import { Typography } from '@mui/material';

const GoalsWrapper = styled('div')(({ theme }) => ({
    padding: '0 20px',
    hr: {
        background: `${theme.palette.grey[600]}`
    }
}));

const Table = styled('table')(({ theme }) => ({
    width: '100%',
    borderCollapse: 'collapse',
    color: `${theme.palette.grey[600]}`
}));

const Row = styled('tr')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[600]}`,
    '&:last-child': {
        borderBottom: 0
    }
}));

const Head = styled('th')(({ theme }) => ({
    paddingBottom: '10px',
    textAlign: 'start',
    ...theme.typography.body1,
    fontWeight: 700,
    color: theme.palette.grey[600]
}));

const Cell = styled('td')(({ theme }) => ({
    padding: '10px 0',
    ...theme.typography.body1,
    color: theme.palette.grey[600]
}));

const HR = styled('div')(({ theme }) => ({
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey[600],
    margin: '10px 0'
}));

const AllGoals = ({ formFields }: any) => {
    const { t } = useTranslation();

    return (
        <GoalsWrapper>
            <Typography tabIndex={0} variant="h2" sx={{ fontWeight: 700 }}>
                {t('TEXT_PDF_MODAL_ALL_GOALS_HEADER')}
            </Typography>
            <HR />
            <Table>
                <Row>
                    <Head tabIndex={0}>{t('TEXT_PDF_MODAL_GOAL_NAME_COLUMN_HEADER')}</Head>
                    <Head tabIndex={0}>{t('TEXT_PDF_MODAL_IMPORTANCE_COLUMN_HEADER')}</Head>
                    <Head tabIndex={0}>{t('TEXT_PDF_MODAL_TARGET_AMOUNT_COLUMN_HEADER')}</Head>
                    <Head tabIndex={0}>{t('TEXT_PDF_MODAL_INITIAL_INVESTMENT_COLUMN_HEADER')}</Head>
                </Row>

                {formFields.map((val: any, key: number) => (
                    <Row key={key}>
                        <Cell tabIndex={0}>{val?.data?.goalName || ''}</Cell>
                        <Cell tabIndex={0}>{t(GOAL_PRIORITY_TRANSLATION_MAP[val?.data?.goalPriority] || '')}</Cell>
                        <Cell tabIndex={0}>${numberWithCommas(val?.data?.goalAmount) || ''}</Cell>
                        <Cell tabIndex={0}>${numberWithCommas(val?.data?.initialInvestment) || ''}</Cell>
                    </Row>
                ))}
            </Table>
        </GoalsWrapper>
    );
};

export default AllGoals;
