import { Key, useState, useEffect } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import {
    // updateActiveTabIndex as updateClientSummaryActiveTabIndex,
    updateFormField,
    updateRequest,
    updateResponse
} from '../../../features/client-summary';
import {
    updateClientSummary
    // updateActiveTabIndex as updateActiveEditGoalTabIndex
} from '../../../features/edit-goal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TabsComponent from '../../../components/molecules/scrollable-tabs';
import PDFModal from '../../pdf';
import { updateActiveTabIndex, updateCopyData, logEventToBackEnd } from '../../../features/global/globalSlice';
import {
    updateAccordionState,
    updateClientInfoData,
    updateClientId,
    updateClientInfoHelperText,
    updaterRiskToleranceHelperText,
    selectRiskToleranceInArray,
    updateInvestorGoalData,
    updateRequestPayload
} from '../../../features/add-client/add-client';
import { RISK_PROFILE_MAP } from '../../../constants';
import { goalObj } from '../../../services/configs/configs';

const TabsContainer = ({
    clientSummary,
    updateProfolioCompositionData,
    portfolioData,
    portfolioComposistionChartData,
    portfolioComposistionEquityValue,
    portfolioComposistionFixedIncomeValue,
    portfolioCompositionYearLabel
}: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const activeTabIndex = useAppSelector((state) => state?.global?.activeTabIndex);
    const [responses, setResponses] = useState([]);
    const [formFields, setFormFields] = useState([]);
    const [requests, setRequests] = useState([]);
    const [pdfModalOpen, setPdfPreviewOpen] = useState(false);

    const toggleTab = (value: Key | null | undefined | number | any, index: any) => {
        dispatch(updateActiveTabIndex(index));
        // dispatch(updateResponse(requests.find((v: any) => v.id === value.id)));
        // dispatch(updateResponse(responses.find((v: any) => v.id === value.id)));
    };

    const handleEditGoals = () => {
        if (!isEmpty(clientSummary)) {
            const summary = cloneDeep(clientSummary);
            dispatch(updateCopyData(summary));
            dispatch(updateClientSummary(summary));
            dispatch(updateActiveTabIndex(activeTabIndex));
            navigate('/edit-goal');
        }
    };

    const handleAddGoal = () => {
        if (!isEmpty(clientSummary)) {
            const clientName =
                clientSummary?.clientFirstName +
                (clientSummary?.clientLastName ? ' ' + clientSummary?.clientLastName : '');
            const obj = {
                investorFullName: clientName,
                investorEmail: clientSummary?.clientEmail,
                investorDOB: clientSummary?.clientDOB
            };
            dispatch(updateClientInfoData(obj));
            dispatch(updateClientId(clientSummary.assignedClientId));
            dispatch(updateClientInfoHelperText(clientName));

            const riskToleranceFromServer = clientSummary.riskTolerance?.toLowerCase();

            if (riskToleranceFromServer && RISK_PROFILE_MAP[riskToleranceFromServer]) {
                dispatch(updaterRiskToleranceHelperText(RISK_PROFILE_MAP[riskToleranceFromServer]));
                dispatch(selectRiskToleranceInArray(RISK_PROFILE_MAP[riskToleranceFromServer]));
                const obj2 = cloneDeep(goalObj);
                dispatch(updateInvestorGoalData([obj2]));
                dispatch(updateRequestPayload([obj2]));
            }

            dispatch(
                updateAccordionState(riskToleranceFromServer && RISK_PROFILE_MAP[riskToleranceFromServer] ? 2 : 1)
            );
            dispatch(updateActiveTabIndex(0));
            navigate('/add-client');
        }
    };

    useEffect(() => {
        if (!isEmpty(clientSummary)) {
            setFormFields(clientSummary.formFields);
            setResponses(clientSummary.response);
            setRequests(clientSummary.request);
            dispatch(updateRequest(clientSummary.request[activeTabIndex]));
            dispatch(updateFormField(clientSummary.formFields[activeTabIndex]));
            dispatch(updateResponse(clientSummary.response[activeTabIndex]));
        }
    }, [clientSummary, activeTabIndex]);

    return !isEmpty(formFields) && !isEmpty(requests) && !isEmpty(responses) ? (
        <>
            <TabsComponent
                tabsData={formFields}
                toggleState={activeTabIndex}
                toggleTab={toggleTab}
                handleEditGoals={handleEditGoals}
                handleAddGoal={handleAddGoal}
                clientSummarPopup
                openPDFPreview={() => {
                    dispatch(logEventToBackEnd('PDF_GENERATED'));
                    setPdfPreviewOpen(true);
                }}
            />
            <PDFModal
                open={pdfModalOpen}
                handleClose={() => setPdfPreviewOpen(false)}
                clientSummary={clientSummary}
                updateProfolioCompositionData={updateProfolioCompositionData}
                portfolioData={portfolioData}
                portfolioComposistionChartData={portfolioComposistionChartData}
                portfolioComposistionEquityValue={portfolioComposistionEquityValue}
                portfolioComposistionFixedIncomeValue={portfolioComposistionFixedIncomeValue}
                portfolioCompositionYearLabel={portfolioCompositionYearLabel}
            />
        </>
    ) : (
        <Skeleton sx={{ margin: '20px 0px' }} variant="rectangular" width="100%" height={60} />
    );
};

export default TabsContainer;
