import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Input from '../../../atoms/input';

const StyledInputGroupContainer = styled('div')(() => ({
    display: 'flex',
    '.MuiInputBase-root': {}
}));

const StyledInputGroupLabel = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '13px',
    fontWeight: 500,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.palette.grey[600]
}));

export const ReadOnlyInput = ({
    label,
    value,
    ...rest
}: {
    label: string;
    value: string | number;
    currencysymbol?: string;
}) => (
    <div>
        <StyledInputGroupLabel variant="body1">{label}</StyledInputGroupLabel>
        <>
            <Input label="" value={value} showcheckmark="" readOnly sx={{ padding: '0 0 0 6px' }} {...rest} />
        </>
    </div>
);

export const ReadOnlyInputGroup = ({
    label,
    value1,
    value2,
    value1CurrencySymbol,
    value2CurrencySymbol,
    ...rest
}: {
    label: string;
    value1: string | number;
    value2: string | number;
    value1CurrencySymbol?: string | number;
    value2CurrencySymbol?: string | number;
    currencysymbol?: string;
}) => (
    <div>
        <StyledInputGroupLabel variant="body1">{label}</StyledInputGroupLabel>
        <StyledInputGroupContainer>
            <Input
                label=""
                value={value1}
                showcheckmark=""
                currencysymbol={value1CurrencySymbol}
                sx={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRightStyle: 'none !important',
                    padding: '0 0 0 6px'
                }}
                readOnly
                {...rest}
            />
            <Input
                label=""
                value={value2}
                showcheckmark=""
                currencysymbol={value2CurrencySymbol}
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, padding: '0 0 0 6px' }}
                readOnly
                {...rest}
            />
        </StyledInputGroupContainer>
    </div>
);
