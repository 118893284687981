import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useOktaAuth } from '@okta/okta-react';

import { useAppDispatch } from '../../../app/hooks';
import ButtonComponent from '../../atoms/button';
import Modal from '../modal';
import { toggleSessionExpiredModal } from '../../../features/auth/authSlice';

const Title = styled(Typography)<TypographyProps>(() => ({
    fontWeight: '600',
    fontSize: '20px',
    margin: '30px'
}));

const Hr = styled('hr')(({ theme }) => ({
    color: theme.palette.grey[100]
}));

const ActionsContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'end',
    margin: '30px',
    '& button': {
        width: '129px',
        minHeight: '40px',
        fontWeight: 'bold',
        '&:first-of-type': {
            margin: '0 5px'
        }
    }
}));

const warningTimeConst = 900 - 115;
const countDownSecondsConst = 115;

function Timeout() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [warningTime, setWarningTime] = useState(warningTimeConst);
    const [popupOpen, setPopupOpen] = useState(false);
    const [countdownTime, setCountdownTime] = useState(countDownSecondsConst);
    const { oktaAuth, authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const { t } = useTranslation();

    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, () => {
                setWarningTime(warningTimeConst);
            });
        });
        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, () => {
                    setWarningTime(warningTimeConst);
                });
            });
        };
    });

    useEffect(() => {
        if (!isAuthenticated || popupOpen) return;
        const counter = setInterval(() => setWarningTime(warningTime - 1), 1000);

        if (!popupOpen && warningTime === 0) {
            setPopupOpen(true);
            setCountdownTime(countDownSecondsConst);
        }
        return () => {
            clearInterval(counter);
        };
    }, [warningTime, popupOpen, isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated || !popupOpen) return;
        const countdownCounter = window.setInterval(() => setCountdownTime(countdownTime - 1), 1000);

        if (countdownTime === 0) {
            dispatch(toggleSessionExpiredModal(true));
            signout();
        }
        return () => {
            clearInterval(countdownCounter);
        };
    }, [popupOpen, countdownTime, isAuthenticated]);

    const signout = async () => {
        handleDismiss();
        const sessionExists = await oktaAuth.session.exists();
        if (sessionExists) {
            oktaAuth.clearStorage();
            oktaAuth.closeSession();
            oktaAuth.tokenManager.clear();
            navigate('/');
        }
    };

    const handleDismiss = () => {
        setPopupOpen(false);
        setWarningTime(warningTimeConst);
        setCountdownTime(countDownSecondsConst);
    };

    if (!isAuthenticated) return null;

    return (
        <Modal style={{ padding: '0' }} handleClose={handleDismiss} open={popupOpen}>
            <Title tabIndex={0}>{t('TIMEOUT_MODAL_TITLE')}</Title>
            <Hr />
            <Typography variant="h6" style={{ margin: '30px' }} tabIndex={0}>
                {t('TIMEOUT_MODAL_CONTENT', { remainingSeconds: countdownTime })}
            </Typography>
            <Hr />
            <ActionsContainer>
                <ButtonComponent
                    variant="outlined"
                    size="medium"
                    data-testid="timeout-signout-button"
                    onClick={signout}
                >
                    {t('TIMEOUT_MODAL_SIGNOUT_BUTTON')}
                </ButtonComponent>
                <ButtonComponent
                    variant="contained"
                    size="medium"
                    data-testid="stay-signin-button"
                    onClick={handleDismiss}
                >
                    {t('TIMEOUT_MODAL_STAY_SIGNIN_BUTTON')}
                </ButtonComponent>
            </ActionsContainer>
        </Modal>
    );
}

export default Timeout;
