/* eslint-disable indent */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setAutoFreeze } from 'immer';
import { FormInput } from '../../components/molecules/client-Info';
import { uipApiInstance } from '../../axios';
import { logout } from '../auth/authSlice';
import { fetchPortfolio } from '../global/globalSlice';
import { RISK_PROFILE_MAP } from '../../constants';
import { logEventToBackEnd } from '../global/globalSlice';

setAutoFreeze(false);
type InitialState = {
    clientInfo: FormInput;
    assignedClientId: string;
    createClientObject: any[];
    chooseGoalData: any;
    riskToleranceData: any[];
    invertorGoalData: any[];
    accordionState: number;
    riskHeading: string;
    activeTabIndex: number;
    formField: any;
    riskToleranceHelperText: any;
    savingGoalHelperText: string;
    requestPayLoadObj: Array<any>;
    wealthSplitterData: any[];
    response: any;
    clientHelperText: string;
    wealthSpitter: boolean;
    loading: boolean;
    openDialog: boolean;
    error?: null | undefined | any;
};
const initialState: InitialState = {
    clientInfo: {
        investorFullName: '',
        investorEmail: '',
        investorDOB: ''
    },
    assignedClientId: '',
    createClientObject: [],
    chooseGoalData: [],
    riskToleranceData: [],
    formField: {},
    activeTabIndex: 0,
    response: [],
    accordionState: 0,
    clientHelperText: '',
    riskToleranceHelperText: '',
    savingGoalHelperText: '',
    invertorGoalData: [],
    requestPayLoadObj: [],
    riskHeading: '',
    wealthSpitter: false,
    wealthSplitterData: [],
    openDialog: false,
    loading: false,
    error: null
};

export const fetchRiskToleranceData = createAsyncThunk(
    'riskTolerancePlan/fetchRiskToleranceData',
    async (_, { getState, dispatch }) => {
        // const response = await axios.get('server/riskTolerance/riskTolerance.json');
        // return response.data;
        const state: any = getState();

        if (!state.global.domainEmail) {
            await dispatch(fetchPortfolio());
        }

        let riskProfileData = state.global.orgInfo?.riskProfile || state.global.orgInfo?.orgInfo?.riskProfile;
        const riskSelected = state.client.clientSummary.riskProfile;

        if (state.client.clientSummary.riskProfile) {
            riskProfileData = riskProfileData.map((x: any) => {
                if (x.label === RISK_PROFILE_MAP[riskSelected]) {
                    return {
                        ...x,
                        active: true
                    };
                } else {
                    return x;
                }
            });
        }

        return riskProfileData;
    }
);

export const createGoalData = createAsyncThunk(
    'addClient/createGoalData',
    async (requestPayload: any, { getState, rejectWithValue, dispatch }) => {
        try {
            const state: any = getState();

            const response = await uipApiInstance.post('/api/advisorportal/runpipe', requestPayload, {
                headers: {
                    clientEmail: state.global.domainEmail,
                    version: 4
                }
            });

            return response.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('RUN_PIPE_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const saveClient = createAsyncThunk(
    'addClient/saveClient',
    async (payload: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance.post('/api/advisorportal/addclient', payload);
            return response.status === 200 && response.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('ADD_CLIENT_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const checkDuplicateClient = createAsyncThunk(
    'addClient/checkDuplicateClient',
    async (email: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance.get(`/api/advisorportal/getclients`, {
                params: { email }
            });

            return response.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_CLIENTS_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);
export const createWealthSplitterData = createAsyncThunk(
    'addClient/wealthSplitter',
    async (requestPayload: any, { getState, dispatch, rejectWithValue }) => {
        try {
            const state: any = getState();
            const response = await uipApiInstance.post('/api/advisorportal/iws', requestPayload, {
                headers: {
                    clientEmail: state.global.domainEmail
                    // version: 4
                }
            });
            return response.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('IWS_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

type FinancialUserType = {
    firstName: string;
    email: string;
    phoneNumber: string;
    role: string;
    tenant: string;
};

export const addUser = createAsyncThunk(
    'admin/addUser',
    async (financialAdvisor: FinancialUserType, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advisorportal/adduser',
                withCredentials: false,
                data: {
                    ...financialAdvisor,
                    settings: {
                        // hierarchy: {
                        //     manager_id: '00ucql4opCihuxT0X696',
                        //     regional_office_id: 'newyork'
                        // },
                        freemium: false
                    }
                }
            });

            return response.data?.success;
        } catch (e) {
            console.log(e);
            dispatch(logEventToBackEnd('ADMIN_ADD_USER_API_ERROR'));
            return rejectWithValue(e);
        }
    }
);

const addClient = createSlice({
    name: 'addClient',
    initialState,
    reducers: {
        updateClientInfoData: (state, action: PayloadAction<any>) => {
            state.clientInfo = action.payload;
        },
        updateClientId: (state, action: PayloadAction<any>) => {
            state.assignedClientId = action.payload;
        },
        updateGoalData: (state, action: PayloadAction<any>) => {
            state.chooseGoalData = action.payload;
        },
        updateRiskToleranceData: (state, action: PayloadAction<any>) => {
            state.riskToleranceData = action.payload;
        },
        updateInvestorGoalData: (state, action: PayloadAction<any>) => {
            state.invertorGoalData = action.payload;
            //  return { ...state, invertorGoalData: [...action.payload] };
        },
        updatecreateClientObject: (state, action: PayloadAction<any>) => {
            state.createClientObject = action.payload;
            // return { ...state, riskToleranceData: [...action.payload] };
        },

        updateRequestPayload: (state, action: PayloadAction<any>) => {
            state.requestPayLoadObj = action.payload;
            // state.requestPayLoadObj = action.payload;
        },
        updateClientInfoHelperText: (state, action: PayloadAction<any>) => {
            state.clientHelperText = action.payload;
        },
        updaterRiskToleranceHelperText: (state, action: PayloadAction<any>) => {
            state.riskToleranceHelperText = action.payload;
        },
        selectRiskToleranceInArray: (state, action: PayloadAction<any>) => {
            state.riskToleranceData = state.riskToleranceData.map((x: any) => {
                if (x.label === action.payload) {
                    return {
                        ...x,
                        active: true
                    };
                } else {
                    return x;
                }
            });
        },
        updateSavingGoalHelperText: (state, action: PayloadAction<any>) => {
            state.savingGoalHelperText = action.payload;
        },
        updateAccordionState: (state, action: PayloadAction<any>) => {
            state.accordionState = action.payload;
        },
        updateWeathSpliter: (state, action: PayloadAction<any>) => {
            state.wealthSpitter = action.payload.data;
        },
        updateFormField: (state, action: PayloadAction<any>) => {
            state.formField = action.payload;
        },
        updateActiveTabIndex: (state, action: PayloadAction<any>) => {
            state.activeTabIndex = action.payload;
        },
        updateLoader: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        updateSplitDialog: (state, action: PayloadAction<any>) => {
            state.openDialog = action.payload;
        },
        resetAddClientReducer: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(fetchRiskToleranceData.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchRiskToleranceData.fulfilled, (state, action) => {
                state.loading = false;
                state.riskToleranceData = action.payload;
                state.error = '';
            }),
            builder.addCase(fetchRiskToleranceData.rejected, (state, action) => {
                state.loading = false;
                state.response = {};
                state.error = action.error;
            });
        builder.addCase(addUser.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(addUser.fulfilled, (state) => {
                state.loading = false;
                state.error = '';
            }),
            builder.addCase(addUser.rejected, (state) => {
                state.loading = false;
                state.error = '';
            });
        builder.addCase(createGoalData.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(createGoalData.fulfilled, (state) => {
                state.loading = false;
                // state.openDialog = false;
                // state.response = action.payload.body;
                state.error = '';
            }),
            builder.addCase(createGoalData.rejected, (state, action) => {
                state.loading = false;
                // state.response = {};
                state.error = action.error;
            });
        builder.addCase(saveClient.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(saveClient.fulfilled, (state, action) => {
                state.loading = false;
                state.assignedClientId = action.payload._id;
                state.error = {};
                state.error = '';
            }),
            builder.addCase(saveClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder.addCase(createWealthSplitterData.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(createWealthSplitterData.fulfilled, (state, action) => {
                state.loading = false;
                state.wealthSplitterData = action.payload.body.goal_response_list;
                state.openDialog = true;
                state.error = '';
            }),
            builder.addCase(createWealthSplitterData.rejected, (state, action) => {
                state.loading = false;
                // state.response = {};
                state.error = action.error;
            });
    }
});

export const {
    updateClientInfoData,
    updateClientId,
    updateGoalData,
    updateRiskToleranceData,
    updateInvestorGoalData,
    resetAddClientReducer,
    updateAccordionState,
    updateClientInfoHelperText,
    updateSavingGoalHelperText,
    updaterRiskToleranceHelperText,
    selectRiskToleranceInArray,
    updateWeathSpliter,
    updateActiveTabIndex,
    updateFormField,
    updateRequestPayload,
    updatecreateClientObject,
    updateSplitDialog,
    updateLoader
} = addClient.actions;

export default addClient.reducer;
