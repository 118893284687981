import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import GlidePathMolecule from '../../../molecules/charts/glide-path';
import { UnknownProperties } from '../../../../constants/types';
import Legends from '../../../molecules/charts/legends/glide-path';
import Loader from '../../../molecules/charts/loader';

const StyledHeaderDescText = styled(Typography)<TypographyProps>(() => ({
    fontWeight: 700
}));

const StyledFooterHeaderText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '18px',
    fontWeight: 600,
    lineHeight: '24px'
}));

const StyledFooterDescText = styled(Typography)<TypographyProps>(() => ({
    fontSize: '16px',
    marginTop: '18px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '20px'
}));

const ContainerWithSuperScript = styled('div')(() => ({
    position: 'relative',
    display: 'inline-flex'
}));

const SuperCript = styled(Typography)<TypographyProps>(() => ({
    marginLeft: '10px',
    position: 'absolute',
    right: -15,
    top: -10
}));

const GlidePathOrganism = ({
    xAxisCategories,
    equityPosition,
    fixedIncomePosition,
    dispatch,
    updateProfolioCompositionData,
    fixedIncomeData,
    equityData,
    seriesData,
    theme,
    translation,
    showLoader,
    showHeader,
    showFooter,
    showLegends,
    showAmount,
    chartHeight,
    pieChartFontSize,
    showSkeletonLoader,
    enableSourceCaveatNumbers
}: UnknownProperties) => {
    const { t } = useTranslation();

    return !isEmpty(xAxisCategories) && !isEmpty(equityData) && !isEmpty(seriesData) && !showLoader ? (
        <>
            {showHeader && (
                <ContainerWithSuperScript>
                    <StyledHeaderDescText variant="h3">{t('HEADER_GLIDE_PATH_SUMMARY_PAGE')}</StyledHeaderDescText>
                    {enableSourceCaveatNumbers && <SuperCript>1</SuperCript>}
                </ContainerWithSuperScript>
            )}
            {showFooter && <StyledFooterHeaderText>{t('HEADER_DESCRIPTION_GLIDE_PATH')}</StyledFooterHeaderText>}
            <GlidePathMolecule
                theme={theme}
                xAxisCategories={xAxisCategories}
                equityPosition={equityPosition}
                fixedIncomePosition={fixedIncomePosition}
                dispatch={dispatch}
                updateProfolioCompositionData={updateProfolioCompositionData}
                fixedIncomeData={fixedIncomeData}
                equityData={equityData}
                seriesData={seriesData}
                translation={translation}
                showAmount={showAmount}
                chartHeight={chartHeight}
                pieChartFontSize={pieChartFontSize}
            />
            <>
                {showLegends && (
                    <div data-testid="glide-path-chart-legend">
                        <Legends
                            equityColor={theme?.palette?.charts?.glidePath?.equity}
                            fixedIncomeColor={theme?.palette?.charts?.glidePath?.fixedIncome}
                        />
                    </div>
                )}
                {showFooter && <StyledFooterDescText>{t('DESCRIPTION_GLIDE_PATH')}</StyledFooterDescText>}
            </>
        </>
    ) : showSkeletonLoader ? (
        <Loader Icon={DonutLargeOutlinedIcon} />
    ) : null;
};

export default GlidePathOrganism;
