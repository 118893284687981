import { useState } from 'react';
import { isEmpty } from 'lodash';
import Highcharts from 'highcharts';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import HighchartsReact from 'highcharts-react-official';
import HC_rounded from 'highcharts-rounded-corners';
import highchartsAnnotations from 'highcharts/modules/annotations';
import DataLabel from '../data-label';
import Tooltip from './tooltip';
import Label from './label';
import { UnknownProperties } from '../../../../constants/types';
import { fontFamily } from '../../../../styles/themes/constants';
import Modal from '../../modal';
import { CtaContainer } from '../../modal/styles';
import ButtonComponent from '../../../atoms/button';

highchartsAnnotations(Highcharts);

interface ExtendedXAxisTitleOptions extends Highcharts.XAxisTitleOptions {
    enabled: boolean;
}

interface ExtendedPlotSeriesOptions extends Highcharts.PlotSeriesOptions {
    borderRadiusTopLeft: string;
}

HC_rounded(Highcharts);

export const HeaderContainer = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
}));

export const ModalHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: '30px'
}));

export const CloseIconContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
        height: '40px',
        width: '40px'
    }
}));

const portfolioCompositionMolecule = ({
    labelXaxis,
    labelYaxis,
    label,
    data,
    chartHeight = 500,
    showYearLabel = true,
    showPercentageOnBar
}: UnknownProperties) => {
    const { t } = useTranslation();
    const [leavingModal, setLeavingModal] = useState('');
    const options: Highcharts.Options = {
        chart: {
            height: `${chartHeight}px`,
            spacingLeft: 0,
            spacingRight: 0,
            borderWidth: 0,
            style: {
                fontFamily
            }
        },
        title: {
            text: ''
        },
        tooltip: {
            enabled: false,
            formatter() {
                return renderToStaticMarkup(<Tooltip label={JSON.parse(this?.key)[0]} />);
            }
        } as any,
        xAxis: {
            labels: {
                enabled: false
            },
            tickWidth: 0
        },
        yAxis: {
            // opposite: true,
            title: {
                enabled: false
            } as ExtendedXAxisTitleOptions,
            labels: {
                format: '{value}%'
            },
            lineWidth: 2,
            lineColor: '#000000'
        },
        annotations: [
            {
                draggable: '',
                labelOptions: {
                    backgroundColor: 'none',
                    shape: 'none',
                    borderWidth: 0
                },
                labels: [
                    {
                        point: {
                            x: labelXaxis,
                            y: labelYaxis,
                            xAxis: 0,
                            yAxis: 0
                        },

                        useHTML: true,
                        formatter() {
                            return showYearLabel && renderToStaticMarkup(<DataLabel header={label} color="#000000" />);
                        }
                    }
                ]
            }
        ],
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {
                stacking: 'normal',
                pointPadding: 0,
                groupPadding: 0.02,
                dataLabels: {
                    enabled: true,
                    color: 'black',
                    align: 'left',
                    // format: "{x} {y} d",
                    inside: false,
                    useHTML: true,
                    formatter() {
                        return renderToStaticMarkup(
                            <Label
                                label={this.key}
                                value={this.y}
                                showPercentageOnBar={showPercentageOnBar}
                                color={this.color as string}
                            />
                        );
                    },
                    style: {
                        fontWeight: 'bold',
                        opacity: 1
                    },
                    verticalAlign: 'middle'
                }
            },
            series: {
                borderRadiusTopLeft: '10%',
                borderRadiusTopRight: '10%',
                borderRadiusBottomLeft: '10%',
                borderRadiusBottomRight: '10%',
                cursor: 'pointer',
                animation: false,
                point: {
                    events: {
                        click() {
                            const name = JSON.parse(this.options.name as any)[2];
                            if (!name.startsWith('https://www.franklintempleton.com')) {
                                setLeavingModal(name);
                            } else {
                                window.open(name, '_blank');
                            }
                        }
                    }
                }
            } as ExtendedPlotSeriesOptions
        },

        series: [
            {
                data,
                type: 'bar',
                colorByPoint: true
            } as any
        ]
    };

    return !isEmpty(data) ? (
        <div data-testid="portfolio-composition-chart">
            <HighchartsReact highcharts={Highcharts} options={options} />
            <Modal handleClose={() => setLeavingModal('')} open={Boolean(leavingModal)}>
                <HeaderContainer>
                    <ModalHeader id="leaving-franklin-modal-title" variant="h3" tabIndex={0}>
                        {t('LEAVING_FRANKLIN_MODAL_TITLE')}
                    </ModalHeader>
                    <CloseIconContainer>
                        <span>{t('LEAVING_FRANKLIN_MODAL_CLOSE_BUTTON')}</span>
                        <CloseIcon
                            role="button"
                            tabIndex={0}
                            aria-label="close-leaving-franklin-popup"
                            onKeyPress={() => setLeavingModal('')}
                            onClick={() => setLeavingModal('')}
                            data-testid="leaving-franklin-modal-close-button"
                        />
                    </CloseIconContainer>
                </HeaderContainer>
                <Typography tabIndex={0}>{t('LEAVING_FRANKLIN_MODAL_CONTENT')}</Typography>
                <CtaContainer>
                    <ButtonComponent
                        variant="outlined"
                        size="medium"
                        data-testid="leaving-franklin-modal-cancel-button"
                        onClick={() => setLeavingModal('')}
                    >
                        {t('BUTTON_CANCEL')}
                    </ButtonComponent>
                    <ButtonComponent
                        variant="contained"
                        size="medium"
                        data-testid="leaving-franklin-modal-ok-button"
                        onClick={() => {
                            window.open(leavingModal, '_blank');
                            setLeavingModal('');
                        }}
                    >
                        {t('BUTTON_OK')}
                    </ButtonComponent>
                </CtaContainer>
            </Modal>
        </div>
    ) : null;
};

export default portfolioCompositionMolecule;
