/* eslint-disable operator-linebreak */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import oktaAuth from '../services/configs/oktaAuth';

const mockApiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_MOCK_API_URL || 'http://localhost:4000'}`
});

export const uipApiInstance = axios.create({
    baseURL: process.env.REACT_APP_UIP_API_URL
});

axios.interceptors.request.use(
    async (config: any) => {
        const uuid = uuidv4();
        config.headers['X-CSRF-Token'] = uuid;

        return config;
    },
    (error: any) => Promise.reject(error)
);

uipApiInstance.interceptors.request.use(
    async (config: any) => {
        const tokens = await oktaAuth.tokenManager.getTokens();

        if (tokens) {
            config.headers.Authorization = 'Bearer ' + tokens?.accessToken?.accessToken;
        }

        const uuid = uuidv4();
        config.headers['X-CSRF-Token'] = uuid;

        return config;
    },
    (error: any) => Promise.reject(error)
);

export default mockApiInstance;
