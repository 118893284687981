import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { uipApiInstance } from '../../axios';
import { logout } from '../auth/authSlice';
import { logEventToBackEnd } from '../global/globalSlice';

export type FinancialAdvisor = {
    id?: string;
    advisorName?: string;
    advisorEmail?: string;
    advisorPhoneNumber?: string;
    numberOfClients?: string;
    numberOfGoals?: string;
};

export type ClientsDataType = {
    lastModified: string | number;
    clientFirstName: string;
    clientLastName: string;
    advisorEmail?: string;
    clientEmail: string;
    goals: string;
    portfolioValue: string;
};

type InitialState = {
    clients: Array<ClientsDataType>;
    advisor: FinancialAdvisor;
    loading: boolean;
    error?: null | undefined | any;
    breadcrumbLabel?: string;
};

const initialState: InitialState = {
    clients: [],
    advisor: {},
    loading: false,
    error: null,
    breadcrumbLabel: ''
};

export const fetchAdvisorAdminFAClients = createAsyncThunk(
    'admin/fetchAdvisorAdminFAClients',
    async (financialAdvisorId: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'GET',
                url: '/api/advisorportal/getclients',
                withCredentials: false
            });

            const formattedData = response.data?.data
                ?.filter((ufd: any) => ufd?.__meta?.user === financialAdvisorId)
                ?.map((d: any) => ({
                    id: d._id,
                    advisorName: d.advisorName || 'N/A',
                    clientFullName: d.firstName + (d.lastName ? ' ' + d.lastName : ''),
                    clientEmail: d.email,
                    goals: d.goals,
                    portfolioValue: d.portfolioValue,
                    lastModified: d.lastUpdatedTime
                }));

            return formattedData;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('GET_CLIENTS_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

const advisorAdminFA = createSlice({
    name: 'advisorAdminFAState',
    initialState,
    reducers: {
        updateSelectedFinancialAdvisor: (state, action: PayloadAction<any>) => {
            state.advisor = action.payload;
        },
        updateSelectedFinancialAdvisorClients: (state, action: PayloadAction<any>) => {
            state.clients = action.payload;
        },
        updateBreadcrumbLabel: (state, action: PayloadAction<any>) => {
            state.breadcrumbLabel = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(fetchAdvisorAdminFAClients.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchAdvisorAdminFAClients.fulfilled, (state, action) => {
                state.loading = false;
                state.clients = action.payload;
                state.error = null;
            }),
            builder.addCase(fetchAdvisorAdminFAClients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { updateSelectedFinancialAdvisor, updateSelectedFinancialAdvisorClients, updateBreadcrumbLabel } =
    advisorAdminFA.actions;

export default advisorAdminFA.reducer;
