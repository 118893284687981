import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useOktaAuth } from '@okta/okta-react';

import { getCurrentYear } from '../../../utils';
import { useAppSelector } from '../../../app/hooks';

const FooterContainer = styled('footer')(({ theme }) => ({
    backgroundColor: theme.palette.layout.primary,
    padding: 10,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5
}));

const DescTypography = styled(Typography)<TypographyProps>(() => ({
    margin: '0 35px'
}));

const FooterContent = styled(Typography)<TypographyProps>(({ theme }) => ({
    textOverflow: 'unset',
    whiteSpace: 'unset',
    color: theme.palette.common.white,
    margin: '0 35px'
}));

const FooterComponent = ({
    eulaVariant = false,
    showFooterWithoutOptions = false
}: {
    eulaVariant?: boolean;
    showFooterWithoutOptions?: boolean;
}) => {
    const { t } = useTranslation();
    const { authState } = useOktaAuth();
    const isAuthLoading = useAppSelector((state) => state.auth.loading);
    const isTrialSite = useAppSelector((state) => state.global.globalConfig?.disclaimers?.showTrialFooter);

    const isAuthenticated = authState?.isAuthenticated;

    if (!(isAuthenticated && !isAuthLoading) && !showFooterWithoutOptions) {
        return null;
    }

    return (
        <div id="footer">
            {!eulaVariant && !showFooterWithoutOptions && (
                <FooterContainer sx={{ bottom: 34, backgroundColor: '#fff' }}>
                    {isTrialSite && (
                        <DescTypography
                            variant="subtitle2"
                            tabIndex={0}
                            sx={{
                                paddingBottom: 1,
                                color: 'black'
                            }}
                            data-testid="footer-description-special"
                        >
                            {t('DESCRIPTION_FOOTER_TRIAL_SITE')}
                        </DescTypography>
                    )}

                    <DescTypography
                        variant="subtitle2"
                        tabIndex={0}
                        data-testid="footer-description-one"
                        sx={{ color: 'black' }}
                    >
                        {t('DESCRIPTION_FOOTER_ONE')}
                    </DescTypography>
                </FooterContainer>
            )}

            <FooterContainer>
                <FooterContent variant="subtitle2" noWrap tabIndex={0} data-testid="footer-description-two">
                    <Trans
                        i18nkey="DESCRIPTION_FOOTER_TWO"
                        defaults={t('DESCRIPTION_FOOTER_TWO')}
                        values={{ YEAR: getCurrentYear() }}
                        components={{ bold: <strong /> }}
                    />
                </FooterContent>
            </FooterContainer>
        </div>
    );
};

export default FooterComponent;
