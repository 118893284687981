const breakpoints = {
    values: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1440,
        xl: 1920
    }
};

export default breakpoints;
