import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface BgColor {
    bgcolor?: string;
}

const Container = styled('div')(() => ({
    display: 'flex',
    marginLeft: '40px'
}));

const LegendContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px'
}));

const EquityBox = styled('div')<BgColor>(({ bgcolor }) => ({
    height: '8px',
    width: '8px',
    backgroundColor: bgcolor || '#B39AF5',
    marginRight: '4px'
}));

const FixedIncomeBox = styled('div')<BgColor>(({ bgcolor }) => ({
    height: '8px',
    width: '8px',
    backgroundColor: bgcolor || '#72DBD5',
    marginRight: '4px'
}));

const StyledLabelTypography = styled(Typography)<TypographyProps>(() => ({
    margin: '0px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    marginRight: '4px'
}));

const StyledAllocationTypography = styled(Typography)<TypographyProps>(() => ({
    margin: '0px',
    fontSize: '14px',
    fontWeight: 700
}));

const Legend = ({ equity = '', fixedIncome = '', equityColor, fixedIncomeColor }: any) => {
    const { t } = useTranslation();

    return (
        <>
            <Container>
                <LegendContainer>
                    <EquityBox bgcolor={equityColor} />
                    <StyledLabelTypography>{t('HEADER_EQUITY')}</StyledLabelTypography>
                    {equity ? (
                        <StyledAllocationTypography variant="body1">{`${equity}%`}</StyledAllocationTypography>
                    ) : fixedIncome === 100 ? (
                        <StyledAllocationTypography variant="body1">{`${0}%`}</StyledAllocationTypography>
                    ) : null}
                </LegendContainer>
                <LegendContainer>
                    <FixedIncomeBox bgcolor={fixedIncomeColor} />
                    <StyledLabelTypography>{t('HEADER_FIXED_INCOME')}</StyledLabelTypography>
                    {fixedIncome ? (
                        <StyledAllocationTypography variant="body1">{`${fixedIncome}%`}</StyledAllocationTypography>
                    ) : equity === 100 ? (
                        <StyledAllocationTypography variant="body1">{`${0}%`}</StyledAllocationTypography>
                    ) : null}
                </LegendContainer>
            </Container>
        </>
    );
};

export default Legend;
