import { useOktaAuth } from '@okta/okta-react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import EULAModal from '../../components/organisms/eula-modal';
import {
    handleOpenEulaModal,
    handleOpenEulaFromClients,
    updateGlobalLoaderState
} from '../../features/global/globalSlice';

const EulaContainer = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const showDocs = useAppSelector((state) => state.global.showEulaModal);
    const openEulaFromClients = useAppSelector((state) => state.global.openEulaFromClients);

    return (
        <EULAModal
            open={showDocs}
            fromClients={openEulaFromClients}
            handleClose={() => {
                dispatch(handleOpenEulaModal(false));
                if (openEulaFromClients) {
                    handleOpenEulaFromClients(false);
                }
            }}
            handleCancel={async () => {
                dispatch(handleOpenEulaModal(false));
                if (!openEulaFromClients) {
                    updateGlobalLoaderState(true);

                    oktaAuth.tokenManager.clear();
                    navigate('/');
                }
            }}
            t={t}
            Trans={Trans}
        />
    );
};

export default EulaContainer;
