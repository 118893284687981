import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from '../auth/authSlice';
import { uipApiInstance } from '../../axios';
import { logEventToBackEnd } from '../global/globalSlice';

type InitialState = {
    clientSummary: any;
    activeTabIndex: number;
    formField: any;
    request: any;
    response: any;
    portfolioComposition: Array<any>;
    portfolioCompositionYearLabel: number;
    equity: number | string;
    fixedIncome: number | string;
    loading: boolean;
    error?: null | undefined | any;
    pdfLoading: boolean;
    suggestionValuesToUpdate: any;
    isUpdatingClient: boolean;
};

const initialState: InitialState = {
    clientSummary: [],
    formField: {},
    request: {},
    response: {},
    portfolioComposition: [],
    portfolioCompositionYearLabel: 0,
    equity: '',
    fixedIncome: '',
    activeTabIndex: 0,
    loading: false,
    error: null,
    pdfLoading: false,
    suggestionValuesToUpdate: {},
    isUpdatingClient: false
};

export const updateClient = createAsyncThunk(
    'editClient/updateClient',
    async (payload: any, { dispatch, rejectWithValue }) => {
        try {
            const res = await uipApiInstance.post('/api/advisorportal/updateclient', payload);
            return res.data;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('UPDATE_CLIENT_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

const clientSummary = createSlice({
    name: 'clientSummaryState',
    initialState,
    reducers: {
        updateClientSummary: (state, action: PayloadAction<any>) => {
            state.clientSummary = action.payload;
        },
        updateActiveTabIndex: (state, action: PayloadAction<any>) => {
            state.activeTabIndex = action.payload;
        },
        updateFormField: (state, action: PayloadAction<any>) => {
            state.formField = action.payload;
        },
        updateRequest: (state, action: PayloadAction<any>) => {
            state.request = action.payload;
        },
        updateResponse: (state, action: PayloadAction<any>) => {
            state.response = action.payload;
        },
        updateProfolioCompositionData: (state, action: PayloadAction<any>) => {
            state.portfolioComposition = action.payload.data;
            state.portfolioCompositionYearLabel = action?.payload?.year || '';
            (state.equity = action.payload.equity || ''), (state.fixedIncome = action.payload.fixedIncome || '');
        },
        updateLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        updatePDFLoading: (state, action: PayloadAction<any>) => {
            state.pdfLoading = action.payload;
        },
        updateFormWithSuggestions: (state, action: PayloadAction<any>) => {
            state.suggestionValuesToUpdate = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState);
        builder.addCase(updateClient.fulfilled, (state) => {
            state.isUpdatingClient = false;
        });
        builder.addCase(updateClient.rejected, (state) => {
            state.isUpdatingClient = false;
        });
        builder.addCase(updateClient.pending, (state) => {
            state.isUpdatingClient = true;
        });
    }
});

export const {
    updateClientSummary,
    updateActiveTabIndex,
    updateFormField,
    updateRequest,
    updateResponse,
    updateProfolioCompositionData,
    updatePDFLoading,
    updateLoading,
    updateFormWithSuggestions
} = clientSummary.actions;

export default clientSummary.reducer;
