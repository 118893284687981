import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { Button } from '@mui/material';
import NewDataTable from '../../containers/client-side-dashboard-table';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    fetchPortfolio,
    updatePdfEdited,
    updateSaveExitEdited,
    updateShowAdvisorPortalButtonOnHeader,
    handleOpenEulaModal,
    handleOpenEulaFromClients
} from '../../features/global/globalSlice';
import { resetAddClientReducer } from '../../features/add-client/add-client';
import { reseteditGoalReducer } from '../../features/edit-goal';
import { resetPersonalizedPlanReducer } from '../../features/personalized-plan';
import EulaContainer from '../../containers/eula';

// export const Container = styled('div')(() => ({
//   margin: '0 20px'
// }));

export const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '36px',
    fontWeight: 200,
    lineHeight: '32px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    margin: '36px 0 40px 0',
    display: 'inline-block'
}));

const HomePage = () => {
    const dispatch = useAppDispatch();
    const { portfolio }: any = useAppSelector((state) => state.global);

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(updateShowAdvisorPortalButtonOnHeader(false));
        if (isEmpty(portfolio)) {
            dispatch(fetchPortfolio());
        }
    }, [portfolio]);

    useEffect(() => {
        dispatch(resetAddClientReducer());
        dispatch(reseteditGoalReducer());
        dispatch(updatePdfEdited(false));
        dispatch(updateSaveExitEdited(false));
        dispatch(resetPersonalizedPlanReducer());
    }, []);

    return (
        <div data-testid="home-page">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <HeaderText tabIndex={0}>{t('HEADER_SUB_HOMEPAGE')}</HeaderText>
                <Button
                    onClick={() => {
                        dispatch(handleOpenEulaModal(true));
                        dispatch(handleOpenEulaFromClients(true));
                    }}
                    variant="text"
                    sx={{ fontSize: '12px', textTransform: 'capitalize', marginLeft: '10px' }}
                    size="small"
                >
                    {t('TEXT_EULA_BUTTON')}
                </Button>
            </div>
            <EulaContainer />
            <NewDataTable />
        </div>
    );
};

export default memo(HomePage);
