import React from 'react';
import { Snackbar, Alert, Slide } from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setApplicationMessage } from '../../../features/messaging/messagingSlice';

function SlideTransition(props: any) {
    return <Slide {...props} direction="up" />;
}

export default function SnackBar() {
    const dispatch = useAppDispatch();
    const { showMessage, variant, message } = useAppSelector((state: any) => state.messaging);

    const handleClose = () => {
        dispatch(
            setApplicationMessage({
                showMessage: false,
                message: '',
                variant: 'error'
            })
        );
    };

    return (
        <Snackbar
            open={showMessage}
            autoHideDuration={variant === 'error' ? null : 6000}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            sx={{ bottom: { sm: 56 } }}
        >
            <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
