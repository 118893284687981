import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { ReactComponent as Logo } from '../../components/organisms/header/assests/logo.svg';

interface HeaderInfo {
    clientName: string;
}

const Header = styled('header')(({ theme }) => ({
    margin: '-20px -20px 0 -20px',
    padding: '20px',
    color: 'white',
    backgroundColor: theme.palette.layout.primary,
    h1: {
        margin: 0,
        fontWeight: 400,
        color: 'white'
    }
}));

const HeaderComponent = ({ clientName }: HeaderInfo) => {
    const logo = useAppSelector((state) => state.global?.globalConfig?.logo || '');
    return (
        <Header>
            <Typography variant="h1">
                {logo ? (
                    <img src={logo} alt="Franklin Templeton" data-testid="brand-logo" width="200" height="auto" />
                ) : (
                    <Logo title="login" data-testid="brand-logo" />
                )}
            </Typography>
            <Typography variant="h1" tabIndex={0}>
                {clientName}
            </Typography>
        </Header>
    );
};

export default HeaderComponent;
