/* eslint-disable operator-linebreak */
import { Typography, styled } from '@mui/material';
import parse from 'html-react-parser';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { String, lowerCase } from 'lodash';
import { numberWithCommas } from '../../../../utils/charts';
import { GOAL_TYPE, GOAL_PRIORITY_PROBABILITY_OF_SUCCESS, GOAL_PRIORITY_TRANSLATION_MAP } from '../../../../constants';
import { updateFormWithSuggestions } from '../../../../features/client-summary';
import { useTranslation } from 'react-i18next';
import { getWealthPathAdjustedWithInflations } from '../../../../utils/forms';

const ProbabilityContainer = styled(Typography)(() => ({
    lineHeight: '45px'
}));

const SuggestionsContainer = styled('div')(() => ({
    marginTop: '5px'
}));

const SuggestionsContentContainer = styled(Typography)(() => ({
    marginTop: '5px'
}));

const SuggestionsHeadingContainer = styled(Typography)(() => ({
    lineHeight: '35px',
    marginBottom: '10px'
}));

const OrContainer = styled(Typography)(() => ({}));

export const RecommendationMolecule = ({
    formField,
    currency,
    theme,
    translation,
    remainProb,
    goalProb,
    targetAmount,
    suggestionList,
    isModerateProb,
    isBadProb,
    goalType,
    response,
    request
}: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const suggestionValuesToUpdate = useAppSelector((state) => state.client.suggestionValuesToUpdate);
    const { inflationRate } = useAppSelector((state) => state.global.orgInfo);

    function createJsx(): JSX.Element[] {
        return suggestionList?.map(
            (
                suggestion: {
                    label: any;
                    value: string;
                    fieldName?: string;
                    textAfterValue?: String;
                },
                i: number,
                suggestionList: [{ label: any; value: string; fieldName?: string; textAfterValue?: string }]
            ) => (
                <div key={i}>
                    <SuggestionsContentContainer variant="h5">
                        {suggestion.label}{' '}
                        {suggestion.value ? (
                            <span
                                style={{
                                    color: theme.palette.recommendation.amount,
                                    borderBottom: `1px solid ${theme.palette.recommendation.amountBorderBottom}`,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    const newSuggestionValuesToUpdate = {
                                        ...suggestionValuesToUpdate
                                    };

                                    if (
                                        (suggestion.fieldName || '').toLowerCase().includes('investment') ||
                                        (suggestion.fieldName || '').toLowerCase().includes('contribution')
                                    ) {
                                        newSuggestionValuesToUpdate[suggestion.fieldName || ''] = numberWithCommas(
                                            formField[suggestion.fieldName || ''] + Number(suggestion.value)
                                        );
                                    } else if ((suggestion.fieldName || '').toLowerCase().includes('withdrawal')) {
                                        newSuggestionValuesToUpdate[suggestion.fieldName || ''] = numberWithCommas(
                                            formField[suggestion.fieldName || ''] - Number(suggestion.value)
                                        );
                                    } else {
                                        newSuggestionValuesToUpdate[suggestion.fieldName || ''] = numberWithCommas(
                                            suggestion.value
                                        );
                                    }

                                    dispatch(updateFormWithSuggestions(newSuggestionValuesToUpdate));
                                }}
                            >
                                {currency}
                                {numberWithCommas(+suggestion.value)}
                            </span>
                        ) : null}
                        {suggestion.textAfterValue ? parse(`${' '}${suggestion.textAfterValue}`) : null}
                    </SuggestionsContentContainer>
                    {suggestionList.length - 1 !== i ? (
                        <OrContainer variant="h5">{t('TEXT_RECOMMENDATIONS_SEPARATOR')}</OrContainer>
                    ) : null}
                </div>
            )
        );
    }
    let probTextColor;
    if (isBadProb) {
        probTextColor = theme.palette.wealthPath.badProb;
    } else if (isModerateProb) {
        probTextColor = theme.palette.wealthPath.moderateProb;
    } else {
        probTextColor = theme.palette.wealthPath.goodProb;
    }

    const wealthPathData: any = getWealthPathAdjustedWithInflations(
        response?.pathReport?.wealthPath || [],
        inflationRate || 0,
        'years',
        request?.currDate
    );

    return (
        <div data-testid="goe-recommendations">
            {!isBadProb &&
                (targetAmount ? (
                    <ProbabilityContainer variant="h4" sx={{ fontWeight: 700 }}>
                        {parse(
                            translation('TEXT_RECOMMENDATION_PLAN_PROBABILITY_MSG_WITH_TARGET_AMOUNT', {
                                goalProb: `<span style="backgroundColor: ${probTextColor}; color: ${
                                    theme.palette.common.white
                                }; border-radius: 4px; border: 1px solid ${probTextColor}; padding: 2px 5px;">${numberWithCommas(
                                    goalProb
                                )}%</span>`,
                                remainProb: `<span style="backgroundColor: ${probTextColor}; color: ${
                                    theme.palette.common.white
                                }; border-radius: 4px; border: 1px solid ${probTextColor}; padding: 2px 5px;">${numberWithCommas(
                                    remainProb
                                )}%</span>`,
                                targetAmount: `${currency}${numberWithCommas(targetAmount)}`,
                                goalType:
                                    goalType === GOAL_TYPE.FUTURE_EVENT
                                        ? translation('TEXT_FUTURE_EVENT_OR_ACTIVITY')
                                        : goalType === GOAL_TYPE.GENERATE_INCOME
                                        ? translation('TEXT_GENERATE_INCOME')
                                        : goalType === GOAL_TYPE.RETIREMENT
                                        ? translation('TEXT_RETIREMENT_SAVINGS')
                                        : 'Invalid Goal Type',
                                finalAmount: `${currency}${numberWithCommas(
                                    wealthPathData?.[wealthPathData?.length - 1].toFixed(2)
                                )}`,
                                lossThreshold: `${currency}${numberWithCommas(
                                    response?.analysisReport?.lossThreshold?.toFixed(2)
                                )}`
                            })
                        )}
                    </ProbabilityContainer>
                ) : (
                    <ProbabilityContainer variant="h4" sx={{ fontWeight: 700 }}>
                        {parse(
                            translation('TEXT_RECOMMENDATION_PLAN_PROBABILITY_MSG', {
                                goalProb: `<span style="backgroundColor: ${probTextColor}; color: ${
                                    theme.palette.common.white
                                }; border-radius: 4px; border: 1px solid ${probTextColor}; padding: 2px 5px;">${numberWithCommas(
                                    goalProb
                                )}%</span>`,
                                goalType:
                                    goalType === GOAL_TYPE.FUTURE_EVENT
                                        ? translation('TEXT_FUTURE_EVENT_OR_ACTIVITY')
                                        : goalType === GOAL_TYPE.GENERATE_INCOME
                                        ? lowerCase(translation('TEXT_GENERATE_INCOME'))
                                        : goalType === GOAL_TYPE.RETIREMENT
                                        ? translation('TEXT_RETIREMENT_SAVINGS')
                                        : 'Invalid Goal Type'
                            })
                        )}
                    </ProbabilityContainer>
                ))}
            {isBadProb && (
                <ProbabilityContainer variant="body1" sx={{ lineHeight: '25px' }}>
                    {translation('TEXT_RECOMMENDATION_GOAL_UNREALISTIC_MSG')}.
                </ProbabilityContainer>
            )}

            {isModerateProb && (
                <ProbabilityContainer variant="h4" sx={{ fontWeight: 700 }}>
                    {parse(
                        translation('TEXT_RECOMMENDATION_PLAN_PROBABILITY_MSG_PARA_2', {
                            goalPriority: translation(
                                GOAL_PRIORITY_TRANSLATION_MAP[
                                    formField.goalPriority as 'Need' | 'Want' | 'Dream' | 'Wish'
                                ]
                            ),
                            threshold:
                                GOAL_PRIORITY_PROBABILITY_OF_SUCCESS[
                                    formField.goalPriority as 'Need' | 'Want' | 'Dream' | 'Wish'
                                ]
                        })
                    )}
                </ProbabilityContainer>
            )}

            {isModerateProb && (
                <SuggestionsContainer>
                    <SuggestionsHeadingContainer variant="h5">
                        {translation('TEXT_RECOMMENDATION_IMPROVE_GOAL_PROB_MSG')}
                    </SuggestionsHeadingContainer>
                    {createJsx()}
                </SuggestionsContainer>
            )}
        </div>
    );
};
