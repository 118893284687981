import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

import TabsComponent from '../../components/molecules/tabs/admin-tabs';
import AdvisorAdminClientsTable from '../../containers/advisor-admin-clients-table';
import AdminDashboardSummary from '../../containers/admin-dashboard';
import AdvisorAdminUserManagement from '../../containers/advisor-admin-user-management';
import FTAdmin from '../../containers/ft-admin';
import { TENANT_ID_MAP } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';

const isAdvisorPortal =
    TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'advisorportaldev'] === 'default';

export const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.primary.dark,
    margin: '36px 0 40px 0',
    display: 'inline-block'
}));

export default function AdvisorAdminDashboard() {
    const location: any = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if (location.state?.tabIndex) {
            setTabIndex(location.state?.tabIndex);
        }
    }, [location]);

    const handleTabChange = (event: any, newValue: React.SetStateAction<number>) => {
        if (location.state?.tabIndex) {
            navigate('/admin', { state: null });
        }

        setTabIndex(newValue);
    };
    const tabs = [
        {
            label: t('TEXT_ADMIN_TAB_1'),
            Component: <AdminDashboardSummary />
        },
        {
            label: t('TEXT_ADMIN_TAB_2'),
            Component: <AdvisorAdminClientsTable />
        },
        {
            label: t('TEXT_ADMIN_TAB_3'),
            Component: <AdvisorAdminUserManagement />
        }
    ];

    if (isAdvisorPortal) {
        tabs.push({
            label: t('TEXT_ADMIN_TAB_5'),
            Component: <FTAdmin />
        });
    }

    return (
        <>
            <HeaderText tabIndex={0} variant="h1">
                {t('TEXT_ADMIN_DASHBOARD_HEADER')}
            </HeaderText>
            <TabsComponent tabs={tabs} value={tabIndex} handleChange={handleTabChange} />
        </>
    );
}
