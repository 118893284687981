import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { InputBaseProps } from '@mui/material/InputBase';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface cssProps {
    value?: any;
    error?: boolean;
}

const getBackgroundColor = (theme: any, value?: any, error?: boolean) => {
    if (error) {
        return theme.palette.goal.formFields.errorBackground;
    }

    if (value) {
        return theme.palette.goal.formFields.background;
    }

    return theme.palette.common.white;
};

const getBorderStyle = (theme: any, value?: any, error?: boolean) => {
    if (error) {
        return `1px solid ${theme.palette.goal.formFields.errorBackground}`;
    }

    if (value) {
        return `1px solid ${theme.palette.primary.main}`;
    }

    return `1px solid ${theme.palette.common.black}`;
};

const BootstrapFormControl = styled(FormControl)<cssProps>(({ theme, value, error }) => ({
    width: '100%',
    display: 'flex',
    input: {
        fontSize: '1rem',
        '&::placeholder': {
            color: theme.palette.common.black,
            fontSize: '0.9rem',
            opacity: 1
        }
    },

    '.Mui-error': {
        // background: error ? theme.palette.goal.formFields.errorBackground : ''
    },
    '.MuiFormControl-root.MuiFormHelperText-root': {
        marginTop: '-6px 0'
    },

    '.MuiInputBase-formControl': {
        background: getBackgroundColor(theme, value, error),
        minHeight: '40px'
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: getBorderStyle(theme, value, error)
    },
    '.MuiFormHelperText-root': {
        marginTop: '-6px 0'
    }
}));

export const BootstrapInputLabel = styled(Typography)<cssProps>(({ theme, error }) => ({
    display: 'flex',
    position: 'relative',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    color: error ? theme.palette.goal.formFields.errorLabel : 'black',
    flexGrow: 1,
    width: '100%',
    // '& :first-child': {
    //   flexGrow: 1
    // },
    '.MuiInputBase-root': {
        width: '100%'
    }
}));
const DatePickerContainer = styled(LocalizationProvider)(() => ({}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    alignSelf: 'flex-end',
    marginTop: '-30px',
    padding: '4px 7px',
    zIndex: '2',
    // '.Mui-error .MuiSvgIcon-root': {
    //   display: 'none'
    // },
    '.MuiSvgIcon-root': {
        fontSize: '18px',
        color: theme.palette.primary.main
    }
}));
// const ErrorSpan = styled('span')(({ theme }) => ({
//   color: theme.palette.goal.formFields.errorLabel,
//   fontSize: '12px'
// }));
interface InputProps extends InputBaseProps {
    label?: string;
    onChange?: any;
    mask?: string;
    error?: boolean;
    inputFormat?: string;
    show?: number;
    disablePicker?: boolean;
    views?: any;
    maxDate?: moment.Moment;
    minDate?: moment.Moment;
}
export default function BasicDatePicker(props: InputProps) {
    const { t } = useTranslation();
    const { label, inputFormat, value, views, mask, error, onChange, onBlur, disablePicker, minDate, disabled } = props;

    return (
        <BootstrapFormControl variant="standard" error={error} value={value}>
            <BootstrapInputLabel variant="subtitle2" error={error} sx={{ fontSize: '0.75rem' }}>
                {label} {label && error ? ` - *${t('TEXT_REQUIRED')}` : ''}
            </BootstrapInputLabel>
            <DatePickerContainer dateAdapter={AdapterMoment}>
                <DatePicker
                    views={views}
                    value={value}
                    inputFormat={inputFormat}
                    onChange={onChange}
                    mask={mask}
                    disableMaskedInput={false}
                    disableOpenPicker={disablePicker}
                    renderInput={(params) => <TextField onBlur={onBlur} {...params} error={error} />}
                    minDate={minDate}
                    disabled={disabled}
                />
                {value && !error && disablePicker ? (
                    <SearchIconWrapper>
                        <CheckIcon />
                    </SearchIconWrapper>
                ) : (
                    ''
                )}
            </DatePickerContainer>
        </BootstrapFormControl>
    );
}
