/* eslint-disable operator-linebreak */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { styled, Avatar, CircularProgress, Box } from '@mui/material';
import MaskedInput from 'react-input-mask';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { HeaderTypography } from '../../components/molecules/color-picker';
import ButtonComponent from '../../components/atoms/button';
import Input from '../../components/atoms/input';
import InfoBox from './infoBox';
import { updateSelectedUser, updateUser } from '../../features/advisor-admin';
import SelectComponent from '../../components/atoms/select';
import { ACCESS_LEVEL_OPTIONS } from '../../constants';
import { BootstrapInputLabel } from '../../components/atoms/input';
import { toShortDate } from '../../utils/timestamp';

interface FormInput {
    fullName: string;
    email: string;
    phoneNumber: string;
    accessLevel: string;
}

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    border: `1px solid ${theme.palette.grey[600]}`,
    padding: '20px',
    width: '70%'
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    alignSelf: 'center',
    height: '120px',
    width: '120px',
    fontSize: '60px',
    backgroundColor: theme?.palette?.layout?.primary
}));

const RightContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 20px'
}));

const ButtonContainer = styled('span')(() => ({
    display: 'flex',
    gap: '5px',
    margin: '6px',
    alignSelf: 'end'
}));

const Form = styled('form')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
}));

const Fields = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
}));

const ErrorSpan = styled('span')(({ theme }) => ({
    color: theme.palette.goal.formFields.errorLabel,
    fontSize: '12px'
}));

const AdvisorSummary = () => {
    const dispatch = useAppDispatch();
    const selectedUser = useAppSelector((state) => state.advisorAdmin.selectedUser);
    const tenant = useAppSelector((state: any) => state.auth.user.tenant);
    const subdomain = useAppSelector((state: any) => state.global?.globalConfig?.subdomain);
    const avatarString =
        selectedUser?.userType === 'admin'
            ? selectedUser?.adminName?.substring(0, 1)
            : selectedUser?.advisorName?.substring(0, 1);
    const [advisorValues, setAdvisorValues] = useState<any>({
        ...selectedUser,
        fullName: selectedUser?.userType === 'admin' ? selectedUser?.adminName : selectedUser?.advisorName,
        advisorEmail: selectedUser?.userType === 'admin' ? selectedUser?.adminEmail : selectedUser?.advisorEmail,
        advisorPhoneNumber:
            selectedUser?.userType === 'admin' ? selectedUser?.adminPhoneNumber : selectedUser?.advisorPhoneNumber,
        accessLevel: selectedUser?.userType,
        createdOn: selectedUser?.createdOn
    });
    const [onEditing, setOnEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<FormInput>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues:
            selectedUser?.userType === 'admin'
                ? {
                      fullName: selectedUser?.adminName,
                      email: selectedUser?.adminEmail,
                      phoneNumber: selectedUser?.adminPhoneNumber,
                      accessLevel: selectedUser?.userType
                  }
                : {
                      fullName: selectedUser?.advisorName,
                      email: selectedUser?.advisorEmail,
                      phoneNumber: selectedUser?.advisorPhoneNumber,
                      accessLevel: selectedUser?.userType
                  }
    });

    const createdOnDate = selectedUser?.createdOn ? toShortDate(selectedUser.createdOn) : t('TEXT_NOT_FOUND');

    const onSubmit = async (values: FormInput) => {
        setLoading(true);
        await dispatch(
            updateUser({
                firstName: values.fullName,
                phoneNumber: values.phoneNumber,
                role: values.accessLevel,
                tenant,
                id: selectedUser?.id,
                createdOn: createdOnDate
            })
        )
            .then((success: any) => {
                if (success) {
                    dispatch(
                        updateSelectedUser({
                            ...selectedUser,
                            userType: values.accessLevel,
                            ...(values.accessLevel !== 'admin'
                                ? {
                                      advisorName: values.fullName,
                                      advisorPhoneNumber: values.phoneNumber,
                                      advisorEmail: values.email
                                  }
                                : {
                                      adminName: values.fullName,
                                      adminPhoneNumber: values.phoneNumber,
                                      adminEmail: values.email
                                  })
                        })
                    );
                }
            })
            .finally(() => {
                setAdvisorValues({
                    ...selectedUser,
                    fullName: values.fullName,
                    advisorPhoneNumber: values.phoneNumber,
                    advisorEmail: values.email,
                    accessLevel: values.accessLevel
                });
                setLoading(false);
                setOnEditing(false);
            });
    };

    return (
        <>
            <HeaderTypography variant="body1" style={{ margin: '30px 0' }}>
                {t('ADMIN_ADVISOR_SUMMARY_HEADER')}
            </HeaderTypography>
            <Wrapper>
                <StyledAvatar>{avatarString}</StyledAvatar>
                <RightContainer>
                    {!onEditing && (
                        <>
                            <div>
                                <InfoBox
                                    title={
                                        selectedUser?.userType === 'admin'
                                            ? t('ADMIN_SUMMARY_FIRST_INFO_TITLE')
                                            : t('ADMIN_ADVISOR_SUMMARY_FIRST_INFO_TITLE')
                                    }
                                >
                                    {advisorValues.fullName}
                                </InfoBox>
                                <InfoBox
                                    title={
                                        selectedUser?.userType === 'admin'
                                            ? t('ADMIN_SUMMARY_SECOND_INFO_TITLE')
                                            : t('ADMIN_ADVISOR_SUMMARY_SECOND_INFO_TITLE')
                                    }
                                >
                                    {advisorValues?.advisorEmail}
                                </InfoBox>
                                <InfoBox title={t('ADMIN_ADVISOR_SUMMARY_THIRD_INFO_TITLE')}>
                                    {advisorValues?.advisorPhoneNumber || t('TEXT_NOT_FOUND')}
                                </InfoBox>
                                <InfoBox title={t('USER_SUMMARY_ACCESS_LEVEL_TITLE')}>
                                    {
                                        ACCESS_LEVEL_OPTIONS(t)?.find((x) => x.value === advisorValues?.accessLevel)
                                            ?.label
                                    }
                                </InfoBox>
                                <InfoBox title={t('USER_SUMMARY_CREATE_ON_TITLE') + ` : ${createdOnDate}`} />
                            </div>
                            <ButtonContainer>
                                <ButtonComponent
                                    variant="contained"
                                    onClick={() => {
                                        setOnEditing(true);
                                    }}
                                >
                                    {t('BUTTON_EDIT')}
                                </ButtonComponent>
                            </ButtonContainer>
                        </>
                    )}
                    {onEditing && (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Fields>
                                <Controller
                                    name="fullName"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('TEXT_ADMIN_ADVISOR_FULL_NAME_VALIDATION')
                                        },
                                        pattern: {
                                            value: /^[0-9A-Za-zñáéíóúü_ ]*$/i,
                                            message: t('TEXT_ADMIN_ADVISOR_FULL_NAME_LETTER_VALIDATION')
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: t('TEXT_ADMIN_ADVISOR_NAME_MAX_VALIDATION_TEXT')
                                        },
                                        minLength: {
                                            value: 2,
                                            message: t('TEXT_ADMIN_ADVISOR_NAME_MIN_VALIDATION_TEXT')
                                        }
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            inputRef={ref}
                                            error={!!errors.fullName}
                                            label={t('ADMIN_ADVISOR_SUMMARY_FIRST_INFO_TITLE')}
                                            type="text"
                                            disabled
                                        />
                                    )}
                                />
                                {errors.fullName && <ErrorSpan role="alert">{errors.fullName.message}</ErrorSpan>}
                                <Controller
                                    name="email"
                                    control={control}
                                    aria-invalid={errors.email ? 'true' : 'false'}
                                    rules={{
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: t('TEXT_ADMIN_ADVISOR_EMAIL_VALIDATION')
                                        }
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Input
                                            name="advisorEmail"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            inputRef={ref}
                                            error={!!errors.email}
                                            label={t('ADMIN_ADVISOR_SUMMARY_SECOND_INFO_TITLE')}
                                            type="email"
                                            CustomEndIcon={<CircularProgress size="24px" />}
                                            disabled
                                        />
                                    )}
                                />

                                {errors.email && <ErrorSpan role="alert">{errors?.email?.message}</ErrorSpan>}
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    rules={{
                                        required: false,
                                        pattern: {
                                            value:
                                                subdomain === 'highpoint'
                                                    ? /^[2-9]{2}\s[0-9]{2}\s[0-9]{4}-[0-9]{4}$/i
                                                    : /^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$/i,
                                            message: t('TEXT_ADMIN_ADVISOR_PHONE_NUMBER_VALIDATION')
                                        }
                                    }}
                                    render={({ field }) => (
                                        <MaskedInput
                                            mask={subdomain === 'highpoint' ? '99 99 9999-9999' : '999-999-9999'}
                                            value={field.value}
                                            onChange={field.onChange}
                                        >
                                            {(inputProps: any) => (
                                                <Input
                                                    {...inputProps}
                                                    error={!!errors.fullName}
                                                    label={t('ADMIN_ADVISOR_SUMMARY_THIRD_INFO_TITLE')}
                                                    type="text"
                                                />
                                            )}
                                        </MaskedInput>
                                    )}
                                />
                                {errors.phoneNumber && <ErrorSpan role="alert">{errors.phoneNumber.message}</ErrorSpan>}

                                <Controller
                                    name="accessLevel"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Access level is required'
                                        }
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <SelectComponent
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            inputRef={ref}
                                            error={!!errors.accessLevel}
                                            autoWidth={false}
                                            multiple={false}
                                            native={false}
                                            label={t('TEXT_ADMIN_ACCESS_LEVEL')}
                                            data={ACCESS_LEVEL_OPTIONS(t)}
                                            placeholder={t('TEXT_DROP_DOWN_PLACEHOLDER')}
                                            id="accessLevel"
                                            customheight="unset"
                                        />
                                    )}
                                />
                                {errors.accessLevel && <ErrorSpan role="alert">{errors.accessLevel.message}</ErrorSpan>}

                                <BootstrapInputLabel>Created On: {createdOnDate}</BootstrapInputLabel>
                            </Fields>
                            <ButtonContainer>
                                <ButtonComponent
                                    variant="outlined"
                                    onClick={() => {
                                        setOnEditing(false);
                                    }}
                                >
                                    {t('BUTTON_CANCEL')}
                                </ButtonComponent>
                                {!loading && (
                                    <ButtonComponent variant="contained" type="submit">
                                        {t('BUTTON_SAVE')}
                                    </ButtonComponent>
                                )}
                                {loading && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                        <CircularProgress />
                                    </Box>
                                )}
                            </ButtonContainer>
                        </Form>
                    )}
                </RightContainer>
            </Wrapper>
        </>
    );
};

export default AdvisorSummary;
