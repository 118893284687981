/* eslint-disable indent */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
// import axios from '../../axios';
import { logout } from '../auth/authSlice';

export type userType = {
    id: string | number;
    firstName: string;
    lastName: string;
    accessLevel: string;
    emailAddress: string;
    username: string;
    lastLogin: string;
};

type InitialState = {
    users: Array<userType>;
    activeTabIndex: number;
    loading: boolean;
    error?: null | undefined | any;
    userSelected?: userType;
};

const initialState: InitialState = {
    users: [],
    activeTabIndex: 0,
    loading: false,
    error: null,
    userSelected: undefined
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await axios.get('server/users/users.json');
    return response.data;
});

export const fetchSearchUsers = createAsyncThunk('users/fetchSearchUsers', async (searchString: string) => {
    const response = await axios.get(`/users?q=${searchString}`);
    return response.data;
});

const userManagement = createSlice({
    name: 'userManagementState',
    initialState,
    reducers: {
        updateUsers: (state, action: PayloadAction<any>) => {
            state.users = action.payload;
        },
        updateActiveTabIndex: (state, action: PayloadAction<any>) => {
            state.activeTabIndex = action.payload;
        },
        selectUser: (state, action: PayloadAction<any>) => {
            state.userSelected = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(fetchUsers.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchUsers.fulfilled, (state) => {
                state.loading = false;
                state.error = '';
            }),
            builder.addCase(fetchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }),
            builder.addCase(fetchSearchUsers.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(fetchSearchUsers.fulfilled, (state) => {
                state.loading = false;
                state.error = '';
            }),
            builder.addCase(fetchSearchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { updateUsers, updateActiveTabIndex, selectUser } = userManagement.actions;

export default userManagement.reducer;
