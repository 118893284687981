import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FinancialAdvisorTable from './financialAdvisorTable';
import ButtonComponent from '../../atoms/button';
import { CtaContainer, ModalContent } from '../../molecules/modal/styles';
import Modal from '../../molecules/modal';

export default function FinancialAdvisor({
    financialAdvisors,
    deleteModalState,
    columns,
    handleCloseDeleteModal,
    advisorSelected,
    handleRemoveFinancialAdvisor
}: any) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNewAdvisor = () => {
        navigate('/add-user', { state: { fromAdvisor: true } });
    };

    return (
        <>
            <FinancialAdvisorTable
                data={financialAdvisors}
                columns={columns}
                button={t('FINANCIAL_ADVISORS_ADD_NEW_ADVISOR')}
                buttonOnClick={handleNewAdvisor}
            />
            <Modal handleClose={handleCloseDeleteModal} open={deleteModalState}>
                <ModalContent id="modal-modal-title" variant="body1">
                    {t('REMOVE_ADVISOR_MODAL_CONFIRMATION_TEXT', {
                        advisorName: advisorSelected.advisorName
                    })}
                </ModalContent>
                <CtaContainer>
                    <ButtonComponent variant="outlined" size="medium" onClick={() => handleCloseDeleteModal()}>
                        {t('TEXT_NO')}
                    </ButtonComponent>
                    <ButtonComponent variant="contained" size="medium" onClick={() => handleRemoveFinancialAdvisor()}>
                        {t('TEXT_YES')}
                    </ButtonComponent>
                </CtaContainer>
            </Modal>
        </>
    );
}
