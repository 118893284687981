import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import serverSideTableProposals from '../features/my-clients/server-side-dashboard-table-slice';
import clientSideTableProposals from '../features/my-clients/client-side-dashboard-table-slice';
import personalizedPlan from '../features/personalized-plan';
import editGoal from '../features/edit-goal';
import globalReducer from '../features/global/globalSlice';
import clientSummaryReducer from '../features/client-summary';
import authReducer from '../features/auth/authSlice';
import addClientReducer from '../features/add-client/add-client';
import userManagementReducer from '../features/user-management';
import translationsReducer from '../features/admin-manage-translation';
import advisorAdmin from '../features/advisor-admin';
import advisorAdminFA from '../features/advisor-admin-fa';
import financialAdvisors from '../features/financialAdvisors';
import messaging from '../features/messaging/messagingSlice';

const persistConfig = {
    key: 'root',
    storage: storageSession
};

const reducers = combineReducers({
    serverSideTableProposals,
    personalizedPlan,
    clientSideTableProposals,
    editGoal,
    global: globalReducer,
    client: clientSummaryReducer,
    auth: authReducer,
    addClient: addClientReducer,
    users: userManagementReducer,
    translations: translationsReducer,
    advisorAdmin,
    advisorAdminFA,
    financialAdvisors,
    messaging
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        })
});

export const persistor = persistStore(store);
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
