import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { isEmpty } from 'lodash';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ADMIN_USER_ACCESS_KEYS } from '../../constants';
import FinancialAdvisorTable from '../../components/organisms/financialAdvisors/financialAdvisorTable';
import ButtonComponent from '../../components/atoms/button';
import Modal from '../../components/molecules/modal';
import { CtaContainer, ModalContent } from '../../components/molecules/modal/styles';
import { HeaderTypography } from '../../components/molecules/color-picker';
import { fetchAdminsAndAdvisors, deleteUser } from '../../features/advisor-admin';
import { updateGlobalLoaderState } from '../../features/global/globalSlice';
import { StyledTableContainer } from '../../components/organisms/financialAdvisors/financialAdvisorTable';
import { updateSelectedUser, updateSelectedFinancialAdvisorClients } from '../../features/advisor-admin';

const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '22px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
}));

const TableWrapper = styled('div')(() => ({
    marginTop: '20px',
    width: '50%'
}));

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
    cursor: 'pointer'
}));

function AdvisorAdminUserManagement() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [removeAdminPopup, setRemoveAdminPopup] = React.useState(false);
    const admins = useAppSelector((state: any) => state.advisorAdmin.admins || []);
    const loading = useAppSelector((state: any) => state.advisorAdmin.loading);
    const tenant = useAppSelector((state: any) => state.auth.user.tenant);
    const clients = useAppSelector((state) => state.advisorAdmin.clients);

    useEffect(() => {
        dispatch(fetchAdminsAndAdvisors());
    }, []);

    const handleRemove = (admin: any) => {
        setRemoveAdminPopup(admin);
    };

    const handleClose = () => setRemoveAdminPopup(false);

    const handleAdminAdvisorNavigate = async (advisor: any) => {
        if (!isEmpty(clients)) {
            const payloadData = await clients.filter((value: any) => value.advisorEmail === advisor.adminEmail);
            dispatch(updateSelectedFinancialAdvisorClients(payloadData));
        }
        dispatch(updateSelectedUser(advisor));
        navigate('/user');
    };

    const columns = [
        {
            accessorKey: ADMIN_USER_ACCESS_KEYS.NAME,
            id: ADMIN_USER_ACCESS_KEYS.NAME,
            header: t('ADMIN_USER_MANAGEMENT_TABLE_NAME_COLUMN'),
            cell: ({ row }: any) => (
                <StyledLink onClick={() => handleAdminAdvisorNavigate(row.original)}>
                    {`${row.original.adminName}`}
                </StyledLink>
            )
        },
        {
            accessorKey: ADMIN_USER_ACCESS_KEYS.EMAIL,
            id: ADMIN_USER_ACCESS_KEYS.EMAIL,
            header: t('ADMIN_USER_MANAGEMENT_TABLE_EMAIL_COLUMN'),
            cell: ({ row }: any) => row.original.adminEmail
        },
        {
            accessorKey: ADMIN_USER_ACCESS_KEYS.REMOVE,
            id: 'remove',
            header: t('ADMIN_USER_MANAGEMENT_TABLE_REMOVE_COLUMN'),
            enableSorting: false,
            cell: ({ row }: any) => (
                <StyledLink onClick={() => handleRemove(row.original)}>
                    {t('TEXT_ADMIN_USER_MANAGEMENT_REMOVE_BUTTON')}
                </StyledLink>
            )
        }
    ];

    const handleAdminDelete = async () => {
        dispatch(updateGlobalLoaderState(true));

        const userId: string = (removeAdminPopup as any).id;
        await dispatch(deleteUser({ userId, tenant }));
        setRemoveAdminPopup(false);

        dispatch(updateGlobalLoaderState(false));
    };

    const handleNewAdmin = () => {
        navigate('/add-user');
    };

    return (
        <Wrapper>
            <HeaderTypography variant="body1">{t('ADMIN_USER_MANAGEMENT_HEADER')}</HeaderTypography>

            {loading ? (
                <TableWrapper>
                    <StyledTableContainer
                        sx={{
                            minHeight: '440px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '50%'
                        }}
                    >
                        <CircularProgress />
                    </StyledTableContainer>
                </TableWrapper>
            ) : (
                <TableWrapper>
                    <FinancialAdvisorTable
                        data={admins}
                        columns={columns}
                        button={t('ADD_NEW_ADMIN_USER_BUTTON')}
                        buttonOnClick={handleNewAdmin}
                    />
                </TableWrapper>
            )}

            {admins.length > 1 && (
                <Modal handleClose={handleClose} open={removeAdminPopup}>
                    <ModalContent id="modal-modal-title" variant="body1">
                        {t('REMOVE_ADMIN_USER_MODAL_CONFIRMATION_TEXT')}
                    </ModalContent>
                    <CtaContainer>
                        <ButtonComponent variant="outlined" size="medium" onClick={handleClose}>
                            {t('TEXT_NO')}
                        </ButtonComponent>
                        <ButtonComponent variant="contained" size="medium" onClick={handleAdminDelete}>
                            {t('TEXT_YES')}
                        </ButtonComponent>
                    </CtaContainer>
                </Modal>
            )}
            {admins.length === 1 && (
                <Modal handleClose={handleClose} open={removeAdminPopup}>
                    <Typography id="modal-modal-title" variant="h2">
                        {t('UNABLE_TO_REMOVE_ADMIN_USER_MODAL_TITLE')}
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6">
                        {t('UNABLE_TO_REMOVE_ADMIN_USER_MODAL_TEXT')}
                    </Typography>
                    <CtaContainer>
                        <ButtonComponent variant="contained" size="medium" onClick={handleClose}>
                            {t('LEAVING_FRANKLIN_MODAL_CLOSE_BUTTON')}
                        </ButtonComponent>
                    </CtaContainer>
                </Modal>
            )}
        </Wrapper>
    );
}

export default AdvisorAdminUserManagement;
