/* eslint-disable operator-linebreak */
import { useEffect, useState, useMemo, memo, useLayoutEffect } from 'react';
import { isEmpty, merge } from 'lodash';
import { ThemeProvider, styled, createTheme } from '@mui/material/styles';
import Favicon from 'react-favicon';
import CssBaseline from '@mui/material/CssBaseline';
import { ErrorBoundary } from 'react-error-boundary';
import { Security } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { palette, ttCommonsPro, typography, breakpoints } from './styles/themes';
import HeaderComponent from './components/organisms/header';
import FooterComponent from './components/organisms/footer';
import { useAppSelector, useAppDispatch } from './app/hooks';
import ScrollToTop from './routes/scrollToTop';
import SkipToContent from './components/molecules/skipToContent';
import ErrorBoundaryComponent from './components/organisms/error-boundary';
import ErrorPopup from './components/molecules/error-modal';
import AppRoutes from './routes/Routes';
import EulaContainer from './containers/eula';
import { getTenantBasedConfig, updateGlobalLoaderState, logEventToBackEnd } from './features/global/globalSlice';
import GlobalLoader from './components/molecules/global-loader';
import { TENANT_ID_MAP, OKTA_DEVICE_TOKEN_KEY } from './constants';
import oktaAuth from './services/configs/oktaAuth';
import Timeout from './components/molecules/timeout';
import SnackBar from './components/molecules/snackbar';
import ReleaseBanner from './components/molecules/release-banner';

const MainSectionContainer: any = styled('main')(({ isAuthenticated }: any) => {
    if (!isAuthenticated) {
        return {
            minHeight: '74vh'
        };
    }

    return {
        minHeight: '74vh',
        margin: '0 35px 150px 35px'
    };
});

const makeUseMemoRun = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

function App() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [updatedPalette, setUpdatedPalette] = useState(palette);
    const [isChanged, setIsChanged] = useState(makeUseMemoRun);
    const { i18n } = useTranslation();

    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

    const { error, showErrorModal, test, loading, globalLoader } = useAppSelector((state) => ({
        ...state.global
    }));

    const globalConfig = useAppSelector((state) => state.global.globalConfig);

    useLayoutEffect(() => {
        if (isEmpty(globalConfig?.styles)) {
            dispatch(updateGlobalLoaderState(true));
        }
    }, []);

    useEffect(() => {
        // Set the Website Title
        const tenants = Object.keys(TENANT_ID_MAP);

        const subDomains = tenants.filter((x) => document?.location?.host.includes(x));

        const subDomain = subDomains?.length ? subDomains[subDomains.length - 1] : 'advisorportaldev';

        document.title = subDomain.toUpperCase();

        // if (isEmpty(globalConfig?.styles)) {
        dispatch(getTenantBasedConfig({ tenantId: TENANT_ID_MAP[subDomain], i18n }));
        // }

        // Check if device token is set and set a new one if its not set per domain
        const deviceToken = localStorage.getItem(OKTA_DEVICE_TOKEN_KEY);

        if (!deviceToken) {
            const uuid = uuidv4();

            localStorage.setItem(OKTA_DEVICE_TOKEN_KEY, uuid.substring(0, 32));
        }
    }, []);

    useEffect(() => {
        if (globalConfig?.styles) {
            const updated = merge(updatedPalette, globalConfig?.styles?.config);
            setUpdatedPalette(updated);
            setIsChanged(makeUseMemoRun);
        }
    }, [globalConfig?.styles?.config, test]);

    const theme = useMemo(
        () =>
            createTheme({
                palette: updatedPalette,
                typography,
                breakpoints,
                components: {
                    MuiCssBaseline: {
                        styleOverrides: {
                            '@font-face': [ttCommonsPro]
                        }
                    },
                    MuiButtonBase: {
                        defaultProps: {
                            disableRipple: true
                        },
                        styleOverrides: {
                            root: {
                                textTransform: 'none'
                            }
                        }
                    },
                    MuiCheckbox: {
                        defaultProps: {
                            disableRipple: true
                        }
                    }
                }
            }),
        [isChanged, updatedPalette]
    );
    useEffect(() => {
        if (!isEmpty(error) && showErrorModal) {
            setShowErrorPopup(showErrorModal);
        } else {
            setShowErrorPopup(showErrorModal as any);
        }
    }, [error, showErrorModal]);

    const customAuthHandler = () => {
        navigate('/');
    };

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '', window.location.origin), { replace: true });
    };

    if (globalLoader) {
        return (
            <ThemeProvider theme={theme}>
                <GlobalLoader open />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary
                FallbackComponent={ErrorBoundaryComponent}
                onError={(error: Error, info: { componentStack: string }) => {
                    console.log(error, info);
                    dispatch(logEventToBackEnd('UI_CRASH'));
                }}
            >
                <Favicon url={globalConfig?.favicon || ''} />
                <SkipToContent />
                <Security
                    oktaAuth={oktaAuth}
                    onAuthRequired={customAuthHandler}
                    restoreOriginalUri={restoreOriginalUri}
                >
                    <ReleaseBanner />
                    <HeaderComponent />
                    <CssBaseline />
                    <MainSectionContainer id="main" isAuthenticated={isAuthenticated}>
                        <ScrollToTop />
                        <AppRoutes />
                        <ErrorPopup open={showErrorPopup} loading={loading} />
                        <EulaContainer />
                    </MainSectionContainer>
                    <FooterComponent />
                    {isAuthenticated && <Timeout />}
                    <SnackBar />
                </Security>
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default memo(App);
