import { styled, useTheme } from '@mui/material/styles';

const BarLabel = styled('span')(() => ({}));

const Label = ({
    label,
    value,
    showPercentageOnBar = true
}: // color
{
    label: string | any;
    value: number | any;
    showPercentageOnBar: boolean;
    color: string;
}) => {
    const theme = useTheme();
    const [ticker] = JSON.parse(label);
    const { black } = theme.palette.common;

    return (
        <div
            style={{
                display: 'inline-flex',
                alignItems: 'center'
                // backgroundColor: color,
                // borderRadius: 2,
                // border: `1px solid ${color}`
            }}
        >
            <BarLabel
                style={{
                    color: black,
                    fontSize: '12px'
                    // textShadow: name === EQUITY ? '0px 0px 3px black' : 'none'
                }}
            >
                {ticker}
            </BarLabel>
            {showPercentageOnBar ? (
                <>
                    {/* <br /> */}
                    <BarLabel
                        style={{
                            color: black,
                            fontWeight: 400,
                            fontSize: '12px',
                            paddingLeft: '10px'
                            // textShadow: name === EQUITY ? '0px 0px 3px black' : 'none'
                        }}
                    >
                        {`${value}%`}
                    </BarLabel>
                </>
            ) : null}
        </div>
    );
};

export default Label;
