import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, IconButton, Collapse } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

import WarningIcon from './WarningIcon.svg';
import { useAppDispatch } from '../../../app/hooks';
import { getReleaseBanner } from '../../../features/global/globalSlice';

const LOCALSTORAGE_ITEM_KEY = 'GOE_APP_BANNER_DISMISSED';

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 20px'
}));

type BannerMessageType = {
    bannerEndTime: string;
    bannerMessage: string;
    bannerStartTime: string;
    bannerStatus: string;
};

export default function ReleaseBanner() {
    const dispatch = useAppDispatch();

    const [showBannerMessage, setShowBannerMessage] = useState<boolean>(false);
    const [bannerMessageContent, setBannerMessageContent] = useState<BannerMessageType>({
        bannerEndTime: '',
        bannerMessage: '',
        bannerStartTime: '',
        bannerStatus: ''
    });

    function shouldDisplayBanner(banner: {
        bannerStartTime: string | number | Date;
        bannerEndTime: string | number | Date;
        bannerStatus: string;
    }) {
        const currentTime = new Date('12/29/2023 16:00:00');

        const startTime = new Date(banner.bannerStartTime);
        const endTime = new Date(banner.bannerEndTime);

        const isTimeInRange = currentTime >= startTime && currentTime <= endTime;

        return banner.bannerStatus === 'active' && isTimeInRange;
    }

    useEffect(() => {
        dispatch(getReleaseBanner()).then(({ payload }) => {
            if (shouldDisplayBanner(payload)) {
                const isBannerDismissed = sessionStorage.getItem(LOCALSTORAGE_ITEM_KEY);

                if (isBannerDismissed !== 'Y') {
                    setShowBannerMessage(true);
                    setBannerMessageContent(payload);
                }
            }
        });
    }, []);

    const handleBannerClose = () => {
        sessionStorage.setItem(LOCALSTORAGE_ITEM_KEY, 'Y');
        setShowBannerMessage(false);
    };

    return (
        <Collapse in={showBannerMessage}>
            <Container>
                <div
                    style={{
                        width: '98%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <img src={WarningIcon} />
                    <Typography variant="body1" sx={{ padding: '10px' }}>
                        {bannerMessageContent.bannerMessage}
                    </Typography>
                </div>

                <div style={{ width: '2%' }}>
                    <IconButton aria-label="close-release-banner-message" onClick={handleBannerClose}>
                        <CloseOutlined />
                    </IconButton>
                </div>
            </Container>
        </Collapse>
    );
}
