import Grid from '@mui/material/Grid';
import { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function SkeletonLoading() {
    const [barCount] = useState(3);
    return (
        <Stack spacing={1}>
            {Array.from(Array(barCount), (_, index) => (
                <div style={{ marginBottom: '20px' }} key={index}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} lg={3}>
                            <Skeleton variant="rectangular" width="100%" height={40} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Skeleton variant="rectangular" width="100%" height={40} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Skeleton variant="rectangular" width="100%" height={40} />
                        </Grid>
                        <Grid item xs={12} md={2} lg={3}>
                            <Skeleton variant="rectangular" width="100%" height={40} />
                        </Grid>
                    </Grid>
                </div>
            ))}
        </Stack>
    );
}

export default SkeletonLoading;
