import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setAutoFreeze } from 'immer';

// import mockApiInstance from '../../axios';
import { uipApiInstance } from '../../axios';
import { logout } from '../auth/authSlice';
import { updateClient } from '../client-summary';
import { logEventToBackEnd } from '../global/globalSlice';

setAutoFreeze(false);
type InitialState = {
    clientSummary: any;
    activeTabIndex: number;
    formField: any;
    response: any;
    request: any;
    portfolioComposition: Array<any>;
    portfolioCompositionYearLabel: number;
    equity: number | string;
    fixedIncome: number | string;
    loading: boolean;
    remodelLoading: boolean;
    saveLoading: boolean;
    error?: null | undefined | any;
    goalEdited?: boolean;
};

const initialState: InitialState = {
    clientSummary: {},
    formField: {},
    response: {},
    request: {},
    portfolioComposition: [],
    portfolioCompositionYearLabel: 0,
    equity: '',
    fixedIncome: '',
    activeTabIndex: 0,
    loading: false,
    remodelLoading: false,
    saveLoading: false,
    error: null,
    goalEdited: false
};

export const updateGoal = createAsyncThunk(
    'editGoal/updateGoal',
    async (payload: any, { dispatch, rejectWithValue }) => {
        try {
            await uipApiInstance.post('/api/advisorportal/updateproposal', payload);
            return true;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('UPDATE_PROPOSAL_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

const editGoal = createSlice({
    name: 'editGoal',
    initialState,
    reducers: {
        updateProfolioCompositionData: (state, action: PayloadAction<any>) => {
            state.portfolioComposition = action.payload.data;
            state.portfolioCompositionYearLabel = action?.payload?.year || '';
            (state.equity = action.payload.equity || ''), (state.fixedIncome = action.payload.fixedIncome || '');
        },
        resetProfolioCompositionData: (state) => {
            state.portfolioComposition = [];
        },
        updateResponse: (state, action: PayloadAction<any>) => {
            state.response = action.payload;
        },
        updateRequest: (state, action: PayloadAction<any>) => {
            state.request = action.payload;
        },
        updateFormField: (state, action: PayloadAction<any>) => {
            state.formField = action.payload;
        },
        updateClientSummary: (state, action: PayloadAction<any>) => {
            state.clientSummary = action.payload;
        },
        updateActiveTabIndex: (state, action: PayloadAction<any>) => {
            state.activeTabIndex = action.payload;
        },
        updateRemodelLoader: (state, action: PayloadAction<any>) => {
            state.remodelLoading = action.payload;
        },
        updateEditLoader: (state, action: PayloadAction<any>) => {
            state.saveLoading = action.payload;
        },
        updateGoalEdited: (state, action: PayloadAction<any>) => {
            state.goalEdited = action.payload;
        },
        reseteditGoalReducer: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState),
            builder.addCase(updateGoal.pending, (state) => {
                state.saveLoading = true;
            }),
            builder.addCase(updateGoal.fulfilled, (state, action) => {
                state.saveLoading = false;
                state.response = action.payload;
                state.error = '';
            }),
            builder.addCase(updateGoal.rejected, (state, action) => {
                state.saveLoading = false;
                state.response = [];
                state.error = action.error;
            });
        builder.addCase(updateClient.pending, (state) => {
            state.saveLoading = true;
        }),
            builder.addCase(updateClient.fulfilled, (state) => {
                state.saveLoading = false;
                state.error = '';
            }),
            builder.addCase(updateClient.rejected, (state, action) => {
                state.saveLoading = false;
                state.error = action.error;
            });
    }
});

export const {
    updateProfolioCompositionData,
    resetProfolioCompositionData,
    updateResponse,
    reseteditGoalReducer,
    updateRequest,
    updateFormField,
    updateActiveTabIndex,
    updateClientSummary,
    updateRemodelLoader,
    updateEditLoader,
    updateGoalEdited
} = editGoal.actions;

export default editGoal.reducer;
