/* eslint-disable operator-linebreak */
/* eslint-disable brace-style */
/* eslint-disable guard-for-in */
// @ts-nocheck
import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';

export const range = (x: number, min: number, max: number) => x >= min && x <= max;
export const numberWithCommas = (x: any) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export function calculatePaginationEntries(totalCount: number, pageNumber: number, pageLimit: number) {
    return {
        lastPage: Math.ceil(totalCount / pageLimit),
        from: (pageNumber - 1) * pageLimit + 1,
        to: pageNumber * pageLimit < totalCount ? pageNumber * pageLimit : totalCount
    };
}
export const getCurrentYear = () => {
    const year = moment().year();

    return year.toString();
};
export function formatLastLoginDate(dateVal: string | number | Date) {
    const newDate = moment(dateVal);

    const sMonth = padValue(newDate.month());
    const sDay = padValue(newDate.date());
    const sYear = newDate.year();
    let sHour: any = newDate.hours();
    const sMinute = padValue(newDate.minutes());
    const sSeconds = newDate.seconds();
    let sAMPM = 'AM';

    const iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
        sAMPM = 'PM';
        sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
        sHour = '12';
    }

    sHour = padValue(sHour);

    return `${sMonth}/${sDay}/${sYear} ${sHour}:${sMinute}:${sSeconds} ${sAMPM}`;
}

export function formatLastLoggedIn(dateVal: string | number | Date) {
    if (!isEmpty(dateVal) && !isUndefined(dateVal)) {
        let lastLoggedIn = dateVal;
        const lastLoggedInMomentObj = moment.utc(lastLoggedIn);
        lastLoggedIn = lastLoggedInMomentObj.isValid()
            ? lastLoggedInMomentObj.local().format('MM/DD/YYYY hh:mm:ss a')
            : lastLoggedIn;

        return lastLoggedIn;
    }
    return '';
}

function padValue(value: number | string) {
    return value < 10 ? `0${value}` : value;
}

export function getInitials(name: string): string {
    let initials;
    const splittedName: any = name.split(' ');

    if (!isUndefined(splittedName) && splittedName.length > 1) {
        initials = splittedName.shift().charAt(0) + splittedName.pop().charAt(0);
    } else {
        initials = name.substring(0, 2);
    }
    return initials.toUpperCase();
}

export function getAdvisorName(name: string): string {
    // let advisorName;
    // const splittedName = name.split(' ');
    // if (splittedName.length > 1) {
    //   advisorName = `${
    //     splittedName[1].charAt(0).toUpperCase() + splittedName[1].slice(1)
    //   }, ${splittedName[0].charAt(0).toUpperCase()}${splittedName[0].slice(1)}`;
    // } else {
    //   advisorName = splittedName[0].charAt(0).toUpperCase() + splittedName[0].slice(1);
    // }
    return name;
}

export function formatClientName(data: any) {
    return !isEmpty(data) && data?.clientFirstName && data.clientLastName
        ? `${data.clientFirstName} ${data.clientLastName}`
        : '';
}

export function formatAdvisorName(data: any) {
    return !isEmpty(data) && data?.userType === 'admin' ? data?.adminName || '' : data?.advisorName || '';
}

export function getMonthName(monthNumber: number) {
    const date = moment();
    date.set('month', monthNumber - 1);
    return date.format('MMMM');
}

export const hex2rgba = (hex: any, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x: string) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const flatten = (obj, prefix) => {
    // store the result
    let output = {};

    // iterate the object
    for (const k in obj) {
        const val = obj[k];

        // new key
        const newKey = prefix ? `${prefix}.${k} ` : k;

        // array and object both are object in js
        if (typeof val === 'object') {
            // if it is array
            if (Array.isArray(val)) {
                // use rest & spread together to convert
                // array to object
                const { ...arrToObj } = val;
                const newObj = flatten(arrToObj, newKey);
                output = { ...output, ...newObj };
            }
            // if it is object
            else {
                const newObj = flatten(val, newKey);
                output = { ...output, ...newObj };
            }
        }
        // normal value
        else {
            output = { ...output, [newKey]: val };
        }
    }

    return output;
};
