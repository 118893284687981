import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { StyledTableCell, StyledTableRow, LoaderContainer, Container } from './styles';
import CircularLoader from './circularLoader';
import { useTranslation } from 'react-i18next';

const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export default function TableSkeletonLoader() {
    const { t } = useTranslation();

    const columnsTranslation = {
        client_full_name: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_1'),
        client_email: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_2'),
        advisor_name: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_3'),
        goals: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_4'),
        portfolio_value: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_5'),
        last_modified: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_6')
    };

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table
                    sx={{
                        '& .MuiTableRow-root:hover': {
                            backgroundColor: 'table.row.hover'
                        }
                    }}
                    stickyHeader
                    aria-label="caption table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="left">{columnsTranslation.client_full_name}</StyledTableCell>
                            <StyledTableCell align="left">{columnsTranslation.client_email}</StyledTableCell>
                            <StyledTableCell align="left">{columnsTranslation.advisor_name}</StyledTableCell>
                            <StyledTableCell>{columnsTranslation.goals}</StyledTableCell>
                            <StyledTableCell align="left">{columnsTranslation.portfolio_value}</StyledTableCell>
                            <StyledTableCell align="left">{columnsTranslation.last_modified}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((_, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row">
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <LoaderContainer>
                <CircularLoader />
            </LoaderContainer>
        </Container>
    );
}
