import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { useAppSelector } from '../../app/hooks';

const Wrapper = styled('div')(() => ({}));

const AdvisorInfo = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    p: {
        margin: '2px'
    }
}));

const Footer = styled('footer')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
}));

const HR = styled('div')(({ theme }) => ({
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey[400],
    margin: '10px 0'
}));

const Disclaimer = styled(Typography)(() => ({}));

const Contact = ({ advisorName, advisorEmail }: { advisorName: string; advisorEmail: string }) => {
    const orgInfo = useAppSelector((state) => state.global.orgInfo);
    const subDomain = document?.location?.host?.split('.')?.reverse()?.[2] || 'highpoint';
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Typography variant="h2" sx={{ marginBottom: '20px' }}>
                {t('TEXT_PDF_CONTACT_ADVISOR_HEADER')}
            </Typography>
            <AdvisorInfo>
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    {advisorName}
                </Typography>
                <Typography variant="h5">{t('TEXT_PDF_FINANCIAL_ADVISOR_HEADER')}</Typography>
                {/* <p>{orgInfo.phoneNumber}</p> */}
                <Typography variant="body1">{advisorEmail}</Typography>
            </AdvisorInfo>
            <HR />
            <Disclaimer>{parse(t('TEXT_PDF_BOTTOM_DISCLAIMER'))}</Disclaimer>
            <HR />
            <Footer>
                <Typography>{subDomain?.toUpperCase()}</Typography>
                <Typography>{orgInfo.address}</Typography>
            </Footer>
        </Wrapper>
    );
};

export default Contact;
