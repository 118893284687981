/* eslint-disable operator-linebreak */
import { memo, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import WealthPathOrganism from '../../components/organisms/charts/wealth-path';
import { GOAL_TYPE, WITHDRAWAL_FREQUENCY, RECOMMENDED, SCHEDULED_FREQUENCY, TENANT_ID_MAP } from '../../constants';
import { annualDistributioncategories } from '../../utils/charts';
import { useAppSelector } from '../../app/hooks';
import { getWealthPathAdjustedWithInflations } from '../../utils/forms';

const isHighPoint =
    TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'advisorportaldev'] === 'highpoint';

const WealthPathContainer = ({
    showHeader,
    showLoader,
    showFooter,
    showAmount,
    chartHeight,
    request,
    response,
    formField,
    ...rest
}: any) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const [xAxisCategories, setXAxisCategories] = useState([]);
    const [wealthPathData, setWealthPathData] = useState([]);
    const [annualDistribution, setAnnualDistribution] = useState([]);
    const [isModerateProb, setIsModerateProb] = useState(false);
    const [isBadProb, setIsBadProb] = useState(false);
    const [isIncomeFlow, setIsIncomeFlow] = useState(false);
    const [isFutureEvent, setFutureEvent] = useState(false);
    const [isRetirementFlow, setRetirementFlow] = useState(false);
    const [targetAmount, setTargetAmount] = useState(null);
    const [goalProbAt75, setGoalProbAt75] = useState<number | any>(null);
    const [goalProbAt50, setGoalProbAt50] = useState<number | any>(null);
    const [goalProb, setGoalProb] = useState<number | any>(null);
    const { enableSourceCaveatNumbers, inflationRate } = useAppSelector((state) => state.global.orgInfo);

    function setProbabilityType() {
        let isModerateProb = false;
        let isBadProb = false;
        const pathReport = response?.analysisReport;
        const meetGoalPriority = pathReport?.meetGoalPriority;
        const isGoalRealistic = pathReport?.isGoalRealistic;
        if (meetGoalPriority && isGoalRealistic) {
            isModerateProb = false;
            isBadProb = false;
        } else if (!meetGoalPriority && isGoalRealistic) {
            isModerateProb = true;
            isBadProb = false;
        } else if (!meetGoalPriority && !isGoalRealistic) {
            isBadProb = true;
            isModerateProb = false;
        }
        setIsModerateProb(isModerateProb);
        setIsBadProb(isBadProb);
    }
    useEffect(() => {
        if (!isEmpty(response) && !isEmpty(request)) {
            const years =
                request?.annualDistribution?.years.map((val: { toString: () => string }) => val.toString()) || [];
            setXAxisCategories(years as any);

            const wealthPathData: any = getWealthPathAdjustedWithInflations(
                response?.pathReport?.wealthPath || [],
                inflationRate || 0,
                formField.scheduledFrequency === SCHEDULED_FREQUENCY.MONTHLY ? 'months' : 'years',
                request?.currDate
            );

            const annualDistribution =
                WITHDRAWAL_FREQUENCY.YEARLY === formField.withdrawalFrequency
                    ? request?.annualDistribution?.amount
                    : WITHDRAWAL_FREQUENCY.MONTHLY === formField.withdrawalFrequency
                    ? annualDistributioncategories(request?.tempInfusions)
                    : SCHEDULED_FREQUENCY.YEARLY === formField.scheduledFrequency
                    ? request?.annualDistribution?.amount
                    : SCHEDULED_FREQUENCY.MONTHLY === formField.scheduledFrequency
                    ? annualDistributioncategories(request?.tempInfusions)
                    : []; // setAnnualDistribution(annualDistribution as any);

            if (!isEmpty(annualDistribution)) {
                const data = annualDistribution?.map((_: any, index: number) =>
                    annualDistribution?.slice(0, index + 1).reduce((a: any, b: any) => a + b)
                );
                setAnnualDistribution(data as any);
            }

            if (response?.analysisReport && response?.analysisReport[RECOMMENDED.PROB_50]) {
                setGoalProbAt50(Math.round(response?.analysisReport[RECOMMENDED.PROB_50] * 100));
            }

            if (response?.analysisReport && response?.analysisReport[RECOMMENDED.PROB_75]) {
                setGoalProbAt75(Math.round(response?.analysisReport[RECOMMENDED.PROB_75] * 100));
            }

            setWealthPathData(wealthPathData as any);
            setGoalProb(Math.round(response?.analysisReport?.currentGoalProbability * 100));
            setProbabilityType();
            setIsIncomeFlow(formField?.goalType === GOAL_TYPE.GENERATE_INCOME);
            setFutureEvent(formField?.goalType === GOAL_TYPE.FUTURE_EVENT);
            setRetirementFlow(formField?.goalType === GOAL_TYPE.RETIREMENT);
            setTargetAmount(formField?.goalAmount);
        }
    }, [response, request]);

    return (
        <WealthPathOrganism
            theme={theme}
            currency={isHighPoint ? 'MXN$' : '$'}
            xAxisCategories={xAxisCategories}
            wealthPathData={wealthPathData}
            annualDistribution={annualDistribution}
            targetAmount={targetAmount}
            translation={t}
            isModerateProb={isModerateProb}
            isBadProb={isBadProb}
            goalProb={goalProb}
            isIncomeFlow={isIncomeFlow}
            isFutureEvent={isFutureEvent}
            isRetirementFlow={isRetirementFlow}
            showHeader={showHeader}
            showFooter={showFooter}
            showAmount={showAmount}
            showLoader={showLoader}
            chartHeight={chartHeight}
            goalProbAt75={goalProbAt75}
            goalProbAt50={goalProbAt50}
            response={response}
            RECOMMENDED={RECOMMENDED}
            enableSourceCaveatNumbers={enableSourceCaveatNumbers}
            {...rest}
        />
    );
};

export default memo(WealthPathContainer);
