/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
import { memo, useState, useEffect, useMemo } from 'react';
import { isEmpty, isUndefined, cloneDeep } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useTheme } from '@mui/material/styles';

import { ColumnDef } from '@tanstack/react-table';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
    StyledExpandableKeyboardArrow,
    ScoreCircleIcon,
    // PointerCursor,
    CellSpacing,
    ExpandableIconContainer,
    ExpandableIcon,
    AlignTableCell,
    ViewLink
} from '../../components/organisms/client-side-dashboard-table/styles';
import { numberWithCommas, addCurrencySymbol, getScoreAndGoalPriority, getScoreColor } from '../../utils/table';
import { TableData } from '../../components/organisms/client-side-dashboard-table/types';
import DataTableOrganism from '../../components/organisms/client-side-dashboard-table';
import {
    fetchClientSideProposal,
    updateDeleteId,
    updateDeleteModalState,
    deleteClient,
    deleteGoal,
    updateClientSummaryModalState
} from '../../features/my-clients/client-side-dashboard-table-slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
// import useDebounce from '../../hooks/useDebounce';
import { PAGINATION, ACCESSOR_KEYS } from '../../constants';
import { updateClientSummary, updateProfolioCompositionData } from '../../features/client-summary';
import Modal from '../../components/molecules/modal';
import { CtaContainer, ModalContent } from '../../components/molecules/modal/styles';
import ClientSummaryModalComponent from '../client-summary';
import TableSkeletonLoader from '../../components/organisms/client-side-dashboard-table/loader';
import ButtonComponent from '../../components/atoms/button';
import {
    updateAccordionState,
    updateClientInfoData,
    updateClientId,
    updateClientInfoHelperText,
    updaterRiskToleranceHelperText,
    selectRiskToleranceInArray,
    updateInvestorGoalData,
    updateRequestPayload
} from '../../features/add-client/add-client';
import { RISK_PROFILE_MAP } from '../../constants';
import { goalObj } from '../../services/configs/configs';
import { updateActiveTabIndex } from '../../features/global/globalSlice';
import { formatTableData } from '../../utils/table';

function DataTableContainer() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { state: navigationState }: any = useLocation();
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [clientSummaryState, setClientSummaryState] = useState(false);
    const [unStructuredData, setUnstructuredData] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [columnVisibile, setColumnVisible] = useState({});
    const navigate = useNavigate();
    const theme = useTheme();

    // const ADVISORS = t('TEXT_ADVISORS');
    // const CLIENTS = t('TEXT_CLIENTS');

    const {
        actualData,
        tableData,
        managerGroupedData,
        advisorGroupedData,
        defaultColumnVisibility,
        columnVisibility,
        showDeleteModal,
        deleteId,
        clientSummaryModalState,
        loading,
        triggerdDelete
    } = useAppSelector((state) => state.clientSideTableProposals || []);
    const loadingUpdate = useAppSelector((state) => state.client.loading);

    const clientSummary = useAppSelector((state) => state.client.clientSummary);
    const formField = useAppSelector((state) => state.client.formField);
    const request = useAppSelector((state: { client: { request: any } }) => state.client.request);
    const response = useAppSelector((state: { client: { response: any } }) => state.client.response);
    const portfolioData = useAppSelector((state) => state?.global?.portfolio || []);
    const portfolioComposistionChartData = useAppSelector((state) => state?.client?.portfolioComposition || []);
    const portfolioCompositionYearLabel = useAppSelector((state) => state?.client?.portfolioCompositionYearLabel || '');
    const portfolioComposistionEquityValue = useAppSelector((state) => state?.client?.equity || '');
    const portfolioComposistionFixedIncomeValue = useAppSelector((state) => state?.client?.fixedIncome || '');

    useEffect(() => {
        setDeleteModalState(showDeleteModal as any);
    }, [deleteId, showDeleteModal]);

    useEffect(() => {
        setClientSummaryState(clientSummaryModalState);
    }, [clientSummaryModalState, clientSummary]);

    useEffect(() => {
        if (!isEmpty(managerGroupedData)) {
            setData(managerGroupedData);
        } else if (!isEmpty(advisorGroupedData)) {
            setData(advisorGroupedData);
        } else {
            setData(tableData);
            setUnstructuredData(actualData);
        }
    }, [tableData, managerGroupedData, advisorGroupedData, actualData]);

    useEffect(() => {
        if (!isEmpty(columnVisibility)) {
            setColumnVisible(columnVisibility);
        } else {
            setColumnVisible(defaultColumnVisibility);
        }
    }, [columnVisibility]);

    useEffect(() => {
        dispatch(fetchClientSideProposal()).then((res: any) => {
            if (res.payload && navigationState?.clientEmail) {
                const requiredTableData = formatTableData(res.payload.data, true);
                const requiredActualData = res.payload.data;
                const clientSummaryToSelect = requiredTableData.find(
                    (x: any) => x.id.clientEmail === navigationState?.clientEmail
                );

                if (clientSummaryToSelect) {
                    const clientDate = requiredActualData.find(
                        (v: any) => v?.clientEmail === navigationState?.clientEmail
                    );
                    const formFields = clientDate?.formFields;
                    if (!isEmpty(formFields)) {
                        dispatch(updateClientSummary(clientDate));
                        dispatch(updateActiveTabIndex(formFields.length - 1));
                        dispatch(updateClientSummaryModalState(true));
                        window.history.replaceState({}, document.title);
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
        if (triggerdDelete) {
            dispatch(fetchClientSideProposal());
        }
    }, [triggerdDelete]);

    const columnsTranslation = {
        manager_name: t('HEADER_TABLE_MANAGER_NAME'),
        advisor_name: t('HEADER_TABLE_ADVISOR_NAME'),
        client_name: t('HEADER_TABLE_CLIENT_NAME'),
        client_full_name: t('HEADER_TABLE_CLIENT_FULL_NAME'),
        risk_tolerance: t('HEADER_TABLE_RISK_TOLERANCE'),
        goal_priority: t('HEADER_TABLE_GOAL_PRIORITY'),
        goal_name: t('HEADER_TABLE_GOAL_NAME'),
        proposal_value: t('HEADER_TABLE_PORTFOLIO_VALUE'),
        goals: t('HEADER_TABLE_GOALS'),
        score: t('HEADER_TABLE_SCORE'),
        last_modified: t('HEADER_TABLE_LAST_MODIFIED'),
        equity_fixed_income: t('HEADER_TABLE_EQUITY_FIXED_INCOME'),
        delete: t('HEADER_TABLE_DELETE'),
        view: t('HEADER_TABLE_VIEW'),
        add_goal: t('TEXT_ADD_GOAL'),
        not_found: t('TEXT_NOT_FOUND')
    };

    const handleClientGoalSummaryClick = (row: any, index: number) => {
        if (!isEmpty(unStructuredData)) {
            const data = unStructuredData.find((v) => v?.clientEmail === row.original.id?.clientEmail);
            if (row.depth >= 1) {
                index = index;
            } else {
                index = 0;
            }
            const formFields = data?.formFields;
            if (!isEmpty(formFields)) {
                dispatch(updateClientSummary(data));
                dispatch(updateActiveTabIndex(index));
                dispatch(updateClientSummaryModalState(true));
            }
        }
    };

    const handleAddClientClick = () => {
        dispatch(updateActiveTabIndex(0));
        navigate('/add-client');
    };

    const handleDelete = (data: any) => {
        dispatch(updateDeleteId(data));
        dispatch(updateDeleteModalState(true));
    };
    const addGoal = (row: any) => {
        if (!isEmpty(unStructuredData)) {
            const data = unStructuredData.find((v) => v?.clientEmail === row.original.id?.clientEmail);
            const clientName = data?.clientFirstName + (data?.clientLastName ? ' ' + data?.clientLastName : '');
            const obj = {
                investorFullName: clientName,
                investorEmail: data?.clientEmail,
                investorDOB: data?.clientDOB
            };
            dispatch(updateClientInfoData(obj));
            dispatch(updateClientId(data.assignedClientId));
            dispatch(updateClientInfoHelperText(clientName));
            const riskToleranceFromServer = data.riskTolerance?.toLowerCase();

            if (riskToleranceFromServer && RISK_PROFILE_MAP[riskToleranceFromServer]) {
                dispatch(updaterRiskToleranceHelperText(RISK_PROFILE_MAP[riskToleranceFromServer]));
                dispatch(selectRiskToleranceInArray(RISK_PROFILE_MAP[riskToleranceFromServer]));
                const obj2 = cloneDeep(goalObj);
                dispatch(updateInvestorGoalData([obj2]));
                dispatch(updateRequestPayload([obj2]));
            }

            dispatch(
                updateAccordionState(riskToleranceFromServer && RISK_PROFILE_MAP[riskToleranceFromServer] ? 2 : 1)
            );
            dispatch(updateActiveTabIndex(0));
            navigate('/add-client');
        }
    };
    const columns = useMemo<ColumnDef<TableData>[]>(
        () => [
            {
                accessorKey: ACCESSOR_KEYS.CLIENT_FULL_NAME,
                id: ACCESSOR_KEYS.CLIENT_FULL_NAME,
                header: columnsTranslation.client_full_name,
                cell: ({ row, getValue }) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <>
                            <ExpandableIconContainer>
                                {row.getCanExpand() ? (
                                    <ExpandableIcon onClick={row.getToggleExpandedHandler()}>
                                        {row.getIsExpanded() ? (
                                            <StyledExpandableKeyboardArrow as={KeyboardArrowDownIcon} />
                                        ) : (
                                            <StyledExpandableKeyboardArrow as={KeyboardArrowRight} />
                                        )}
                                    </ExpandableIcon>
                                ) : (
                                    <CellSpacing> </CellSpacing>
                                )}
                            </ExpandableIconContainer>

                            {!isEmpty(getValue()) ? (
                                <span style={{ marginBottom: '5px' }}>{getValue() as string}</span>
                            ) : null}
                        </>
                    </div>
                )
            },
            {
                accessorKey: ACCESSOR_KEYS.RISK_TOLERANCE,
                id: ACCESSOR_KEYS.RISK_TOLERANCE,
                header: columnsTranslation.risk_tolerance,
                cell: ({ getValue }) => {
                    const riskProfile = getValue() as string;
                    return <>{!isEmpty(riskProfile) ? riskProfile[0].toUpperCase() + riskProfile.slice(1) : null}</>;
                }
            },
            {
                accessorKey: ACCESSOR_KEYS.GOAL_NAME,
                enableSorting: false,
                id: ACCESSOR_KEYS.GOAL_NAME,
                header: columnsTranslation.goal_name,
                cell: ({ getValue, row }) => {
                    if (!isEmpty(getValue())) {
                        return <>{getValue() as string}</>;
                    }
                    if (row?.original?.showAddGoalButton) {
                        return <ViewLink onClick={() => addGoal(row)}>{columnsTranslation.add_goal}</ViewLink>;
                    }

                    return <span>-</span>;
                }
            },
            {
                accessorKey: ACCESSOR_KEYS.GOAL_PRIORITY,
                id: ACCESSOR_KEYS.GOAL_PRIORITY,
                header: columnsTranslation.goal_priority,
                cell: ({ getValue }) => {
                    if (!isEmpty(getValue())) {
                        return <>{getValue() as 'Want' | 'Need' | 'Wish' | 'Dream'}</>;
                    }

                    return '-';
                }
            },
            {
                accessorKey: ACCESSOR_KEYS.INITIAL_INVESTMENT,
                id: ACCESSOR_KEYS.GOAL_AMOUNT,
                header: columnsTranslation.proposal_value,
                cell: ({ getValue, row }) =>
                    (getValue() || getValue() === 0) && !row.original.showAddGoalButton ? (
                        <AlignTableCell position="end">
                            {addCurrencySymbol(numberWithCommas(getValue()))}
                        </AlignTableCell>
                    ) : row.original.showAddGoalButton ? (
                        <AlignTableCell position="end">{columnsTranslation.not_found}</AlignTableCell>
                    ) : (
                        ''
                    )
            },
            {
                accessorKey: ACCESSOR_KEYS.GOALS,
                id: ACCESSOR_KEYS.GOALS,
                header: columnsTranslation.goals,
                cell: ({ getValue, row }) =>
                    getValue() ? (
                        <AlignTableCell position="center">{getValue() as string | number}</AlignTableCell>
                    ) : row.original.showAddGoalButton ? (
                        <AlignTableCell position="center">{columnsTranslation.not_found}</AlignTableCell>
                    ) : (
                        ''
                    )
            },
            {
                accessorKey: ACCESSOR_KEYS.SCORE,
                id: ACCESSOR_KEYS.SCORE,
                header: columnsTranslation.score,
                cell: ({ getValue, row }) => (
                    <>
                        {!row.original.goalPriority &&
                        !isEmpty(row.originalSubRows) &&
                        (getValue() || getValue() === 0) ? (
                            <AlignTableCell position="center">
                                <ScoreCircleIcon
                                    color={getScoreColor(
                                        getScoreAndGoalPriority(row.original, row.originalSubRows),
                                        theme
                                    )}
                                >
                                    {getValue()}
                                </ScoreCircleIcon>
                            </AlignTableCell>
                        ) : (
                            <>
                                {getValue() || getValue() === 0 ? (
                                    <AlignTableCell position="center">
                                        <ScoreCircleIcon
                                            color={getScoreColor(
                                                {
                                                    score: row.original.score,
                                                    goalPriority: row.original.goalPriority
                                                },
                                                theme
                                            )}
                                        >
                                            {getValue()}
                                        </ScoreCircleIcon>
                                    </AlignTableCell>
                                ) : row.original.showAddGoalButton ? (
                                    <AlignTableCell position="center">{columnsTranslation.not_found}</AlignTableCell>
                                ) : (
                                    <AlignTableCell position="center">-</AlignTableCell>
                                )}
                            </>
                        )}
                    </>
                )
            },
            {
                accessorKey: ACCESSOR_KEYS.LAST_MODIFIED,
                id: ACCESSOR_KEYS.LAST_MODIFIED,
                header: columnsTranslation.last_modified,
                enableGlobalFilter: true,
                cell: ({ getValue, row }) => {
                    if (!isUndefined(getValue()) && !isEmpty(getValue())) {
                        const date = getValue() as string;
                        return <AlignTableCell position="end">{date}</AlignTableCell>;
                    }
                    if (row?.original?.showAddGoalButton) {
                        return <AlignTableCell position="end">{columnsTranslation.not_found}</AlignTableCell>;
                    }
                }
            },
            {
                accessorKey: ACCESSOR_KEYS.EQUITY_FIXED_INCOME,
                enableSorting: false,
                id: ACCESSOR_KEYS.EQUITY_FIXED_INCOME,
                header: columnsTranslation.equity_fixed_income,
                cell: ({ getValue, row }) =>
                    !isEmpty(getValue()) ? (
                        <AlignTableCell position="center">{getValue() as string}</AlignTableCell>
                    ) : row.original.showAddGoalButton ? (
                        <AlignTableCell position="center">{columnsTranslation.not_found}</AlignTableCell>
                    ) : (
                        <AlignTableCell position="center">-</AlignTableCell>
                    )
            },
            {
                accessorKey: ACCESSOR_KEYS.ID,
                id: 'deleteIcon',
                header: columnsTranslation.delete,
                enableSorting: false,
                cell: ({ row, getValue }) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <>
                            {!row.original.showAddGoalButton && (
                                <ViewLink
                                    style={{ marginRight: '20px' }}
                                    onClick={() => handleClientGoalSummaryClick(row, row.index)}
                                >
                                    {columnsTranslation.view}
                                </ViewLink>
                            )}

                            {getValue() && (
                                <AlignTableCell position="center">
                                    <DeleteIcon onClick={() => handleDelete(row.original.id)} />
                                </AlignTableCell>
                            )}
                        </>
                    </div>
                )
            }
        ],
        [unStructuredData]
    );

    const handleCloseDeleteModal = () => {
        dispatch(updateDeleteModalState(false));
    };

    const handleCloseClientSummaryModal = () => {
        dispatch(updateClientSummaryModalState(false));
        dispatch(updateActiveTabIndex(0));
    };

    const handleClientGoalDelete = () => {
        if (deleteId.clientEmail && !deleteId.goal) {
            dispatch(deleteClient(deleteId.clientId));
            dispatch(updateDeleteModalState(false));
        } else if (deleteId.clientEmail && deleteId.goal) {
            dispatch(deleteGoal(deleteId.goal));
            dispatch(updateDeleteModalState(false));
        }
    };
    return (
        <>
            {!loading && !loadingUpdate ? (
                <>
                    <DataTableOrganism
                        data={data}
                        columns={columns}
                        handleAddClientClick={handleAddClientClick}
                        columnVisibility={columnVisibile}
                        pageLimit={PAGINATION.PAGE_LIMIT}
                    />

                    <Modal handleClose={handleCloseDeleteModal} open={deleteModalState}>
                        {deleteId.clientEmail && !deleteId.goal ? (
                            <ModalContent id="modal-modal-title" variant="body1">
                                {t('DESCRIPTION_CLIENT_AND_GOAL_DELETE_CONFIRMATION_TEXT')}
                            </ModalContent>
                        ) : (
                            <ModalContent id="modal-modal-title" variant="body1">
                                {t('DESCRIPTION_GOAL_DELETE_CONFIRMATION_TEXT')}
                            </ModalContent>
                        )}
                        <CtaContainer>
                            <ButtonComponent variant="outlined" size="medium" onClick={() => handleCloseDeleteModal()}>
                                {t('TEXT_NO')}
                            </ButtonComponent>
                            <ButtonComponent variant="contained" size="medium" onClick={() => handleClientGoalDelete()}>
                                {t('TEXT_YES')}
                            </ButtonComponent>
                        </CtaContainer>
                    </Modal>

                    <ClientSummaryModalComponent
                        open={clientSummaryState}
                        handleClose={handleCloseClientSummaryModal}
                        clientSummary={clientSummary}
                        formField={formField}
                        request={request}
                        response={response}
                        portfolioComposistionChartData={portfolioComposistionChartData}
                        portfolioComposistionEquityValue={portfolioComposistionEquityValue}
                        portfolioComposistionFixedIncomeValue={portfolioComposistionFixedIncomeValue}
                        portfolioCompositionYearLabel={portfolioCompositionYearLabel}
                        updateProfolioCompositionData={updateProfolioCompositionData}
                        portfolioData={portfolioData}
                    />
                </>
            ) : (
                <TableSkeletonLoader />
            )}
        </>
    );
}

export default memo(DataTableContainer);
