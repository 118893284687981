/* eslint-disable  */
/* eslint-disable guard-for-in */
// @ts-nocheck
import moment from 'moment';
import { isEqual } from 'lodash';
import { ADD_CLIENT_ANNUAL, FORMAT, INFUSION_ANNUAL } from '../constants';
import store from '../app/store';

export const delay = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export const fixedDate = '01/01/2022';
/* Table */

export const formatCurrency = (num: any, doNotAllowZero = true) => {
    let value = num.toString();
    value = value.replace(/[^.0-9]/g, '');

    if (doNotAllowZero) {
        value = value.replace(/\b0+/g, '');
    }

    let splittedValue = value.split('.');

    if (splittedValue[1]?.length > 2) {
        splittedValue[1] = splittedValue[1][0] + splittedValue[1][1];
        value = [splittedValue[0], splittedValue[1]].join('.');
    }

    if (splittedValue?.length > 2) {
        value = [splittedValue[0], splittedValue[1]].join('.');
    }

    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value;
};

export const removeNonNumeric = (num: any) => {
    return num?.toString().replace(/[^.0-9]/g, '');

    // if (Number(decimalValue) < 1) {
    //     return '0';
    // }

    // return num?.toString().replace(/[^0-9]/g, '');
};
export const removeNonNumericDecimals = (num: any) => num?.toString().replace(/[^0-9.]/g, '');
export const removeLeadingZeroes = (num: any) => num?.toString().replace(/\b0+/g, '');

export const numberWithCommas = (x: any) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const dateFormat = (x: any) => x.format('DD-MM-YYYY');
export const yearFormat = (x: any) => x.format('YYYY-MM-DD');
export const addCurrencySymbol = (value: number) => `$${value}`;

export const checkIfFormValuesAreEqual = (originalFormValues: any, changedFormValues: any) => {
    const comparisons = [];

    Object.keys(originalFormValues).forEach((key: string) => {
        if (typeof originalFormValues[key] === 'string' && typeof changedFormValues[key] === 'number') {
            comparisons.push(removeNonNumeric(originalFormValues[key]) == changedFormValues[key]);
        } else if (typeof originalFormValues[key] === 'number' && typeof changedFormValues[key] === 'string') {
            comparisons.push(originalFormValues[key] == removeNonNumeric(changedFormValues[key]));
        } else {
            comparisons.push(isEqual(originalFormValues[key], changedFormValues[key]));
        }
    });

    return !Boolean(comparisons.filter((x) => !x).length);
};

export const getCurrentYear = () => {
    const year = moment().year();

    return year.toString();
};
export const current_date = dateFormat(moment());
export const generateIncomeCalculation = (
    startingYear: number | string,
    startingMonth: number | string,
    withdrawalPeriod: number | string
) => {
    const current_date = dateFormat(moment());
    const current_year = Number(getCurrentYear());
    const goal_starting_Date = `${Number(startingYear)}-${
        startingMonth.length > 1 ? startingMonth : `0${startingMonth}`
    }-01`;
    const distributionStartDate = dateFormat(moment(goal_starting_Date, 'YYYY-MM-DD'));

    const monthlyInfusionStartingDate = yearFormat(moment().add(1, 'months').set('date', 1));

    const infusion_start_Date = yearFormat(moment());
    const infusion_end_Date = dateFormat(
        moment(distributionStartDate, 'DD-MM-YYYY').add(Number(withdrawalPeriod), 'years')
    );

    return {
        current_date,
        current_year,
        distributionStartDate,
        monthlyInfusionStartingDate,
        infusion_start_Date,
        infusion_end_Date
    };
};
export const generateIncomeInfusionArrayCalculation = (
    withdrawalFrequency: string,
    infusion_start_Date: string,
    infusion_end_Date: string,
    distributionStartDate: string,
    withdrawalPeriod: number | string,
    withdrawalAmount: number | string,
    monthlyInfusionStartingDate: string
) => {
    let goalEndYear;
    let goalEndDate;
    let infusions: any[] = [];
    let tempInfusions: any[] = [];

    if (withdrawalFrequency === ADD_CLIENT_ANNUAL) {
        goalEndDate = dateFormat(moment(distributionStartDate, FORMAT.DATE).add(Number(withdrawalPeriod) - 1, 'years'));

        goalEndYear = moment(goalEndDate, 'DD-MM-YYYY').year();

        const amount = withdrawalAmount;

        const { infusionArr, tempArr } = getYearlyInfusionsForIncome(
            -amount,
            infusion_start_Date,
            distributionStartDate,
            infusion_end_Date,
            distributionStartDate
        );
        infusions = infusionArr;
        tempInfusions = tempArr;

        // const monthString = (
        //     moment(distributionStartDate, FORMAT.DATE).get('month') + 1
        // ).toString();
        // const dateString = moment(distributionStartDate, FORMAT.DATE).get('date').toString();

        // const tempDateTime = `${moment(infusion_start_Date).get('year')}-${
        //     monthString.length > 1 ? monthString : `0${monthString}`
        // }-${dateString.length > 1 ? dateString : `0${dateString}`}`;

        // if (moment(tempDateTime).isAfter(infusion_start_Date, 'date')) {
        //     infusions.unshift(0);
        // }
    } else {
        goalEndDate = dateFormat(moment(distributionStartDate, FORMAT.DATE).add(withdrawalPeriod, 'years'));

        goalEndYear = moment(goalEndDate, 'DD-MM-YYYY').year();
        const amount = withdrawalAmount;
        infusions = getMonthlyInfusionArrayForIncome(
            -amount,
            monthlyInfusionStartingDate,
            distributionStartDate,
            infusion_end_Date,
            distributionStartDate
        ).infusionArr;
        tempInfusions = getMonthlyInfusionArrayForIncome(
            -amount,
            monthlyInfusionStartingDate,
            distributionStartDate,
            infusion_end_Date,
            distributionStartDate
        ).tempArr;
        infusions.unshift(0);
        tempInfusions.unshift({
            amount: 0,
            date: moment().format('DD-MM-YYYY')
        });
    }
    return { goalEndDate, goalEndYear, infusions, tempInfusions };
};

export const getInflatedAmount = (amount, inflationRate, currentDate, infusionDate, daysOrYears) =>
    amount *
    (1 + inflationRate / 100) ** (infusionDate.diff(currentDate, 'days') / (daysOrYears === 'months' ? 30 : 365));

export const getInfusionsAdjustedWithInflations = (
    tempInfusions: { amount: number; date: string }[],
    inflationRate: number,
    daysOrYears: 'months' | 'years',
    currDate: string
) => {
    const currentDate = moment(currDate, 'DD-MM-YYYY');

    return tempInfusions.map(({ amount, date }) => {
        const infusionDate = moment(date, 'DD-MM-YYYY');

        return Number(
            parseFloat(getInflatedAmount(amount, inflationRate, currentDate, infusionDate, daysOrYears)).toFixed(2)
        );
    });
};

export const getWealthPathAdjustedWithInflations = (
    wealthPath: number[],
    inflationRate: number,
    daysOrYears: 'months' | 'years',
    currDate: string
) => {
    const currentDate = moment(currDate, 'DD-MM-YYYY');

    return wealthPath.map((inf, ind) => {
        let infusionDate = currentDate.clone().add(ind, 'years');

        if (ind !== 0) {
            infusionDate = infusionDate.startOf('month').startOf('year');
        }

        return Number(
            parseFloat(inf / (1 + inflationRate / 100) ** (infusionDate.diff(currentDate, 'days') / 365)).toFixed(2)
        );
    });
};

export const createReqPayloadObject = (
    distributionStartDate: string,
    scenarioType: string,
    startDate: string,
    tempInfusions: any,
    riskProfile: string,
    initialInvestment: string | number,
    goalPriority: any,
    goalName: string,
    accountNumber: string | number,
    bequestAmount: string | number,
    endDate: string,
    infusionType: any,
    infusions: any,
    annualDistributionAmount: any,
    annualDistributionYears: any
) => {
    const currentState = store.getState();
    const reallocationFreq: string = currentState?.global?.orgInfo?.goeReallocationFrequency || INFUSION_ANNUAL;
    const reqPayloadObj = {
        cashflowDate: distributionStartDate,
        isNewInvestmentTenure: true,
        isNewRiskProfile: true,
        isNewGoal: true,
        reallocationFreq: reallocationFreq,
        getPath: true,
        currentPortfolioId: null,
        scenarioType,
        isNewGoalPriority: true,
        lossThreshold: null,
        currDate: startDate,
        tempInfusions,
        riskProfile,
        currentWealth: initialInvestment,
        initialInvestment,
        goalPriority,
        startDate,
        goalName,
        accountNumber,
        goalAmount: bequestAmount,
        endDate,
        infusionType,
        infusions,
        annualDistribution: {
            amount: annualDistributionAmount,
            years: annualDistributionYears
        }
    };
    return reqPayloadObj;
};

export const retirementSavingCalculation = (
    startingYear: number | string,
    startingMonth: number | string,
    withdrawalPeriod: number | string
) => {
    const current_date = dateFormat(moment());
    const current_year = Number(getCurrentYear());
    const infusion_starting_Date = yearFormat(moment());
    const retirement_Date: string = `${startingYear}-${
        startingMonth.length > 1 ? startingMonth : `0${startingMonth}`
    }-01`;
    const contributionEndDate = moment(retirement_Date).format('DD-MM-YYYY');
    const endDate = moment(retirement_Date, 'YYYY-MM-DD')
        .add(withdrawalPeriod - 1, 'years')
        .format('DD-MM-YYYY');

    const endYear = moment(endDate, 'DD-MM-YYYY').year();
    return {
        current_date,
        current_year,
        contributionEndDate,
        retirement_Date,
        endDate,
        endYear,
        infusion_starting_Date
    };
};
export const retirementSavingInfusionArrayCalculation = (
    contribAmount: string | number,
    withdrawalAmount: string | number,
    infusion_starting_Date: string,
    contributionEndDate: string,
    autoEsclationFrequency: number | null,
    autoEsclationPercentage: number | null,
    scheduledContributionFrequency: string,
    retirement_Date: string,
    endDate: string
) => {
    let infusions: any[] = [];
    let tempInfusions: any[] = [];
    const distributionStartDate = moment(retirement_Date).format('DD-MM-YYYY');
    const secondStartDate = moment(contributionEndDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const infusionsEndDate = moment(endDate, 'DD-MM-YYYY').add(1, 'years').format('DD-MM-YYYY');
    if (scheduledContributionFrequency === ADD_CLIENT_ANNUAL) {
        infusions = getYearlyInfusionArray(
            contribAmount,
            infusion_starting_Date,
            contributionEndDate,
            contributionEndDate,
            autoEsclationFrequency,
            autoEsclationPercentage
        ).infusionArr;

        infusions = infusions.concat(
            getYearlyInfusionsForIncome(
                -withdrawalAmount,
                secondStartDate,
                distributionStartDate,
                infusionsEndDate,
                distributionStartDate
            ).infusionArr
        );

        tempInfusions = getYearlyInfusionArray(
            contribAmount,
            infusion_starting_Date,
            contributionEndDate,
            contributionEndDate,
            autoEsclationFrequency,
            autoEsclationPercentage
        ).tempArr;

        tempInfusions = tempInfusions.concat(
            getYearlyInfusionsForIncome(
                -withdrawalAmount,
                secondStartDate,
                distributionStartDate,
                infusionsEndDate,
                distributionStartDate
            ).tempArr
        );

        const monthString = (moment(contributionEndDate, 'DD-MM-YYYY').get('month') + 1).toString();
        const dateString = moment(contributionEndDate, 'DD-MM-YYYY').get('date').toString();

        const tempDateTime = `${moment(infusion_starting_Date, 'YYYY-MM-DD').get('year')}-${
            monthString.length > 1 ? monthString : `0${monthString}`
        }-${dateString.length > 1 ? dateString : `0${dateString}`}`;

        if (moment(tempDateTime).isAfter(infusion_starting_Date, 'date')) {
            infusions.unshift(0);
            tempInfusions.unshift({
                amount: 0,
                date: moment(infusion_starting_Date, 'YYYY-MM-DD').format('DD-MM-YYYY')
            });
        }
    } else {
        const monthlyInfusionStartingDate = moment().add(1, 'months').set('date', 1).format('YYYY-MM-DD');
        infusions = getMonthlyInfusionArray(
            contribAmount,
            monthlyInfusionStartingDate,
            contributionEndDate,
            contributionEndDate,
            autoEsclationFrequency,
            autoEsclationPercentage
        ).infusionArr;

        infusions = infusions.concat(
            getMonthlyInfusionArrayForIncome(
                -withdrawalAmount,
                secondStartDate,
                distributionStartDate,
                endDate,
                infusionsEndDate
            ).infusionArr
        );
        tempInfusions = getMonthlyInfusionArray(
            contribAmount,
            monthlyInfusionStartingDate,
            contributionEndDate,
            contributionEndDate,
            autoEsclationFrequency,
            autoEsclationPercentage
        ).tempArr;
        tempInfusions = tempInfusions.concat(
            getMonthlyInfusionArrayForIncome(
                -withdrawalAmount,
                secondStartDate,
                distributionStartDate,
                endDate,
                infusionsEndDate
            ).tempArr
        );
        infusions.unshift(0);
        tempInfusions.unshift({
            amount: 0,
            date: moment().format('DD-MM-YYYY')
        });
    }
    return { infusions, tempInfusions, distributionStartDate };
};
export const futureEventCalculation = (endYear: number | string, endMonth: number | string) => {
    const startDate = dateFormat(moment());
    const current_year = Number(getCurrentYear());
    const end_Date = `${endYear}-${endMonth.length > 1 ? endMonth : `0${endMonth}`}-01`;
    const goal_end_Date = dateFormat(moment(end_Date));
    const monthlyInfusionStartingDate = yearFormat(moment());
    const goal_start_Date = yearFormat(moment());

    return {
        startDate,
        current_year,
        goal_end_Date,
        monthlyInfusionStartingDate,
        goal_start_Date
    };
};
export const futureEventInfusionArrayCalculation = (
    amount: number | string,
    // startingDate: string,
    endDate: string,
    monthlyInfusionStartingDate: string,
    scheduledFrequency: string
) => {
    let goalEndYear;
    let goalEndDate;
    let infusions: any[] = [];
    let tempInfusions: any[] = [];

    const startingDate = yearFormat(moment());
    if (scheduledFrequency === ADD_CLIENT_ANNUAL) {
        infusions = getYearlyInfusionArrayForFutureEvent(
            amount,
            startingDate,
            endDate,
            endDate,
            null,
            null
        ).infusionArr;
        tempInfusions = getYearlyInfusionArrayForFutureEvent(
            amount,
            startingDate,
            endDate,
            endDate,
            null,
            null
        ).tempArr;
    } else {
        infusions = getMonthlyInfusionArray(
            amount,
            monthlyInfusionStartingDate,
            endDate,
            startingDate,
            null,
            null
        ).infusionArr;
        tempInfusions = getMonthlyInfusionArray(
            amount,
            monthlyInfusionStartingDate,
            endDate,
            startingDate,
            null,
            null
        ).tempArr;
    }

    const [infusionFormatVal, tempInfusionFormatVal] = formatInfusionArr(
        scheduledFrequency,
        infusions,
        tempInfusions,
        startingDate,
        endDate
    );
    infusions = infusionFormatVal;
    tempInfusions = tempInfusionFormatVal;
    infusions.push(0);
    tempInfusions.push({
        amount: 0,
        date: moment(tempInfusions[tempInfusions.length - 1].date, 'DD-MM-YYYY')
            .add(1, scheduledFrequency === ADD_CLIENT_ANNUAL ? 'years' : 'months')
            .format('DD-MM-YYYY')
    });

    return { goalEndDate, goalEndYear, infusions, tempInfusions };
};
const formatInfusionArr = (scheduledFrequency, infusions, tempInfusions, startingDate, endDate) => {
    const infusionFormatVal: any[] = infusions;
    const tempInfusionFormatVal: any[] = tempInfusions;

    const monthString = (moment(endDate, 'DD-MM-YYYY').get('month') + 1).toString();

    const tempDateTime = `${moment(startingDate).get('year')}-${
        monthString.length > 1 ? monthString : `0${monthString}`
    }-01`;

    if (scheduledFrequency === ADD_CLIENT_ANNUAL) {
        if (moment(tempDateTime).isAfter(startingDate, 'date')) {
            infusionFormatVal.unshift(0);
            if (tempInfusionFormatVal.length > 0) {
                tempInfusionFormatVal.unshift({
                    amount: 0,
                    date: moment(startingDate).format('DD-MM-YYYY')
                });
            }
        }
    } else if (moment(startingDate).get('date') !== moment(endDate, 'DD-MM-YYYY').get('date')) {
        if (
            moment(tempDateTime).get('date') > moment(startingDate).get('date') &&
            moment(tempDateTime).isAfter(startingDate, 'date') &&
            (moment(tempDateTime).isAfter(startingDate, 'month') || moment(tempDateTime).isSame(startingDate, 'month'))
        ) {
            infusionFormatVal.unshift(0);
            if (tempInfusionFormatVal.length > 0) {
                tempInfusionFormatVal.unshift({
                    amount: 0,
                    date: moment(startingDate).format('DD-MM-YYYY')
                });
            }
        }

        if (
            moment(tempDateTime).get('date') > moment(startingDate).get('date') &&
            moment(tempDateTime).isBefore(startingDate, 'month')
        ) {
            infusionFormatVal.unshift(0);
            if (tempInfusionFormatVal.length > 0) {
                tempInfusionFormatVal.unshift({
                    amount: 0,
                    date: moment(startingDate).format('DD-MM-YYYY')
                });
            }
        }
    }

    return [infusionFormatVal, tempInfusionFormatVal];
};

export const getYearlyInfusionArrayForFutureEvent = (
    amount: number,
    yearlyInfusionStartingDate: string,
    endDate: string,
    cashFlowDate: string,
    frequency: number | null,
    percent: number | null
) => {
    const yearlyInfusions = [];
    const tempArr = [];
    endDate = moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    cashFlowDate = moment(cashFlowDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    let i = 0;
    let tempAmount = amount;

    for (
        let m = moment(yearlyInfusionStartingDate);
        m.isBefore(endDate, 'year');
        m.add(1, 'years').set('date', 1).set('month', 0).format('YYYY-MM-DD')
    ) {
        let isFound = false;
        const dateStart = m.clone();
        const dateEnd = `${m.get('year')}-12-31`;
        for (let j = dateStart; j.isSameOrBefore(dateEnd); j.add(1, 'days')) {
            if (
                j.get('date') === moment(cashFlowDate).get('date') &&
                j.get('month') + 1 === moment(cashFlowDate).get('month') + 1
            ) {
                yearlyInfusions.push(tempAmount);
                tempArr.push({ amount: tempAmount, date: moment(j).format('DD-MM-YYYY') });
                isFound = true;
                if (percent && frequency) {
                    i += 1;
                    if (i === frequency) {
                        i = 0;
                        tempAmount = parseFloat((tempAmount + (tempAmount * percent) / 100).toFixed(2));
                    }
                }
                break;
            }
        }
        if (!isFound) {
            yearlyInfusions.push(0);
            tempArr.push({ amount: 0, date: moment(m).format('DD-MM-YYYY') });
        }
    }
    return { infusionArr: yearlyInfusions, tempArr };
};
export const getMonthlyInfusionArrayForFutureEvent = (
    amount: number,
    monthlyInfusionStartingDate: string,
    endDate: string,
    cashFlowDate: string,
    frequency: number | null,
    percent: number | null
) => {
    const monthlyInfusionsArray = [];
    const tempArr = [];
    endDate = moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    cashFlowDate = moment(cashFlowDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    let i = 0;
    let tempAmount = amount;

    for (let m = moment(monthlyInfusionStartingDate); m.isBefore(endDate, 'month'); m.add(1, 'months').set('date', 1)) {
        let isFound = false;
        const dateStart = m.clone();
        const dateEnd = `${moment(dateStart).format('YYYY-MM')}-${moment(
            dateStart.format('YYYY-MM'),
            'YYYY-MM'
        ).daysInMonth()}`;
        for (let j = dateStart; j.isSameOrBefore(dateEnd); j.add(1, 'days')) {
            if (j.get('date') === moment(cashFlowDate).get('date')) {
                monthlyInfusionsArray.push(tempAmount);
                tempArr.push({ amount: tempAmount, date: moment(m).format('DD-MM-YYYY') });
                isFound = true;

                if (this.shouldEnableMonthlyFreqForRtrmnt()) {
                    if (percent && frequency) {
                        i += 1;
                        if (i === frequency) {
                            i = 0;
                            tempAmount = parseFloat((tempAmount + (tempAmount * percent) / 100).toFixed(2));
                        }
                    }
                }
                break;
            }
        }
        if (!isFound) {
            monthlyInfusionsArray.push(0);
            tempArr.push({ amount: 0, date: moment(m).format('DD-MM-YYYY') });
        }
    }
    return { infusionArr: monthlyInfusionsArray, tempArr };
};
export const getYearlyInfusionsForIncome = (
    amount: number,
    startDate: string,
    distributionStartDate: string,
    endDate: string,
    lastReallocatedDate: string
) => {
    const yearlyInfusions = [];
    const tempArr = [];
    distributionStartDate = moment(distributionStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

    for (
        let m = moment(startDate);
        m.isBefore(distributionStartDate);
        m.set('date', 1).set('month', moment(distributionStartDate, 'YYYY-MM-DD').month()).add(1, 'years')
    ) {
        yearlyInfusions.push(0);
        tempArr.push({ amount: 0, date: moment(m).format('DD-MM-YYYY') });

        if (m.year() === moment(startDate).year()) {
            const sameYearDate = m.set('date', 1).set('month', moment(distributionStartDate, 'YYYY-MM-DD').month());

            if (sameYearDate.isBefore(distributionStartDate) && sameYearDate.isAfter(startDate)) {
                yearlyInfusions.push(0);
                tempArr.push({ amount: 0, date: moment(sameYearDate).format('DD-MM-YYYY') });
            }
        }
    }
    yearlyInfusions.push(
        ...getYearlyInfusionArray(amount, distributionStartDate, endDate, lastReallocatedDate, null, null).infusionArr
    );
    tempArr.push(
        ...getYearlyInfusionArray(amount, distributionStartDate, endDate, lastReallocatedDate, null, null).tempArr
    );

    return { infusionArr: yearlyInfusions, tempArr };
};

export const getYearlyInfusionArray = (
    amount: number,
    yearlyInfusionStartingDate: string,
    endDate: string,
    lastReallocatedDate: string,
    frequency: number | null,
    percent: number | null
) => {
    const yearlyInfusions = [];
    const tempArr = [];
    endDate = moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    lastReallocatedDate = moment(lastReallocatedDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    let i = 0;
    let tempAmount = amount;

    for (
        let m = moment(yearlyInfusionStartingDate);
        m.isBefore(endDate, 'year');
        m.add(1, 'years').set('date', 1).set('month', 0).format('YYYY-MM-DD')
    ) {
        let isFound = false;
        const dateStart = m.clone(); // 2023-04-19
        const dateEnd = `${m.get('year')}-12-31`; // 2023-12-31

        for (let j = dateStart; j.isSameOrBefore(dateEnd); j.add(1, 'days')) {
            if (
                j.get('date') === moment(lastReallocatedDate).get('date') &&
                j.get('month') + 1 === moment(lastReallocatedDate).get('month') + 1
            ) {
                yearlyInfusions.push(tempAmount);
                tempArr.push({ amount: tempAmount, date: moment(j).format('DD-MM-YYYY') });
                isFound = true;
                if (percent && frequency) {
                    i += 1;
                    if (i === frequency) {
                        i = 0;
                        tempAmount = parseFloat((tempAmount + (tempAmount * percent) / 100).toFixed(2));
                    }
                }
                break;
            }
        }
        if (!isFound) {
            yearlyInfusions.push(0);
            tempArr.push({ amount: 0, date: moment(m).format('DD-MM-YYYY') });
        }
    }

    return { infusionArr: yearlyInfusions, tempArr };
};

/** *************************we need to change variable name */
export const getMonthlyInfusionArrayForIncome = (
    amount: number,
    monthlyInfusionStartingDate: string,
    distributionStartDate: string,
    endDate: string,
    lastReallocatedDate: string
) => {
    const monthlyInfusions = [];
    const tempArr = [];
    distributionStartDate = moment(distributionStartDate, 'DD-MM-YYYY').startOf('month').format('YYYY-MM-DD');
    // distributionStartDate = moment(distributionStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    for (
        let m = moment(monthlyInfusionStartingDate);
        m.isBefore(distributionStartDate);
        m.set('date', 1).add(1, 'months')
    ) {
        monthlyInfusions.push(0);
        tempArr.push({ amount: 0, date: moment(m).format('DD-MM-YYYY') });
    }
    monthlyInfusions.push(
        ...getMonthlyInfusionArray(amount, distributionStartDate, endDate, lastReallocatedDate, null, null).infusionArr
    );
    tempArr.push(
        ...getMonthlyInfusionArray(amount, distributionStartDate, endDate, lastReallocatedDate, null, null).tempArr
    );
    monthlyInfusions.push(amount);
    tempArr.push({ amount, date: moment(endDate, 'DD-MM-YYYY').format('DD-MM-YYYY') });
    return { infusionArr: monthlyInfusions, tempArr };
};
export const getMonthlyInfusionArray = (
    amount: number,
    monthlyInfusionStartingDate: string,
    endDate: string,
    lastReallocatedDate: string,
    frequency: number | null,
    percent: number | null
) => {
    const monthlyInfusionsArray = [];
    const tempArr = [];
    endDate = moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    lastReallocatedDate = moment(lastReallocatedDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    let i = 0;
    let tempAmount = amount;

    for (let m = moment(monthlyInfusionStartingDate); m.isBefore(endDate); m.add(1, 'months').set('date', 1)) {
        let isFound = false;
        const dateStart = m.clone();
        const dateEnd = `${moment(dateStart).format('YYYY-MM')}-${moment(
            dateStart.format('YYYY-MM'),
            'YYYY-MM'
        ).daysInMonth()}`;
        for (let j = dateStart; j.isSameOrBefore(dateEnd); j.add(1, 'days')) {
            if (j.get('date') === moment(lastReallocatedDate).set('date', 1).get('date')) {
                monthlyInfusionsArray.push(tempAmount);
                tempArr.push({ amount: tempAmount, date: moment(j).format('DD-MM-YYYY') });
                isFound = true;
                if (percent && frequency) {
                    i += 1;
                    if (i === frequency * 12) {
                        i = 0;
                        tempAmount = parseFloat((tempAmount + (tempAmount * percent) / 100).toFixed(2));
                    }
                }
                break;
            }
        }
        if (!isFound) {
            monthlyInfusionsArray.push(0);
            tempArr.push({ amount: 0, date: moment(m).format('DD-MM-YYYY') });
        }
    }
    return { infusionArr: monthlyInfusionsArray, tempArr };
};
