import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import ButtonComponent from '../../components/atoms/button';
import ModalComponent from '../../components/molecules/modal';
import { CtaContainer, ModalContent } from '../../components/molecules/modal/styles';
import { updateReleaseBanner } from '../../features/global/globalSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Container = styled(Box)(() => ({}));

const Table = styled('table')(({ theme }) => ({
    margin: '20px 0',
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: '0 0 10px 0'
}));

const TableRow = styled('tr')(() => ({}));

const TableHead = styled('th')(({ theme }) => ({
    ...theme.typography.body1,
    padding: '10px',
    textAlign: 'left',
    color: theme.palette.grey[400]
}));

const TableData = styled('td')(({ theme }) => ({
    ...theme.typography.body1,
    padding: '10px',
    textAlign: 'left',
    color: theme.palette.primary.main
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.common.white
}));

export default function UpcomingReleaseBanner() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const scheduledReleaseBanner = useAppSelector((state) => state.global.scheduledReleaseBanner);
    const isUpdatingReleaeBanner = useAppSelector((state) => state.global.loading);

    const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);

    const onRemoveReleaseBanner = () => {
        dispatch(updateReleaseBanner({ ...scheduledReleaseBanner, bannerStatus: 'inactive' })).then(() => {
            setRemoveConfirmation(false);
        });
    };

    const startDateAndTime = moment(scheduledReleaseBanner?.bannerStartTime, 'MM/DD/YYYY hh:mm:ss');
    const endDataAndTime = moment(scheduledReleaseBanner?.bannerEndTime, 'MM/DD/YYYY hh:mm:ss');
    // const now = moment();

    return (
        <Container>
            <Typography variant="h2">{t('TEXT_RELEASE_BANNER_HEADER')}</Typography>

            <Table>
                <TableRow>
                    <TableHead>{t('TEXT_RELEASE_BANNER_TABLE_HEADER_1')}</TableHead>
                    <TableHead>{t('TEXT_RELEASE_BANNER_TABLE_HEADER_2')}</TableHead>
                    <TableHead>{t('TEXT_RELEASE_BANNER_TABLE_HEADER_3')}</TableHead>
                    <TableHead>{t('TEXT_RELEASE_BANNER_TABLE_HEADER_4')}</TableHead>
                    <TableHead>{t('TEXT_RELEASE_BANNER_TABLE_HEADER_5')}</TableHead>
                </TableRow>

                {scheduledReleaseBanner?.bannerStatus === 'active' ? (
                    <TableRow>
                        <TableData>{startDateAndTime.format('MMMM D')}</TableData>
                        <TableData>{startDateAndTime.format('h:mm a')}</TableData>
                        <TableData>{endDataAndTime.format('MMMM D')}</TableData>
                        <TableData>{endDataAndTime.format('h:mm a')}</TableData>
                        <TableData
                            sx={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setRemoveConfirmation(true);
                            }}
                        >
                            {t('TEXT_RELEASE_BANNER_TABLE_HEADER_5')}
                        </TableData>
                    </TableRow>
                ) : (
                    <TableRow>
                        <TableData colSpan={5} sx={{ textAlign: 'center' }}>
                            <ButtonComponent
                                variant="contained"
                                size="medium"
                                data-testid="schedule-upcoming-banner"
                                onClick={() => navigate('/schedule-upcoming-release')}
                            >
                                {t('TEXT_SCHEDULE_RELEASE_BANNER_BUTTON')}
                            </ButtonComponent>
                        </TableData>
                    </TableRow>
                )}
            </Table>

            <ModalComponent open={removeConfirmation} handleClose={() => {}}>
                <ModalContent id="modal-modal-title" variant="body1">
                    {t('REMOVE_MODAL_CONFIRMATION_TEXT')}
                </ModalContent>
                <CtaContainer>
                    <ButtonComponent
                        variant="outlined"
                        size="medium"
                        onClick={() => {
                            setRemoveConfirmation(false);
                        }}
                        disabled={isUpdatingReleaeBanner}
                    >
                        {t('TEXT_NO')}
                    </ButtonComponent>
                    <ButtonComponent
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            onRemoveReleaseBanner();
                        }}
                    >
                        {isUpdatingReleaeBanner ? <StyledCircularProgress /> : t('TEXT_YES')}
                    </ButtonComponent>
                </CtaContainer>
            </ModalComponent>
        </Container>
    );
}
