import { styled, Typography } from '@mui/material';

type InfoBoxType = {
    title: string;
    children?: any;
};

const Wrapper = styled('div')(() => ({
    margin: '10px 5px'
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginBottom: '5px'
}));

const InfoBox = ({ title, children }: InfoBoxType) => (
    <Wrapper>
        <Title>{title}</Title>
        <div>{children}</div>
    </Wrapper>
);

export default InfoBox;
