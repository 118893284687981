import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ButtonComponent from '../../atoms/button';

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    position: 'absolute' as 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    color: theme.palette.common.white,
    zIndex: 100,
    fontSize: '1rem',
    padding: '10px 32px !important',
    overflowX: 'scroll',
    maxHeight: '85%',
    '@media(max-width: 991px)': {
        top: '10%',
        transform: 'translate(-50%, 0%)',
        padding: '10px 32px !important',
        width: '90%'
    }
}));

export const ModalContent = styled(Typography)<TypographyProps>(() => ({
    fontSize: '30px',
    lineHeight: '38px'
}));

const ErrorBoundaryContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 10px 0px;

    & h1,
    h3,
    h4 {
        padding: 10px 0px;
    }
`;

const ErrorPopup = ({ open, loading }: { open: boolean; loading: boolean }) => {
    const { t } = useTranslation();

    const onClick = () => {
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    };
    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowY: 'scroll' }}
                disableScrollLock
                disableEscapeKeyDown
                disableEnforceFocus
            >
                <StyledBox sx={{ bgcolor: 'background.paper', p: 4, borderRadius: 1 }}>
                    <ErrorBoundaryContainer>
                        <Typography variant="h1">{t('TEXT_ERROR_SERVER')}</Typography>
                        <Typography variant="h3">{t('DESCRIPTION_ERROR')}</Typography>
                        {loading ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <ButtonComponent sx={{ margin: '16px 0px' }} variant="contained" onClick={onClick}>
                                {' '}
                                {t('TEXT_ERROR_RETRY')}
                            </ButtonComponent>
                        )}
                    </ErrorBoundaryContainer>
                </StyledBox>
            </Modal>
        </>
    );
};

export default ErrorPopup;
