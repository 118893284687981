import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Retirement } from './assets/retirement.svg';
import { ReactComponent as FutureEvent } from './assets/futureEvent.svg';
import { ReactComponent as Income } from './assets/income.svg';
import { ReactComponent as FutureEventHighpoint } from './assets/futureEventHighpoint.svg';

interface Icon {
    [key: string]: any;
}

export const ICON: Icon = {
    retirement: <Retirement className="svgIcon" />,
    futureEvent: <FutureEvent className="svgIcon" />,
    futureEventHighpoint: <FutureEventHighpoint className="svgIcon" />,
    income: <Income className="svgIcon" />
};
interface CircleProps {
    active?: boolean | number;
    // disableProps?: boolean | number;
}

interface DataList {
    value: number;
    label: string;
    active: boolean;
    helperText: string;
    iconType: string;
    changeActive: any;
    disabled: boolean;
}

const CircleAndContentContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    zIndex: 2
}));

const Circle = styled('div')<CircleProps>(({ theme, active }) => ({
    backgroundColor: active ? theme.palette.primary.main : theme.palette.common.white,
    color: active ? theme.palette.common.white : theme.palette.grey[600],
    borderRadius: '50%',
    height: '85px',
    width: '85px',
    '.is-disabled': {
        pointerEvents: 'none'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: active ? `3px solid ${theme.palette.primary.main}` : `3px solid ${theme.palette.grey[600]}`,
    transition: '1.2s ease',
    cursor: 'pointer',
    'svg.MuiSvgIcon-root': {
        fontSize: '3.5rem'
    },
    'svg.svgIcon > *': {
        fill: active ? theme.palette.common.white : ''
    },

    '.hide': {
        display: 'none !important',
        color: 'blue'
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        border: `3px solid ${theme.palette.primary.main}`,
        boxShadow: '0 0 0 20px rgb(55 105 255 / 33%)'
    },
    'hover + .hide': {
        background: 'blue'
    }
}));

const Content = styled(Typography)<TypographyProps>(() => ({
    marginTop: '30px'
}));
const Helper = styled(Content)(() => ({
    fontSize: '12px'
}));

const ChooseGoalCircle = ({ value, label, active, helperText, iconType, changeActive, disabled }: DataList) => {
    const [show] = useState(false);
    const { t } = useTranslation();

    return (
        <CircleAndContentContainer data-testid="circle-contianer" key={`${value}`}>
            <Circle
                aria-disabled={disabled}
                data-testid={t(label)}
                aria-label={t(label)}
                className={disabled ? 'is-disabled' : ''}
                onClick={changeActive}
                active={+active}
            >
                {ICON[iconType]}
            </Circle>
            <Content variant="h4">{t(label)}</Content>
            {active || show ? <Helper variant="subtitle1">{helperText}</Helper> : ''}
        </CircleAndContentContainer>
    );
};

export default ChooseGoalCircle;
