import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { Typography } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import ButtonComponent from '../../components/atoms/button';
import { useTranslation } from 'react-i18next';

interface TargetBoxProps {
    // eslint-disable-next-line no-unused-vars
    checkAndAddFile: { (file: any): void };
    uploadedFile: any;
}

interface WrapperProps {
    imageUploadError?: string;
}

const Wrapper = styled('div')<WrapperProps>(({ imageUploadError }) => ({
    border: `2px dashed ${imageUploadError ? 'red' : 'grey'}`,
    height: '15rem',
    width: '100%',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}));

const Image = styled('img')(() => ({
    width: '70%',
    height: '60%',
    objectFit: 'contain'
}));

const TargetBox = ({ checkAndAddFile, uploadedFile }: TargetBoxProps) => {
    const { t } = useTranslation();
    const logo = useAppSelector((state) => state.global?.globalConfig?.logo || '');
    const [imageUploadError, setImageUploadError] = useState('');

    const checkFileValid = (file: any) => {
        if (file?.size > 250000) {
            setImageUploadError(t('TEXT_ADMIN_LOGO_MAX_SIZE'));
            return false;
        }

        if (file?.type !== 'image/svg+xml') {
            setImageUploadError(t('TEXT_ADMIN_LOGO_MUST_BE_SVG'));
            return false;
        }

        setImageUploadError('');
        return true;
    };

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop(item: any) {
            if (item && Object.keys(item)) {
                const file = item?.files;
                const fileValid = checkFileValid(file?.[0]);
                if (file && fileValid) {
                    checkAndAddFile(file);
                }
            }
        }
    });

    const isActive = canDrop && isOver;

    function handleAddFile(event: any) {
        event.preventDefault();
        const file = event.target.files[0];
        const fileValid = checkFileValid(file);
        if (file && fileValid) {
            checkAndAddFile([file]);
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Wrapper ref={drop} tabIndex={0} imageUploadError={imageUploadError}>
                {uploadedFile && <Image src={URL.createObjectURL(uploadedFile)} />}
                {!uploadedFile && logo && <Image src={logo} />}
                <Typography variant="h5" style={{ color: 'grey' }}>
                    {isActive ? t('TEXT_ADMIN_RELEASE') : t('TEXT_ADMIN_DRAG_AND_DROP')}
                </Typography>
                <Typography variant="h5" style={{ color: 'grey', marginBottom: '0.5rem' }}>
                    {t('TEXT_RECOMMENDATIONS_SEPARATOR')}
                </Typography>
                <ButtonComponent variant="contained" data-testid="upload-company-logo-browse" onClick={() => {}}>
                    <input
                        title=""
                        type="file"
                        name="file"
                        tabIndex={0}
                        id="file"
                        onChange={handleAddFile}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="file"> {t('TEXT_ADMIN_SUMMARY_UPLOAD_BUTTON')}</label>
                </ButtonComponent>
            </Wrapper>

            <Typography variant="body2" style={{ color: 'red', marginBottom: '0.5rem' }}>
                {imageUploadError}
            </Typography>
        </div>
    );
};

export default TargetBox;
