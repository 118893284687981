import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import GenerateIncomeForm from '../../../components/molecules/investment-goals-forms/forms/generate-income-form';
import FutureEventForm from '../../../components/molecules/investment-goals-forms/forms/future-event-form';
import RetirementSavingForm from '../../../components/molecules/investment-goals-forms/forms/retirement-saving-form';
import SkeletonComponent from './skeletonLoading';
import { ADD_CLIENT_RETIREMENT_GOAL, ADD_CLIENT_INCOME_GOAL, GOAL_TYPE } from '../../../constants';

const FormComponent = ({ formdata, showLoader, applySplit, formState }: any) => {
    const { t } = useTranslation();
    const [formValues, setFormValues] = useState<any>({});

    useEffect(() => {
        if (!isEmpty(formdata)) {
            setFormValues(cloneDeep(formdata));
        }
    }, [formdata, formState]);
    const marks = [
        {
            value: 1,
            label: t('TEXT_DREAM')
        },
        {
            value: 2,
            label: t('TEXT_WISH')
        },
        {
            value: 3,
            label: t('TEXT_WANT')
        },
        {
            value: 4,
            label: t('TEXT_NEED')
        }
    ];

    return !isEmpty(formValues) && !showLoader ? (
        <>
            {formValues && formValues?.data?.goalType === ADD_CLIENT_INCOME_GOAL ? (
                <GenerateIncomeForm
                    data={formValues?.data}
                    id={formValues?.id}
                    goalId={formValues?.goalId}
                    marks={marks}
                    wealthSplit={formValues?.wealthSplit}
                    applySplit={applySplit}
                    edit
                />
            ) : formValues?.data?.goalType === ADD_CLIENT_RETIREMENT_GOAL ? (
                <RetirementSavingForm
                    data={formValues?.data}
                    id={formValues?.id}
                    goalId={formValues?.goalId}
                    marks={marks}
                    applySplit={applySplit}
                    wealthSplit={formValues?.wealthSplit}
                    edit
                />
            ) : formValues?.data?.goalType === GOAL_TYPE.FUTURE_EVENT ? (
                <FutureEventForm
                    data={formValues?.data}
                    id={formValues?.id}
                    goalId={formValues?.goalId}
                    marks={marks}
                    applySplit={applySplit}
                    wealthSplit={formValues?.wealthSplit}
                    edit
                />
            ) : null}
        </>
    ) : (
        <SkeletonComponent />
    );
};

export default FormComponent;
