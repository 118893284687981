import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const NotFoundContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 100px 0px;

    & h1,
    h3,
    h4 {
        padding: 10px 0px;
    }

    & a {
        color: #3769ff;
        cursor: pointer;
    }
`;

export default function NotFound() {
    const { t } = useTranslation();

    return (
        <>
            <NotFoundContainer>
                <Typography variant="h1">{t('HEADER_404')}</Typography>
                <Typography variant="h3">{t('HEADER_NOT_FOUND')}</Typography>
                <Typography variant="body1">
                    {t('DESCRIPTION_NOT_FOUND')}
                    <Link to="/clients"> {t('TEXT_HOME_PAGE')}</Link>
                </Typography>
            </NotFoundContainer>
        </>
    );
}
