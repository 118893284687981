/* eslint-disable operator-linebreak */
// @ts-nocheck
import { isEmpty, isUndefined, memoize, sum } from 'lodash';
import i18n from 'i18next';
import moment from 'moment';
import {
    GOAL_PRIORITY,
    ACCESSOR_KEYS,
    TENANT_ID_MAP,
    RISK_PROFILE_MAP,
    GOAL_PRIORITY_TRANSLATION_MAP
} from '../constants';

const isHighPoint =
    TENANT_ID_MAP[document?.location?.host?.split('.')?.reverse()?.[2] || 'advisorportaldev'] === 'highpoint';

export const numberWithCommas = (x: any) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const addCurrencySymbol = (value: number) => (isHighPoint ? `MXN$${value || 0}` : `$${value || 0}`);

export const getScoreAndGoalPriority = (mainRowValues: any, subRowsValues: any) => {
    if (!mainRowValues.goalPriority && !isEmpty(subRowsValues)) {
        const data = subRowsValues.map((val: any) => ({
            score: val.score,
            goalPriority: val.goalPriority
        }));

        const minScoreValue = data.reduce((previous: { score: number }, current: { score: number }) =>
            current.score < previous.score ? current : previous
        );
        return minScoreValue;
    }
};

export const getScoreColor = (data: { score: any; goalPriority: string | number | undefined }, theme: any) => {
    let color = '';
    if (data.score < 35) {
        color = theme.palette.table.score[50];
    } else if (
        (data.goalPriority === GOAL_PRIORITY.NEED && data.score < 85) ||
        (data.goalPriority === GOAL_PRIORITY.WANT && data.score < 75) ||
        (data.goalPriority === GOAL_PRIORITY.WISH && data.score < 60) ||
        (data.goalPriority === GOAL_PRIORITY.DREAM && data.score < 50)
    ) {
        color = theme.palette.table.score[75];
    } else {
        color = theme.palette.table.score[100];
    }
    return color;
};

export const convertDateFormatMMDDYYYY = (value: string) => {
    if (!isEmpty(value) && !isUndefined(value)) {
        const datearray = value.split('-');
        const newdate = `${datearray[1]}/${datearray[0]}/${datearray[2]}`;
        return newdate;
    }
};

export function returnEquityFixedIncomeRatio(row: any) {
    return row?.formFields.map((val) => val.data.equityFixedIncomeRatio);
}

export function returnTableCellValue(row: { formFields: string }, name: string) {
    return isEmpty(row?.formFields) ? [] : row?.formFields.map((val: { data: { [x: string]: any } }) => val.data[name]);
}

export function returnGoalId(row: any): any {
    return isEmpty(row?.formFields) ? [] : row?.formFields.map((val: { goalId: number }) => val.goalId);
}

export function returnGoalResponse(value: { formFields: string }, i: string | number) {
    return isEmpty(value?.formFields) ? [] : value?.formFields[i];
}

export const getScore = (row: { response: string }) => {
    if (!isEmpty(row?.response)) {
        const scores = row?.response?.map((val: { data: { analysisReport: any } }) => val?.data?.analysisReport);
        return scores.map((v: { currentGoalProbability: any }) => v.currentGoalProbability);
    }
    return '';
};

export const getScoreForGoal = (value: { response: string; formFields: string }, i: string | number) => {
    if (!isEmpty(value?.response) && !isEmpty(value?.formFields)) {
        let filterResonseObject = value?.response?.filter((v1: { id: any }) =>
            value?.formFields?.find((v2: { id: any }) => v2?.id === v1?.id)
        )[i];
        filterResonseObject = filterResonseObject?.data?.analysisReport;
        return filterResonseObject?.currentGoalProbability;
    }

    return '';
};

export const removeWhiteSpacesAndSymbolsAndMakeCamelCase = (str: string) =>
    str
        ?.trim()
        .toLowerCase()
        .replace(/([^A-Z0-9]+)(.)/gi, function () {
            return arguments[2].toUpperCase();
        });

export const findEquityFixedIncome = (data: { assets: any[] }) => {
    const equityfixedincome = data?.assets?.reduce(
        (obj: any, item: { type: any; totalAllocatedvalue: any }) =>
            Object.assign(obj, {
                [removeWhiteSpacesAndSymbolsAndMakeCamelCase(item.type)]: item.totalAllocatedvalue
            }),
        {}
    );
    return `${equityfixedincome.equity}/${equityfixedincome.fixedIncome}`;
};

const formatDate = (date) => {
    if (date) {
        let latestDate = moment(date as string, 'YYYY-MM-DD HH:mm:ss.SSSS').format('MM/DD/YYYY');

        if (latestDate === 'Invalid date') {
            latestDate = moment(date as string, 'DD-MM-YYYY').format('MM/DD/YYYY');
        }
        return latestDate;
    }
    return '';
};

const latestDate = (dates: any[]) => {
    dates.sort((a: string, b: string) => {
        const date1 = moment(a, 'YYYY-MM-DD HH:mm:ss.SSSS').valueOf();
        const date2 = moment(b, 'YYYY-MM-DD HH:mm:ss.SSSS').valueOf();

        return +date2 - +date1;
    });

    return dates[0];
};

export const getLastModifiedDate = (value: any) => {
    let dates = returnTableCellValue(value, 'startDate');
    dates = dates.map((_, i) => (!isEmpty(value?.formFields?.[i]?.updatedAt) ? value?.formFields?.[i]?.updatedAt : ''));
    return !isEmpty(dates[0]) ? latestDate(dates) : '';
};

const getTime = (date: any) => {
    const newDate = moment(date);

    return newDate.valueOf();
};

export const returnFirstLevelStructure = (value: any, caculateTotalGoalAmount: boolean) => ({
    id: { clientEmail: value?.clientEmail, goal: '', clientId: value?.assignedClientId },
    goalName: '',
    goalAmount:
        caculateTotalGoalAmount && !isEmpty(returnTableCellValue(value, ACCESSOR_KEYS.GOAL_AMOUNT))
            ? sum(returnTableCellValue(value, ACCESSOR_KEYS.GOAL_AMOUNT))
            : '',
    initialInvestment:
        caculateTotalGoalAmount && !isEmpty(returnTableCellValue(value, ACCESSOR_KEYS.INITIAL_INVESTMENT))
            ? sum(returnTableCellValue(value, ACCESSOR_KEYS.INITIAL_INVESTMENT))
            : '',
    goals: returnTableCellValue(value, 'goalName').length,
    score: !isEmpty(getScore(value)) ? Math.round(Math.min(...getScore(value)) * 100) : '',
    lastModified: formatDate(getLastModifiedDate(value)) || '',
    equityFixedIncome: '',
    goalPriority: '',
    showAddGoalButton: isEmpty(value?.formFields) && isEmpty(value?.request) && isEmpty(value?.response),
    subRows: returnTableCellValue(value, 'goalName')
        .map((goal: any, i: string | number) => ({
            managerName: '',
            advisorName: '',
            clientName: `${value?.clientFirstName} ${value?.clientLastName}`,
            clientFirstName: value?.clientFirstName,
            clientLastName: value?.clientLastName,
            id: {
                clientEmail: value?.clientEmail,
                goal: returnGoalId(value)[i],
                clientId: value?.id
            },
            goalName: goal,
            goalAmount: returnTableCellValue(value, ACCESSOR_KEYS.GOAL_AMOUNT)[i],
            initialInvestment: returnTableCellValue(value, ACCESSOR_KEYS.INITIAL_INVESTMENT)[i],
            goals: '',
            equityFixedIncome: returnEquityFixedIncomeRatio(value)[i],
            lastModified: !isEmpty(value?.formFields?.[i]?.updatedAt)
                ? formatDate(value?.formFields[i]?.updatedAt)
                : '' || '',
            score: Math.round(getScoreForGoal(value, i) * 100),
            goalPriority: i18n.t(GOAL_PRIORITY_TRANSLATION_MAP[returnTableCellValue(value, 'goalPriority')[i]]),
            showAddGoalButton: false
        }))
        .sort((a: any, b: any) => getTime(b.lastModified) - getTime(a.lastModified))
});

export const returnGoalStructure = (value: any) => ({
    id: { clientEmail: value?.clientEmail, goal: '', clientId: value?.assignedClientId },
    goalName: returnTableCellValue(value, 'goalName')[0] || '',
    goalAmount: !isEmpty(returnTableCellValue(value, ACCESSOR_KEYS.GOAL_AMOUNT))
        ? returnTableCellValue(value, ACCESSOR_KEYS.GOAL_AMOUNT)[0]
        : -1,
    initialInvestment: !isEmpty(returnTableCellValue(value, ACCESSOR_KEYS.INITIAL_INVESTMENT))
        ? returnTableCellValue(value, ACCESSOR_KEYS.INITIAL_INVESTMENT)[0]
        : -1,
    goals: returnTableCellValue(value, 'goalName').length,
    score: !isEmpty(getScore(value)) ? Math.round(Math.min(...getScore(value)) * 100) : '',
    lastModified: formatDate(getLastModifiedDate(value)) || value?.updatedAt || '',
    equityFixedIncome: !isEmpty(returnEquityFixedIncomeRatio(value)[0]) ? returnEquityFixedIncomeRatio(value)[0] : '',
    goalPriority: i18n.t(GOAL_PRIORITY_TRANSLATION_MAP[returnTableCellValue(value, 'goalPriority')[0]]) || '',
    showAddGoalButton: isEmpty(value?.formFields) && isEmpty(value?.request) && isEmpty(value?.response)
});

export const formatTableData = (data: any[], caculateTotalGoalAmount: boolean) => {
    if (!isEmpty(data)) {
        const sortedDataByLastName = data?.sort((a, b) => a?.clientFirstName.localeCompare(b?.clientFirstName));
        const res = sortedDataByLastName?.map((value: any) =>
            returnTableCellValue(value, 'goalName').length > 1
                ? {
                      ...(value.managerName && { managerName: value.managerName }),
                      ...(value.advisorName && { advisorName: value.advisorName }),
                      clientName: `${value?.clientFirstName} ${value?.clientLastName}`,
                      clientFirstName: value?.clientFirstName,
                      clientLastName: value?.clientLastName,
                      riskProfile: i18n.t(RISK_PROFILE_MAP[value?.riskTolerance]),

                      ...returnFirstLevelStructure(value, caculateTotalGoalAmount)
                  }
                : {
                      ...(value.managerName && { managerName: value.managerName }),
                      ...(value.advisorName && { advisorName: value.advisorName }),
                      clientName: `${value?.clientFirstName} ${value?.clientLastName}`,
                      clientFirstName: value?.clientFirstName,
                      clientLastName: value?.clientLastName,
                      riskProfile: i18n.t(RISK_PROFILE_MAP[value?.riskTolerance]),
                      ...returnGoalStructure(value)
                  }
        );
        return res;
    }
    return [];
};

/* Grouping Table Data */

function findValuesBasedOnKey(obj: { [x: string]: any }, findKey: string): any {
    const output = [];

    for (const key in obj) {
        if (key === findKey) output.push(obj[key]);
        else if (typeof obj[key] === 'object') output.push(...findValuesBasedOnKey(obj[key], findKey));
    }

    return output;
}

const _groupBy = (arr = [], prop: string) => {
    const row = {
        managerName: '',
        advisorName: '',
        clientName: '',
        clientFirstName: '',
        clientLastName: '',
        id: '',
        goalName: '',
        goalAmount: '',
        goals: '',
        score: '',
        lastModified: '',
        equityFixedIncome: '',
        actualData: ''
    };
    const map = arr.reduce(
        (map, { [prop]: propToGroupBy, ...props }: any) =>
            map.set(propToGroupBy, [...(map.get(propToGroupBy) ?? []), { [prop]: '', ...props }]),
        new Map()
    );
    return [...map.entries()].map(([propToGroupBy, subRows]) => ({
        ...row,
        [prop]: propToGroupBy,
        subRows
    }));
};

export const groupByManager = (arr: any) => {
    const managerList = _groupBy(arr, ACCESSOR_KEYS.MANAGER_NAME);
    managerList.forEach((manager) => {
        manager.subRows = _groupBy(manager.subRows, ACCESSOR_KEYS.ADVISOR_NAME);
    });

    managerList.forEach((manager: any) => {
        if (manager.goalAmount === '') {
            const goalAmount = sum(findValuesBasedOnKey(manager.subRows, ACCESSOR_KEYS.GOAL_AMOUNT).filter(Boolean));
            manager.goalAmount = goalAmount;
        }

        if (manager.advisorName === '') {
            manager.advisorName = manager.subRows.length;
        }

        if (manager.clientName === '') {
            const clientName = findValuesBasedOnKey(manager.subRows, ACCESSOR_KEYS.CLIENT_NAME).filter(Boolean);
            manager.clientName = clientName.length;
        }
    });

    managerList.forEach((manager) => {
        manager.subRows.forEach(
            (advisor: {
                [x: string]: any;
                goalAmount: number | any;
                subRows: any | any[];
                clientName: string | any;
            }) => {
                if (advisor.goalAmount === '') {
                    const goalAmount = sum(
                        findValuesBasedOnKey(advisor.subRows, ACCESSOR_KEYS.GOAL_AMOUNT).filter(Boolean)
                    );
                    advisor.goalAmount = goalAmount;
                }

                if (advisor.clientName === '') {
                    advisor.clientName = advisor.subRows.length;
                }
            }
        );
    });

    managerList.forEach((manager) => {
        manager.subRows.forEach((advisor: { subRows: any[] }) =>
            advisor.subRows.forEach((client: { [x: string]: number; goalAmount: number | any; subRows: any }) => {
                if (client.goalAmount === '') {
                    const goalAmount = sum(
                        findValuesBasedOnKey(client.subRows, ACCESSOR_KEYS.GOAL_AMOUNT).filter(Boolean)
                    );
                    client.goalAmount = goalAmount;
                }
            })
        );
    });

    return managerList;
};

export const groupByAdvisor = (arr: any) => {
    const advisorList = _groupBy(arr, ACCESSOR_KEYS.ADVISOR_NAME);

    advisorList.forEach((advisor: any) => {
        if (advisor.goalAmount === '') {
            const goalAmount = sum(findValuesBasedOnKey(advisor.subRows, ACCESSOR_KEYS.GOAL_AMOUNT).filter(Boolean));
            advisor.goalAmount = goalAmount;
        }

        if (advisor.clientName === '') {
            advisor.clientName = advisor.subRows.length;
        }
    });

    advisorList.forEach((advisor) => {
        advisor.subRows.forEach((client: { [x: string]: number; goalAmount: number | any; subRows: any }) => {
            if (client.goalAmount === '') {
                const goalAmount = sum(findValuesBasedOnKey(client.subRows, ACCESSOR_KEYS.GOAL_AMOUNT).filter(Boolean));
                client.goalAmount = goalAmount;
            }
        });
    });

    return advisorList;
};

export const memoizeGroupByAdvisor = memoize(groupByAdvisor);
export const memoizeGroupByManager = memoize(groupByManager);

export function formatClientName(data: any) {
    return !isEmpty(data) && data?.clientFirstName ? `${data.clientFirstName} ${data.clientLastName}` : '';
}
