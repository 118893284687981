import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Grid, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import FormField from '../../components/molecules/client-summary/formFields';
import RecommendationContainer from '../charts/recommendation';
import GlidePathContainer from '../charts/glide-path';
import WealthPathContainer from '../charts/wealth-path';
import PortfolioCompositionContainer from './portfolio-composition-chart';
import { formatDataForGlidePathCharts, getCurrentYear } from '../../utils/charts';
import { UnknownProperties } from '../../constants/types';

const Wrapper = styled('div')(() => ({
    padding: '20px'
}));

const ChartsSection = styled('div')(() => ({
    marginTop: '20px'
}));

const DescTypography = styled(Typography)<TypographyProps>(() => ({
    marginBottom: 0,
    marginTop: '4px'
}));

const HR = styled('div')(({ theme }) => ({
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey[400],
    marginBottom: '10px'
}));

const SingleGoal = ({ formField, response, request, updateProfolioCompositionData, externalData }: any) => {
    const { t } = useTranslation();
    const portfolioData = useAppSelector((state) => state?.global?.portfolio || []);
    const tenantId = useAppSelector((state) => state?.global?.globalConfig?.subdomain);
    const enableSourceCaveatNumbers = useAppSelector((state) =>
        Boolean(state.global.orgInfo?.enableSourceCaveatNumbers)
    );
    const [portfolioDataForChart, setPorfolioChartData] = useState({});
    useEffect(() => {
        if (!isEmpty(response) && !isEmpty(portfolioData) && !isEmpty(request)) {
            const portfolio_path = response?.data?.pathReport?.portfolioPath;
            const years: any =
                request?.data?.annualDistribution?.years.map((val: { toString: () => string }) => val.toString()) || [];
            const { series }: UnknownProperties = formatDataForGlidePathCharts(portfolio_path, portfolioData);
            const equityLabelValue = JSON.parse(series[years.indexOf(getCurrentYear())]?.portfolio).find(
                (v: { type: string }) => v.type === 'Equity'
            ).totalAllocatedvalue;

            const portfolioDataForChart = {
                chartData: JSON.parse(series[years.indexOf(getCurrentYear())]?.portfolio),
                label: getCurrentYear(),
                equity: equityLabelValue,
                fixedIncome: 100 - equityLabelValue
            };

            setPorfolioChartData(portfolioDataForChart);
        }
    }, [response, portfolioData, request]);
    return (
        <Wrapper>
            <Typography tabIndex={0} variant="h2" sx={{ fontWeight: 700 }}>
                {formField?.data?.goalName}
            </Typography>
            <HR />
            <FormField data={formField} isPdfForm />
            <ChartsSection>
                <Grid container spacing={1} marginBottom={tenantId === 'banorte' ? '5px' : '10px'}>
                    <Grid item xs={6} sm={6}>
                        <WealthPathContainer
                            showHeader
                            showLegends={false}
                            showSkeletonLoader
                            formField={formField?.data}
                            response={response?.data}
                            request={request?.data}
                            enableSourceCaveatNumbers={enableSourceCaveatNumbers}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <RecommendationContainer
                            showSkeletonLoader
                            request={request?.data}
                            response={response?.data}
                            formField={formField?.data}
                        />
                    </Grid>
                    {/* <Grid container spacing={1}>
                        
                    </Grid> */}
                    <Grid item xs={12} sm={12} marginTop={tenantId === 'banorte' ? '5px' : '10px'}>
                        <GlidePathContainer
                            showHeader
                            showLegends
                            showSkeletonLoader
                            portfolioData={portfolioData}
                            response={response?.data}
                            request={request?.data}
                            updateProfolioCompositionData={updateProfolioCompositionData}
                            disableDispatch
                            enableSourceCaveatNumbers={enableSourceCaveatNumbers}
                            chartHeight={tenantId === 'banorte' ? 435 : 450}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} marginTop={tenantId === 'banorte' ? '5px' : '10px'}>
                        <PortfolioCompositionContainer
                            showHeader
                            showLegends
                            showSkeletonLoader
                            portfolioDataForChart={portfolioDataForChart}
                            enableSourceCaveatNumbers={enableSourceCaveatNumbers}
                            chartHeight={tenantId === 'banorte' ? 435 : 450}
                        />
                        {tenantId === 'banorte' && (
                            <DescTypography data-testid="client-summary-modal-footer-text">
                                {t('BANORTE_CHART_UNDERNEATH_PDF_DISCLAIMER')}
                            </DescTypography>
                        )}
                    </Grid>
                </Grid>

                <DescTypography data-testid="client-summary-modal-footer-text">
                    {t('DESCRIPTION_FOOTER_ONE')}
                </DescTypography>

                {enableSourceCaveatNumbers && (
                    <DescTypography
                        data-testid="client-summary-modal-footer-super-script-1"
                        sx={{ margin: 0, padding: 0 }}
                    >
                        {t('ORIGIN_OF_DATA_POINT_1', {
                            asOfDate: moment(formField?.updatedAt).format('MM/DD/YYYY')
                        })}
                    </DescTypography>
                )}

                {enableSourceCaveatNumbers && externalData?.data && (
                    <DescTypography
                        data-testid="client-summary-modal-footer-super-script-2"
                        sx={{ margin: 0, padding: 0 }}
                    >
                        {t('ORIGIN_OF_DATA_POINT_2', {
                            asOfDate: moment(externalData?.data?.lastUpdatedAt).format('MM/DD/YYYY')
                        })}
                    </DescTypography>
                )}
            </ChartsSection>
        </Wrapper>
    );
};

export default SingleGoal;
