/* eslint-disable no-lone-blocks */
import { memo, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import GlidePathContainer from '../../containers/charts/glide-path';
import WealthPathContainer from '../../containers/charts/wealth-path';
import RecommendationContainer from '../../containers/charts/recommendation';
import PortfolioCompositionContainer from '../../containers/charts/portfolio-composition';
import { fetchPortfolio, updateShowAdvisorPortalButtonOnHeader } from '../../features/global/globalSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TabsContainer from '../../containers/edit-goal/tabs';
import FormContainer from '../../containers/edit-goal/form';
import ModalComponent from '../../components/molecules/modal';
import { CtaContainer, ModalContent } from '../../components/molecules/modal/styles';
import ButtonComponent from '../../components/atoms/button';
import {
    updateGoal,
    updateGoalEdited,
    updateProfolioCompositionData,
    updateClientSummary as editClientSummary,
    updateFormField as editFormField,
    updateRequest as editRequest,
    updateResponse as editResponse
} from '../../features/edit-goal';
import { updateClientSummary } from '../../features/client-summary';
import { GOAL_TYPE } from '../../constants';
import { calculateRequestPayloadForGenInc } from '../../components/molecules/investment-goals-forms/forms/generate-income-form';
import { calculateRequestPayloadForRetire } from '../../components/molecules/investment-goals-forms/forms/retirement-saving-form';
import { calculateRequestPayloadForFuture } from '../../components/molecules/investment-goals-forms/forms/future-event-form';
import { createGoalData, createWealthSplitterData, updateSplitDialog } from '../../features/add-client/add-client';
import { current_date } from '../../utils/forms';
import { updateClient } from '../../features/client-summary';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import { addEquityFixedIncomeRatio } from '../../services/configs/configs';
import {
    updateDialogState,
    updateSaveExitEdited,
    updatePdfEdited,
    toggleEditGoalWealthSplitterApplied
} from '../../features/global/globalSlice';
// import { calculateFormDataForGenerateIncome } from '../../utils';

const ChartsSection = styled('div')(() => ({
    marginTop: '40px'
}));

const LoaderContainer = styled('div')(() => ({
    margin: '20% 45%'
}));
export const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '36px',
    fontWeight: 200,
    lineHeight: '32px',
    letterSpacing: '0.02em',
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    margin: '36px 0 40px 0',
    display: 'inline-block'
}));

const CloseIconContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
        height: '40px',
        width: '40px',
        cursor: 'pointer'
    }
}));

const EditGoal = () => {
    const navigate = useNavigate();
    const { state: navigationState }: any = useLocation();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [clientData, setClientData] = useState<any>({});
    const [modalState, setModalState] = useState(false);
    const [formIds, setFormIds] = useState<any[]>([]);
    const [splitResponse, setSplitResponse] = useState<any[]>([]);
    const [formState, setFormState] = useState(false);

    const [blockUserNavigation, setBlockUserNavigation] = useState(false);

    const showDialog = useAppSelector((state) => state.global.showDialog);
    const portfolio = useAppSelector((state) => state.global.portfolio);
    const inflationRate = useAppSelector((state) => state.global.orgInfo?.inflationRate);
    const [showPrompt, confirmNavigation, cancelNavigation]: any = useCallbackPrompt(blockUserNavigation);

    const clientSummary = useAppSelector((state) => state.editGoal.clientSummary);

    const formField = useAppSelector((state) => state.editGoal.formField);
    const request = useAppSelector((state) => state.editGoal.request);
    const response = useAppSelector((state) => state.editGoal.response);
    const portfolioData = useAppSelector((state) => state?.global?.portfolio || []);
    const loader = useAppSelector((state) => state.addClient.loading);
    const saveLoader = useAppSelector((state) => state.editGoal.saveLoading);
    const portfolioComposistionChartData = useAppSelector((state) => state?.editGoal?.portfolioComposition || []);
    const activeTabIndex = useAppSelector((state) => state?.global?.activeTabIndex);
    const portfolioCompositionYearLabel = useAppSelector(
        (state) => state?.editGoal?.portfolioCompositionYearLabel || ''
    );
    const portfolioComposistionEquityValue = useAppSelector((state) => state?.editGoal?.equity || '');
    const portfolioComposistionFixedIncomeValue = useAppSelector((state) => state?.editGoal?.fixedIncome || '');
    const userEmail = useAppSelector((state) => state.auth?.user?.userEmail);

    useEffect(() => {
        dispatch(toggleEditGoalWealthSplitterApplied(false));
    }, []);

    useEffect(() => {
        dispatch(updateShowAdvisorPortalButtonOnHeader(false));

        if (isEmpty(portfolio)) {
            dispatch(fetchPortfolio());
        }
    }, [portfolio]);

    useEffect(() => {
        if (!isEmpty(clientSummary)) {
            setClientData(clientSummary);
        }
    }, [clientSummary]);

    const handleCloseIcon = () => {
        setBlockUserNavigation(false);
        if (showDialog) {
            setModalState(true);
        } else {
            setTimeout(() => navigate('/clients'), 500);
        }
    };

    const handleModalCloseAndNavigate = (handleNavigate: any) => {
        if (handleNavigate) {
            setModalState(false);
            navigate('/clients');
            dispatch(updateGoalEdited(false));
        } else {
            setBlockUserNavigation(true);
            setModalState(false);
        }
    };

    const saveGoalAndExit = async () => {
        setBlockUserNavigation(false);
        const formattedPayload = { ...clientSummary };
        formattedPayload.advisorEmail = userEmail;

        formattedPayload.formFields = addEquityFixedIncomeRatio(formattedPayload, portfolio);

        delete formattedPayload.id;
        delete formattedPayload.proposalDate;
        try {
            if (navigationState?.updateClientPayload) {
                await dispatch(updateClient(navigationState?.updateClientPayload));
            }

            const res = await dispatch(updateGoal(formattedPayload));

            if (res) {
                dispatch(updateDialogState(false));
                dispatch(updatePdfEdited(false));
                dispatch(updateSaveExitEdited(false));
                if (navigationState?.nextNavigationRoute) {
                    dispatch(updateClientSummary(formattedPayload));
                    navigate(navigationState?.nextNavigationRoute);
                } else {
                    dispatch(updateClientSummary(formattedPayload));
                    navigate('/clients');
                }
            }
        } catch (err) {
            setBlockUserNavigation(true);
        }
    };
    const splitWealth = () => {
        const formIds = clientSummary?.formFields
            .filter((formField: any) => formField.wealthSplit)
            .map((formField: any) => formField.goalId);

        setFormIds(formIds);

        const reqPayloadN = clientSummary?.request
            .filter((item: any) => formIds.includes(item.goalId))
            .map((item: any) => ({ ...item.data, goal_id: item.goalId }));

        let lumpSumN = 0;

        const goal_profile_list_N = reqPayloadN.map((item: any) => {
            lumpSumN += item.currentWealth;
            return {
                cashflowDate: item.cashflowDate,
                // riskProfile: clientSummary.riskProfile,
                endDate: item.endDate,
                goalPriority: item.goalPriority,
                curr_wealth: item.initialInvestment,
                cashflowType: item.infusionType,
                scenarioType: item.scenarioType,
                goal_id: item.goal_id,
                goalValue: item.goalAmount,
                cashflow: item.infusions,
                purpose: item.goalName
            };
        });

        const objN = {
            initial_lumpsum: lumpSumN,
            riskProfile: clientSummary?.riskTolerance,
            start_date: current_date,
            goal_profile_list: goal_profile_list_N
        };

        dispatch(createWealthSplitterData(objN)).then((response) => {
            if (response?.payload?.body?.goal_response_list) {
                const split_response = response?.payload?.body?.goal_response_list;
                setSplitResponse(split_response);
                //setSplitFormData(allFormData);
            }
        });
    };
    const applySplit = () => {
        const splitFormData = clientSummary?.formFields;
        formIds.forEach((id: any) => {
            const indexOfGoalInSplitFormData = splitFormData.findIndex((x: any) => x.goalId === id);
            const indexOfGoalInSplitResponse = splitResponse.findIndex((x) => x.Goal_ID == id);

            splitFormData[indexOfGoalInSplitFormData].data.initialInvestment = Math.round(
                splitResponse[indexOfGoalInSplitResponse]?.Wealth_Split
            );

            if (splitFormData[indexOfGoalInSplitFormData].data.goalType === GOAL_TYPE.GENERATE_INCOME) {
                const { reqPayloadObj } = calculateRequestPayloadForGenInc(
                    splitFormData[indexOfGoalInSplitFormData].data,
                    inflationRate || 0
                );
                remodelData(reqPayloadObj, indexOfGoalInSplitFormData, splitFormData[indexOfGoalInSplitFormData]);
            } else if (splitFormData[indexOfGoalInSplitFormData].data.goalType === GOAL_TYPE.RETIREMENT) {
                const { reqPayloadObj } = calculateRequestPayloadForRetire(
                    splitFormData[indexOfGoalInSplitFormData].data,
                    inflationRate || 0
                );
                remodelData(reqPayloadObj, indexOfGoalInSplitFormData, splitFormData[indexOfGoalInSplitFormData]);
            } else {
                const { reqPayloadObj } = calculateRequestPayloadForFuture(
                    splitFormData[indexOfGoalInSplitFormData].data,
                    inflationRate || 0
                );
                remodelData(reqPayloadObj, indexOfGoalInSplitFormData, splitFormData[indexOfGoalInSplitFormData]);
            }
        });
    };
    const remodelData = (requestPayload: any, id: any, formField: any) => {
        let requestObj: any = {};
        let responseObj: any = {};
        try {
            dispatch(createGoalData(requestPayload)).then((response) => {
                if (response.payload.body) {
                    requestObj = {
                        data: requestPayload,
                        id: clientData.request[id].id,
                        goalId: clientData.request[id].goalId
                    };
                    responseObj = {
                        id: clientData.response[id].id,
                        goalId: clientData.response[id].goalId,
                        data: response.payload.body
                    };
                    if (id === activeTabIndex) {
                        const cloneObj = { ...clientSummary };
                        cloneObj.formFields[id] = formField;
                        cloneObj.request[id] = requestObj;
                        cloneObj.response[id] = responseObj;
                        dispatch(editFormField(formField));
                        dispatch(editRequest(requestObj));
                        dispatch(editResponse(responseObj));
                        dispatch(editClientSummary(cloneObj));
                    } else {
                        const cloneObj = { ...clientSummary };
                        cloneObj.formFields[id] = formField;
                        cloneObj.request[id] = requestObj;
                        cloneObj.response[id] = responseObj;
                        dispatch(editClientSummary(cloneObj));
                    }
                    dispatch(updateGoalEdited(false));
                }
                dispatch(updateSplitDialog(false));
                dispatch(toggleEditGoalWealthSplitterApplied(true));
                setFormState(!formState);
            });
        } catch (err) {
            // Handle Error Here
            // console.error(err);
        }
    };
    return saveLoader ? (
        <LoaderContainer>
            <CircularProgress sx={{ height: '80px !important', width: '80px !important' }} />
        </LoaderContainer>
    ) : (
        <div data-testid="edit-goal">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <HeaderText tabIndex={0}>{t('HEADER_EDIT_GOAL')}</HeaderText>
                <CloseIconContainer>
                    <CloseIcon
                        role="button"
                        tabIndex={0}
                        aria-label="close-edit-plan-page"
                        onKeyPress={() => handleCloseIcon()}
                        onClick={() => handleCloseIcon()}
                        sx={{ color: 'common.black' }}
                    />
                </CloseIconContainer>
            </div>
            <TabsContainer
                clientSummary={clientData}
                saveGoalAndExit={saveGoalAndExit}
                portfolioData={portfolioData}
                updateProfolioCompositionData={updateProfolioCompositionData}
                portfolioComposistionChartData={portfolioComposistionChartData}
                portfolioComposistionEquityValue={portfolioComposistionEquityValue}
                portfolioComposistionFixedIncomeValue={portfolioComposistionFixedIncomeValue}
                portfolioCompositionYearLabel={portfolioCompositionYearLabel}
                splitWealth={splitWealth}
            />
            <FormContainer showLoader={loader} applySplit={applySplit} formState={formState} formdata={formField} />
            <ChartsSection>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <WealthPathContainer
                            showHeader={false}
                            showFooter
                            showLegends={false}
                            showLoader={loader}
                            showSkeletonLoader
                            request={request?.data}
                            response={response?.data}
                            formField={formField?.data}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <RecommendationContainer
                            showSkeletonLoader
                            showLoader={loader}
                            request={request?.data}
                            response={response?.data}
                            formField={formField?.data}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} marginTop={2}>
                        <GlidePathContainer
                            showHeader={false}
                            showFooter
                            showLegends={false}
                            showSkeletonLoader
                            showLoader={loader}
                            portfolioData={portfolioData}
                            request={request?.data}
                            response={response?.data}
                            updateProfolioCompositionData={updateProfolioCompositionData}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} marginTop={2}>
                        <PortfolioCompositionContainer
                            showHeader={false}
                            showFooter
                            showLegends={false}
                            showSkeletonLoader
                            showLoader={loader}
                            chartData={portfolioComposistionChartData}
                            equity={portfolioComposistionEquityValue}
                            fixedIncome={portfolioComposistionFixedIncomeValue}
                            label={portfolioCompositionYearLabel}
                        />
                    </Grid>
                </Grid>
            </ChartsSection>
            <ModalComponent
                open={modalState || showPrompt}
                handleClose={() => {
                    handleModalCloseAndNavigate(false);
                    setBlockUserNavigation(true);
                    cancelNavigation();
                }}
            >
                <ModalContent id="modal-modal-title" variant="body1">
                    {t('TEXT_MODAL_CONFIRMATION')}
                </ModalContent>
                <CtaContainer>
                    <ButtonComponent
                        variant="outlined"
                        size="medium"
                        onClick={() => {
                            handleModalCloseAndNavigate(false);
                            setBlockUserNavigation(true);
                            cancelNavigation();
                        }}
                    >
                        {t('TEXT_NO')}
                    </ButtonComponent>
                    <ButtonComponent
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            if (showPrompt) {
                                confirmNavigation();
                            } else {
                                handleModalCloseAndNavigate(true);
                            }
                        }}
                    >
                        {t('TEXT_YES')}
                    </ButtonComponent>
                </CtaContainer>
            </ModalComponent>
        </div>
    );
};

export default memo(EditGoal);
