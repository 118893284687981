import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function GlobalLoader({ open }: { open: boolean }) {
    return (
        <div>
            <Backdrop
                sx={{
                    color: 'primary.main',
                    backgroundColor: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={open}
            >
                <CircularProgress sx={{ width: '80px !important', height: '80px !important' }} />
            </Backdrop>
        </div>
    );
}
