/* eslint-disable indent */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../auth/authSlice';

export type FinancialAdvisor = {
    advisorFirstName: string;
    advisorLastName: string;
    advisorEmail: string;
    advisorPhoneNumber: string;
    numberOfClients: string;
    numberOfGoals: string;
};

type InitialState = {
    financialAdvisors: Array<FinancialAdvisor>;
    loading: boolean;
    advisorEmailId: string;
    error?: null | undefined | any;
    showDeleteModal: boolean;
    triggerdDelete: boolean;
};

const initialState: InitialState = {
    financialAdvisors: [],
    loading: false,
    advisorEmailId: '',
    error: null,
    showDeleteModal: false,
    triggerdDelete: false
};

const financialAdvisor = createSlice({
    name: 'financialAdvisorsState',
    initialState,
    reducers: {
        updateFinancialAdvisors: (state, action: PayloadAction<any>) => {
            state.financialAdvisors = action.payload;
        },
        updateDeleteModalState: (state, action) => {
            state.showDeleteModal = action.payload;
        },
        updateDeleteId: (state, action) => {
            state.advisorEmailId = action.payload;
        },
        updatedTriggeredDelete: (state, action) => {
            state.triggerdDelete = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState);
    }
});

export const { updateFinancialAdvisors, updateDeleteModalState, updateDeleteId, updatedTriggeredDelete } =
    financialAdvisor.actions;

export default financialAdvisor.reducer;
